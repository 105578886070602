import Vue from 'vue'
import axios from 'axios'
import endpoints from '@/config/endpoints'
import { cloneDeep } from 'lodash'

export const BASE_FLEX = {
  client_customer: {
    client_field: {
      api_name: '',
    },
    customer: {
      first_name: '',
      last_name: '',
      email: '',
    }
  },
  customers: [],
  metadata: {
    template_id: null,
    reward_algorithm_id: null,
    is_test: false,
    staff_user: null,
  },
  transaction: {},
  // backend will not accept nullable fields for this, so must remain undefined
  release_type: undefined, // possible options 'fx' flex 'as' auto-select 'au' auto-release
}

// to do
// separate BASE_FLEX for v1 and v2
/* Different payload format for multiple emails
  No `client_customer`, instead has `customers` and `api_name`
  {
    customers: [{
      email: 'test@laasie.ai',
      first_name: '',
      last_name: ''
    }, {
      email: 'test2@laasie.ai',
      first_name: '',
      last_name: ''
    }],
    api_name: '',
    flex_metadata: {
      template_id: null,
      reward_algorithm_id: null,
      is_test: false,
      staff_user: null,
    },
    release_type: undefined
  }
*/

export default {
  namespaced: true,
  state: {
    testEmailRecipients: '',
    customerEmails: '',
    flex: cloneDeep(BASE_FLEX),
  },
  getters: {
    GET_FLEX_PAYLOAD (state, _, rootState) {
      return () => {
        const customerEmails = state.customerEmails.split(',')
        const hasMultipleCustomerEmails = customerEmails.length > 1

        const payload = cloneDeep(state.flex)

        payload.metadata.staff_user = rootState.userProfile?.email

        if (hasMultipleCustomerEmails) {
          if (rootState.primaryHotel) payload.api_name = rootState.primaryHotel.hotelCode

          payload.flex_metadata = payload.metadata

          // payload format for multiple emails with customers not client_customer
          for (const customerEmail of customerEmails) {
            if (customerEmail) {
              payload.customers.push({
                email: customerEmail.trim(),
                first_name: '',
                last_name: ''
              })
            }
          }

          Vue.delete(payload, 'client_customer') // v2 uses customers
          Vue.delete(payload, 'metadata') // v2 uses flex_metadata
        } else {
          if (rootState.primaryHotel) payload.client_customer.client_field.api_name = rootState.primaryHotel.hotelCode
          // set email value in client_customer for single email
          payload.client_customer.customer.email = state.customerEmails
          Vue.delete(payload, 'customers')
        }

        return payload
      }
    },
    GET_TEST_FLEX_PAYLOAD (state, getters, rootState) {
      return () => {
        // use v2 flex payload format
        const payload = cloneDeep(state.flex)
        const testEmails = state.testEmailRecipients.split(',')

        payload.flex_metadata = payload.metadata
        payload.flex_metadata.staff_user = rootState.userProfile?.email
        payload.flex_metadata.is_test = true
        if (rootState.primaryHotel) payload.api_name = rootState.primaryHotel.hotelCode

        testEmails.map(email => {
          payload.customers.push({
            email: email.trim(),
            first_name: '',
            last_name: ''
          })
        })

        Vue.delete(payload, 'client_customer')
        Vue.delete(payload, 'metadata')

        return payload
      }
    },
    CUSTOMER_FIRST_NAME (state) {
      return state.flex.client_customer.customer.first_name
    },
    CUSTOMER_LAST_NAME (state) {
      return state.flex.client_customer.customer.last_name
    },
    CUSTOMER_EMAILS (state) {
      return state.customerEmails
    },
    EMAIL_DESIGN_ID (state) {
      return state.flex.metadata.template_id
    },
    ALGO_ID (state) {
      return state.flex.metadata.reward_algorithm_id
    },
    TEST_EMAIL_RECIPIENTS (state) {
      return state.testEmailRecipients
    },
    RELEASE_TYPE (state) {
      return state.flex.release_type
    },
  },
  mutations: {
    SET_TEST_EMAIL_RECIPIENTS (state, emails) {
      state.testEmailRecipients = emails
    },
    RESET_FLEX (state) {
      state.flex = cloneDeep(BASE_FLEX)
    },
    SET_CUSTOMER_FIRST_NAME (state, firstName) {
      Vue.set(state.flex.client_customer.customer, 'first_name', firstName)
    },
    SET_CUSTOMER_LAST_NAME (state, lastName) {
      Vue.set(state.flex.client_customer.customer, 'last_name', lastName)
    },
    SET_CUSTOMER_EMAILS (state, emails) {
      state.customerEmails = emails
    },
    SET_EMAIL_DESIGN_ID (state, emailDesignId) {
      Vue.set(state.flex.metadata, 'template_id', emailDesignId)
    },
    SET_ALGO_ID (state, algoId) {
      Vue.set(state.flex.metadata, 'reward_algorithm_id', algoId)
    },
    SET_RELEASE_TYPE (state, releaseType) {
      Vue.set(state.flex, 'release_type', releaseType)
    },
  },
  actions: {
    async SEND_FLEX_EMAIL ({ dispatch, getters }) {
      const flexPayload = getters.GET_FLEX_PAYLOAD()
      await dispatch('POST_CREATE_FLEX', { flexPayload, showSuccess: true, multipleEmails: false })
    },
    async SEND_TEST_FLEX_EMAIL ({ dispatch, getters }) {
      const flexPayload = getters.GET_TEST_FLEX_PAYLOAD()
      // use v2 flex route

      const response = await dispatch('POST_CREATE_FLEX', { flexPayload, showSuccess: false, multipleEmails: true })

      if (!response.error) {
        dispatch(
          'messages/ADD_SUCCESS',
          { message: 'Test email(s) sent!' },
          { root: true }
        )
      }
    },
    async SEND_FLEX_EMAILS ({ dispatch, getters }) {
      const flexPayload = getters.GET_FLEX_PAYLOAD()
      await dispatch('POST_CREATE_FLEX', { flexPayload, showSuccess: true, multipleEmails: true })
    },
    async POST_CREATE_FLEX ({ dispatch }, { flexPayload, showSuccess, multipleEmails }) {
      const endpoint = multipleEmails ? endpoints.valley.flexRoute2 : endpoints.valley.flexRoute
      console.log('[POST_CREATE_FLEX] endpoint', endpoint)
      console.log('[POST_CREATE_FLEX] payload', flexPayload)
      const toEmails = multipleEmails
        ? flexPayload.customers.map(customer => customer.email).join(', ')
        : flexPayload.client_customer.customer.email

      let response
      let error
      try {
        response = await axios.post(endpoint, flexPayload)
      } catch (err) {
        error = err.response.data

        dispatch(
          'messages/ADD_ERROR',
          { message: `There was an error sending to ${toEmails}. Please try again or contact your CSM.` },
          { root: true }
        )
      }
      console.log('[CREATE_FLEX]', response || error)

      if (!error && showSuccess) {
        dispatch(
          'messages/ADD_SUCCESS',
          { message: `Flex Reward Email was sent to ${toEmails}` },
          { root: true }
        )
      }

      return {
        error,
        data: response && response.data
      }
    },
  }
}
