






































































































































// import Vue from 'vue'
import Button from '@/components/button/Button.vue'
import SvgClose from '@/components/icons/SvgClose.vue'

export interface StyleConfig {
  width: string | undefined;
  buttons: Record<'width', string>;
}

export interface CopyConfig {
  title: string | undefined;
  body: string | undefined;
  cancel: string | undefined;
  submit: string | undefined;
}

export type ButtonType = 'primary' | 'secondary'
export type ModalEvent = 'submit' | 'cancel' | 'escape'

export interface ButtonConfig {
  copy: string;
  click: () => void;
  type: ButtonType;
}

export interface ButtonConfigs {
  left: ButtonConfig;
  right: ButtonConfig;
}

export default {
  name: 'Modal',
  directives: {
    // TODO investigate if this is working as expeected
    focus: {
      inserted (el) {
        el.focus()
      },
      // TODO remove
      // async update (el, binding) {
      //   if (binding.value) {
      //     await Vue.nextTick()
      //     el.focus()
      //   }
      // }
    }
  },
  components: {
    Button,
    SvgClose,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    closeOnClick: {
      type: Boolean,
      default: false
    },
    closeOnSubmit: {
      type: Boolean,
      default: true
    },
    destructive: {
      type: Boolean,
      default: false,
    },
    modalStyle: {
      type: Object as () => StyleConfig,
      required: false,
      default: () => ({}),
    },
    copy: {
      type: Object as () => CopyConfig,
      required: false,
      default: () => ({}),
    },
  },
  data () {
    return {
      closeButtonWidth: 10,
      fill: '#000000',
      showContent: false,
      outgoingEvent: undefined as ModalEvent | undefined,
      defaultStyles: {
        minWidth: '0px',
        width: '600px',
      }
    }
  },
  computed: {
    showSlots (): boolean {
      // if the default slot is present, do not show all other slots
      return !this.$slots.default
    },
    minWidth (): string {
      return this.modalStyle?.minWidth || this.defaultStyles.minWidth
    },
    width (): string {
      return this.modalStyle?.width || this.defaultStyles.width
    },
    buttonConfigs (): ButtonConfigs {
      return {
        left: {
          copy: this.copy.cancel,
          click: this.cancel,
          type: this.destructive ? 'primary' : 'secondary',
        },
        right: {
          copy: this.copy.submit,
          click: this.submit,
          type: this.destructive ? 'secondary' : 'primary',
        },
      }
    },
  },
  watch: {
    show (value) {
      if (value === true) {
        this.showContent = true
      }
    }
  },
  mounted (): void {
    this.setupEscListener()
    this.setOutgoingEvent(null)
  },
  destroyed (): void {
    this.removeEscListener()
    this.setOutgoingEvent(null)
  },
  methods: {
    handleBackdropClick (): void {
      if (this.closeOnClick) {
        this.cancel()
      }
    },
    hideContent () {
      this.showContent = false
    },
    cancel (): void {
      this.setOutgoingEvent('cancel')
      this.hideContent()
    },
    submit (): void {
      this.setOutgoingEvent('submit')

      if (this.closeOnSubmit) {
        this.hideContent()
      }
    },
    setOutgoingEvent (value: undefined | null | ModalEvent): void {
      this.outgoingEvent = value
    },
    emitEvent (): void {
      console.log('modal event', this.outgoingEvent)
      this.$emit(this.outgoingEvent)
      this.setOutgoingEvent(null)
    },
    handleEsc (event: KeyboardEvent): void {
      if (this.show && event.keyCode === 27) {
        this.cancel()
      }
    },
    setupEscListener (): void {
      document.addEventListener('keydown', this.handleEsc)
    },
    removeEscListener (): void {
      document.removeEventListener('keydown', this.handleEsc)
    },
  }
}
