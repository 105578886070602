






















































































import { MenuLink } from '@/types'
import { getDisabled } from '@/utils/getDisabled'

export default {
  name: 'MenuItem',
  props: {
    link: {
      type: Object,
      required: true,
      default: () => ({
        copy: '',
        routeName: '',
      })
    },
  },
  data () {
    return {
      active: false,
      icons: {
        dropdown: ['fas', 'angle-down'],
        lock: ['fas', 'lock'],
      }
    }
  },
  computed: {
    show (): boolean {
      if (typeof this.link.show === 'function') {
        return this.link.show()
      }
      return true
    },
    menuWidth (): string | void {
      if (typeof this.link.style?.width === 'number') {
        return `${this.link.style.width}px`
      }
      return null
    },
    showDropdown (): boolean {
      return !this.showRouterLink(this.link) && this.active
    },
    isChildActive (): boolean {
      if (Array.isArray(this.link.children)) {
        return Boolean(
          this.link.children.find(link => this.getActive(link))
        )
      }
      return false
    }
  },
  methods: {
    showRouterLink (link: MenuLink): boolean {
      const { children } = link
      const hasChildren = Array.isArray(children) && children.length > 0
      return !hasChildren
    },
    getActive (link: MenuLink): boolean {
      return this.$route.name === link.routeName
    },
    getDisabled
  },
}
