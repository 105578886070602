export function getRangeOptions (min = 0, max = 0): Array<{ label: string; value: number }> {
  let i = min
  const options = []

  while (i <= max) {
    options.push({
      label: i + '',
      value: i
    })
    i++
  }

  return options
}

export function findOptionsMatch (e): boolean {
  let el = e.target
  let counter = 0
  const maxDepth = 10
  while (el && el !== document.body && counter < maxDepth) {
    if ([
      'dropdown',
      'dropdown-option',
      'dropdown-list-item',
      'options-container'
    ].indexOf(el.className) > -1) {
      return true
    }
    el = el.parentNode
    counter++
  }
  return false
}
