<template>
  <div>
    <div>{{ copy.body }}</div>
    <md-switch v-model="mabEnabled"> {{ mabStatusCopy }} </md-switch>
  </div>
</template>

<script>

export default {
  name: 'MabEnable',
  data () {
    return {
      copy: {
        body: 'Set the MAB of ALL funnel UX\'s in this design set upon duplication',
        mabDisable: 'MAB Disable All',
        mabEnable: 'MAB Enable All'
      }
    }
  },
  computed: {
    mabStatusCopy () {
      return this.$store.state.designDuplication.design.mab_enabled ? this.copy.mabEnable : this.copy.mabDisable
    },
    mabEnabled: {
      get () {
        return this.$store.state.designDuplication.design.mab_enabled
      },
      set () {
        return this.$store.commit('designDuplication/UPDATE_MAB_ENABLED',
          !this.$store.state.designDuplication.design.mab_enabled
        )
      }
    }
  }
}
</script>
