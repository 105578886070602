<template>
  <div id="merchants-dropdown">
    <FilteredDropdown
      :selectedItem="merchantInView"
      :items="merchantsList"
      @selected="handleSelected"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { merchantViews } from '@/constants'
import FilteredDropdown from '@/components/dropdown/FilteredDropdown'

export default {
  name: 'MerchantsDropdown',
  components: {
    FilteredDropdown,
  },
  computed: {
    ...mapGetters('merchant', [
      'merchantsList',
      'merchantInView',
    ]),
    ...mapState('merchant', [
      'merchantId',
    ]),
  },
  methods: {
    ...mapActions('merchant', [
      'SET_MERCHANT_ID'
    ]),
    handleSelected (merchant) {
      this.SET_MERCHANT_ID({ id: merchant.id })
      // if user toggles dropdown while viewing wizard, push them to promotions
      const redirectViews = [
        merchantViews.MERCHANT_PROMOTIONS_WIZARD_NEW,
        merchantViews.MERCHANT_PROMOTIONS_WIZARD_EDIT,
      ]
      const routeName = redirectViews.includes(this.$route.name)
        ? merchantViews.MERCHANT_PROMOTIONS
        : this.$route.name
      this.$router.push({
        name: routeName,
        params: { id: this.$store.state.merchant.merchantId }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
