












































































import Checkbox from '@/components/checkbox/Checkbox.vue'
import { isPlainObject, isString } from 'lodash'
import { computedWidth } from '@/utils/computedWidth'
import { isOnHorizontalViewportEdge, isOnVerticalViewportEdge } from '@/utils/isOnViewportEdge'
import { OptsStats } from '@/types'

/**
 * if component is over edge of viewport it will emit `edge`
 * event that can used by parent component.
 */

export type EventType = 'select' | 'remove'
export type Option = string | number | Record<string, string | number | boolean >

export default {
  name: 'DropdownOptions',
  components: {
    Checkbox
  },
  inject: [
    'Dashboard_getContentWidth',
    'injected__getParentRect'
  ],
  props: {
    maxHeight: {
      type: Number,
      required: false,
      default: 300
    },
    allSelected: {
      type: Boolean,
      default: false,
      required: false,
    },
    checkbox: {
      type: Boolean,
      default: false,
      required: false
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false
    },
    hideSelected: {
      type: Boolean,
      default: false,
      required: false
    },
    // can use custom key for rendering text in each option el
    labelKey: {
      type: String,
      required: false,
      default: 'label'
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
      validator (value) {
        if (Array.isArray(value) && value.length === 0) {
          return true
        }
        return value.every(option => isPlainObject(option))
      }
    },
    classes: {
      type: Array,
      default: () => [],
      validator (value) {
        return value.every(option => isString(option))
      }
    },
    optionsClasses: {
      type: Array,
      required: false,
      validator (value): boolean {
        return value.every(item => typeof item === 'string')
      },
      default: () => ([])
    },
    optionsStyles: {
      type: Object,
      default: () => ({}),
      required: false
    },
    width: {
      type: [String, Number],
      default: 'auto',
      required: false,
      validator (value) {
        if (typeof value === 'number') {
          return value > 0
        }
        return ['auto'].includes(value)
      }
    },
    zIndex: {
      type: String,
      default: 'z-index: 99999 !important',
      required: false,
      validator (value) {
        if (typeof value !== 'string') return false
        return value.includes(' !important')
      }
    },
    show: {
      type: Boolean,
      default: true,
      required: false,
    },
    isSelected: {
      type: Function,
      required: false,
      default: () => false
    }
  },
  data () {
    return {
      showOptions: false,
      copy: {
        allSelected: 'No options'
      },
      refLabels: {
        option: 'option'
      }
    }
  },
  computed: {
    showEmptyState (): boolean {
      return this.options.length === 0
    },
    showEmptyOptions (): boolean {
      return this.hideSelected && this.allSelected && !this.showEmptyState
    },
    estimatedHeight (): number {
      const optionHeight = 48
      const yAxisPadding = 16
      return (this.options.length * optionHeight) + yAxisPadding
    },
    height (): number {
      if (this.estimatedHeight < this.maxHeight) {
        return this.estimatedHeight
      }
      return this.maxHeight
    }
  },
  watch: {
    async show (newValue) {
      if (newValue === true) {
        /**
         * Used in parent to calculate new x/y coordinates depending on whether or not
         * options are about to cross horizontal / vertical viewport edge
         * @event ready
         */
        const invertVertically = await this.isOnVerticalViewportEdge()
        const invertHorizontally = await this.isOnHorizontalViewportEdge()
        const event: OptsStats = {
          height: this.height,
          invertVertically,
          invertHorizontally,
        }
        this.$emit('ready', event)
        this.showOptions = true
      }
    },
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('wheel', this.handleScroll)
    window.addEventListener('resize', this.handleScroll)
    this.showOptions = this.show
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('wheel', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
  },
  methods: {
    computedWidth,
    /**
     * remove focus+style on option if hovering over another. prevents
     * focus & hover styles from making it look like there are 2 elements in focus.
     */
    handleMouseover (): void {
      const active = document.activeElement as HTMLElement | void
      if (active) active.blur()
    },
    handleClick (option: Option, idx: number): void {
      if (typeof option === 'object' && option.disabled) return
      this.isSelected(option) ? this.remove(option, idx) : this.select(option)
    },
    select (option: Option): void {
      /**
       * Selected event emitted when option is selected.
       * @event select
       * @property {object} option the option object
       */
      this.$emit('selected', option)
    },
    remove (option: Option, idx: number): void {
      /**
       * event emitted when user clicks on option to remove it from selected.
       * @event remove
       * @property {object} option the option object
       */
      this.$emit('remove', option)
      this.blur(idx)
    },
    getRef (idx: number): HTMLElement | undefined {
      return this.$refs[this.refLabels.option][idx]
    },
    blur (idx: number): void {
      const item = this.getRef(idx)
      if (item) item.blur()
    },
    focus (idx: number): void {
      const item = this.getRef(idx)
      if (item) item.focus()
    },
    focusPrev (idx): void {
      this.focus(idx - 1)
    },
    focusNext (idx): void {
      this.focus(idx + 1)
    },
    getLabel (opt): string {
      return opt[this.labelKey] || ''
    },
    async isOnVerticalViewportEdge (): Promise<boolean> {
      return isOnVerticalViewportEdge({
        parentContainer: this.injected__getParentRect(),
        elementHeight: this.height,
        contentHeight: window.innerHeight,
      })
    },
    async isOnHorizontalViewportEdge (): Promise<boolean> {
      const options = this.$refs[this.refLabels.option]
      if (options?.length) {
        await this.$nextTick()
        const first = options[0]
        const rect = first.getBoundingClientRect()
        // TODO investigate removing getContentWidth and replacing w/ window.innerWidth
        return isOnHorizontalViewportEdge(rect, this.Dashboard_getContentWidth())
      }
      return false
    },
    showOption (option: Option): boolean {
      if (this.hideSelected) return !this.isSelected(option)
      return true
    },
    handleScroll ($event): void {
      this.$emit('scrolled', $event)
    }
  },
}
