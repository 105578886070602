<template>
  <div id="preview-wrapper">

    <div class="preview-info-header">
      <div class="md-body-2 laas-bold laas-section-title">
        {{ copy.title }}
      </div>
      <div class="laas-oprm-body-text-color">
        {{ copy.body }}
      </div>
    </div>

      <md-card class="preview-content">
        <div class="preview-content-inner">
          <md-card-header>
            <div v-if="!showPlaceholderIcon">
              <img
                class="reward-thumbnail md-image"
                :src="IMAGE_PREVIEW"
                alt="image-preview"
              />
            </div>
            <div v-if="showPlaceholderIcon" id="preview-icon-tile">
              <div id="preview-icon-content">
                <md-icon>image</md-icon>
              </div>
            </div>

            <md-card-header-text>
              <div class="md-body-2 laas-bold">
                {{ nameCopy }}
              </div>
              <div class="md-body-1 laas-oprm-body-text-color">
                {{ descriptionCopy }}
              </div>
            </md-card-header-text>
          </md-card-header>

          <md-card-content>
            <div class="md-body-2 laas-bold">
              {{ copy.instructions }}
            </div>
            <div class="md-body-1">
              <div v-if="!editingInstructions"> {{ copy.placeholder.redemptionInstructions }} </div>
              <div v-if="editingInstructions" v-html="reward.redemptionInstructions"></div>
            </div>
            <div class="laas-caption">
              <div v-if="!editingTerms"> {{ copy.placeholder.redemptionTerms }} </div>
              <div v-if="editingTerms" v-html="reward.termsAndConditions"></div>
            </div>
          </md-card-content>
        </div>
      </md-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'RedemptionPreview',
  data () {
    return {
      copy: {
        title: 'Reward Redemption Preview',
        instructions: 'Instructions',
        body: 'This is what the reward will look like in the guest\'s email when it is redeemable',
        placeholder: {
          redemptionTerms: 'Enter your terms here.',
          redemptionInstructions: 'Enter your instructions here.',
          name: 'Your Business Name',
          description: 'Your Reward Description'
        },
      }
    }
  },
  computed: {
    ...mapState('oprm', ['reward']),
    ...mapGetters('oprm', ['IMAGE_PREVIEW']),
    showPlaceholderIcon () {
      return !this.IMAGE_PREVIEW
    },
    description () {
      return this.reward.creativeConfigs[0].thumbnailText
    },
    nameCopy () {
      if (!this.reward.merchant) return this.copy.placeholder.name
      return this.reward.merchant.name
    },
    descriptionCopy () {
      return this.description || this.copy.placeholder.description
    },
    editingInstructions () {
      return this.reward.redemptionInstructions !== ''
    },
    editingTerms () {
      return this.reward.termsAndConditions !== ''
    }
  },
}
</script>

<style lang="sass">
#preview-wrapper
  .md-card
    width: 370px
    box-shadow: none
    background-color: #F8F8F8
    border-radius: 3px

    .md-card-header-text
      margin-top: 10px

  .preview-info-header
    margin-left: 18px

  .reward-thumbnail
    object-fit: cover
    width: 100px
    height: 100px
    margin-right: 20px

  #preview-icon-content
    width: 100px
    height: 100px
    line-height: 100px
    text-align: center
    margin-right: 20px
    background-color: rgb(192, 192, 192)

  .preview-info-header
    margin-top: 20px
    max-width: 370px

  .preview-content
    padding: 20px
    margin-top: 20px

  .preview-content-inner
    border: 1px solid #979797
    border-radius: 3px
    width: 330px
    background-color: #FFFFFF
</style>
