<template>
  <div class="md-body-1">
    <div class="body-copy">
      {{ copy.body }}
    </div>
    <div class="button-container">
      <md-button
        class="toggle-button"
        :class="{ 'list-active-button': propertyListActive }"
        @click="showPropertyList"
        :disabled="hotelButtonDisabled"
      >
        {{ copy.propertyButton }}
      </md-button>
      <md-button
        class="toggle-button"
        :class="{ 'list-active-button': !propertyListActive }"
        @click="showGroupList"
        :disabled="groupButtonDisabled"
      >
        {{ copy.groupButton }}
      </md-button>
    </div>

    <div v-if="propertyListActive">
      <PropertySearchComponent
        :searchData="hotels"
        :propertySearch="propertyListActive"
      />
    </div>

    <div v-if="!propertyListActive">
      <PropertySearchComponent
        :searchData="groups"
        :propertySearch="propertyListActive"
      />
    </div>
  </div>
</template>

<script>
import PropertySearchComponent from '@/components/hotel-dashboard/shared/PropertySearchComponent'
import { mapState } from 'vuex'

export default {
  name: 'DestinationDuplication',
  components: {
    PropertySearchComponent
  },
  data () {
    return {
      groups: {},
      hotels: {},
      propertyListActive: true,
      copy: {
        body: 'Select from list of hotel properties or groups. You may only select from one list.',
        propertyButton: 'Select Properties',
        groupButton: 'Select Groups',
        selectAll: 'Select All',
        noResults: 'Sorry, we couldn\'t find a hotel with that name.',
        selectedProperties: 'Selected Properties',
      }
    }
  },
  computed: {
    ...mapState('designDuplication', [
      'design'
    ]),
    hotelButtonDisabled () {
      return this.design.hotel_ids?.length > 0 || this.design.hotel_group_ids?.length > 0
    },
    groupButtonDisabled () {
      if (this.design.hotel_ids?.length === 0 && this.design.hotel_group_ids?.length === 0) {
        return false
      }
      return this.design.hotel_ids?.length > 0
    }
  },
  methods: {
    showPropertyList () {
      this.propertyListActive = true
    },
    showGroupList () {
      this.propertyListActive = false
    }
  },
}
</script>

<style scoped lang="sass">
.button-container
  margin-top: 8px
  justify-content: flex-start
  .md-button-content
    text-transform: none

.body-copy
  width: 700px

.toggle-button
  background-color: #E9E9E9
  color: black
  border-radius: 20px
  width: 150px
  height: 40px

.button-container
  .list-active-button
    color: #fff
    background-color: black

    &[disabled]
      background-color: #E9E9E9

    .md-button-content
      color: white
      font-size: 15px

.list-active-button:first-child
    margin-left: 0
</style>
