<template>
  <div
    id="laas-header"
    class="flex flex-row justify-between w-full"
  >
    <div class="flex-grow-1 flex flex-col justify-center pl-1 pr-2">
      <div class="text-xl font-bold">
        {{ copy.title }}
      </div>
      <div
        v-if="copy.subtitle"
        class="text-sm pt-2"
      >
        {{ copy.subtitle }}
      </div>
    </div>
    <div
      id="btn-wrapper"
      class="flex flex-col justify-center pr-1"
      :style="styles"
    >
      <slot name="button" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    styles: {
      type: Object,
      required: false,
      default: () => ({})
    },
    copy: {
      type: Object,
      required: true,
      default: () => ({
        title: '',
        subtitle: ''
      })
    }
  }
}
</script>

<style lang="sass" scoped>
#laas-header
  padding: 21px 16px 16px 24px
</style>
