

































import { mapState } from 'vuex'
import { Workflow, RegisteredNode, WorkflowMap } from '@/types'
import NodeTree from '@/components/hotel-dashboard/workflow-manager/dag/NodeTree.vue'
import Node from '@/components/hotel-dashboard/workflow-manager/dag/Node.vue'
import AddWithArrow from '@/components/icons/AddWithArrow.vue'

export type Args = { parent: number; children: number[] }

export type Provide = {
  DagContainer__pushNode: (node: RegisteredNode) => void;
  DagContainer__add: (args: Args) => void;
  DagContainer__delete: ({ node: Workflow }) => void;
  DagContainer__edit: ({ id: number }) => void;
}

const ECM = 'ecm'
const ADD = 'add'
const DELETE = 'delete'
const EDIT = 'edit'

export default {
  name: 'DagContainer',
  components: {
    NodeTree,
    Node,
    AddWithArrow,
  },
  provide (): Provide {
    return {
      DagContainer__pushNode: this.pushNode,
      DagContainer__add: this.add,
      DagContainer__delete: this.delete,
      DagContainer__edit: this.edit,
    }
  },
  data () {
    return {
      nodesList: [] as RegisteredNode[],
      isMounted: false,
      copy: {
        placeholder: {
          title: 'Trigger',
          body: 'Define your trigger'
        }
      },
      iconStyle: {
        color: this.$theme.colors.gray['200'],
        hoverColor: this.$theme.colors.gray['400'],
        width: 8, // important: consult circle-icon-wrapper class below when changing
        fill: '#FFFFFF',
        strokeWidth: '2'
      },
    }
  },
  computed: {
    ...mapState(ECM, [
      'dag'
    ]),
    first (): Workflow | void {
      return Object.values((this.dag as WorkflowMap))
        .find((node: Workflow) => !node.parent)
    },
    show (): boolean {
      return Boolean(this.first)
    },
  },
  mounted (): void {
    this.isMounted = true
  },
  methods: {
    pushNode (node: RegisteredNode): void {
      this.nodesList.push(node)
    },
    add ({ parent, children }: Args): void {
      this.$emit(ADD, { parent, children })
    },
    delete ({ node }: { node: Workflow }): void {
      this.$emit(DELETE, { node })
    },
    edit ({ id }: { id: number }): void {
      this.$emit(EDIT, { id })
    }
  }
}
