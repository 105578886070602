<template>
  <div
    id="menu-container"
    :style="{ height: `${menuStyles.height}px` }"
    class="bg-white flex-none flex flex-row w-full drop-shadow z-50"
  >
    <div id="branding-items-container" class="flex flex-row flex-none">
      <div
        v-show="this.$store.getters.IS_ADMIN"
        @click="toggleShowAdminMenu"
        @mouseleave="hideAdminMenu"
        class="vertically-center"
      >
        <div
          id="icon-container"
          class="border-radius-6 vertically-center flex-none ml-2 mr-0 my-0 hover-glow"
        >
          <font-awesome-icon :icon="icons.grid" />
          <div
            v-show="showAdminMenu"
            id="admin-menu"
            class="menu"
          >
            <ul class="dropdown-option-container pb-2 h-auto">
              <li class="dropdown-option-header-container rounded-sm">
                <div class="dropdown-option-header">
                  {{ copy.current }}
                </div>
                <div>
                  {{ navConfig.currentApp }}
                </div>
              </li>
              <li class="pt-1 px-4">
                <div class="dropdown-option-header">
                  {{ copy.switch }}
                </div>
              </li>
              <li
                v-for="link in navConfig.adminToggles" :key="link.copy"
                class="dropdown-option-2"
                @click="navFromMenuLink(link.routeName)"
              >
                {{ link.copy }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        id="admin-icon-placeholder"
        class="w-2"
        v-show="!this.$store.getters.IS_ADMIN"
      />
      <div id="branding-container" class="flex flex-col">
        <img id="branding-logo" src="../../assets/img/laasie_logo_2020__dark.svg">
      </div>
    </div>

    <div id="menu-items-container" class="flex flex-row items-center flex-1 ma-0 pa-0">
      <MenuItem
        v-for="link in navConfig.topMenuLinks"
        :link="link"
        :key="link.copy"
      />
    </div>

    <div id="logout-container" class="flex-0 flex flex-row items-center pr-6">
      <div class="menu-item">
        <span class="copy-container font-normal">
          <router-link :to="{ name: 'logout' }">
            {{ copy.logout }}
          </router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from '@/components/navigation/MenuItem'
import { menuStyles } from '@/constants'

export default {
  name: 'Menu',
  components: {
    MenuItem,
  },
  props: {
    navConfig: {
      type: Object,
      required: true,
      default: () => ({
        topMenuLinks: [],
        links: [],
        adminToggles: [],
      }),
    },
  },
  data () {
    return {
      menuStyles,
      showAdminMenu: false,
      copy: {
        current: 'Current App',
        switch: 'Switch to',
        logout: 'Logout'
      },
      icons: {
        grid: ['fas', 'th'],
      },
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('LOGOUT_USER')
    },
    revealAdminMenu () {
      this.showAdminMenu = true
    },
    hideAdminMenu () {
      this.showAdminMenu = false
    },
    toggleShowAdminMenu () {
      this.showAdminMenu = !this.showAdminMenu
    },
    navFromMenuLink (routeName) {
      return this.$router.push(
        {
          name: routeName,
          params: this.$route.params,
        }
      )
    }
  }
}
</script>

<style lang="sass" scoped>
@import menu-styles

#icon-container
  cursor: pointer
  padding: 0px 10px
  height: 34px

.admin-icon
  color: #707070

#branding-container
  margin-top: 2px
  padding: 0 30px 0 8px
  @apply vertically-center

.dropdown-option-header-container
  @apply px-4
  @apply pt-1
  @apply pb-2
  background-color: #f6f6f6
  height: 53px

.dropdown-option-header
  font-size: 11px
  color: #404040

#branding-logo
  height: 25px
  width: auto
</style>
