<template lang="html">
  <div id="account-container" class="md-layout-item bg-white pb-10">
    <div class="md-content">
      <div class="md-headline">
        {{ copy.welcomeHeadline }}
      </div>
      <div class="md-subheading">
        {{ copy.welcomeSubheader }}
      </div>
      <br/>

      <div class="md-headline">
        {{ copy.readyHeadline }}
      </div>
      <div class="md-subheading">
        {{ copy.readySubheader }}
      </div>
      <br/>
      <form @submit.prevent="formComplete">
        <div id="merchant-account-form" class="md-layout">
          <div class="md-layout-item md-size-60">
            <md-field id="merchant-account-form-name">
              <label>{{ copy.nameInputLabel }}</label>
              <md-input
                v-model="selectedName"
                id="merchant-account-form-name-input"
              />
            </md-field>

            <md-field>
              <label for="bookingEngine">{{ copy.bookingEngineInputLabel }}</label>
              <md-select
                v-model="selectedBookingEngine"
                name="bookingEngine"
                id="merchant-account-form-booking-engine-dropdown"
              >
                <md-option v-for="be in bookingEngineNames"
                           :value="be"
                           :key="be">
                  {{ be }}
                </md-option>
              </md-select>
            </md-field>

            <md-field v-if="showCustomBookingEngineField">
              <label for="customBookingEngine">
                {{ copy.bookingEngineCustomLabel }}
              </label>
              <md-input
                v-model="selectedBookingEngineCustomProvider"
                id="merchant-account-form-custom-booking-engine-input"
              />
            </md-field>

            <md-field id="merchant-account-form-image-file-field">
              <label>{{ copy.imageInputLabel }}</label>
              <md-file
                id="merchant-account-form-image-file-field"
                @md-change="onFileChange"
                accept="image/*"
                placeholder="83x83 recommended"
              />
            </md-field>
          </div>
          <div id="logo-preview" class="md-layout-item md-size-40">
            <div class="md-layout md-alignment-center-center">
              <div class="md-caption">
                {{ copy.logoPreviewTitle }}
              </div>
            </div>
            <div class="md-layout md-alignment-center-center">
              <img
                :src="selectedImageUrl"
                class="thumbnail-image"
              >
            </div>
          </div>
        </div>
        <div id="account-btn-container" class="md-layout md-alignment-center-right">
          <button
            id="account-done-btn"
            class="gold-button"
            type="submit"
          >
            {{ copyDoneButton }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { merchantViews } from '@/constants'

const OTHER = 'Other'

export default {
  name: 'AccountContainer',
  props: {
    initFormInput: {
      type: Object,
      required: true,
      default: () => ({
        id: undefined,
        name: undefined,
        bookingEngine: undefined,
        image: undefined,
      })
    },
  },
  data () {
    return {
      selectedName: this.initFormInput.name,
      selectedBookingEngine: this.initFormInput.bookingEngine,
      selectedBookingEngineCustomProvider: this.initFormInput.bookingEngineCustomProvider,
      selectedImage: undefined, // replaced by image file on upload
      selectedImageUrl: this.initFormInput.image, // replaced by temporary image url on upload
      // constants
      copy: {
        welcomeHeadline: 'Welcome to Laasie Promotions!',
        welcomeSubheader: `As a Laasie merchant partner you can grow your business by providing exclusive discounts to Laasie's traveler network.
        Getting started is easy - finish setting up your account below, then you're all set to create your first promotion.`,
        readyHeadline: 'Ready to get started?',
        readySubheader: 'Add your business information below to finish setting up your account.',
        logoPreviewTitle: 'Business Logo Preview',
        doneButtonSetup: 'Set Up Account',
        doneButtonUpdate: 'Update Account',
        nameInputLabel: 'Business Name',
        imageInputLabel: 'Business Logo',
        bookingEngineInputLabel: 'Booking Engine Provider',
        bookingEngineCustomLabel: 'Booking Engine Provider\'s Name',
      }
    }
  },
  computed: {
    ...mapGetters('merchant', [
      'bookingEngineNames',
      'merchantInView',
      'isInitialAccountSetup',
    ]),
    copyDoneButton () {
      return this.$route.name === merchantViews.MERCHANT_ACCOUNT_NEW
        ? this.copy.doneButtonSetup
        : this.copy.doneButtonUpdate
    },
    showCustomBookingEngineField () {
      return this.selectedBookingEngine === OTHER
    },
  },
  methods: {
    onFileChange (files) {
      const imageFile = files[0]
      console.log('[onFileChange] imageFile', imageFile)
      if (imageFile) {
        this.selectedImage = imageFile
        this.selectedImageUrl = URL.createObjectURL(imageFile)
      }
    },
    async formComplete () {
      // must be determined before dispatch as getter will update
      const nextRouteName = this.isInitialAccountSetup
        ? merchantViews.MERCHANT_PROMOTIONS_WIZARD_NEW
        : merchantViews.MERCHANT_PROMOTIONS

      if (this.initFormInput.bookingEngine === OTHER &&
        this.selectedBookingEngine !== OTHER) {
        this.selectedBookingEngineCustomProvider = ''
      }

      await this.$store.dispatch('merchant/CREATE_OR_UPDATE_MERCHANT', {
        merchant: {
          id: this.initFormInput.id,
          name: this.selectedName,
          bookingEngine: this.selectedBookingEngine,
          bookingEngineCustomProvider: this.selectedBookingEngineCustomProvider,
          image: this.selectedImage,
        },
        nextRoute: {
          name: nextRouteName
        },
      })
    }
  },
  async created () {
    this.$store.dispatch('SET_IS_LOADING', true)
    await this.$store.dispatch('merchant/FETCH_BOOKING_ENGINES')
    this.$store.dispatch('SET_IS_LOADING', false)
  },
}
</script>

<style lang="sass" scoped>
  @import ../navigation/menu-styles

  #account-container > .md-content
    width: 90%
    margin: 0 auto
    padding-top: 50px
  #merchant-account-form,
  #account-btn-container
    width: 700px
    margin: 0 auto
  #logo-preview
    .md-caption
      margin-top: 15px
    .thumbnail-image
      width: 83px
      height: 83px
      margin-top: 15px
</style>
