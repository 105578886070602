


















































































































































































































































































































































































































































































export default {
  name: 'IconFlamingoBay',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
