import { ErrorHandlerCopy } from '@/lib/error-handlers/copy'
import { ErrorHandler } from '@/types'

/**
 * @description
 * we will store the cognito error by their string message
 * and check incoming errors against them to see how to handle them.
 */

export default class ErrorHandlerBase {
  private message: string
  public friendlyMessage: string

  constructor ({ message, friendlyMessage }: ErrorHandler.CopyConfig) {
    this.message = message
    this.friendlyMessage = friendlyMessage
  }

  private isMatch (errorMessage: string): boolean {
    return errorMessage === this.message
  }

  public check (errorMessage: string): { isMatch: boolean; friendlyMessage: string } {
    return {
      isMatch: this.isMatch(errorMessage),
      friendlyMessage: this.friendlyMessage
    }
  }
}

const IncorrectPasswordErrorHandler = new ErrorHandlerBase(ErrorHandlerCopy.incorrectPassword)
const UserDoesNotExistErrorHandler = new ErrorHandlerBase(ErrorHandlerCopy.userDoesNotExist)
const EnterAValidUrlErrorHandler = new ErrorHandlerBase(ErrorHandlerCopy.enterAValidUrl)
const AlgorithmNameIsTaken = new ErrorHandlerBase(ErrorHandlerCopy.algorithmNameIsTaken)

export {
  IncorrectPasswordErrorHandler,
  UserDoesNotExistErrorHandler,
  EnterAValidUrlErrorHandler,
  AlgorithmNameIsTaken
}
