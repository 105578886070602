<template>
  <div
    id="email-template-container"
    class="flex flex-wrap"
    :style="{
      padding: preview ? '20px' : '0px',
      width: '100%',
    }"
  >
    <EmailEditor
      v-show="!preview"
      ref="emailEditor"
      min-height="79vh"
      :options="editorOptions"
      :style="{ width: 'inherit' }"
      @load="editorLoaded"
    />

    <div
      v-if="preview"
      id="preview-container"
    >
      <div id="header-container">
        <slot name="emailBuilderPreviewHeader" />
        <div
          v-if="showButtons"
          id="header"
          class="section-header"
        >
          {{ copy.header }}
        </div>
        <div
          v-if="showButtons"
          id="button-container"
        >
          <div class="button-text">
            <router-link :to="campaignRoute">
              {{ copy.buttons.back }}
            </router-link>
          </div>
          <div class="button-text">
            <router-link :to="editDesignRoute">
              {{ copy.buttons.edit }}
            </router-link>
          </div>
        </div>
        <div id="toggle-container">
          <span id="icon-container">
            <md-icon>{{ toggleIcon }}</md-icon>
          </span>
          <a
            href=""
            class="toggle-text"
            @click.prevent="togglePreviewMobile"
          >
            {{ toggleCopy }}
          </a>
        </div>
      </div>
      <div
        id="iframe-container"
        :class="{ background: previewMobile }"
      >
        <iframe
          id="preview-iframe"
          ref="iframe"
          :frameBorder="0"
          :style="computedIframeStyle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { EmailEditor } from 'vue-email-editor'
import { editorOptions } from '@/constants/unlayer-editor'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { userViews, campaignStateOptions, edmHashOptions, campaignEditorHashOptions } from '@/constants'
import { debounce } from 'lodash'

const { SENT, PENDING } = campaignStateOptions

/**
 * in preview mode we need to hide unlayer editor component
 * but still have it mounted to use its hooks in setting up
 * the initial json to html export. this is done using v-show.
 *
 * in place of the unlayer preview we mount an iframe and
 * write the html string to the iframe. when previewing as desktop
 * the iframe is responsive to the width of the viewport. as mobile,
 * the width is fixed.
 */
export default {
  name: 'EmailBuilder',
  inject: [
    'Dashboard_getContentHeight'
  ],
  components: {
    EmailEditor
  },
  props: {
    preview: {
      type: Boolean,
      required: false,
      default: () => false
    },
    fromEcm: {
      type: Boolean,
      required: false,
      default: () => false
    },
    workflow: {
      type: Boolean,
      required: false,
      default: () => false
    },
    emailDesignId: {
      type: Number,
      required: false,
      default: null
    },
    save: {
      type: Function,
      required: false
    }
  },
  data () {
    return {
      editorOptions,
      previewMobile: false,
      iframeStyle: {
        height: '0px',
        width: '0px',
      },
      copy: {
        header: 'Selected Design',
        buttons: {
          back: 'Select Different Design',
          edit: 'Edit Design',
          mobile: 'Mobile',
          desktop: 'Desktop'
        }
      },
    }
  },
  computed: {
    ...mapState('edm', ['emailDesign']),
    ...mapState('ecm', [
      'campaign',
      'activeNode',
    ]),
    ...mapGetters('edm', [
      'GET_EMAIL_DESIGN'
    ]),
    computedIframeStyle () {
      return {
        ...this.iframeStyle,
        border: this.previewMobile ? '2px solid rgb(247, 247, 247)' : null
      }
    },
    toggleCopy () {
      return `View As ${this.previewMobile ? this.copy.buttons.desktop : this.copy.buttons.mobile}`
    },
    toggleIcon () {
      return this.previewMobile ? 'desktop_windows' : 'smartphone'
    },
    showButtons () {
      return this.preview && this.fromEcm && [SENT, PENDING].indexOf(this.campaign.state) === -1
    },
    campaignRoute () {
      return {
        name: this.$route.params.campaignId
          ? userViews.CAMPAIGNS_EDIT
          : userViews.CAMPAIGNS_NEW,
        params: {
          hotelId: this.$route.params.hotelId
        },
        hash: `#${campaignEditorHashOptions.SELECT_EMAIL_DESIGN}`,
        query: {
          force: true
        }
      }
    },
    initialSave () {
      return this.emailDesign.html === '<html>{{{unsubscribe}}}</html>'
    },
    editDesignRoute () {
      return {
        name: userViews.EMAIL_DESIGNS_EDIT,
        params: {
          hotelId: this.$route.params.hotelId,
          emailDesignId: this.campaign.dag.emailDesignId
        },
        hash: `#${edmHashOptions.SETTINGS}`,
        query: {
          fromEcm: true,
          campaign: this.campaign.id,
          createCopy: false
        }
      }
    }
  },
  watch: {
    async emailDesignId (newValue, oldValue) {
      if (typeof newValue === 'number' && newValue !== oldValue) {
        const emailDesign = this.GET_EMAIL_DESIGN({ id: newValue })
        this.$store.commit('edm/SET_EMAIL_DESIGN', emailDesign)
        if (!emailDesign.html) {
          const response = await this.$store.dispatch(
            'edm/FETCH_EMAIL_STRING',
            { url: emailDesign.htmlUrl }
          )
          this.$store.commit('edm/SET_EMAIL_DESIGN_HTML', response.data)
        }
        this.renderIframe(emailDesign)
      }
    },
  },
  mounted () {
    if (this.preview) {
      // if there is no email design selected, push back to SelectEmailDesign
      // this could happen on a hard reload with query params but state.campaign.dag.emailDesignId
      // is undefined
      if (this.fromEcm && !this.campaign.dag.emailDesignId) {
        return this.$router.push({
          name: this.$route.params.campaignId
            ? userViews.CAMPAIGNS_EDIT
            : userViews.CAMPAIGNS_NEW,
          params: {
            hotelId: this.$route.params.hotelId,
            campaignId: this.$route.params.campaignId,
          }
        })
      }
      this.setupIframe()
    }
  },
  methods: {
    ...mapMutations('edm', [
      'UPDATE_EMAIL_DESIGN_JSON',
      'UPDATE_EMAIL_DESIGN_HTML'
    ]),
    resetIframeScrollpoint () {
      this.$refs.iframe.contentWindow.scrollTo(0, 0)
    },
    togglePreviewMobile () {
      this.previewMobile = !this.previewMobile
      this.setupIframe()
      this.resetIframeScrollpoint()
    },
    addUpdateEventListener () {
      this.$refs.emailEditor.editor.addEventListener('design:updated',
        (event) => {
          console.log('design:update', event)
          this.exportHtmlAndJson()
        })
    },
    addInitialExportEventListener () {
      this.$refs.emailEditor.editor.addEventListener('design:loaded',
        async (event) => {
          console.log('design:loaded', event)
          // after a user first creates an email design and then lands here,
          // we need to export the html from unlayer, commit to store and save.
          this.handleExportEvent()
        })
    },
    async handleExportEvent () {
      if (this.preview) {
        let emailDesign
        if (this.fromEcm) {
          emailDesign = this.GET_EMAIL_DESIGN({ id: this.campaign.dag.emailDesignId })
          if (!emailDesign) {
            console.warn('Cannot get email deisgn by id:', this.campaign.dag.emailDesignId)
            return
          }
        } else if (this.workflow) {
          emailDesign = this.GET_EMAIL_DESIGN({ id: this.activeNode.emailDesignId })
          const response = await this.$store.dispatch(
            'edm/FETCH_EMAIL_STRING',
            { url: emailDesign.htmlUrl }
          )
          this.$store.commit('edm/SET_EMAIL_DESIGN_HTML', response.data)
        } else {
          emailDesign = this.emailDesign
          if (emailDesign && emailDesign.htmlUrl) {
            const response = await this.$store.dispatch(
              'edm/FETCH_EMAIL_STRING',
              { url: emailDesign.htmlUrl }
            )
            this.$store.commit('edm/SET_EMAIL_DESIGN_HTML', response.data)
          }
        }
        this.renderIframe(emailDesign)
        return
      }
      if (this.initialSave) {
        this.exportHtmlAndJson()
        this.save({ initialSave: true })
      }
    },
    async editorLoaded () {
      this.setEditorWidth()
      this.addInitialExportEventListener()
      await this.$refs.emailEditor.editor.loadDesign(this.emailDesign.json)
      this.addUpdateEventListener()
    },
    async renderIframe (emailDesign) {
      const html = this.workflowHtml || emailDesign.html
      if (!html) {
        console.warn('cannot get html')
        return
      }
      try {
        const doc = this.$refs.iframe.contentWindow.document
        doc.write(html)
        doc.close()
        this.addResizeListener()
        this.setupIframe()
      } catch (error) {
        console.warn(error)
      }
    },
    exportHtmlAndJson () {
      // return for testing
      return this.$refs.emailEditor.editor.exportHtml(
        (data) => {
          console.log('[exportHtmlAndJson]', data)
          const htmlString = data.html.replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s+/g, ' ')
          this.$store.commit('edm/SET_EMAIL_DESIGN_JSON', data.design)
          this.$store.commit('edm/SET_EMAIL_DESIGN_HTML', htmlString)
        },
        {
          cleanup: true,
          minify: true
        }
      )
    },
    setupIframe () {
      let height
      const width = this.previewMobile ? '375px' : 'inherit'

      if (this.previewMobile) {
        height = 800
      } else {
        /* set iframe height to match right column if it exists */
        const rightColumn = this.$parent.$refs.rightCol
        height = rightColumn ? rightColumn.offsetHeight : this.Dashboard_getContentHeight()
      }
      this.iframeStyle.width = width
      this.iframeStyle.height = `${height - 40}px`
    },
    addResizeListener () {
      const handleResize = debounce(() => {
        this.setupIframe()
      }, 750)
      window.addEventListener('resize', handleResize, { trailing: true })
    },
    setEditorWidth () {
      const unlayer = this.$refs.emailEditor
      if (unlayer) {
        const iframe = unlayer.$el.children[0]
        iframe.style.minWidth = null
        iframe.style.width = '100%'
      } else {
        console.warn('Cannot find email editor to set iframe width')
      }
    }
  },
}
</script>

<style lang="sass" scoped>
@import ../../../../assets/sass/transitions.sass

#preview-container
  width: 100%
#header-container
  width: 100%
  min-height: 30px
  @apply flex flex-row items-baseline
  a
    @apply text-gray-400
  a:hover
    @extend .laas-link-hover

#header
  color: black
  @apply text-md
  font-weight: bold
#button-container
  flex-grow: 1
  display: flex
  flex-direction: row
  justify-content: flex-start
  padding: 0px 14px
.button-text
  padding: 0px 10px
  font-size: 13px
  text-decoration: underline
  font-weight: normal
#toggle-container
  padding: 0 10px

  &:hover
    .md-icon
      @extend .laas-link-hover

#icon-container
  padding-bottom: 3px
  .md-icon
    @apply text-gray-400

.toggle-text
  margin-top: 2px
  font-size: 13px
  line-height: 20px
  font-weight: bold

  &:hover
    text-decoration: none

.md-icon.md-icon-font.md-theme-default
  color: black
  font-size: 14px !important
  vertical-align: top !important
  height: 21px !important
  width: 20px !important
#iframe-container
  margin: 0 auto
  padding-top: 30px
  padding-bottom: 30px
  width: 100%
  display: flex
  justify-content: center
.background
  background-image: linear-gradient(45deg, #eee 25%, transparent 25%), linear-gradient(-45deg, #eee 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #eee 25%), linear-gradient(-45deg, transparent 75%, #eee 25%)
  background-size: 20px 20px
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px

.fade-enter-active, .fade-leave-active
  transition: opacity 1s
.fade-enter, .fade-leave-to
  opacity: 0

</style>
