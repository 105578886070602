import { cognitoGroups, userTypes } from '@/constants'
import { UserParser } from '@/parsers'
import cognitoConfig from '@/config/cognito'

export const actions = {
  /**
    * fetches all live hotels from cradle.
    * parses hotel objects using schema.
    * COMMITSa list to store.
    * @param {Function} commit Vuex
    * @return {undefined}
    */
  async FETCH_HOTELS_AND_GROUPS ({ dispatch }) {
    await Promise.all([
      dispatch('hotel/FETCH_HOTEL_GROUPS', null, { root: true }),
      dispatch('hotel/FETCH_HOTELS', { fetchAll: true, pageSize: 1000 }, { root: true })
    ])
  },
  /**
    * fetches users with DASH_GET_USER lambda fn.
    * iterates over type.GROUP users and creates a list of Promise objects.
    * parses the list of hotels and enriches each group users.
    * with their list of hotels.
    * COMMITS both a list and object (key'd on email) to the store.
    * NOTE
    * we are enriching group users with their associated hotels at run-time
    * so that we can filter for users associated with specific hotels
    * in addition to their group.
    * @param {Function} commit Vuex
    * @return {undefined}
    */
  async FETCH_USERS ({ dispatch, commit, getters, rootState, rootGetters }) {
    let users = []
    const params = { UserPoolId: cognitoConfig.USER_POOL_ID }
    try {
      while (true) {
        const response = await rootState.authController.cognitoIdp.listUsers(params).promise()
        users = users.concat(response.Users)
        if (!response.PaginationToken) break
        params.PaginationToken = response.PaginationToken
      }
      console.log('[FETCH_USER]', users)
    } catch (error) {
      dispatch('messages/ADD_ERROR', error, { root: true })
    }
    const usersObject = users.reduce((acc, rawUser) => {
      const parsedUser = UserParser.parseUser({
        rawUser,
        allHotelsObject: getters.hotelCognitoMetadata,
        allGroupsObject: rootState.hotel.hotelGroupsObj,
        allHotelsList: getters.hotelAccessDropdown,
        allGroupsList: rootGetters['hotel/hotelGroupList'],
        allMerchantsObject: rootGetters['merchant/merchantCognitoMetadata']
      })
      acc[parsedUser.email] = parsedUser
      return acc
    }, {})

    commit('SET_USERS_OBJECT', usersObject)
  },
  async CREATE_NEW_USER ({ dispatch, getters, rootState }, { newUser }) {
    console.log('[CREATE_NEW_USER] user:', JSON.stringify(newUser, null, 2))
    // NOTE cognito allows you to keep creating same user unless pw is confirmed
    if (getters.getUserByEmail(newUser.email)) {
      dispatch('messages/ADD_ERROR', new Error('User with email already exists.'), { root: true })
      return
    }

    const groupName = cognitoGroups[newUser.type]
    const payload = getters.getCreateUserPayload(newUser)

    try {
      await rootState.authController.cognitoIdp.adminCreateUser({
        UserPoolId: cognitoConfig.USER_POOL_ID,
        Username: payload.Username,
        TemporaryPassword: rootState.authController.generatePassword(),
        UserAttributes: payload.Attributes
      }).promise()
      const response = await rootState.authController.cognitoIdp.adminAddUserToGroup({
        UserPoolId: cognitoConfig.USER_POOL_ID,
        Username: payload.Username,
        GroupName: groupName
      }).promise()
      console.log('[CREATE_NEW_USER] response', response)
      dispatch(
        'messages/ADD_SUCCESS',
        { message: `${payload.Username} successfully created` },
        { root: true }
      )
      dispatch('FETCH_USERS')
    } catch (error) {
      dispatch('messages/ADD_ERROR', error, { root: true })
      throw (error)
    }
  },
  /**
    * @param {String} userEmail necessary in payload
    * @param {Object} properties the full list of hotels a user has access for
    */
  async EDIT_USER ({ dispatch, getters, rootState }, {
    createEnv,
    userType,
    userEmail,
    propertyIds,
    hssEditorAccess,
    reportingAccess,
    oprmAccess,
    edmAccess,
    rewardAlgoAccess,
    flexAccess,
  }) {
    console.log('[EDIT_USER]', JSON.stringify(propertyIds))
    const attributes = {
      hssEditorAccess,
      reportingAccess,
      oprmAccess,
      edmAccess,
      rewardAlgoAccess,
      flexAccess,
      groups: []
    }
    if (userType === userTypes.HOTEL) {
      const hotels = propertyIds.map(id => getters.getHotelPayloadById(id))
      attributes.customProfile = {
        email: userEmail,
        createEnv,
        type: userType,
        hotels,
        merchantIds: [],
      }
    } else if (userType === userTypes.MERCHANT) {
      attributes.customProfile = {
        email: userEmail,
        createEnv,
        type: userType,
        hotels: null,
        merchantIds: propertyIds,
      }
    } else if (userType === userTypes.GROUP) {
      const groups = getters.getGroupsPayload(propertyIds)
      attributes.customProfile = {
        email: userEmail,
        createEnv,
        type: userType,
        hotels: null,
        merchantIds: null
      }
      attributes.groups = groups
    }
    const response = await rootState.authController.updateUserAttributes({
      user: {
        username: userEmail,
        attributes: attributes
      },
      isAdminEditing: true
    })
    if (response.error) {
      console.log(response.error)
      dispatch('messages/ADD_ERROR', response.error, { root: true })
    }
    if (!response.error) {
      dispatch('messages/ADD_SUCCESS',
        { message: `Your changes for ${userEmail} have been successfully saved.` },
        { root: true }
      )
    }
    dispatch('FETCH_USERS')
  },
  async DELETE_USER ({ dispatch, rootState }, { userEmail }) {
    try {
      await rootState.authController.cognitoIdp.adminDeleteUser({
        UserPoolId: cognitoConfig.USER_POOL_ID,
        Username: userEmail
      }).promise()
      dispatch('FETCH_USERS')
    } catch (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
      return error
    }

    dispatch('messages/ADD_SUCCESS',
      { message: `${userEmail} has been successfully deleted.` },
      { root: true }
    )
  },
  async DISABLE_USER ({ dispatch, rootState }, { userEmail }) {
    try {
      await rootState.authController.cognitoIdp.adminDisableUser({
        UserPoolId: cognitoConfig.USER_POOL_ID,
        Username: userEmail
      }).promise()
      dispatch('FETCH_USERS')
    } catch (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
    }
  },
  async ENABLE_USER ({ dispatch, rootState }, { userEmail }) {
    try {
      await rootState.authController.cognitoIdp.adminEnableUser({
        UserPoolId: cognitoConfig.USER_POOL_ID,
        Username: userEmail
      }).promise()
      // remove user from usersList & userStore
      dispatch('FETCH_USERS')
    } catch (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
      return error
    }

    dispatch('messages/ADD_SUCCESS',
      { message: `${userEmail} has been successfully enabled.` },
      { root: true }
    )
  },
  /**
    * NOTE current workaround for lack of lambda fn to reset user PW
    * this will delete the user if they exist and then recreate them
    * with the same parameters.
    */
  async RESET_USER_PASSWORD ({ dispatch, rootState }, { userEmail }) {
    try {
      // set to FORCE_CHANGE_PASSWORD state
      await rootState.authController.cognitoIdp.adminSetUserPassword({
        UserPoolId: cognitoConfig.USER_POOL_ID,
        Username: userEmail,
        Password: rootState.authController.generatePassword()
      }).promise()
      // resend email
      const response = await rootState.authController.cognitoIdp.adminCreateUser({
        UserPoolId: cognitoConfig.USER_POOL_ID,
        Username: userEmail,
        TemporaryPassword: rootState.authController.generatePassword(),
        MessageAction: 'RESEND'
      }).promise()
      console.log(response)
    } catch (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
      return error
    }

    dispatch('messages/ADD_SUCCESS',
      { message: `Password for ${userEmail} has been successfully reset.` },
      { root: true }
    )
  },
}
