



























































import moment from 'moment'
import { mapMutations, mapState } from 'vuex'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { getTimeZone } from '@/utils/dates'

export default {
  name: 'SeasonalReward',
  components: {
    Datetime,
  },
  data () {
    return {
      timeZone: getTimeZone(),
      copy: {
        required: 'Required',
        description: 'Is this reward only offered during a specific timeframe?',
        offerTimeframe: {
          title: 'Offer Timeframe',
          body: 'When should we offer this reward? This reward will be offered if the guest\'s stay dates overlap with <strong>any dates</strong> in the offer time frame below.'
        }
      }
    }
  },
  watch: {
    seasonStart (newValue) {
      if (new Date(newValue) > new Date(this.seasonEnd)) {
        this.seasonEnd = null
      }
    }
  },
  computed: {
    ...mapState('oprm', [
      'reward',
    ]),
    seasonal: {
      get (): string | void {
        return this.reward.seasonal
      },
      set (value: boolean): void {
        this.SET_REWARD_FIELD({
          field: 'seasonal',
          value
        })
      }
    },
    seasonStart: {
      get (): string | void {
        return this.reward.seasonStart
      },
      set (value: string): void {
        if (value === '') return
        const formatted = moment.utc(value).format('YYYY-MM-DD')
        console.log('<SeasonalReward> [seasonStart] set', value)
        this.SET_REWARD_FIELD({
          field: 'seasonStart',
          value: formatted
        })
      }
    },
    seasonEnd: {
      get (): string | void {
        return this.reward.seasonEnd
      },
      set (value: string): void {
        if (value === '') return
        const formatted = moment.utc(value).format('YYYY-MM-DD')
        console.log('<SeasonalReward> [seasonEnd] set', value)
        this.SET_REWARD_FIELD({
          field: 'seasonEnd',
          value: formatted
        })
      }
    },
    seasonStartMinDate (): string {
      return new Date().toISOString()
    },
    seasonEndMinDate (): string {
      if (!this.seasonStart) {
        return new Date().toISOString()
      }
      return moment(this.seasonStart).add(1, 'days').toISOString()
    },
    toggleCopy (): string {
      return this.seasonal ? 'Yes' : 'No'
    }
  },
  methods: {
    ...mapMutations('oprm', [
      'SET_REWARD_FIELD'
    ])
  }
}
