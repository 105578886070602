























































































































































































































import Vue from 'vue'
import ClickOutside from 'vue-click-outside'
import Fuse from 'fuse.js/dist/fuse.min.js'
import Label from '@/components/label/Label.vue'
import Tag from '@/components/select/Tag.vue'
import DropdownOptions from '@/components/dropdown/options/DropdownOptions.vue'
import IconsContainer from '@/components/select/IconsContainer.vue'
import { isEqual, throttle } from 'lodash'
import { helperText } from '@/components/props/index'
import HelperText from '@/components/helper-text/HelperText.vue'
import { FALSY } from '@/constants'
import { OptsStats, ElementRect, OptionsYPosition } from '@/types'
import { getOptionsYPosition, getElementRect } from '@/utils/misc'
import { findOptionsMatch } from '@/utils/dropdown-utils'

export type Option =
  | string
  | number
  | Record<string, string | number | boolean>

export type OptionsWidth = { width: string }

export interface FuseOptions {
  includeMatches: boolean;
  isCaseSensitive: boolean;
  includeScore: boolean;
  shouldSort: boolean;
  findAllMatches: boolean;
  threshold: number;
  ignoreLocation: boolean;
  keys: string[];
}

export default {
  name: 'Select',
  components: {
    Label,
    DropdownOptions,
    IconsContainer,
    Tag,
    HelperText,
  },
  provide () {
    return {
      /**
       * provide a utility for DropdownOptions to get the container rect
       * in order to pass to the viewport edge utils
       */
      injected__getParentRect: this.getContainerRect,
    }
  },
  directives: {
    ClickOutside,
  },
  props: {
    offsetHeight: {
      type: Number,
      required: true,
      default: 0,
    },
    showDisabledCopy: {
      type: Boolean,
      default: true
    },
    /**
     * use false to hide all disabled copy.
     * use string to override default copy.
     */
    disabledCopy: {
      type: [String, Boolean],
      default: () => ''
    },
    closeIcon: {
      type: Boolean,
      default: true
    },
    /**
     * value being emitted will be passed through this function.
     * ideal for working with lists of id's as your v-model.
     */
    reduce: {
      type: Function,
      required: false,
      default: option => option
    },
    /**
     * option will be selected upon render.
     */
    initialSelect: {
      type: Object as () => Option,
      required: false,
      default: null
    },
    /**
     * adds checkbox to left of all options
     */
    checkbox: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * will remove option from dropdown if selected
     */
    hideSelected: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * will render input & allowsuser to filter options.
     * multiselect mode will always allow the user to filter.
     */
    filter: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * incoming value
     */
    value: {
      type: [Array, String, Object, Number],
      required: false,
      default: null
    },
    /**
     * key on all objects that should have a unique value
     * can use this when comparing large objects
     */
    trackBy: {
      type: String,
      required: false,
      default: null
    },
    /**
     * close dropdown options after selecting an option
     */
    closeOnSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * maximum number of selections
     */
    max: {
      type: Number,
      required: false,
      default: null
    },
    /**
     * multiselect mode
     */
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * displays required text in label
     */
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * displays label above the Select element
     */
    label: {
      type: String,
      required: false,
      default: ''
    },
    tabindex: {
      type: Number,
      default: 0,
      required: false
    },
    /**
     * prevents user from opening dropdown options
     */
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * options user can select
     */
    options: {
      type: Array as () => Option[],
      default: () => [],
      required: true,
    },
    /**
     * tell dropdown options which key/value should be rendered
     */
    labelKey: {
      type: String,
      required: false,
      default: 'label'
    },
    /**
     * apply custom style to label
     */
    labelClasses: {
      type: Array as () => string[],
      required: false,
      validator (value): boolean {
        return value.every(item => typeof item === 'string')
      },
      default: () => []
    },
    /**
     * text shown when there are zero options selected
     */
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * text shown in the filter input when there are zero options selected
     */
    inputPlaceholder: {
      type: String,
      required: false,
      default: 'Start typing to search...'
    },
    /**
     * text rendered in dropdown options when user is filtering options
     * and there are zero matches
     */
    emptyStateCopy: {
      type: String,
      required: false,
      default: 'No results found.'
    },
    /**
     * configuration for fuzzy search
     */
    fuseOptions: {
      type: Object as () => FuseOptions,
      required: false,
      default: () => ({
        includeMatches: false,
        isCaseSensitive: false,
        includeScore: false,
        shouldSort: false,
        findAllMatches: false,
        threshold: 0.0,
        ignoreLocation: false,
        keys: [] // labelKey should always be in this list
      })
    },
    useFuse: {
      type: Boolean,
      required: false,
      default: true
    },
    helperText
  },
  data () {
    return {
      throttledHandleScroll: undefined, // set in mount hook
      inverted: false,
      optionsY: { top: '0px' } as OptionsYPosition,
      optionsWidth: { width: '0px' } as OptionsWidth,
      fuse: undefined,
      isMounted: false,
      showInput: false,
      showDropdown: false,
      filterTerm: '',
      refLabels: {
        tagContainer: 'tag-container',
        multiselectContainer: 'multiselect-container',
        input: 'input',
        selectContainer: 'select-container',
        controlContainer: 'control-container',
      },
      icons: {
        minimize: ['fas', 'angle-down'],
      },
      copy: {
        inputPlaceholder: 'Type to search',
        notEditable: 'Not Editable',
      },
      optionsContainerStyles: undefined
    }
  },
  computed: {
    selectStyles (): string[] {
      const baseStyles = ['selected-label', 'px-4']
      const placeholderStyle = this.internalValue?.length === 0 ? 'text-gray-400' : 'text-black'

      return [...baseStyles, placeholderStyle]
    },
    filteredOptions (): Option[] {
      if (this.filterTerm.length === 0 || !this.useFuse) {
        return this.options
      }

      return this.fuzzySearch(this.filterTerm)
    },
    // always reference the value as an array
    internalValue (): string[] | number [] | Option[] {
      // account for value being 0 or false
      if (FALSY.includes(this.value)) {
        return []
      }
      if (Array.isArray(this.value)) {
        return this.value
      }
      return [this.value]
    },
    allSelected (): boolean {
      if (this.multiple && this.max) {
        return this.internalValue.length === this.max
      }
      return this.internalValue.length === this.options.length
    },
    trackByValues (): string [] | number[] | Option[] {
      return this.trackBy
        ? this.internalValue.map(v => v[this.trackBy])
        : this.internalValue
    },
    selectedLabel (): string {
      if (this.hasSelections) {
        if (typeof this.internaValue === 'object') {
          return this.internalValue[0][this.labelKey]
        }
        return this.getLabel(this.internalValue[0])
      }
      return this.placeholder
    },
    tagContainerHeights (): Record<string, string | void> {
      return {
        height: this.hasSelections ? null : '40px',
        minHeight: '40px'
      }
    },
    tagContainerPadding (): string[] {
      return this.hasSelections ? ['pt-1', 'px-1'] : ['pl-2']
    },
    /** FIXME
     * why is this not used?
     */
    optionsPosition (): Record<string, string> {
      let position
      if (this.optionsEdge === true) {
        position = 'right'
      } else {
        position = 'left'
      }
      return { [position]: '0px' }
    },
    multiselectInputWidth (): string {
      if (!this.hasSelections) return '100%'
      if (this.filterTerm.length < 1) return '6px'
      return `${this.filterTerm.length * 8}px`
    },
    showEmptyState (): boolean {
      if (this.filter) {
        return this.filterTerm.length > 0 && this.filteredOptions.length === 0
      }
      return false
    },
    hasSelections (): boolean {
      return this.internalValue.length > 0
    },
    showMultiselectLabel (): boolean {
      return !this.hasSelections && !this.showDropdown
    },
    multiselectPlaceholder (): string {
      return this.hasSelections ? '' : this.placeholder
    },
    renderDisabledCopy (): boolean {
      return this.disabled && this.showDisabledCopy
    },
    disabledCopyWithDefault (): string {
      if (this.disabledCopy === false) return ''
      return this.disabledCopy || this.copy.notEditable
    }
  },
  watch: {
    options (): void {
      this.setupFuse()
    },
    filterTerm (newValue: string): void {
      this.$emit('selectFilterChange', newValue)
    }
  },
  mounted () {
    this.throttledHandleScroll = throttle(this.handleScroll, 1000)
    if (this.initialSelect) {
      this.internalValue.push(this.initialSelect)
    }
    this.setupFuse()
    this.isMounted = true
  },
  methods: {
    handleScroll (e): void {
      const match = findOptionsMatch(e)
      if (!match) {
        this.closeAll()
      }
    },
    getContainerRect (): ElementRect {
      const container = this.$refs[this.refLabels.selectContainer]
      return getElementRect(container)
    },
    findOptionFromReducedValue (value): Option | void {
      const matches = this.options.filter(option => isEqual(this.reduce(option), value))
      return matches[0]
    },
    updateValue (value: Option | Option[] | void): void {
      this.$emit('input', value)
    },
    onClickOutside (): void {
      if (this.showDropdown || this.showInput) {
        this.closeAll()
      }
    },
    getLabel (item: Option | string | number) {
      const option = this.findOptionFromReducedValue(item)
      return option && option[this.labelKey]
    },
    tagLabel (item: Option | string | number): string | number | boolean {
      return typeof item === 'object'
        ? item[this.labelKey]
        : this.getLabel(item)
    },
    /**
     * managing top position of dropdown options
     */
    setInverted (event: boolean): void {
      this.inverted = event
    },
    async handleReady (optsStats: OptsStats): Promise<void> {
      this.setInverted(optsStats.invertVertically)
      this.setOptionsEdge(optsStats.invertHorizontally)

      const container = this.$refs[this.refLabels.controlContainer]
      this.optionsY = getOptionsYPosition(
        optsStats.height,
        container,
        this.inverted,
        this.offsetHeight
      )
      const selectCoords = getElementRect(container)
      const width: OptionsWidth = { width: `${selectCoords.width}px` }
      this.optionsWidth = width
    },
    /**
     * managing fuse search & options
     */
    setupFuse (): void {
      this.fuse = new Fuse(
        this.options,
        this.getSearchOptions(this.fuseOptions)
      )
    },
    fuzzySearch (filterTerm): Option[] {
      return this.fuse.search(filterTerm).map(item => item.item)
    },
    /**
     * guarantee labelKey is always in fuseOptions in case implementor
     * does not update fuseOptions props but changes labelKey prop
     */
    getSearchOptions (fuseOptions: FuseOptions): FuseOptions {
      return {
        ...fuseOptions,
        keys: [...fuseOptions.keys, this.labelKey]
      }
    },
    /**
     * managing optionsEdge to prevent dropdown-options from crossing viewport edge
     */
    setOptionsEdge (value: boolean): void {
      this.optionsEdge = value
    },
    /**
     * selecting & removing options
     */
    selectAll (): void {
      this.updateValue(this.options)
    },
    removeAll (): void {
      this.updateValue(this.multiple ? [] : null)
      this.$emit('remove')
    },
    async handleSelect (option: Option): Promise<void> {
      if (this.isSelected(option)) {
        return
      }

      /**
       * @event select only emits the new option.
       */
      this.$emit('select', option)

      const _option = typeof option === 'object'
        ? this.reduce(option)
        : option

      let value

      if (this.multiple) {
        value = [...this.internalValue, _option]
      } else {
        value = _option
      }

      this.updateValue(value)
      this.clearFilterTerm()
      this.focusInput()

      if (this.closeOnSelect) {
        await this.$nextTick()
        this.closeInput()
        this.closeDropdown()
      }
    },
    /**
     * while using multiselect inline input to filter results
     * we will allow the enter key to select the option if there
     * is only one showing
     */
    handleEnter (): void {
      if (this.allSelected) {
        this.closeInput()
        this.closeDropdown()
      }
      if (this.filteredOptions.length !== 1) {
        return
      }
      this.handleSelect(this.filteredOptions[0])
    },
    async handleRemove (option: Option): Promise<void> {
      if (!this.multiple) {
        return
      }
      /**
       * @event remove - only emits the option to be removed.
        */
      this.$emit('remove', option)

      const _option = this.getOption(option)
      const idx = this.trackByValues.indexOf(_option)

      this.updateValue([
        ...this.internalValue.slice(0, idx),
        ...this.internalValue.slice(idx + 1)
      ])

      if (this.showDropdown) {
        this.focusInput()
      }
    },
    /**
     * while using multiselect inline input to filter results
     * we will allow the delete key to remove the last selected option
     * in the list if they have not entered a filter term
     */
    handleRemoveByDeleteKey (): void {
      if (!this.hasSelections || this.filterTerm.length > 0) {
        return
      }
      const [lastOption] = this.internalValue.slice(-1)
      this.handleRemove(lastOption)
    },
    /**
     * knowing which options are selected
     */
    getOption (option: Option): Option {
      let _option
      if (this.trackBy) {
        _option = option[this.trackBy]
        // if trackBy does not work, resort to original object
        if (!_option) {
          console.error('Cannot get option using trackBy value of: ' + this.trackBy)
          _option = option
        }
      } else {
        _option = typeof option === 'object' ? this.reduce(option) : option
      }

      return _option
    },
    isSelected (option: Option): boolean {
      const _option = this.getOption(option)
      return this.trackByValues.indexOf(_option) > -1
    },
    /**
     * toggling input/dropdown-options state
     */
    openInput (): void {
      this.showInput = true
    },
    closeInput (): void {
      this.showInput = false
      this.clearFilterTerm()
    },
    async openDropdown (): Promise<void> {
      this.showDropdown = true
      if (!this.multiple && !this.filter) {
        await this.$nextTick()
        this.focusFirstOption()
      }
    },
    closeDropdown (): void {
      this.showDropdown = false
    },
    closeAll (): void {
      this.closeInput()
      this.closeDropdown()
    },
    /**
     * click handlers
     */
    handleClick (): void {
      if (this.disabled) return
      if (this.filter || this.multiple) {
        this.openInput()
        this.focusInput()
      }
      this.openDropdown()
    },
    /**
     * managing filter/search term
     */
    clearFilterTerm (): void {
      this.filterTerm = ''
    },
    /**
     * managing focus
     */
    async focusInput (): Promise<void> {
      await this.$nextTick()
      const { input } = this.refLabels
      const target = this.$refs[input] as HTMLElement | null
      if (target) target.focus()
    },
    focusFirstOption (): void {
      const dropdownOptions = this.$children.filter((child) => {
        return child.$options?._componentTag === 'DropdownOptions'
      }) as Vue[]

      const option = dropdownOptions[0]
      if (option) {
        const el = option.$refs.option[0] as HTMLElement | undefined
        if (el) {
          el.focus()
          return
        }
      }
      console.error('Cannot find DropdownOptions to focus on first option')
    },
  }
}
