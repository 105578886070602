












































































import Paginator from '@/components/common/Paginator.vue'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import { userViews } from '@/constants'
import moment from 'moment'
import ExpandingList from '@/components/common/ExpandingList.vue'
import SwitchWrapper from '@/components/common/SwitchWrapper.vue'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton.vue'
import { capitalizeAllWords } from '@/lib/filters'

export type RewardCreativeConfig = {
  promotedText: string | undefined;
  rewardThumbnail: string | undefined;
}

export default {
  name: 'OnPropertyRewardsTable',
  components: {
    'md-table-pagination': Paginator, // override vue material component
    ExpandingList,
    SwitchWrapper,
    StepperButton
  },
  props: {
    merchant: {
      type: Number,
      required: false,
    }
  },
  data () {
    return {
      rewardsLoaded: false,
      page: 1,
      pageSize: 20,
      copy: {
        status: {
          active: 'Active',
          inactive: 'Inactive',
          inProgress: 'In Progress',
        },
        empty: 'No rewards yet'
      },
      propertyListExpanded: false,
    }
  },
  filters: {
    capitalizeAllWords
  },
  computed: {
    ...mapGetters('oprm', [
      'PAGE_INFO',
      'REWARDS_LIST',
    ]),
    ...mapGetters([
      'IS_ADMIN',
      'HOTEL_GROUP_IS_RETAIN',
    ]),
    ...mapState([
      'userIsRetain',
    ]),
  },
  methods: {
    ...mapActions('oprm', ['FETCH_REWARDS']),
    ...mapMutations('oprm', ['CLEAR_REWARDS']),
    async fetchRewards (): Promise<void> {
      await this.FETCH_REWARDS({
        page: this.page,
        pageSize: this.pageSize,
        merchant: this.merchant
      })
    },
    businessName (opr): string {
      return opr.merchantName
    },
    truncateRewardName (oprPromotedText): string {
      if (oprPromotedText.length < 51) return oprPromotedText
      return `${oprPromotedText.substr(0, 51)} ...`
    },
    hasRcc (opr): RewardCreativeConfig {
      if (opr.creativeConfigs && opr.creativeConfigs.length > 0) {
        return opr.creativeConfigs[0]
      }
      return {
        promotedText: undefined,
        rewardThumbnail: undefined
      }
    },
    oprName (opr): string {
      const rcc = this.hasRcc(opr)
      if (!rcc.promotedText) return ''
      return this.truncateRewardName(rcc.promotedText)
    },
    updatedOn (opr): string {
      return moment(opr.modified).format('D MMM YYYY, LT')
    },
    rewardThumbnail (opr): string {
      const rcc = this.hasRcc(opr)
      if (!rcc.thumbnailImage) return ''
      return rcc.thumbnailImage
    },
    updatePage (pageNumber): void {
      this.page = pageNumber
      this.fetchRewards()
    },
    updatePageSize (pageSize): void {
      this.pageSize = pageSize
      this.fetchRewards()
    },
    togglePropertiesList (): void {
      this.propertyListExpanded = !this.propertyListExpanded
    },
    navToRewardEditor (oprUuid): void {
      this.$router.push({
        name: userViews.OP_REWARDS_WIZARD_EDIT,
        params: {
          hotelId: this.$route.params.hotelId,
          rewardUuid: oprUuid,
        }
      })
    },
    navToRewardWizard (): void {
      this.$router.push({
        name: userViews.OP_REWARDS_WIZARD_NEW,
        params: {
          hotelId: this.$route.params.hotelId
        }
      })
    },
    cost (opr): string {
      return `$ ${opr.cost}`
    },
    value (opr): string {
      return `$ ${opr.value}`
    }
  },
  async mounted (): Promise<void> {
    this.rewardsLoaded = false
    this.CLEAR_REWARDS()
    await this.fetchRewards()
    this.rewardsLoaded = true
  },
}
