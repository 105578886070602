<template>
  <svg
    id="icon-itunes"
    viewBox="0 0 90 90"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.itunes}">

    <title id="title">itunes icon</title>
    <desc id="desc">itunes icon</desc>

    <g>
      <path id="iTunes" d="M45,0C20.148,0,0,20.148,0,45c0,24.852,20.148,45,45,45
        c24.852,0,45-20.148,45-45C90,20.148,69.852,0,45,0zM45,81C25.119,81,9,64.881,
        9,45S25.119,9,45,9s36,16.119,36,36S64.881,81,45,81z M61.48,57.059c0.289,
        3.828-2.584,7.313-6.422,7.791c-3.832,0.476-7.185-2.24-7.472-6.067c-0.293
        -3.823,2.59-7.313,6.422-7.787c1.214-0.149,2.373,0.019,3.41,0.438V32.639l
        -18,3.348v23.48h-0.035c0.008,0.066,0.025,0.127,0.025,0.193c0.291,3.828-2.584,
        7.309-6.416,7.787c-3.84,0.475-7.18-2.241-7.471-6.064c-0.295-3.828,2.58-7.316,
        6.416-7.787c1.213-0.154,2.373,0.019,3.41,0.443V26.974v-0.005l26.113-4.46l0.025
        -0.009v0.004l0.01-0.004l-0.01,0.119v34.25h-0.035C61.463,56.936,61.475,56.992,
        61.48,57.059z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconItunes',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
