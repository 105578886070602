var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"md-content-item lato-font pb-12 mb-12",attrs:{"id":"reward-algorithms-wizard"}},[_c('WizardHeader',{on:{"clickEvent":_vm.cancel}},[_c('template',{slot:"header"},[_c('span',[_vm._v(_vm._s(_vm.headerCopy))])])],2),_c('div',{attrs:{"id":"stepper-container"}},[_c('div',{staticClass:"w-100 flex pl-6 mb-4",attrs:{"id":"header-container"}},[(_vm.description)?_c('span',{attrs:{"id":"description-copy"}},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e()]),_c('CustomStepper',{attrs:{"editing":true,"step-headers":_vm.stepHeaders},scopedSlots:_vm._u([{key:_vm.steps.STEP_1,fn:function(ref){
var slotScope = ref.slotScope;
return [_c('WizardStep',{attrs:{"editing":true,"reverse-order-on-mobile":false}},[_c('CustomStepHeader',{attrs:{"slot":"step-header","index":slotScope.idx,"label":_vm.stepHeaders[0].name,"meta":_vm.copy.required},slot:"step-header"}),_c('RamName',{attrs:{"slot":"left-content","index":slotScope.idx},slot:"left-content"})],1)]}},{key:_vm.steps.STEP_2,fn:function(ref){
var slotScope = ref.slotScope;
return [_c('WizardStep',{attrs:{"editing":true,"reverse-order-on-mobile":false}},[_c('CustomStepHeader',{attrs:{"slot":"step-header","index":slotScope.idx,"label":_vm.stepHeaders[1].name,"meta":_vm.copy.required},slot:"step-header"}),_c('RewardsOffered',{attrs:{"slot":"left-content","index":slotScope.idx},slot:"left-content"})],1)]}},{key:_vm.steps.STEP_3,fn:function(ref){
var slotScope = ref.slotScope;
return [_c('WizardStep',{attrs:{"editing":true,"reverse-order-on-mobile":false}},[_c('CustomStepHeader',{attrs:{"slot":"step-header","index":slotScope.idx,"label":_vm.stepHeaders[2].name,"meta":_vm.copy.required},slot:"step-header"}),_c('SelectableRewards',{staticClass:"ram-sub-component",attrs:{"slot":"left-content","index":slotScope.idx},slot:"left-content"})],1)]}},{key:_vm.steps.STEP_4,fn:function(ref){
var slotScope = ref.slotScope;
return [_c('WizardStep',{attrs:{"editing":true,"reverse-order-on-mobile":false}},[_c('CustomStepHeader',{attrs:{"slot":"step-header","index":slotScope.idx,"label":_vm.stepHeaders[3].name},slot:"step-header"}),_c('div',{staticClass:"flex flex-col",style:({ maxWidth: '600px' }),attrs:{"slot":"left-content"},slot:"left-content"},[_c('Tabs',[_c('Tab',{attrs:{"title":"On-Property Rewards"}},[_c('OverrideRewardsContainer',{attrs:{"reward-type":'onProperty',"exclude-campaign-rewards":_vm.excludeCampaignRewards}})],1),_c('Tab',{attrs:{"title":"Marketplace Rewards"}},[_c('OverrideRewardsContainer',{attrs:{"reward-type":'marketPlace',"exclude-campaign-rewards":_vm.excludeCampaignRewards}})],1)],1)],1),_c('div',{staticClass:"copy-container",attrs:{"slot":"right-content","index":slotScope.idx},slot:"right-content"},[_c('div',{staticClass:"pb-3",domProps:{"innerHTML":_vm._s(_vm.copy.overrideRewards)}})])],1)]}}],null,true)})],1),_c('div',{attrs:{"id":"footer"}},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-60",attrs:{"id":"button-container"}},[_c('StepperButton',{ref:"footer-cancel-button",attrs:{"copy":_vm.copy.buttons.cancel,"disabled":false,"inverse":true},on:{"clickEvent":_vm.cancel}}),_c('StepperButton',{ref:"footer-create-update-button",attrs:{"copy":_vm.buttonCopy,"disabled":_vm.isDisabled,"width":178},on:{"clickEvent":_vm.createOrUpdate}})],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }