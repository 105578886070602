<template>
  <svg
    id="icon-ticket"
    viewBox="0 0 568.953 568.953"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.ticket}">

    <title id="title">ticket icon</title>
    <desc id="desc">ticket icon</desc>

    <g>
      <path d="M406.206,204.891l-1.709,1.679c-3.384,3.366-7.547-0.152-8.9-1.466l-32.199-32.326c-4.173-4.181-7.772-1.596-9.021-0.434
        l-5.838,5.829c-4.068,4.06-7.868-0.347-7.868-0.347l-1.544-1.566c0,0-3.4-3.375,0.677-7.434l29.927-29.792
        c1.796-1.626,5.431-4.051,8.553-0.924l0.963,0.984c3.461,3.644,1.466,6.862,0.217,8.271l-6.02,5.99
        c-0.815,0.841-3.461,4.281,1.379,9.156l31.653,31.792C408.227,196.273,410.725,200.458,406.206,204.891L406.206,204.891z
        M377.987,234.107l-19.656,19.587c-5.118,5.096-9.88,1.214-11.242-0.1l-39.686-39.829c0,0-4.936-6.61,0.252-11.789l19.223-19.131
        c0,0,4.06-4.533,7.407-1.163l1.527,1.527c0,0,4.719,3.791-0.052,8.544l-10.479,10.418c-4.008,4.007-0.025,7.772-0.025,7.772
        l3.097,3.075c0,0,4.181,4.381,8.935-0.373l8.432-8.397c0,0,4.181-4.307,7.391-1.089l1.258,1.258
        c3.513,3.838,1.943,6.814,0.746,8.219l-8.103,8.071c-5.074,5.049-2.212,8.788-1.31,9.729l5.552,5.569
        c1.665,1.305,4.39,2.45,7.295-0.443l10.34-10.305c0,0,4.242-4.584,7.026-1.804l1.762,1.761
        C381.414,229.08,379.41,232.489,377.987,234.107L377.987,234.107z M341.226,269.603l-5.205,5.178
        c-3.227,3.214-8.311,2.004-8.311,2.004l-29.866-7.035l-1.292,7.872l12.326,12.383c4.424,4.433,3.436,7.85,2.039,9.802
        l-2.689,2.654c-3.782,3.791-8.05,0.477-9.663-1.059l-40.128-40.284c0,0-5.647-5.612-1.57-9.655l2.507-2.498
        c1.57-1.314,5.309-3.322,10.227,1.609l17.479,17.553l-1.744-10.305l-3.019-27.181c0,0-0.468-4.06,2.611-7.13l4.068-4.06
        c0,0,4.936-4.975,5.751,2.173c0,0,0.017,0,0.017,0.03l3.886,33.396l40.241,9.212C341.043,264.849,344.461,266.358,341.226,269.603
        L341.226,269.603z M289.334,318.874c-2.412,4.753-5.508,9.264-9.741,13.497c-7.469,7.443-15.423,10.852-23.881,10.253
        c-8.466-0.572-16.916-5.1-25.347-13.566c-5.309-5.335-8.995-10.947-11.042-16.915c-2.056-5.951-2.333-11.78-0.815-17.557
        c1.509-5.769,4.693-11.064,9.577-15.944c3.912-3.886,8.813-6.857,14.244-9.282c1.804-0.291,5.126-0.316,6.705,3.37l0.616,1.436
        c2.698,6.306-4.849,8.415-4.849,8.415l0.009,0.026c-0.312,0.152-0.659,0.273-0.98,0.417c-2.611,1.236-4.849,2.797-6.723,4.684
        c-4.094,4.051-5.734,8.77-4.918,14.079c0.824,5.3,3.964,10.687,9.438,16.187c11.39,11.433,21.391,12.891,29.97,4.328
        c2.498-2.472,4.91-6.089,7.287-10.175c0.026,0,0.173,0.052,0.173,0.052s3.131-5.396,6.072-2.454l1.813,1.839
        C290.028,314.415,289.837,317.174,289.334,318.874L289.334,318.874z M240.714,369.711l-2.368,2.359
        c-3.678,3.66-8.492-0.564-9.663-1.691l-39.859-40.028c0,0-5.495-5.665-0.98-10.141l1.674-1.666c0,0,4.779-4.45,11.35,2.16
        l39.135,39.282C244.323,364.341,242.146,368.054,240.714,369.711L240.714,369.711z M208.58,401.72l-2.923,2.896
        c-3.279,3.262-6.632,1.172-8.34-0.347l-37.743-37.907l-9.503,9.481c-4.814,4.806-8.926-0.147-8.926-0.147l-1.262-1.284
        c0,0-3.422-3.07,1.783-8.232l28.903-28.795c0,0,4.55-5.031,8.883-0.693l0.989,0.988c3.24,3.444,1.219,6.559-0.178,8.067
        l-9.807,9.785l36.286,36.454C211.243,396.497,209.976,399.889,208.58,401.72L208.58,401.72z M559.666,182.754l-31.593-31.718
        c-12.708-12.764-32.295-2.355-32.295-2.355l-0.052-0.035c-20.411,8.501-44.804,4.493-61.381-12.144
        c-16.586-16.664-20.498-41.108-11.875-61.494c0.078-0.195,0.078-0.321,0.173-0.525c7.365-19.504-3.114-32.304-6.211-35.539
        l-31.739-31.87c-13.871-13.918-28.93-1.262-32.607,2.281L9.422,350.648c-19.036,18.94-3.34,34.069-3.34,34.069l36.763,36.896
        c0,0,9.069,9.819,26.674,2.706c0.564-0.226,0.989-0.312,1.475-0.468c20.932-10.08,46.734-6.61,64.043,10.756
        c16.49,16.568,20.498,40.822,12.075,61.138h0.026c0,0-10.696,19.848,2.173,32.755l32.195,32.339
        c2.29,2.255,19.214,17.453,40.341-3.557l340.456-339.085C567.438,212.221,575.453,198.602,559.666,182.754L559.666,182.754z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconTicket',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
