import { VueConstructor, PluginObject } from 'vue'
import VueGtag from 'vue-gtag'

export type useGaConfig = {
  v: VueConstructor;
  lib?: PluginObject<unknown>;
  router: object;
  isProd?: boolean;
}

export const GA_ID = 'UA-126987187-1'

export function onAfterTrack () {
  console.log('[analytics] successful track')
}

export function useGA ({ v, lib = VueGtag, router, isProd = false }: useGaConfig): void {
  try {
    const config = {
      enabled: isProd,
      config: {
        id: GA_ID
      },
      onAfterTrack
    }

    v.use(
      lib,
      config,
      router // passing router enables auto-tracking
    )
  } catch (e) {
    console.warn('[analytics] tracking disabled due to error:', e)
  }
}
