<template>
  <div id="properties-col-container">
    <!-- LEFT COL: EITHER PROPERTY OR GROUP SEARCH -->
    <div id="properties-left-col" class="properties-col">

      <md-content id="select-all-container">
        <md-checkbox v-model="selectAll">{{ copy.selectAll }}</md-checkbox>
      </md-content>

      <md-content v-if="showFilter" id="filter-container">
        <md-field>
          <md-input v-model="filterTerm" :placeholder="'Filter By Name'"></md-input>
        </md-field>
        <md-progress-spinner
          class="md-accent center"
          md-mode="indeterminate"
          :md-diameter="30"
          v-show="!fetchHotelsSuccess"
        />
      </md-content>
      <div v-if="propertySearch">
        <md-content class="options-container md-scrollbar">
          <div v-if="showNoResults" id="no-results">{{ copy.noResults }}</div>
          <md-checkbox
            v-for="hotel in hotelOpts"
            v-model="hotels"
            :value="hotel.cradleId"
            :key="hotel.cradleId"
            :disabled="!fetchHotelsSuccess"
          >
            {{ hotel.name }}
          </md-checkbox>
        </md-content>
      </div>

      <div v-if="!propertySearch">
        <md-content class="options-container md-scrollbar">
          <div v-if="showNoResults" id="no-results">{{ copy.noResults }}</div>
          <md-checkbox
            v-for="group in groupOpts"
            v-model="groups"
            :value="group.id"
            :key="group.id"
          >
            {{ group.name }}
          </md-checkbox>
        </md-content>
      </div>
    </div>

    <!-- RIGHT COLUMN: META INFO -->
    <div id="properties-right-col" class="properties-col">

      <!-- properties -->
      <div v-if="propertySearch">
        <div class="selected-column-title">{{ selectedPropertiesCopy }}</div>
        <md-content id="selected-container" class="md-scrollbar">
          <div v-for="name in modifiedHotelNames" :key="name">
            {{ name }}
          </div>
        </md-content>
      </div>

      <!-- groups -->
      <div v-if="!propertySearch">
        <div class="selected-column-title">{{ selectedGroupsCopy }}</div>
        <md-content id="selected-container" class="md-scrollbar">
          <div v-for="name in MODIFIED_GROUP_NAMES" :key="name">
            {{ name }}
          </div>
        </md-content>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PropertySearchComponent',
  props: {
    searchData: {
      required: true,
      type: Object,
    },
    propertySearch: {
      required: true,
      type: Boolean,
    },
  },
  data () {
    return {
      filterTerm: '',
      selectAll: false,
      copy: {
        selectedProperties: 'Selected Properties',
        selectedGroups: 'Selected Groups',
        selectAll: 'Select All',
        noResults: 'No Results'
      }
    }
  },
  computed: {
    ...mapGetters('designDuplication', [
      'MODIFIED_HOTEL_NAMES',
      'MODIFIED_GROUP_NAMES'
    ]),
    ...mapGetters('hotel', [
      'hotelObjsByCradleId',
      'hotelGroupList',
    ]),
    ...mapState('oprm', [
      'reward'
    ]),
    ...mapState('designDuplication', [
      'design'
    ]),
    ...mapState('hotel', [
      'fetchHotelsSuccess'
    ]),
    modifiedHotelNames () {
      return this.MODIFIED_HOTEL_NAMES(this.design.hotel_ids)
    },
    hotels: {
      get () {
        return this.design.hotel_ids
      },
      set (hotelIds) {
        this.$store.commit('designDuplication/SET_HOTEL_IDS', hotelIds)
      }
    },
    groups: {
      get () {
        return this.design.hotel_group_ids
      },
      set (groupIds) {
        this.$store.commit('designDuplication/SET_HOTEL_GROUP_IDS', groupIds)
      }
    },
    hotelOpts () {
      if (this.filterTerm) {
        const lowerterm = this.filterTerm.toLowerCase().replace(/\s/g, '')
        return Object.values(this.hotelObjsByCradleId).filter(hotel => {
          return typeof (hotel.name) === 'string' &&
            hotel.name.toLowerCase().replace(/\s/g, '').indexOf(lowerterm) > -1
        })
      } else {
        return Object.values(this.hotelObjsByCradleId)
      }
    },
    groupOpts () {
      if (this.filterTerm) {
        const lowerterm = this.filterTerm.toLowerCase()
        return this.hotelGroupList.filter(group => {
          return typeof (group.name) === 'string' &&
            group.name.toLowerCase().replace(' ', '').indexOf(lowerterm) > -1
        })
      } else {
        return Object.values(this.hotelGroupList)
      }
    },
    selectedPropertiesCopy () {
      return this.design.hotel_ids.length > 0 ? `${this.copy.selectedProperties}: (${this.hotels.length})` : ''
    },
    selectedGroupsCopy () {
      return this.design.hotel_group_ids.length > 0 ? `${this.copy.selectedGroups}: (${this.design.hotel_group_ids.length})` : ''
    },
    showFilter () {
      return Object.values(this.hotelObjsByCradleId).length > 20 || this.hotelGroupList.length > 0
    },
    showNoResults () {
      return this.filterTerm.length > 0 &&
        ((this.propertySearch && this.hotelOpts.length === 0) ||
        (!this.propertySearch && this.groupOpts.length === 0))
    },
  },
  watch: {
    selectAll (newValue) {
      if (this.propertySearch) {
        let hotelIds
        if (newValue === true) {
          hotelIds = this.hotelOpts.map(hotel => hotel.cradleId)
        } else {
          hotelIds = []
        }
        return this.$store.commit('designDuplication/SET_HOTEL_IDS', hotelIds)
      } else {
        let groupIds
        if (newValue === true) {
          groupIds = this.groupOpts.map(group => group.id)
        } else {
          groupIds = []
        }
        return this.$store.commit('designDuplication/SET_HOTEL_GROUP_IDS', groupIds)
      }
    }
  },
}
</script>

<style lang="sass">
#properties-col-container
  display: flex
  flex-direction: row
  min-width: 700px

#properties-left-col
  width: 350px

#properties-right-col
  width: 350px
  margin-left: 30px

.selected-column-title
  margin-bottom: 10px
  margin-top: 5px

.options-container.md-content
  display: flex
  flex-direction: column
  min-width: 100%
  min-height: 200px
  max-height: 400px
  overflow: auto
  background-color: #eee
  padding: 10px
  margin-bottom: 16px
  border-bottom-left-radius: 3px
  border-bottom-right-radius: 3px
  .md-checkbox
    margin: 8px 8px 8px 4px
    font-size: 12px !important
  .md-checkbox.md-theme-default.md-checked .md-checkbox-container
    background-color: black !important
    border-color: black !important

#select-all-container.md-content
  padding: 0 10px
  .md-checkbox
    margin: 8px 8px 8px 4px
    font-size: 12px !important
  .md-checkbox.md-theme-default.md-checked .md-checkbox-container
    background-color: black !important
    border-color: black !important

#no-results
  font-size: 12px
  padding: 0px 6px

#filter-container
  display: flex
  align-items: flex-start
  padding: 0
  .md-field:before
    height: 0px !important
  .md-field:after
    height: 0px !important
  .md-field
    background-color: #eee
    border-top-left-radius: 3px
    border-top-right-radius: 3px
    padding: 0
    margin: 0
    min-height: 32px
    input
      font-size: 12px
      padding: 10px 14px
      margin: 0
    input::placeholder
      font-size: 12px

.auto-item
  font-family: Lato !important
</style>
