<template lang="html">
  <div id="promotions-table-container">
    <div v-if="!promotionsFetchSuccess" class="progress-spinner">
      <md-progress-spinner
        class="md-accent"
        md-mode="indeterminate"
      />
    </div>
    <md-table v-if="promotionsFetchSuccess" v-model="promotionsList">
      <PromotionsTableRow
        slot="md-table-row"
        slot-scope="{ item }"
        :promotion="item"
        @updateActive="updateActive"
      />
      <div class="empty-list-wrapper">
        <md-table-empty-state :md-label="copy.empty">
          <StepperButton
            :style="{ width: '236px' }"
            @clickEvent="PromotionsContainer_navToPromotionsWizard"
            :disabled="false"
            :copy="copy.emptyStateButton"
          />
        </md-table-empty-state>
      </div>
    </md-table>
  </div>
</template>

<script>
import PromotionsTableRow from '@/components/merchant-dashboard/PromotionsTableRow'
import { mapGetters, mapState } from 'vuex'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton'

export default {
  name: 'PromotionsTable',
  components: {
    PromotionsTableRow,
    StepperButton,
  },
  inject: ['PromotionsContainer_navToPromotionsWizard'],
  data () {
    return {
      copy: {
        empty: 'No promotions currently.',
        emptyStateButton: 'Create Promotion',
      }
    }
  },
  computed: {
    ...mapState('merchant', [
      'promotionsFetchSuccess',
    ]),
    ...mapGetters('merchant', [
      'promotionsList',
    ]),
  },
  methods: {
    updateActive (e) {
      console.log('<PromotionsTable> updateActive event:', e)
      this.$store.dispatch('merchant/UPDATE_PROMOTION', {
        uuid: e.uuid,
        properties: {
          active: e.active
        }
      })
    }
  }
}
</script>

<style lang="sass">
@import '../navigation/menu-styles'
</style>

<style lang="sass" scoped>
.progress-spinner
  text-align: center
  margin: 20px 0
</style>
