export const storageUtils = {
  getWithInitialValue ({ initialValue, key, storage }) {
    const { error } = storageUtils.checkStorage(storage)
    if (error) {
      return
    }
    let value
    const stored = storageUtils.get({ storage, key })
    if (stored === undefined || stored === null) {
      value = initialValue
      storageUtils.set({ storage, key, value })
    } else {
      value = JSON.parse(stored)
    }
    return value
  },
  get ({ storage, key }) {
    const { error } = storageUtils.checkStorage(storage)
    if (error) {
      return
    }
    return window[storage].getItem(key)
  },
  set ({ storage, key, value }) {
    const { error } = storageUtils.checkStorage(storage)
    if (error) {
      return
    }
    window[storage].setItem(key, value)
  },
  checkStorage (storage) {
    let error
    if (!window[storage]) {
      error = new Error('Cannot use storage: ' + storage)
      console.warn(error)
    }
    return { error }
  }
}
