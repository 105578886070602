import Vue from 'vue'

export const mutations = {
  SET_CRADLE_HOTEL_OBJ (state, hotel) {
    Vue.set(state, 'cradleHotelObj', hotel)
  },
  SET_CRADLE_HOTELS_OBJ (state, hotelsObj) {
    Vue.set(state, 'cradleHotelsObj', { ...state.cradleHotelsObj, ...hotelsObj })
  },
  ADD_CRADLE_HOTEL_OBJ (state, hotel) {
    Vue.set(state.cradleHotelsObj, hotel.id, hotel)
  },
  SET_FUNNEL_UXS (state, funnelUxs) {
    Vue.set(state, 'fuxs', funnelUxs)
  },
  SET_TEMPLATE_FIELDS (state, { templateName, channel, fields }) {
    Vue.set(state.templateFields[channel], templateName, fields)
  },
  SET_BOOKING_ENGINES_OBJ (state, bookingEnginesObj) {
    Vue.set(state, 'bookingEnginesObj', bookingEnginesObj)
  },
  SET_HOTEL_GROUPS_OBJ (state, hotelGroupsObj) {
    Vue.set(state, 'hotelGroupsObj', hotelGroupsObj)
  },
  SET_CRADLE_TEMPLATES (state, allTemplateOpts) {
    state.allTemplateOpts = allTemplateOpts
  },
  SET_DEFAULT_TEMPLATE_DATA (state, { context, templateData }) {
    Vue.set(state.defaultTemplateData, context, templateData)
  },
  FETCH_HOTELS_SUCCESS (state, status) {
    state.fetchHotelsSuccess = status
  }
}
