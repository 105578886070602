import 'amazon-cognito-js'
import AWS from 'aws-sdk'
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoRefreshToken
} from 'amazon-cognito-identity-js'
import generator from 'generate-password'
import { UserParser } from '@/parsers'

const TOKEN_EXPIRATION_BUFFER = 5 * 60 * 1000 // 5min
const DEV_TIMEOUT_KEY = '__sw_dev_timeout__'

export default class CognitoAdapter {
  constructor (config) {
    this.user = undefined // set when logged in
    this.cognitoJwtToken = undefined // set when logged in
    this.cognitoJwtIdTokenExpiration = undefined
    this.cognitoJwtAccessToken = undefined
    this.cognitoJwtAccessTokenExpiration = undefined
    this.config = config
    this.userPool = new CognitoUserPool({
      UserPoolId: this.config.USER_POOL_ID,
      ClientId: this.config.CLIENT_ID
    })
    console.log('this.userPool', this.userPool)
    console.log('this.config.USER_POOL_ID,', this.config.USER_POOL_ID,)
    console.log('this.config.CLIENT_ID', this.config.CLIENT_ID)
    this.cognitoIdpCredentials = undefined
    this.cognitoIdp = undefined
  }

  getTokenRefreshTimeout () {
    if (process.env.VUE_APP_MODE !== 'production' &&
      window.localStorage && window.localStorage.getItem(DEV_TIMEOUT_KEY)
    ) {
      console.log('[getTokenRefreshTimeout] using dev timeout from localStorage')
      return window.localStorage.getItem('__sw_dev_timeout__')
    }
    const timeRemaining = this.getTokenTimeRemaining()
    const timeout = timeRemaining - TOKEN_EXPIRATION_BUFFER

    return timeout
  }

  getTokenTimeRemaining () {
    const expSeconds = this.cognitoJwtIdTokenExpiration < this.cognitoJwtAccessTokenExpiration
      ? this.cognitoJwtIdTokenExpiration
      : this.cognitoJwtAccessTokenExpiration
    console.log('[getTokenTimeRemaining] cognitoJwtIdTokenExpiration', this.cognitoJwtIdTokenExpiration)
    console.log('[getTokenTimeRemaining] cognitoJwtAccessTokenExpiration', this.cognitoJwtAccessTokenExpiration)
    const now = new Date(Date.now())
    const exp = new Date(expSeconds * 1000)
    console.log('[getTokenTimeRemaining] session will expire at:', exp)

    return exp - now
  }

  tokenToExpireSoon () {
    return this.getTokenTimeRemaining() < TOKEN_EXPIRATION_BUFFER
  }

  async loginUser ({ username, password }) {
    this._clearCache()
    const response = await this._authenticateUserGetToken({ username, password })
    if (response.session) {
      await this._refreshCredentials({ session: response.session })
    }
    return {
      error: response.error,
      session: response.session,
    }
  }

  recoverPassword ({ username }) {
    return new Promise((resolve) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: this.userPool
      })

      cognitoUser.forgotPassword({
        onSuccess: () => {
          resolve({
            error: undefined,
            success: true,
          })
        },
        onFailure: err => {
          console.log('forgotPassword err', err)
          resolve({
            error: err,
            success: false,
          })
        }
      })
    })
  }

  /*
  verify temporary code sent to user when forgotPassword is invoked
  */
  confirmPassword ({ username, code, newPw }) {
    return new Promise((resolve) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: this.userPool
      })

      cognitoUser.confirmPassword(code, newPw, {
        onSuccess: () => resolve({
          error: undefined,
          success: true,
        }),
        onFailure: err => {
          console.log('confirmPassword err:', err)
          resolve({
            error: err,
            success: undefined,
          })
        }
      })
    })
  }

  async verifySession () {
    const cognitoUser = this.userPool.getCurrentUser()
    console.log('verifySession - cognitoUser', cognitoUser)

    if (cognitoUser) {
      return cognitoUser.getSession(async (error, session) => {
        console.log('getSession', error, session)
        this.user = cognitoUser
        if (session && session.isValid()) {
          await this._refreshCredentials({ session })
        }
        return {
          error,
          isValid: session && session.isValid(),
        }
      })
    } else {
      return {
        error: new Error('No Cognito user found'),
        isValid: false,
      }
    }
  }

  async refreshSession () {
    return new Promise(resolve => {
      const cognitoUser = this.userPool.getCurrentUser()
      if (cognitoUser) {
        const keyPrefix = 'CognitoIdentityServiceProvider.' +
          this.userPool.getClientId() +
          '.' +
          cognitoUser.username
        const refreshTokenKey = keyPrefix + '.refreshToken'
        const refreshToken = new CognitoRefreshToken({
          RefreshToken: cognitoUser.storage.getItem(refreshTokenKey)
        })
        if (refreshToken) {
          cognitoUser.refreshSession(
            refreshToken,
            async (error, session) => {
              this.user = cognitoUser
              if (session && session.isValid()) {
                await this._refreshCredentials({ session })
              }
              resolve({
                error,
                success: session && session.isValid(),
              })
            }
          )
        } else {
          resolve({
            error: new Error('No cognito refresh token found'),
            success: false,
          })
        }
      } else {
        resolve({
          error: new Error('No Cognito user found'),
          success: false,
        })
      }
    })
  }

  _authenticateUserGetToken ({ username, password }) {
    return new Promise((resolve) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      })
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: this.userPool
      })
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          this.user = cognitoUser
          resolve({
            error: undefined,
            session: result,
          })
        },
        onFailure: (error) => {
          resolve({
            error,
            session: undefined
          })
        },
      })
    })
  }

  logout () {
    try {
      // wrap in try/catch. if !authd user navs to route this can throw
      AWS.config.credentials.clearCachedId()
    } catch (error) {
      console.log(error)
    }

    const user = this.userPool.getCurrentUser()
    if (user) user.signOut()
  }

  _clearCache () {
    AWS.config.region = this.config.REGION
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: this.config.IDENTITY_POOL_ID
    })
    AWS.config.credentials.clearCachedId()
  }

  async _refreshCredentials ({ session }) {
    this.cognitoJwtToken = session.idToken.jwtToken
    this.cognitoJwtIdTokenExpiration = session.idToken.payload.exp
    this.cognitoJwtAccessToken = session.accessToken.jwtToken
    this.cognitoJwtAccessTokenExpiration = session.accessToken.payload.exp

    AWS.config.region = this.config.REGION
    // store to reuse
    this.cognitoIdpCredentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: this.config.IDENTITY_POOL_ID,
      Logins: {
        [this.config.LOGIN_KEY]: session.idToken.jwtToken
      }
    })
    this.cognitoIdp = new AWS.CognitoIdentityServiceProvider({
      apiVersion: '2016-04-18',
      region: this.config.REGION,
      credentials: this.cognitoIdpCredentials,
    })
    AWS.config.credentials = this.cognitoIdpCredentials
    await AWS.config.credentials.refreshPromise()
  }

  changePassword (userData) {
    return new Promise((resolve) => {
      this._clearCache()

      const { username, temporaryPw, newPw } = userData

      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: temporaryPw
      })

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: this.userPool
      })

      // possibly redundant
      AWS.config.region = this.config.REGION
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.config.IDENTITY_POOL_ID
      })

      return cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          console.log('changePassword, authUser, result', result)
          AWS.config.credentials.refresh(err => {
            if (err) {
              console.log('changePassword authenticateUser', err)
              resolve({
                error: err,
                success: false
              })
            } else {
              resolve({
                error: undefined,
                success: true
              })
            }
          })
        },
        newPasswordRequired: (userAttributes) => {
          console.log('userAttributes', userAttributes)
          /*
            need to remove this immutable property so Cognito
            can assign a truthy value
          */
          console.log('userAttributes.email_verified', userAttributes.email_verified)
          delete userAttributes.email_verified

          cognitoUser.completeNewPasswordChallenge(newPw, userAttributes, {
            onSuccess: result => {
              console.log('changePassword, authUser, result', result)
              AWS.config.credentials.refresh(err => {
                this._clearCache()
                if (err) {
                  console.log('changePassword onSuccess', err)
                  resolve({
                    error: err,
                    success: false
                  })
                } else {
                  resolve({
                    error: undefined,
                    success: true
                  })
                }
              })
            },
            newPasswordRequired: (userAttributes) => {
              console.log('(2) userAttributes.email_verified', userAttributes.email_verified)
              delete userAttributes.email_verified

              cognitoUser.completeNewPasswordChallenge(newPw, userAttributes, this.newPasswordRequired)
            },
            onFailure: err => {
              console.log('onFailure', err)
              resolve({
                error: err,
                success: false
              })
            }
          })
        },
        onFailure: err => {
          console.log('changePassword onFailure', err)
          resolve({
            error: err,
            success: false
          })
        }
      })
      //
    })
  }

  getUser () {
    return new Promise((resolve) => {
      this.user.getUserAttributes((error, attrsResult) => {
        if (error) {
          console.log(error)
          resolve({
            error,
            userProfile: undefined
          })
        }

        try {
          const parsed = UserParser.toInternal({ Attributes: attrsResult })
          if (!parsed.attributes.customProfile) {
            throw new Error('Failed to get user profile from Cognito user object.')
          }
          const userProfile = {
            hssEditorAccess: parsed.attributes.hssEditorAccess,
            reportingAccess: parsed.attributes.reportingAccess,
            oprmAccess: parsed.attributes.oprmAccess,
            edmAccess: parsed.attributes.edmAccess,
            rewardAlgoAccess: parsed.attributes.rewardAlgoAccess,
            flexAccess: parsed.attributes.flexAccess,
            ...parsed.attributes.customProfile,
            // must be positioned after spread of customProfile
            groups: parsed.attributes.groups,
          }
          resolve({
            error: undefined,
            userProfile,
          })
        } catch (error) {
          console.log(error)
          resolve({
            error,
            userProfile: undefined
          })
        }
      })
    })
  }

  getCognitoToken () {
    return this.cognitoJwtToken
  }

  getAccessToken () {
    return this.cognitoJwtAccessToken
  }

  updateUserAttributes ({ user, isAdminEditing }) {
    const UPDATE_USER_ATTRIBUTES = 'updateUserAttributes'
    const ADMIN_USER_USER_ATTRIBUTES = 'adminUpdateUserAttributes'

    const rawUser = UserParser.toRepresentation(user)

    let method
    let params

    if (isAdminEditing) {
      method = ADMIN_USER_USER_ATTRIBUTES
      params = {
        Username: rawUser.Username,
        UserPoolId: this.config.USER_POOL_ID,
        UserAttributes: rawUser.Attributes,
      }
    } else {
      method = UPDATE_USER_ATTRIBUTES
      params = {
        AccessToken: this.getAccessToken(),
        UserAttributes: rawUser.Attributes,
      }
    }
    console.log('[updateUserAttributes] params:', params, 'method:', method)

    return new Promise((resolve, reject) => {
      this.cognitoIdp[method](params, (err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve({ error: undefined, data: data })
        }
      })
    })
  }

  generatePassword () {
    return generator.generate({
      length: 10,
      numbers: true,
      symbols: false,
      uppercase: true,
      excludeSimilarCharacters: true,
      strict: true,
    })
  }
}
