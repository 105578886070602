var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-size-100",attrs:{"id":"oprm-redemption-container"}},[_c('CustomStepper',{attrs:{"editing":_vm.editing,"step-headers":_vm.stepHeaders},on:{"done":_vm.create},scopedSlots:_vm._u([{key:_vm.dynamicSlotNames[0],fn:function(ref){
var slotScope = ref.slotScope;
return [_c('RedemptionInstructionsStep',{attrs:{"index":slotScope.idx,"editing":_vm.editing}})]}},{key:_vm.dynamicSlotNames[1],fn:function(ref){
var slotScope = ref.slotScope;
return [_c('RedemptionTermsStep',{attrs:{"index":slotScope.idx,"editing":_vm.editing}})]}},{key:_vm.dynamicSlotNames[2],fn:function(ref){
var slotScope = ref.slotScope;
return [_c('RedemptionRewardCostStep',{attrs:{"index":slotScope.idx,"editing":_vm.editing,"meta":_vm.retainFeatureCopy}})]}},{key:_vm.dynamicSlotNames[3],fn:function(ref){
var slotScope = ref.slotScope;
return [_c('RedemptionExpirationStep',{attrs:{"index":slotScope.idx,"editing":_vm.editing,"meta":_vm.retainFeatureCopy}})]}},{key:_vm.dynamicSlotNames[4],fn:function(ref){
var slotScope = ref.slotScope;
return [_c('AdvanceNoticeStep',{attrs:{"index":slotScope.idx,"editing":_vm.editing,"meta":_vm.retainFeatureCopy}})]}},{key:_vm.dynamicSlotNames[5],fn:function(ref){
var slotScope = ref.slotScope;
return [_c('SeasonalRewardStep',{attrs:{"index":slotScope.idx,"editing":_vm.editing,"meta":_vm.retainFeatureCopy}})]}},{key:_vm.dynamicSlotNames[6],fn:function(ref){
var slotScope = ref.slotScope;
return [_c('RedemptionActivateStep',{attrs:{"index":slotScope.idx,"editing":_vm.editing,"disable-last-step":_vm.isRewardCreating}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }