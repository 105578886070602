













































































































import { mapState, mapGetters } from 'vuex'
import { Workflow, WorkflowMap, NodeOperator, CampaignEventConfig, UTMEventName, EventName } from '@/types'
import AddWithLine from '@/components/icons/AddWithLine.vue'
import AddWithArrow from '@/components/icons/AddWithArrow.vue'
import SplitPathLine from '@/components/icons/SplitPathLine.vue'
import SvgClose from '@/components/icons/SvgClose.vue'
import Node from '@/components/hotel-dashboard/workflow-manager/dag/Node.vue'
import { MODULE_STRINGS, UTM_EVENT_NAMES } from '@/constants'
import { triggerNodeDatetime } from '@/utils/dates'
import { isBookingMilestone, isLifetimeValueMilestone, isRoomNightsMilestone } from '@/utils/dag'

const { ECM, RAM } = MODULE_STRINGS

/**
 * @note the width is fixed when showing 2+ nodes
 */
const NODE_WIDTH = 220

export default {
  name: 'NodeTree',
  components: {
    AddWithLine,
    AddWithArrow,
    SplitPathLine,
    SvgClose,
    Node
  },
  inject: [
    'DagContainer__pushNode',
    'DagContainer__add',
    'DagContainer__delete',
    'DagContainer__edit',
  ],
  props: {
    registerWithParent: {
      type: Function,
      default: null,
      required: false,
    },
    nodeId: {
      type: Number,
      required: true,
    },
    depth: {
      type: Number,
      required: true
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'max-content'
    }
  },
  data () {
    return {
      hover: false,
      registeredNodes: [] as number[],
      childContainerWidth: 0,
      NODE_WIDTH,
      ref: `node-tree-${this.depth}-${this.nodeId}`,
      childContainerRef: `node-tree-child-container-${this.depth}-${this.nodeId}`,
      iconStyle: {
        color: this.$theme.colors.gray['200'],
        hoverColor: this.$theme.colors.gray['400'],
        width: 8, // important: consult circle-icon-wrapper class below when changing
        fill: '#FFFFFF',
        strokeWidth: '2'
      },
    }
  },

  computed: {
    ...mapState(ECM, [
      'dag'
    ]),
    ...mapGetters(ECM, [
      'GET_CAMPAIGN_EVENT_TYPE',
      'GET_CAMPAIGN_TRIGGER',
      'BOOKING_OFFER_ID',
      'AUTO_RELEASE_ID'
    ]),
    ...mapGetters(RAM, [
      'GET_ALGO'
    ]),
    id (): string {
      return `node-container-${this.nodeId}`
    },
    children (): number[] {
      const children = [...this.node.children]
      return children.sort((a, b) => (a - b))
    },
    columnGap (): string {
      return this.node.children.length === 0 ? '0px' : '20px'
    },
    columns (): number {
      if (this.node.children.length < 1) {
        return 1
      }
      return this.node.children.length
    },
    /**
     * specify a fixed width so that split branch siblings are same width
     * or pass null to allow nested NodeTree to use default width prop value
     */
    childNodeWidth (): number | void {
      return this.node.children.length > 1 ? NODE_WIDTH : null
    },
    node (): Workflow | Pick<Workflow, 'children'> {
      const node = (this.dag as WorkflowMap)[this.nodeId]
      return node || { children: [] }
    },
    showDelete (): boolean {
      return this.depth > 1
    },
    showSplitLine (): (node: Workflow) => boolean {
      return (node: Workflow) => node.children.length === 2
    },
    showAddWithLine (): (node: Workflow) => boolean {
      return (node: Workflow) => node.children.length === 1
    },
  },
  watch: {
    /**
     * when all children have been registered we set the width of the child
     * container to determine length of split path arrow.
     */
    async registeredNodes (newValue: number[]): Promise<void> {
      if (newValue.length >= this.node.children.length) {
        await this.$nextTick()
        this.setChildContainerWidth()
      }
    },
    async children (): Promise<void> {
      await this.$nextTick()
      this.handleUpdate()
    }
  },
  async mounted (): Promise<void> {
    this.resetRegisteredNodes()
    await this.$nextTick()
    this.DagContainer__pushNode({
      ref: this.ref,
      depth: this.depth,
      el: this.$el,
      children: this.node.children?.length || 0,
      childrenContainer: this.$refs[this.childContainerRef],
    })

    /**
     * used to tell when line width prop should be calculated
     */
    if (typeof this.registerWithParent === 'function') {
      this.registerWithParent(this.nodeId)
    }
  },
  methods: {
    setHover (value: boolean): void {
      this.hover = value
    },
    handleUpdate (): void {
      this.setChildContainerWidth()
      if (this.depth === 1) return
      this.$emit('update')
    },
    resetRegisteredNodes (): void {
      this.registeredNodes = [] as number[]
    },
    setChildContainerWidth (): void {
      const childContainer = this.$refs[this.childContainerRef] as HTMLDivElement | void
      if (childContainer) {
        this.childContainerWidth = childContainer.clientWidth
      }
    },
    register (nodeId: number): void {
      this.registeredNodes.push(nodeId)
    },
    getTitle (): string {
      if (!this.node) return ''
      const eventType = this.GET_CAMPAIGN_EVENT_TYPE({ id: this.node.triggerTypeId }) as CampaignEventConfig | void
      if (!eventType) return ''
      const { category, name } = eventType
      if (this.depth === 1) return 'Trigger'
      if (category === 'Test Event') return 'Run Test'
      if (category === 'Special Offer Event') {
        if (this.node.isPromotion) {
          return 'Promotion Offer'
        }
        if (this.node.triggerTypeId === this.BOOKING_OFFER_ID) {
          return 'Booking Offer'
        }
        if (this.node.triggerTypeId === this.AUTO_RELEASE_ID) {
          return 'Milestone Offer'
        }
      }
      if (category === 'Status Event') return 'Check Status'
      if (category === 'Action Event' && name === 'Send Email') return 'Email'
      if (['Delay Event', 'Action Event'].includes(category)) return name
      return ''
    },
    getAlgoName (): string {
      const algo = this.GET_ALGO({ id: this.node.rewardAlgoId }) || {}
      return `Offer rewards from "${algo.identifier || 'algorithm'}"`
    },
    getBody (): string {
      if (!this.node) return ''
      const { triggerTypeId } = this.node

      if (triggerTypeId === this.BOOKING_OFFER_ID) {
        return this.getAlgoName()
      }

      const eventType = this.GET_CAMPAIGN_EVENT_TYPE({ id: triggerTypeId }) as CampaignEventConfig | void
      if (!eventType) return ''
      const { category, copy, copyForOperatorNot, name } = eventType
      if (category === 'Test Event') return '50% of Audience'
      if (category === 'Milestone Event') {
        if (isBookingMilestone(name)) {
          return `Milestone: ${this.node.param} total bookings`
        } else if (isRoomNightsMilestone(name)) {
          return `Milestone: ${this.node.param} total room nights`
        } else if (isLifetimeValueMilestone(name)) {
          return `Milestone: $${this.node.param} LTV`
        } else {
          return 'Milestone'
        }
      }
      if (category === 'Scheduled Event') return triggerNodeDatetime(this.node)
      if (this.depth === 1) return copy
      if (category === 'Special Offer Event') return `${eventType.copy} "${this.node.displayName}"`
      if (category === 'Action Event' && name === 'Send Email') return `Send "${this.node.emailDesignName}"`
      if (category === 'Action Event') return copy
      if (category === 'Status Event') {
        if (UTM_EVENT_NAMES.includes(name as UTMEventName)) {
          if (this.node.operator === 'nt' as NodeOperator) {
            return `User did not enroll via UTM source "${this.node.param}"`
          }
          if (this.node.operator === 'no' as NodeOperator) {
            return `User enrolled via UTM source "${this.node.param}"`
          } else {
            console.log('node operator for UTM event is neither "nt" or "no", check config')
            return 'UTM default'
          }
        }
        if (name as EventName === 'Has Booking Channel') {
          if (this.node.operator === 'nt' as NodeOperator) {
            return `Booking does not have channel "${this.node.param}"`
          }
          if (this.node.operator === 'no' as NodeOperator) {
            return `Booking has channel "${this.node.param}"`
          } else {
            console.log('node operator for Booking channel is neither "nt" or "no", check config')
            return 'Booking channel default'
          }
        }
        if (name as EventName === 'Has Rate Type') {
          if (this.node.operator === 'nt' as NodeOperator) {
            return `Booking does not have rate type "${this.node.param}"`
          }
          if (this.node.operator === 'no' as NodeOperator) {
            return `Booking has rate type "${this.node.param}"`
          } else {
            console.log('node operator for Rate Type is neither "nt" or "no", check config')
            return 'Booking rate type default'
          }
        }
        if (this.node.operator === 'nt' as NodeOperator) return copyForOperatorNot
        return copy
      }
      if (category === 'Delay Event') return `Wait ${this.node.param}`
      return ''
    },
  }
}
