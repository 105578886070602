<template>
  <svg
    id="icon-dining"
    viewBox="0 0 380.721 380.721"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.dining}">

    <title id="title">dining icon</title>
    <desc id="desc">dining icon</desc>

    <g>
      <path d="M190.372,29.813c-88.673,0-160.546,71.873-160.546,160.547c0,63.891,
      37.418,118.894,91.445,144.734l5.025-97.098c0,0-9.452-3.102-11.521-4.112c
      -20.048-8.831-34.612-34.833-34.612-68.58c0-36.825,28.559-68.544,56.17-68.806
      c0.035,0,0.035,0,0.058,0c0.012,0,0.035,0,0.046,0c27.617,0.261,56.177,31.98,
      56.177,68.806c0,33.747-14.582,59.738-34.589,68.58c-0.157,0.069-11.613,4.229
      -11.613,4.229l5.612,107.961c12.315,3.031,25.119,4.81,38.37,4.81c17.87,0,
      34.984-3.044,51.041-8.424 l4.973-96.214c-13.105-2.882-24.283-11.225-31.289
      -21.692c-8.679-13.001,6.681-128.067,6.681-128.067h9.992v107.978h9.923V96.499
      h10.852v107.99h9.876V96.499h11.259v107.99h9.91V96.499h7.668c0,0,15.36,115.066,
      6.67,128.067c-6.937,10.364-18.01,18.683-30.952,21.634c-0.232,0.082-0.442,
      0.162-0.442,0.162l4.322,82.761c47.823-27.804,80.053-79.46,80.053-138.762C350.907
      ,101.687,279.034,29.813,190.372,29.813z"/>
      <path d="M103.662,148.98c0.575-2.341,1.348-4.566,1.383-4.606c0.25-0.877
      -0.215-1.772-1.168-2.341c-0.906-0.488-2.01-0.238-2.521,0.546c0,0-1.098,1.749
      -2.69,4.334c-1.295,2.225-3.212,5.78-4.52,10.207c-1.133,3.88-2.486,9.039-3.096,
      14.813c-0.261,3.033-0.32,5.769-0.227,8.586c0.227,3.212,0.714,5.96,1.644,9.103
      c3.154,8.964,5.583,17.644,14.349,26.206c2.771,2.847,5.618,4.787,7.674,
      6.216l0.726,0.511c0.442,0.291,0.866,0.559,1.284,0.803c1.726,0.906,2.881,1.231,
      3.091,1.277c0.859,0.232,1.743-0.186,2.108-0.883c0.343-0.732,0.093-1.65-0.621-2.23
      c0,0-1-0.871-2.283-2.498c-0.877-0.988-1.83-2.417-2.939-4.031c-0.784-1.139
      -1.708-2.429-2.568-3.684c-4.27-6.518-6.732-15.615-10.137-24.934c-0.668-2.022
      -1.214-4.642-1.418-6.867c-0.272-2.568-0.4-5.042-0.4-7.337c-0.099-5.333,0.139
      -9.911,0.604-13.623C102.471,154.36,102.889,151.217,103.662,148.98z"/>
      <path d="M190.372,0C85.415,0,0,85.397,0,190.36C0,295.3,85.415,380.721,190.372,
      380.721c104.952,0,190.35-85.421,190.35-190.361C380.721,85.397,295.324,0,190.372,
      0z M190.372,366.523c-97.144,0-176.18-79.03-176.18-176.163c0-97.144,79.036-176.18,
      176.18-176.18c97.133,0,176.175,79.036,176.175,176.18C366.546,287.493,287.504,
      366.523,190.372,366.523z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconDining',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
