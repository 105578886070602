import { isPlainObject } from 'lodash'

export const ParserUtils = {
  parseWithSchema ({ schema, data }) {
    if (!data) return
    return Object.keys(schema).reduce((acc, key) => {
      // handle coniguration object as value in schema config
      if (isPlainObject(schema[key])) {
        if (schema[key].key && schema[key].schema) {
          const dataItem = data[schema[key].key]
          // handle array of items
          if (Array.isArray(dataItem)) {
            acc[key] = []
            dataItem.forEach(item => {
              acc[key].push(
                ParserUtils.parseWithSchema({
                  schema: schema[key].schema,
                  data: item
                })
              )
            })
          } else {
            acc[key] = ParserUtils.parseWithSchema({
              schema: schema[key].schema,
              data: data[schema[key].key]
            })
          }
        } else {
          console.error('Object is missing `key` or `schema` key(s)')
        }
      // handle string in schema config
      } else if (Object.keys(data).indexOf(schema[key]) > -1) {
        acc[key] = data[schema[key]]
      }
      return acc
    }, {})
  },
  parseWithSchemaInverse ({ schema, data }) {
    return Object.keys(data).reduce((acc, key) => {
      if (Object.keys(schema).indexOf(key) > -1) {
        if (typeof data[key] !== 'undefined') {
          acc[schema[key]] = data[key]
        }
      }
      return acc
    }, {})
  },
  /**
    * @param {function} parser wrapper on parseWithSchema with fixed schema
    * @param {string} indexKey use the value of this key within parsed object
    * for the key of your return object IE -> 'email' for { email: User, email: User}
    * @param {array} rawList raw items to apply as the argument of the parser
    * @return {object} keyed object of parsed items
    */
  getParsedObject ({ parser, indexKey, rawList }) {
    return rawList.reduce((acc, rawItem) => {
      const parsedItem = parser(rawItem)
      const key = parsedItem[indexKey]
      acc[key] = parsedItem
      return acc
    }, {})
  }
}
