<template lang="html">
  <div id="recover-container">
    <div class="center md-headline">Recover Password</div>

    <div v-if="errorMsg" id="show-error">
      <span>
        {{ errorMsg }}
      </span>
    </div>

    <form id="reset-form" @submit.prevent="submit" @focus="show = true">
      <div id="extra-info">
        We'll email instructions on how to reset your password.
      </div>
      <md-field>
        <label>Email</label>
        <md-input :md-toggle-password="false"
                   v-model="username"
                   type="text"
                   required
                   ></md-input>
     </md-field>
     <md-field>
       <label>Confirm Email</label>
         <md-input :md-toggle-password="false"
                    v-model="confirmUsername"
                    type="text"
                    required
                    ></md-input>
      </md-field>

      <div class="auth-view-button-container">
        <button type="submit" class="dark-button">
          Reset
        </button>
      </div>
    </form>

    <div id="help" class="center">
      Having trouble? Please contact your account manager.
    </div>
  </div>
</template>

<script>
const matchingUsernames = 'The email addresses you entered do not match.'
const usernameInvalid = 'No account associated with the email you entered.'

export default {
  name: 'RecoverPassword',
  components: {},
  data () {
    return {
      username: '',
      confirmUsername: '',
      showError: false,
      errorMsg: '',
      errorMessages: {
        matchingUsernames,
        usernameInvalid
      }
    }
  },
  methods: {
    async submit () {
      const username = this.username.toLowerCase()
      const confirmUsername = this.confirmUsername.toLowerCase()

      if (username === confirmUsername) {
        await this.$store.dispatch('RECOVER_PASSWORD', { username })

        if (this.$store.state.recoverPasswordError) {
          this.username = ''
          this.confirmUsername = ''
          this.errorMsg = this.$store.state.recoverPasswordError.message
        } else {
          this.$router.push({
            path: '/confirm'
          })
        }
      } else {
        /* clear fields */
        this.username = ''
        this.confirmUsername = ''

        this.errorMsg = this.errorMessages.matchingUsernames
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  @import ../navigation/menu-styles

  #recover-container
    @apply rounded-md
    top: 50%
    width: 400px
    margin: 100px auto
    padding: 30px 0
    background-color: white

    #help, #password-details
      font-size: 12px

    #extra-info
      font-size: 14px
      text-align: center
      padding: 20px

    #show-error
      font-size: 14px
      color: #bf0000
      text-align: center
      padding: 20px
      // margin-bottom: 20px

    .center
      text-align: center

    a
      text-decoration: underline
      color: black
    a:hover
      text-decoration: none
      color: #175276

  #reset-form
    margin: 0px 0px 20px 0px
    padding: 0px 40px

  #valid-container
    text-align: center
    margin: 0px 0px
    padding: 10px

  .valid
    background-color: #DAFAC0

  .invalid
    background-color: #FFADAC
</style>
