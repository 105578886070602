































































import { steps, userViews } from '@/constants'
import CustomStepper from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/CustomStepper.vue'
import RedemptionInstructionsStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/RedemptionInstructionsStep.vue'
import RedemptionTermsStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/RedemptionTermsStep.vue'
import RedemptionRewardCostStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/RedemptionRewardCostStep.vue'
import RedemptionExpirationStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/RedemptionExpirationStep.vue'
import RedemptionActivateStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/RedemptionActivateStep.vue'
import AdvanceNoticeStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/AdvanceNoticeStep.vue'
import SeasonalRewardStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/SeasonalRewardStep.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Redemption',
  inject: ['Wizard_nextTab'],
  components: {
    CustomStepper,
    RedemptionInstructionsStep,
    RedemptionTermsStep,
    RedemptionRewardCostStep,
    RedemptionExpirationStep,
    RedemptionActivateStep,
    SeasonalRewardStep,
    AdvanceNoticeStep,
  },
  props: {
    editing: {
      type: Boolean,
      required: true,
      default: false
    },
  },
  data () {
    return {
      steps, // expose constants
      copy: {
        retainFeature: '(Retain Feature)'
      }
    }
  },
  computed: {
    ...mapState([
      'primaryHotel'
    ]),
    ...mapState('oprm', [
      'isRewardCreating'
    ]),
    ...mapGetters(['IS_ADMIN']),
    retainFeatureCopy () {
      return this.IS_ADMIN ? this.copy.retainFeature : ''
    },
    stepHeaders (): Array<{ name: string; isRetain: boolean }> {
      const postBookingFeatures = [
        { name: 'Advance Notice', isRetain: true },
        { name: 'Seasonal Reward', isRetain: true },
      ]

      return [
        { name: 'Redemption Instructions', isRetain: false },
        { name: 'Terms and Conditions', isRetain: false },
        { name: 'Reward Cost & Value', isRetain: true },
        { name: 'Reward Expiration', isRetain: true },
        ...(this.primaryHotel.showOprmPostBookingRewardFeatures ? postBookingFeatures : []),
        { name: 'Activate Reward', isRetain: false }
      ]
    },
    /**
     * the return value you must be an array of strings.
     * the slots cannot invoke a method or computed property and pass an argument
     * so they will reference an index position in the array.
     * null will prevent a slot from rendering.
     */
    dynamicSlotNames (): string[] {
      let slotNames
      if (this.primaryHotel.showOprmPostBookingRewardFeatures) {
        slotNames = [
          steps.STEP_1,
          steps.STEP_2,
          steps.STEP_3,
          steps.STEP_4,
          steps.STEP_5,
          steps.STEP_6,
          steps.STEP_7
        ]
      } else {
        slotNames = [
          steps.STEP_1,
          steps.STEP_2,
          steps.STEP_3,
          steps.STEP_4,
          null,
          null,
          steps.STEP_5
        ]
      }

      return slotNames
    }
  },
  methods: {
    create () {
      this.$store.dispatch('oprm/CREATE_OR_UPDATE_REWARD', {
        nextRoute: {
          name: userViews.OP_REWARDS
        }
      })
    }
  }
}
