<template>
  <div id="bulk-edit-design-set-search">
    <div class="md-body-1">{{ copy.body }}</div>
    <div class="search-header laas-bold">{{ copy.searchHeader }}</div>
    <div id="name-auto-complete-container">
      <md-autocomplete
        id="auto-complete"
        v-model="searchTerm"
        :md-options="DESIGN_SET_AUTOCOMPLETE_LIST"
        @md-changed="searchDesignSets($event)"
        @md-selected="setDesignToEdit($event)"
        :md-input-placeholder="copy.searchPlaceholder"
        :disabled="!this.fetchHotelsSuccess"
      >
        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
          <div class="auto-item">
            <md-highlight-text :md-term="term">
              {{ item.name }}
            </md-highlight-text>
          </div>
        </template>
      </md-autocomplete>
      <div class="spinner-container">
        <md-progress-spinner
          class="md-accent center"
          md-mode="indeterminate"
          v-show="!fetchHotelsSuccess"
          :md-diameter="30"
        />
      </div>
    </div>
    <button v-if="showPreview" id="preview-design-button">
      <a :href="`${previewFuxHref}`" target="_blank">
        {{ copy.previewButton }}
      </a>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'DesignSetSearch',
  data () {
    return {
      searchTerm: '',
      showPreview: false,
      copy: {
        body: 'Search for the design set you would like to perform an action on.',
        searchHeader: 'Search Design Set',
        searchPlaceholder: 'Start typing the design set name...'
      }
    }
  },
  computed: {
    ...mapGetters('designDuplication', [
      'DESIGN_SET_AUTOCOMPLETE_LIST'
    ]),
    ...mapState('hotel', [
      'fetchHotelsSuccess'
    ]),
    ...mapState('designDuplication', [
      'bulkEditSaveSuccess'
    ]),
  },
  methods: {
    searchDesignSets (searchTerm) {
      if (typeof searchTerm !== 'string') return
      if (!searchTerm || searchTerm.length < 2) return
      this.$store.dispatch('designDuplication/FETCH_DESIGN_SETS', { searchTerm })

      if (this.designSetSaveSuccess === true) {
        this.$store.commit('designDuplication/SET_DESIGN_SET_SAVE_SUCCESS', false)
      }
    },
    setDesignToEdit (value) {
      this.$store.commit('designDuplication/SET_BULK_EDIT_DESIGN_SET_ID', value.id)
      this.$store.commit('designDuplication/SET_BULK_EDIT_NAME', value.name)
      this.$store.commit('designDuplication/SET_BULK_EDIT_HOTEL_IDS', value.hotel_ids)
      this.$store.commit('designDuplication/SET_BULK_EDIT_FUX_ID', value.fux.fux_id)
    }
  },
  watch: {
    bulkEditSaveSuccess (to, from) {
      if (to === true && from === false) this.searchTerm = ''
    }
  }
}
</script>

<style scoped lang="sass">
#bulk-search
  width: 600px

#auto-complete
  width: 600px
  background-color: #e9e9e9
  color: black
  input, div.md-menu
    padding-left: 5px
    padding-bottom: 8px
    overflow: hidden

#name-auto-complete-container
  display: flex
  align-items: flex-start

.spinner-container
  margin-left: 30px
  padding-top: 10px

.search-header
  margin-top: 15px
</style>
