<template>
  <div
    v-click-outside="reset"
    id="filtered-dropdown-container"
  >
    <div
      v-focus="!showInput"
      @click="handleInputClick"
      id="input-container"
      class="flex flex-col cursor-pointer text-xs h-10 justify-center rounded-md"
      :class="{ 'bg-gray-100': !showInput }"
    >
      <div
        v-show="!showInput"
        class="flex flex-row flex-no-wrap text-black px-4"
      >
        <span id="display" class="text-left flex-grow px-2">
          {{ selectedItem[filterKey] }}
        </span>
        <span
          v-if="multipleItems"
          id="icon-container"
          class="flex-grow-0 flex flex-col justify-center"
        >
          <font-awesome-icon :icon="icons.minimize"/>
        </span>
      </div>
    </div>
    <div v-show="showInput" id="dropdown-container">
      <div id="input-container">
        <input
          v-show="showInput"
          v-model.trim="filterTerm"
          @click="toggleDropdown"
          @keydown.down.prevent="focus(0)"
          @keydown.esc.prevent="hideInput(); clearFilterTerm();"
          id="input"
          class="cursor-pointer h-6"
          ref="input"
          type="text"
          :placeholder="placeholder"
          tabindex="0"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
        >
      </div>
      <ul v-show="showInput" id="dropdown-list" tabindex="0">
        <li
          v-for="(item, idx) in filteredItems"
          :key="item[filterKey]"
          ref="item"
          @click="select(item)"
          @keydown.up.prevent="focusPrev(idx)"
          @keydown.down.prevent="focusNext(idx)"
          @keydown.enter.prevent="select(item)"
          @keydown.esc.prevent="clearFilterTerm"
          class="dropdown-option-2"
          tabindex="0"
        >
          {{ item[filterKey] }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Fuse from 'fuse.js/dist/fuse.min.js'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'FilteredDropdown',
  directives: {
    ClickOutside,
    focus: {
      inserted (el) {
        el.focus()
      },
      async update (el, binding) {
        if (binding.value) {
          await Vue.nextTick()
          el.focus()
        }
      }
    }
  },
  props: {
    filterKey: {
      type: String,
      required: false,
      default: () => 'name'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    items: {
      type: Array,
      required: true,
      default: () => ([])
    },
    selectedItem: {
      type: Object,
      required: true,
      default: () => ({ name: '', id: '' })
    },
    fuseOptions: {
      type: Object,
      required: false,
      default: () => ({
        includeMatches: false,
        isCaseSensitive: false,
        includeScore: false,
        shouldSort: false,
        findAllMatches: false,
        threshold: 0.1,
        ignoreLocation: true,
        keys: ['name']
      })
    },

  },
  mounted () {
    this.setupFuse()
  },
  watch: {
    items () {
      this.setupFuse()
    }
  },
  data () {
    return {
      fuse: undefined,
      showInput: false,
      showDropdown: false,
      filterTerm: '',
      copy: {
        placeholder: 'Type to search'
      },
      icons: {
        minimize: ['fas', 'angle-down'],
      },
    }
  },
  computed: {
    multipleItems () {
      return this.items.length > 1
    },
    placeholder () {
      return this.copy.placeholder
    },
    filteredItems () {
      if (this.filterTerm.length === 0) {
        return this.items
      }
      return this.fuzzySearch(this.filterTerm)
    },
  },
  methods: {
    revealInput () {
      if (this.multipleItems) {
        this.showInput = true
      }
    },
    async handleInputClick () {
      this.revealInput()
      await this.$nextTick()
      this.$refs.input.focus()
    },
    hideInput () {
      this.showInput = false
    },
    toggleDropdown () {
      this.showDropdown = !this.showDropdown
    },
    hideDropdown () {
      this.showDropdown = false
    },
    reset () {
      this.clearFilterTerm()
      this.hideInput()
      this.hideDropdown()
    },
    fuzzySearch (filterTerm) {
      return this.fuse.search(filterTerm).map(item => item.item)
    },
    async select (item) {
      this.reset()
      this.$emit('selected', item)
    },
    clearFilterTerm () {
      this.filterTerm = ''
    },
    handleInputEsc () {
      this.clearFilterTerm()
      this.hideInput()
    },
    async focus (idx) {
      const item = this.$refs.item[idx]
      if (item) item.focus()
    },
    focusPrev (idx) {
      this.focus(idx - 1)
    },
    focusNext (idx) {
      this.focus(idx + 1)
    },
    setupFuse () {
      this.fuse = new Fuse(this.items, this.fuseOptions)
    }
  },
}
</script>

<style lang="sass" scoped>
@import ../navigation/menu-styles

#filtered-dropdown-container
  position: relative
  width: 250px

#display
  line-height: 14px

#icon-container
  font-size: 14px

#dropdown-container
  @apply menu-radius-shadow
  @apply bg-white
  // follow three rules allow for drop-shadow to show
  @apply py-2
  width: 96%
  margin: 0px 2px
  position: absolute
  top: 10px

#dropdown-list
  @apply dropdown-option-container
  max-height: 270px
  width: 100%
  overflow-x: hidden
  overflow-y: auto
  &:focus
    outline: none

#input
  padding-left: 16px
  width: 100%
  @apply text-gray-400
  &:hover
    @apply cursor-text
  &:focus
    outline: none
</style>
