<template>
  <div
    v-if="show"
    id="on-property-rewards-wizard-container"
    :ref="refLabels.wizardContainer"
    :style="{ width: '100%', height: 'auto' }"
    class="md-content-item mb-12"
  >
    <WizardHeader @clickEvent="cancel">
      <template slot="header">
        <span>{{ headerCopy }}</span>
      </template>
      <template
        v-if="editing"
        slot="button-container"
      >
        <StepperButton
          :copy="copy.buttons.cancel"
          :disabled="false"
          :inverse="true"
          @clickEvent="cancel"
        />
        <StepperButton
          :copy="copy.buttons.save"
          :disabled="isSaveButtonDisabled"
          @clickEvent="update"
        />
      </template>
    </WizardHeader>
    <div
      :style="{ backgroundColor: 'white', height: 'auto', width: '100%' }"
      class="mb-4"
    >
      <md-tabs
        md-sync-route
        md-alignment="fixed"
      >
        <template
          slot="md-tab"
          slot-scope="{ tab }"
        >
          <span class="md-title lato-font">{{ tab.label }}</span>
        </template>

        <md-tab
          :id="tabs.TAB_LEFT"
          :md-label="copy.leftColTitle"
          :to="{ hash: `#${tabs.TAB_LEFT}` }"
        >
          <Content :editing="editing" />
        </md-tab>

        <md-tab
          :id="tabs.TAB_RIGHT"
          :md-disabled="!editing"
          :md-label="copy.rightColTitle"
          :to="{ hash: `#${tabs.TAB_RIGHT}` }"
        >
          <Redemption :editing="editing" />
        </md-tab>
      </md-tabs>
    </div>
    <div
      id="on-property-rewards-wizard-container-footer"
      class="pr-2"
    >
      <div
        v-if="editing"
        id="footer-button-container"
        class="button-container"
      >
        <StepperButton
          :copy="copy.buttons.cancel"
          :disabled="false"
          :inverse="true"
          @clickEvent="cancel"
        />
        <StepperButton
          :copy="copy.buttons.save"
          :disabled="isSaveButtonDisabled"
          @clickEvent="update"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * NOTE - this component manages two tabs. the first tab's child component will
 * emit an event to indicate when the tab is complete and this will activate
 * the second tab as well as open the second tab.
 */

import { mapActions, mapState, mapGetters } from 'vuex'
import Content from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/Content'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton'
import Redemption from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/Redemption'
import { cloneDeep } from 'lodash'
import WizardHeader from '@/components/common/WizardHeader'

import { steps, userViews, tabs } from '@/constants'

const SCROLL_TOP_EVENT = 'scrollTop'

export default {
  name: 'OnPropertyRewardsWizardContainer',
  provide () {
    return {
      Wizard_nextTab: this.nextTab
    }
  },
  components: {
    Content,
    Redemption,
    StepperButton,
    WizardHeader,
  },
  props: {
    editing: {
      type: Boolean,
      required: true,
    }
  },
  data () {
    return {
      steps, // expose constants
      tabs,
      activeTab: tabs.TAB_LEFT,
      show: false,
      originalReward: undefined, // used to determine if user has made potential changes to reward
      refLabels: {
        wizardContainer: 'on-property-rewards-wizard-container'
      },
      copy: {
        edit: 'Edit Reward',
        create: 'Create New Reward',
        leftColTitle: 'Content',
        rightColTitle: 'Settings',
        buttons: {
          save: 'Save',
          cancel: 'Cancel'
        }
      },
    }
  },
  computed: {
    ...mapState('oprm', [
      'reward',
    ]),
    ...mapGetters('oprm', [
      'IS_REUSABLE_PROMO_CODE_VALID'
    ]),
    headerCopy () {
      return this.editing ? this.copy.edit : this.copy.create
    },
    isSaveButtonDisabled () {
      if (!this.originalReward) return true
      // if the user has not made a change, we disable
      // if user makes change but the required fields are not valid, we disable
      // else we enable
      return this.$store.getters['oprm/IS_EDIT_DISABLED'](this.originalReward)
    },
  },
  async mounted () {
    this.show = false
    this.setup()
    await this.syncRouteHashToActiveTab()
    this.show = true
  },
  methods: {
    ...mapActions('oprm', [
      'SET_REWARD_PRIMARY_HOTEL'
    ]),
    ...mapActions('messages', [
      'ADD_ERROR'
    ]),
    async nextTab () {
      await this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        hash: `#${this.tabs.TAB_RIGHT}`
      })
    },
    async setup () {
      await this.SET_REWARD_PRIMARY_HOTEL()
      this.show = true
      if (this.editing) {
        this.originalReward = cloneDeep(this.reward)
      }
    },
    scrollTop () {
      this.$emit(SCROLL_TOP_EVENT)
    },
    update () {
      console.log('this.IS_REUSABLE_PROMO_CODE_VALID', this.IS_REUSABLE_PROMO_CODE_VALID)
      if (!this.IS_REUSABLE_PROMO_CODE_VALID) {
        this.ADD_ERROR(
          new Error('Reward requires a reusable promo code.')
        )
        return
      }
      if (this.reward.seasonal &&
        (!this.reward.seasonStart || !this.reward.seasonEnd)
      ) {
        this.ADD_ERROR(new Error('Seasonal rewards require both a start and end date.'))
        return
      }
      this.$store.dispatch('oprm/CREATE_OR_UPDATE_REWARD', {
        uuid: this.$route.params.rewardUuid,
        properties: null,
        nextRoute: {
          name: userViews.OP_REWARDS,
          params: {
            hotelId: this.$route.params.hotelId
          }
        }
      })
    },
    cancel () {
      this.$store.commit('oprm/RESET_REWARD')
      this.$router.push({
        name: userViews.OP_REWARDS,
        params: {
          hotelId: this.$route.params.hotelId
        }
      })
    },
    async syncRouteHashToActiveTab () {
      if (!this.$route.hash) {
        await this.$router.replace({
          name: this.$route.name,
          params: this.$route.params,
          hash: `#${this.tabs.TAB_LEFT}`
        })
      }
    },
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.rewardUuid !== from.params.rewardUuid) {
      this.show = false
      this.setup()
    }
    next()
  }
}
</script>

<style lang="sass" scoped>
#on-property-rewards-wizard-container
  width: 100%
  height: auto
  padding: 10px
</style>

<style lang="sass">
// must be unscoped. use parent selector to prevent leak into other components.

#on-property-rewards-wizard-container
  font-family: Lato !important

  a.router-link-active
    background-color: white

  .md-tabs-navigation.md-elevation-0 > button > div > div
    color: black

  .md-tabs.md-theme-default .md-tabs-navigation
    @apply bg-gray-100

  .md-tabs-navigation.md-elevation-0
    display: flex
    justify-content: space-around
    width: 100%
    > a
      min-width: 50%
      height: 55px
      border: none
    .md-title
      color: black
      font-weight: 600
      font-size: 16px
      text-transform: none !important

  #footer-button-container
    height: 100px

  #on-property-rewards-wizard-container-footer
    display: flex
    width: 100%
    justify-content: flex-end

  .md-tabs .md-tabs-indicator
    background-color: transparent !important

  // removes thin line between steps
  .md-steppers .md-stepper:after
    width: 0px !important

.laas-oprm-stepper-content
  max-width: 570px
  @media only screen and (max-width: 970px)
    padding-bottom: 40px

.laas-oprm-active-icon-tile
  background-color: rgba(34, 34, 80, 0.2) !important

@media only screen and (max-width: 970px)
  #category-step-container
    flex-direction: column-reverse
</style>
