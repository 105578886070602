import Vue from 'vue'
import { rewardDefaults, rewardDefaultsRetain } from '@/store/modules/oprm/reward-defaults.ts'
import { MerchantParser, PromotionParser, CradleCognitoHotelParser } from '@/parsers'
import endpoints from '@/config/endpoints'
import axios from 'axios'
import { ParserUtils } from '@/parsers/utils'
import { sortByName, createFormData, createAutocompleteObject } from '@/utils/misc'
import { schemas } from '@/schemas'
import { expiryTypes } from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/constants'
import QueryString from 'query-string'
import router from '@/router'
import { isEqual, cloneDeep, isEmpty } from 'lodash'
import { userViews } from '@/constants'

const ID = 'id'
const UUID = 'uuid'
const POST = 'post'
const PATCH = 'patch'

const REWARD = {
  creativeConfigs: [{
    promotedText: undefined,
    promotedIcon: undefined,
    thumbnailImage: undefined
  }],
  requiresNotice: false,
}

export const state = {
  fetchHotelsSuccess: false,
  imageUploadFile: undefined,
  rewards: {},
  reward: cloneDeep(REWARD),
  merchants: {},
  hotels: {},
  hotelsByCradleId: {},
  pageInfo: {
    page: 1,
    total: undefined,
    pageSize: 10
  },
  isRewardCreating: false
}

export const getters = {
  GET_HOTEL_BY_CRADLE_ID (state) {
    return (cradleId) => state.hotelsByCradleId[cradleId]
  },
  IMAGE_PREVIEW (state) {
    const imageUploadFile = state.imageUploadFile
    if (imageUploadFile) {
      return URL.createObjectURL(imageUploadFile)
    } else {
      return state.reward.creativeConfigs[0].thumbnailImage
    }
  },
  IS_EDIT_DISABLED (state, getters) {
    return (original) => {
      // original represents a snapshot of the reward before user began editing
      if (isEqual(original, state.reward) && !state.imageUploadFile) {
        return true
      }
      // we check all of the individual getters used to disable next button in stepper
      // when user is editing reward in expanded view.
      // seasonal reward requirements are handled by container
      // which will throw error and prevent save
      // if the start and end times do not have values.
      return getters.IS_CATEGORY_SELECTOR_DISABLED ||
        getters.IS_TEXT_DISPLAY_DISABLED ||
          getters.IS_IMAGE_DISPLAY_DISABLED ||
            getters.IS_PROPERTIES_DISABLED ||
              getters.IS_REDEMPTION_INSTRUCTIONS_DISABLED ||
                getters.IS_REDEMPTION_EXPIRATION_DISABLED
    }
  },
  IS_REDEMPTION_INSTRUCTIONS_DISABLED (state) {
    return !state.reward.redemptionInstructions
  },
  IS_TERMS_AND_CONDITIONS_DISABLED (state) {
    return !state.reward.termsAndConditions
  },
  IS_REDEMPTION_EXPIRATION_DISABLED (state) {
    if (state.reward.expiryType === expiryTypes.FROM_DISPATCH) {
      return !state.reward.redemptionExpiration
    }
    if (state.reward.expiryType === expiryTypes.SET_EXPIRATION_DATE) {
      return !state.reward.specificExpirationDate
    }
    return false
  },
  IS_CATEGORY_SELECTOR_DISABLED (state) {
    return state.reward.promotionCategory === undefined ||
      state.reward.promotionCategory === null
  },
  IS_TEXT_DISPLAY_DISABLED (state) {
    const { promotedText, promotedIcon } = state.reward.creativeConfigs[0]
    return !promotedText || !promotedIcon
  },
  IS_IMAGE_DISPLAY_DISABLED (state) {
    const { thumbnailText, thumbnailImage } = state.reward.creativeConfigs[0]
    if (!state.reward.merchant) return true
    if (!state.reward.merchant.name) return true
    if (!thumbnailText) return true
    if (!thumbnailImage && !state.imageUploadFile) return true
    return false
  },
  IS_PROPERTIES_DISABLED (state) {
    return state.reward.onPropertyHotels !== undefined && state.reward.onPropertyHotels.length < 1
  },
  IS_SEASONAL_REWARD_DISABLED (state) {
    if (state.reward.seasonal) {
      return !(state.reward.seasonStart && state.reward.seasonEnd)
    }
    return false
  },
  IS_REUSABLE_PROMO_CODE_VALID (state) {
    if (state.reward.redemptionMethod === 're') {
      return Boolean(state.reward.reusablePromoCode)
    }
    return true
  },
  REWARDS_LIST (state) {
    return Object.values(state.rewards)
  },
  GET_REWARD (state) {
    return ({ uuid }) => state.rewards[uuid]
  },
  MERCHANTS (state) {
    return Object.values(state.merchants)
  },
  MERCHANT_OPTS (state, getters) {
    const opts = getters.MERCHANTS.map(merchant => {
      return createAutocompleteObject({ data: merchant, key: 'name' })
    })
    return opts.sort(sortByName)
  },
  PAGE_INFO (state) {
    return state.pageInfo
  },
  // can handle partial or full reward
  // will default to state.reward given no argument
  REWARD_PAYLOAD (state) {
    return (properties) => {
      const payload = properties || state.reward

      // ensure no seasonStart & seasonEnd values if reward is not seasonal
      if (!payload.seasonal) {
        payload.seasonStart = null
        payload.seasonEnd = null
      }

      // not required
      delete payload.merchantName

      // merchant was changed from PK/Number to { id, name } when fetched
      // merchant must be PK as Number for POST/PATCH
      if (payload.merchant && payload.merchant.id) {
        payload.merchant = payload.merchant.id
      }

      const toRepr = PromotionParser.inverseParsePromotion(payload)

      if (toRepr.reward_creative_configs && toRepr.reward_creative_configs[0]) {
        toRepr.thumbnail_image = state.imageUploadFile
        delete toRepr.reward_creative_configs[0].thumbnail_image
      }

      return Object.keys(toRepr).reduce((acc, currKey) => {
        if (Object.values(schemas.promotionNestedFields).indexOf(currKey) > -1) {
          acc[currKey] = JSON.stringify(toRepr[currKey])
        } else {
          acc[currKey] = toRepr[currKey]
        }
        return acc
      }, {})
    }
  },
  HOTEL_OPTS (state) {
    const opts = Object.values(state.hotels)
    return opts.sort(sortByName)
  },
  GET_HOTEL_NAME (state) {
    // onPropertyHotels only contains api_names. given an api_name, we can use list of all
    // hotels fetched for Properties tab to get the pretty name of the hotel for rendering
    // will default to a pretty version of api_name if hotel is not found
    return (apiName) => {
      const hotel = state.hotels[apiName]
      if (!hotel) {
        // capitalize is handled by styling
        return apiName.split('-').join(' ')
      }
      return state.hotels[apiName].name
    }
  }
}

export const mutations = {
  SET_REWARD (state, reward) {
    state.reward = reward
  },
  RESET_REWARD (state) {
    state.reward = cloneDeep(REWARD)
  },
  SET_REWARD_FIELD (state, { field, value }) {
    Vue.set(state.reward, field, value)
  },
  SET_MERCHANTS (state, merchants) {
    state.merchants = { ...state.merchants, ...merchants }
  },
  ADD_MERCHANT (state, merchant) {
    Vue.set(state.merchants, merchant.id, merchant)
  },
  SET_REWARDS (state, newRewards) {
    Vue.set(state, 'rewards', newRewards)
  },
  CLEAR_REWARDS (state) {
    state.rewards = {}
  },
  ADD_REWARD (state, reward) {
    Vue.set(state.rewards, reward.uuid, reward)
  },
  SET_PAGE_INFO (state, pageInfo) {
    state.pageInfo = pageInfo
  },
  SET_HOTELS (state, hotels) {
    state.hotels = { ...state.hotels, ...hotels }
  },
  SET_HOTELS_BY_CRADLE_ID (state, hotels) {
    state.hotelsByCradleId = { ...state.hotelsByCradleId, ...hotels }
  },
  SET_IMAGE_UPLOAD_FILE (state, file) {
    state.imageUploadFile = file
  },
  CLEAR_IMAGE_UPLOAD_FILE (state) {
    state.imageUploadFile = null
  },
  SET_FETCH_HOTELS_SUCCESS (state, bool) {
    state.fetchHotelsSuccess = bool
  },
  IS_REWARD_CREATING (state, bool) {
    state.isRewardCreating = bool
  }
}

export const actions = {
  async FETCH_REWARDS ({ commit, dispatch, rootState }, { page = 1, pageSize = 10, merchant }) {
    console.log(`[FETCH REWARDS]: page: ${page} pageSize: ${pageSize} merchant: ${merchant}`)
    const queryString = QueryString.stringify({
      page,
      merchant,
      page_size: pageSize,
      division: 'op',
      fields: 'active,uuid,merchant,reward_creative_configs,on_property_hotels,merchant_name,cost,value,modified'
    })
    const endpoint = `${endpoints.mesa.oprmPromotionRoute}?${queryString}`
    let response
    let error

    try {
      response = await axios.get(endpoint, {
        headers: {
          Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
        }
      })
    } catch (err) {
      error = err.response.data
    }

    if (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      commit('SET_REWARDS',
        ParserUtils.getParsedObject({
          rawList: response.data.results,
          parser: PromotionParser.parsePromotion,
          indexKey: UUID,
        })
      )
      commit('SET_PAGE_INFO', {
        total: response.data.count,
        page: page,
        pageSize: pageSize
      })
    }
  },
  // REWARD_PAYLOAD  getter will default to state.reward given an
  // undefined `properties` argument
  async CREATE_OR_UPDATE_REWARD (
    { rootState, commit, getters, dispatch },
    { uuid, properties, nextRoute }
  ) {
    const isCreate = !uuid
    const endpoint = isCreate
      ? `${endpoints.mesa.selfServePromotionRoute}`
      : `${endpoints.mesa.selfServePromotionRoute}${uuid}/`
    const method = isCreate
      ? POST
      : PATCH
    const rawPayload = getters.REWARD_PAYLOAD(properties)
    console.log('[CREATE_OR_UPDATE_REWARD] rawPayload', rawPayload)
    const payload = createFormData(rawPayload)

    let response
    let error

    commit('IS_REWARD_CREATING', true)

    try {
      response = await axios[method](
        endpoint,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    } catch (err) {
      error = err.response.data
    }
    console.log('[CREATE_OR_UPDATE_REWARD]', response, error)

    if (error) {
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      const reward = PromotionParser.parsePromotion(response.data)
      commit('ADD_REWARD', reward)

      const refreshSession = await rootState.authController.refreshSession()
      if (refreshSession.error) {
        commit('IS_REWARD_CREATING', false)
        return dispatch('messages/ADD_ERROR', new Error(refreshSession.error), { root: true })
      }

      if (nextRoute) {
        router.push({
          name: nextRoute.name,
          params: nextRoute.params || rootState.route.params,
        })
      }
    }

    commit('IS_REWARD_CREATING', false)
  },
  async GET_AND_SET_REWARD ({ rootState, commit, dispatch, rootGetters }) {
    let reward
    if (rootState.route.params.rewardUuid === undefined) {
      reward = rootGetters.HOTEL_GROUP_IS_RETAIN ? { ...rewardDefaultsRetain } : { ...rewardDefaults }
    } else {
      // ensure the temporary file is always falsy when setting the reward for use in wizard
      const rewardUuid = rootState.route.params.rewardUuid
      const endpoint = endpoints.mesa.selfServePromotionRoute + `${rewardUuid}/`
      let response
      let error

      try {
        response = await axios.get(endpoint, {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
          }
        })
      } catch (err) {
        error = err.response.data
      }
      console.log('[GET_AND_SET_REWARD]', response)

      if (error) {
        console.log(error)
        dispatch('messages/ADD_ERROR', error, { root: true })
      } else {
        reward = PromotionParser.parsePromotion(response.data)
      }
    }
    console.log('GET_AND_SET_REWARD', JSON.stringify(reward, null, 2))
    const newMerchant = {
      id: reward.merchant,
      name: reward.merchantName
    }
    // add merchant to merchant list so that autocomplete can find it
    commit('ADD_MERCHANT', newMerchant)
    // fix merchant payload to match autocomplete
    reward.merchant = newMerchant
    commit('SET_REWARD', reward)
  },
  async FETCH_MERCHANTS (
    { rootState, commit, dispatch },
    { search, page = 1, pageSize = 10, nextUrl, fetchAll, oprList = false }
  ) {
    const queryString = QueryString.stringify({
      search,
      page,
      page_size: pageSize,
      rewards__division: oprList ? 'op' : null
    })
    let endpoint = `${endpoints.mesa.oprmMerchantRoute}?${queryString}`
    if (nextUrl) {
      endpoint = nextUrl.replace('http', 'https')
    }

    let response
    let error

    try {
      response = await axios.get(endpoint, {
        headers: {
          Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
        }
      })
    } catch (err) {
      error = err.response.data
    }

    console.log('FETCH_MERCHANTS', response, error)
    if (error) {
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      const merchants = ParserUtils.getParsedObject({
        rawList: response.data.results,
        parser: MerchantParser.parseMerchant,
        indexKey: ID,
      })
      commit('SET_MERCHANTS', merchants)
      if (fetchAll && response.data.next) {
        await dispatch('FETCH_MERCHANTS', { nextUrl: response.data.next, fetchAll: true, oprList })
      }
    }
  },
  async CREATE_MERCHANT ({ rootState, commit, dispatch }, merchant) {
    let METHOD
    let endpoint = endpoints.mesa.oprmMerchantRoute
    if (!merchant.id) {
      METHOD = POST
    } else {
      METHOD = PATCH
      endpoint = `${endpoint}${merchant.id}/`
    }
    let data
    let error

    try {
      const response = await axios[METHOD](
        endpoint,
        merchant,
        {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
          }
        }
      )
      data = response.data
      error = null
      console.log('[CREATE_OR_UPDATE_MERCHANT]', response)
    } catch (err) {
      error = err.response.data
      dispatch('messages/ADD_ERROR', error, { root: true })
      return err
    }

    // update reward's merchant in state
    const newMerchant = MerchantParser.parseMerchant(data)
    await commit('SET_REWARD_FIELD', {
      field: 'merchant',
      value: newMerchant
    })
    await commit('ADD_MERCHANT', newMerchant)
    return { data, error }
  },
  async FETCH_HOTELS ({ rootState, commit, dispatch },
    { nextUrl, fetchAll, pageSize, searchTerm }
  ) {
    commit('SET_FETCH_HOTELS_SUCCESS', false)
    let endpoint

    const queryParams = {
      fields: 'funnel_uxs', // exclude
      search: searchTerm,
      page_size: pageSize,
    }
    const qs = QueryString.stringify(queryParams).replace('fields=', 'fields!=')
    endpoint = `${endpoints.cradle.hotelRoute}?${qs}`

    if (nextUrl) {
      // the url returned from cradle is `http://..`
      endpoint = nextUrl.replace('http', 'https')
    }

    let response
    let error

    try {
      response = await axios.get(endpoint, {
        headers: {
          Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
        }
      })
    } catch (err) {
      error = err.response.data
    }

    if (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      const hotels = ParserUtils.getParsedObject({
        rawList: response.data.results || response.data,
        parser: CradleCognitoHotelParser.parseHotel,
        indexKey: 'hotelCode',
      })
      commit('SET_HOTELS', hotels)
      const hotelsByCradleId = Object.values(hotels).reduce((acc, hotel) => {
        acc[hotel.cradleId] = hotel
        return acc
      }, {})
      commit('SET_HOTELS_BY_CRADLE_ID', hotelsByCradleId)
    }
    if (fetchAll && response.data.next) {
      await dispatch('FETCH_HOTELS', { nextUrl: response.data.next, fetchAll: true })
    }
    commit('SET_FETCH_HOTELS_SUCCESS', true)
  },
  /**
   * for hotel users set the primaryHotel based on onPropertyHotels to check
   * retain access with HOTEL_GROUP_IS_RETAIN getter
   * for group/admin users the getter should return the same value no matter
   * what the primaryHotel is; return early for those user types
   */
  async SET_REWARD_PRIMARY_HOTEL ({ commit, dispatch, rootGetters, rootState, state }) {
    await dispatch('GET_AND_SET_REWARD')
    if (isEmpty(state.hotels)) {
      await dispatch('FETCH_HOTELS', { fetchAll: true, pageSize: 1000 })
    }
    if (isEmpty(state.reward.onPropertyHotels) || !rootGetters.IS_HOTEL_USER) {
      return
    }
    for (const apiName of state.reward.onPropertyHotels) {
      const cradleHotel = state.hotels[apiName]
      if (cradleHotel) {
        if (!isEqual(cradleHotel, rootState.primaryHotel)) {
          commit('SET_PRIMARY_HOTEL', cradleHotel, { root: true })
        }
        return
      }
    }
    // no hotel found; configuration error
    router.push({
      name: userViews.OP_REWARDS,
      params: {
        hotelId: rootState.route.params.hotelId
      }
    })
    dispatch(
      'messages/ADD_ERROR',
      new Error('An error occured. Please contact support.'),
      { root: true }
    )
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
