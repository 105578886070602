<template>
  <svg
  id="icon-savings"
  viewBox="0 0 512 512"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  version="1.1"
  role="img">

  <title id="title">savings icon</title>
  <desc id="desc">savings icon</desc>
    <g>
      <g>
        <path d="M196,60c-5.52,0-10,4.48-10,10s4.48,10,10,10s10-4.48,10-10S201.52,60,196,60z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M256,140c-5.514,0-10-4.486-10-10c0-5.514,4.486-10,10-10c3.542,0,7.282,1.807,10.815,5.227
          c3.971,3.841,10.3,3.736,14.141-0.232c3.84-3.969,3.736-10.3-0.232-14.141c-5.075-4.911-10.153-7.689-14.724-9.205V90
          c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10v11.72c-11.639,4.128-20,15.243-20,28.28c0,16.542,13.458,30,30,30
          c5.514,0,10,4.486,10,10c0,5.514-4.486,10-10,10c-4.272,0-8.884-2.687-12.985-7.565c-3.553-4.228-9.862-4.773-14.089-1.219
          c-4.228,3.554-4.773,9.862-1.22,14.089c5.346,6.359,11.632,10.79,18.294,13.024V210c0,5.523,4.478,10,10,10
          c5.522,0,10-4.477,10-10v-11.72c11.639-4.128,20-15.243,20-28.28C286,153.458,272.542,140,256,140z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M472,146c-22.056,0-40,17.944-40,40v71.27c-13.284-3.421-27.943,0.112-38.281,10.449l-67.065,67.065
          C317.204,344.221,312,356.781,312,370.15V429h-10c-5.522,0-10,4.477-10,10v63c0,5.523,4.478,10,10,10h140c5.522,0,10-4.477,10-10
          v-63c0-5.523-4.478-10-10-10h-10v-4.72c0-2.629,1.068-5.206,2.932-7.069l62.42-62.42C506.798,345.344,512,332.786,512,319.43V186
          C512,163.944,494.056,146,472,146z M432,492H312v-43h120V492z M492,319.43c0,8.014-3.122,15.55-8.791,21.219l-62.42,62.42
          c-5.586,5.586-8.789,13.317-8.789,21.211V429h-80v-58.85c0-8.022,3.12-15.556,8.791-21.219l67.07-67.07
          c7.506-7.507,19.564-7.844,27.45-0.768c0.057,0.05,0.114,0.101,0.172,0.15c0.228,0.195,0.442,0.405,0.658,0.621
          C439.919,285.639,442,290.659,442,296s-2.081,10.361-5.861,14.139l-49.69,49.69c-3.905,3.905-3.905,10.237,0,14.143
          c3.906,3.905,10.236,3.906,14.143,0l49.688-49.687C457.837,316.731,462,306.687,462,296c0-9.84-3.538-19.132-10-26.44V186
          c0-11.028,8.972-20,20-20s20,8.972,20,20V319.43z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M210,429h-10v-58.85c0-13.369-5.204-25.929-14.648-35.361l-67.07-67.07C107.943,257.38,93.284,253.848,80,257.27V186
          c0-22.056-17.944-40-40-40c-22.056,0-40,17.944-40,40v133.43c0,13.356,5.202,25.914,14.648,35.361l62.42,62.419
          c1.864,1.864,2.932,4.44,2.932,7.07V429H70c-5.522,0-10,4.477-10,10v63c0,5.523,4.478,10,10,10h140c5.522,0,10-4.477,10-10v-63
          C220,433.477,215.522,429,210,429z M28.791,340.65C23.122,334.98,20,327.444,20,319.43V186c0-11.028,8.972-20,20-20
          s20,8.972,20,20v83.559c-6.462,7.308-10,16.599-10,26.441c0,10.687,4.163,20.731,11.719,28.281l50,50
          c3.906,3.905,10.236,3.905,14.143,0c3.905-3.905,3.905-10.237,0-14.143l-50.003-50.003C72.081,306.361,70,301.341,70,296
          s2.081-10.361,5.856-14.135c0.218-0.217,0.433-0.427,0.66-0.622l0.172-0.15c7.885-7.076,19.944-6.738,27.45,0.768l67.075,67.074
          c5.666,5.659,8.786,13.192,8.786,21.214V429h-80v-4.72c0.001-7.894-3.202-15.625-8.788-21.211L28.791,340.65z M200,492H80v-43h120
          V492z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M256,0c-82.71,0-150,67.29-150,150s67.29,150,150,150s150-67.29,150-150S338.71,0,256,0z M256,280
          c-71.683,0-130-58.318-130-130S184.317,20,256,20s130,58.318,130,130S327.683,280,256,280z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M325.054,64.369c-26.021-21.01-60.077-28.934-93.421-21.745c-5.399,1.164-8.832,6.484-7.668,11.883
          c1.164,5.398,6.485,8.828,11.883,7.667c27.409-5.91,55.345,0.561,76.643,17.755C333.786,97.124,346,122.663,346,150
          c0,49.626-40.374,90-90,90c-49.626,0-90-40.374-90-90c0-14.824,3.68-29.518,10.642-42.492c2.611-4.866,0.783-10.928-4.083-13.54
          c-4.867-2.612-10.93-0.783-13.54,4.083C150.502,113.924,146,131.887,146,150c0,60.654,49.346,110,110,110s110-49.346,110-110
          C366,116.589,351.076,85.378,325.054,64.369z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconSavings'
}
</script>

<style lang="sass" scoped>
svg
  path
    fill: inherit !important
</style>
