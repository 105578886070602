<template lang="html">
  <Dashboard :navConfig="navConfig"/>
</template>

<script>
import Dashboard from '@/components/dashboard/Dashboard'
import { adminNavConfig } from '@/config/nav/adminNavConfig'

export default {
  name: 'AdminDashboard',
  components: {
    Dashboard,
  },
  data () {
    return {
      navConfig: adminNavConfig,
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
