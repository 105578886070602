



























import Vue from 'vue'
import { CustomStepper as CustomStepperConfig } from '@/types'
import { steps } from '@/constants'
import CustomStepper from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/CustomStepper.vue'
import CustomStepHeader from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepHeader.vue'
import WizardStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/WizardStep.vue'
import MabEnable from '@/components/hotel-dashboard/bulk-update/bulk-edit/MabEnable.vue'
import DesignSetSearch from '@/components/hotel-dashboard/bulk-update/bulk-edit/DesignSetSearch.vue'

export default Vue.extend({
  name: 'BulkDesignEditor',
  components: {
    CustomStepper,
    WizardStep,
    MabEnable,
    DesignSetSearch,
    CustomStepHeader
  },
  data () {
    return {
      steps, // expose constants
      stepHeaders: [
        { name: 'Select the Design Set', isRetain: false },
        { name: 'Enable/Disable Design Set', isRetain: false }
      ] as CustomStepperConfig.StepHeaders,
      copy: {
        selectBody: 'Search for the design set you would like to perform an action on.',
        searchFieldHeader: 'Search Design Set',
      }
    }
  }
})
