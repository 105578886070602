<template lang="html">
  <md-card class="fux-ctx-list">
    <md-card-header>
      <div class="md-title no-wrap">
        {{ contextPretty }}
      </div>
    </md-card-header>
    <md-card-content>
      <md-list>
        <md-list-item>
          <span class="md-caption">Desktop</span>
          <md-button
            class="md-icon-button md-list-action"
            @click="navToEditor({
              channel: 'dt'
            })"
          >
            <md-icon>add_circle</md-icon>
          </md-button>
        </md-list-item>
        <md-divider />
        <md-list-item
          v-for="fux in dtFuxs"
          :key="fux.uuid"
        >
          <md-icon
            v-if="fux.enabled"
            :style="enabled"
          >
            brightness_1
          </md-icon>
          <md-icon
            v-else
            class="disabled-circle"
          >
            brightness_1
          </md-icon>
          <span class="md-list-item-text no-wrap">
            {{ fux.name }}
            <md-tooltip class="fux-name-tooltip">
              {{ fux.name }}
            </md-tooltip>
          </span>
          <div class="button-tooltip-container">
            <md-tooltip
              v-if="disableEditor(fux)"
              md-direction="right"
            >
              {{ copy.disabledEditorTooltip }}
            </md-tooltip>
            <md-button
              :disabled="disableEditor(fux)"
              class="md-icon-button md-list-action"
              @click="navToEditor({
                fuxUuid: fux.uuid,
                channel: 'dt'
              })"
            >
              <md-icon>build</md-icon>
            </md-button>
          </div>
        </md-list-item>
        <md-divider />
        <md-list-item>
          <span class="md-caption">Mobile</span>
          <md-button
            class="md-icon-button md-list-action"
            @click="navToEditor({
              channel: 'mb'
            })"
          >
            <md-icon>add_circle</md-icon>
          </md-button>
        </md-list-item>
        <md-divider />
        <md-list-item
          v-for="fux in mbFuxs"
          :key="fux.uuid"
        >
          <md-icon
            v-if="fux.enabled"
            :style="enabled"
          >
            brightness_1
          </md-icon>
          <md-icon
            v-else
            class="disabled-circle"
          >
            brightness_1
          </md-icon>
          <span class="md-list-item-text no-wrap">
            {{ fux.name }}
            <md-tooltip class="fux-name-tooltip">
              {{ fux.name }}
            </md-tooltip>
          </span>
          <div class="button-tooltip-container">
            <md-tooltip
              v-if="disableEditor(fux)"
              md-direction="right"
            >
              {{ copy.disabledEditorTooltip }}
            </md-tooltip>
            <md-button
              :disabled="disableEditor(fux)"
              class="md-icon-button md-list-action"
              @click="navToEditor({
                fuxUuid: fux.uuid,
                channel: 'mb'
              })"
            >
              <md-icon>build</md-icon>
            </md-button>
          </div>
        </md-list-item>
      </md-list>
    </md-card-content>
  </md-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { userViews, colors } from '@/constants'
import { CTX_URL_MAPPING } from '@/components/hotel-dashboard/editor/constants'
import { EditorUtils } from '@/editor/utils'

export default {
  name: 'FuxCtxList',
  props: {
    context: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      editorRoute: userViews.EDITOR,
      createEditorRoute: userViews.EDITOR_NEW,
      copy: {
        disabledEditorTooltip: 'This isn\'t supported right now. Please contact your AM to view and edit.'
      }
    }
  },
  computed: {
    ...mapState('hotel', [
      'cradleHotelObj',
    ]),
    ...mapGetters('hotel', [
      'fuxSubset',
    ]),
    ...mapGetters([
      'TOKEN',
      'IS_ADMIN',
    ]),
    dtFuxs () {
      return this.fuxSubset({
        context: this.context,
        channel: 'dt'
      })
    },
    mbFuxs () {
      return this.fuxSubset({
        context: this.context,
        channel: 'mb'
      })
    },
    contextPretty () {
      const ctxMap = {
        hp: 'Homepage',
        be: 'Booking Engine',
        cp: 'Confirmation Page'
      }
      return ctxMap[this.context]
    },
    clientSite () {
      return this.cradleHotelObj[CTX_URL_MAPPING[this.context]] || 'https://rownyc.com'
    },
    disableEditor () {
      return fux => {
        if (this.IS_ADMIN) return false
        return !fux.editable
      }
    },
    enabled () {
      return { color: colors.enabled }
    }
  },
  methods: {
    navToEditor ({ fuxUuid, channel }) {
      window.location.href = EditorUtils.buildUrl({
        fuxUuid,
        channel,
        base: this.clientSite,
        context: this.context,
        hotelId: this.cradleHotelObj.legacyId,
      })
    },
  },
}
</script>

<style lang="sass">
  .fux-ctx-list
    max-width: 280px
    .md-list-item-content
      cursor: default
  .fux-name-tooltip
    margin-top: -10px
  .no-wrap
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
  .disabled-circle
    color: rgba(0, 0, 0, 0.12) !important
</style>
