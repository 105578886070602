import '@/assets/css/tailwind.css'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../tailwind.config.js'
import { TailwindConfig } from '@/types'
import Vue from 'vue'
import Vuex from 'vuex'
import VueResource from 'vue-resource'
import VueMaterial from 'vue-material'
import PrettyRadio from 'pretty-checkbox-vue/radio'
import store from '@/store'
import router from '@/router'
import Vuelidate from 'vuelidate'
import { useGA } from '@/analytics'
import App from '@/App.vue'
import PortalVue from 'portal-vue'
import { sync } from 'vuex-router-sync'
import { $breakpointMixin } from '@/utils/breakpointMixin'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCommentAlt, faChartBar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faUserSecret,
  faAngleDown,
  faEnvelopeOpenText,
  faAngleLeft,
  faAngleRight,
  faTh,
  faCog,
  faGift,
  faTools,
  faEye,
  faChartLine,
  faLock,
  faChevronRight,
  faChevronLeft,
  faArrowLeft,
  faArrowRight,
  faPlus,
  faLink,
  faPaintBrush,
  faWrench,
  faUnlock,
  faExclamationTriangle,
  faInfoCircle,
  faNetworkWired
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faUserSecret,
  faCommentAlt,
  faEnvelopeOpenText,
  faChartBar,
  faChartLine,
  faCog,
  faGift,
  faTools,
  faAngleDown,
  faAngleLeft,
  faAngleLeft,
  faEye,
  faLock,
  faChevronRight,
  faChevronLeft,
  faArrowLeft,
  faArrowRight,
  faPlus,
  faLink,
  faPaintBrush,
  faWrench,
  faUnlock,
  faAngleRight,
  faTh,
  faExclamationTriangle,
  faInfoCircle,
  faNetworkWired
)

Vue.filter('capitalize', value => {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.component('font-awesome-icon', FontAwesomeIcon)

const isProd = process.env.VUE_APP_MODE === 'production'
Vue.config.devtools = !isProd
Vue.config.productionTip = false

sync(store, router)
Vue.use(Vuex)
Vue.use(VueResource)
Vue.use(VueMaterial)
useGA({ v: Vue, router, isProd })
Vue.component('p-radio', PrettyRadio)
Vue.use(Vuelidate)
Vue.mixin($breakpointMixin)
Vue.use(PortalVue)

const config: TailwindConfig = resolveConfig(tailwindConfig)
Vue.prototype.$theme = config.theme

Vue.config.errorHandler = (err, vm, info) => {
  console.log('Error:', err)
  console.log('vm:', vm)
  console.log('info:', info)
}

/* eslint-disable no-new */
new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  store,
  router,
})
