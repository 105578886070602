










import props from '@/components/icons/props'

export default {
  name: 'Check',
  props,
}
