import { schemas, reverseSchemas } from '@/schemas/cognitoUserSchema'
import { userTypes } from '@/constants'
import { cloneDeep } from 'lodash'

/**
  * exports two parsing tools for Cognito-specific users & hotel objects
  * @export {CognitoUserParser} handles the flattening and parsing of a Cognito User Object
  * @export {CognitoHotelParser} wrapper around parsing with hotel schema
  */

export const CognitoUserParser = {
  /**
    * NOTE all attribute values are output in lower case, including email
    * maps keys using defined schema
    * unpacks attributes attached user
    * all string values are lowered
    * @param {Object} rawUser
    * @param {Object} allHotelsObject
    * @param {Array} allHotelsList
    * @param {Array} allGroupsList
    * @return {Object} flattened & parsed profile
      username
      createDate
      lastModifiedDate
      enabled
      status (has user reset temp password?)
      sub (uuid for user)
      email
      emailVerified (auto set to true)
      createEnv (development or prod)
      type (admin, hotel, group)
      hotels Array
        hotel: { hotelCode, name, id }
      groupIds: number[]
    */
  parseUser ({ rawUser, allHotelsObject, allHotelsList, allGroupsList, allMerchantsObject }) {
    const user = CognitoUserParser.toInternal(rawUser)
    const attributes = user.attributes
    const profile = attributes.customProfile

    const hotelIds = []
    let groupIds = []
    const _searchString = []

    _searchString.push(attributes.email)

    // enrichment
    if (profile.type === userTypes.GROUP) {
      groupIds = attributes.groups.map(group => group.id)

      allHotelsList.forEach(hotel => {
        if (groupIds.includes(hotel.hotelGroupId)) {
          hotelIds.push(hotel.id)
          _searchString.push(hotel.name)
        }
      })

      _searchString.push(
        ...attributes.groups.map(group => group.name)
      )
    } else if (profile.type === userTypes.HOTEL) {
      profile.hotels.forEach(hotel => {
        hotelIds.push(hotel.id)
        _searchString.push(hotel.name)
        if (allHotelsObject[hotel.id]) groupIds.push(allHotelsObject[hotel.id].hotelGroupId)
      })
    } else if (profile.type === userTypes.ADMIN) {
      // FIXME lists only need to be generated once
      allHotelsList.forEach(h => hotelIds.push(parseInt(h.id)))
      allGroupsList.forEach(g => groupIds.push(parseInt(g.id)))
    } else if (profile.type === userTypes.MERCHANT) {
      profile.merchantIds.forEach(id => {
        if (allMerchantsObject[id]) {
          _searchString.push(allMerchantsObject[id].name)
        }
      })
    }

    const searchString = _searchString.join('').toLowerCase().replace(/\s/g, '')
    const hoverEmail = false

    const hssEditorAccess = attributes.hssEditorAccess
    const reportingAccess = attributes.reportingAccess
    const oprmAccess = attributes.oprmAccess
    const edmAccess = attributes.edmAccess
    const rewardAlgoAccess = attributes.rewardAlgoAccess
    const flexAccess = attributes.flexAccess

    delete user.attributes
    delete attributes.customProfile
    delete attributes.hssEditorAccess
    delete attributes.reportingAccess
    delete attributes.oprmAccess
    delete attributes.edmAccess
    delete attributes.rewardAlgoAccess
    delete attributes.flexAccess
    delete profile.group
    delete profile.hotels
    delete attributes.groups

    return {
      hssEditorAccess,
      reportingAccess,
      oprmAccess,
      edmAccess,
      rewardAlgoAccess,
      flexAccess,
      searchString,
      hoverEmail,
      hotelIds,
      groupIds: [...new Set(groupIds)],
      ...user,
      ...attributes,
      ...profile,
    }
  },
  toInternal (rawUser) {
    const user = Object.keys(rawUser).reduce((acc, key) => {
      if (reverseSchemas.user[key]) {
        acc[reverseSchemas.user[key]] = rawUser[key]
      }
      return acc
    }, {})

    // attribtues is a list of objects => [{ Name, Value },..]
    user.attributes = user.attributes.reduce((acc, curr) => {
      if (reverseSchemas.attributes[curr.Name]) {
        const key = reverseSchemas.attributes[curr.Name]
        acc[key] = curr.Value
      }
      return acc
    }, {})
    user.attributes.hssEditorAccess = (user.attributes.hssEditorAccess === 'true')
    user.attributes.reportingAccess = (user.attributes.reportingAccess === 'true')
    user.attributes.oprmAccess = (user.attributes.oprmAccess === 'true')
    user.attributes.edmAccess = (user.attributes.edmAccess === 'true')
    user.attributes.rewardAlgoAccess = (user.attributes.rewardAlgoAccess === 'true')
    user.attributes.flexAccess = (user.attributes.flexAccess === 'true')
    // profile is an object as a string of json
    const jsonProfile = JSON.parse(user.attributes.customProfile)
    user.attributes.customProfile = Object.keys(jsonProfile).reduce((acc, key) => {
      if (reverseSchemas.profile[key]) {
        acc[reverseSchemas.profile[key]] = jsonProfile[key]
      }
      return acc
    }, {})

    if (typeof user.attributes.groups === 'string') {
      user.attributes.groups = JSON.parse(user.attributes.groups)
    }

    return user
  },
  toRepresentation (user) {
    let rawUser = cloneDeep(user)

    if (rawUser.attributes && rawUser.attributes.customProfile) {
      rawUser.attributes.customProfile = JSON.stringify(
        Object.keys(
          user.attributes.customProfile
        ).reduce((acc, key) => {
          if (schemas.profile[key]) {
            acc[schemas.profile[key]] = rawUser.attributes.customProfile[key]
          }
          return acc
        }, {})
      )
    }

    if (rawUser.attributes.hssEditorAccess !== undefined) {
      rawUser.attributes.hssEditorAccess = rawUser.attributes.hssEditorAccess.toString()
    }
    if (rawUser.attributes.reportingAccess !== undefined) {
      rawUser.attributes.reportingAccess = rawUser.attributes.reportingAccess.toString()
    }
    if (rawUser.attributes.oprmAccess !== undefined) {
      rawUser.attributes.oprmAccess = rawUser.attributes.oprmAccess.toString()
    }

    if (rawUser.attributes.edmAccess !== undefined) {
      rawUser.attributes.edmAccess = rawUser.attributes.edmAccess.toString()
    }

    if (rawUser.attributes.rewardAlgoAccess !== undefined) {
      rawUser.attributes.rewardAlgoAccess = rawUser.attributes.rewardAlgoAccess.toString()
    }

    if (rawUser.attributes.flexAccess !== undefined) {
      rawUser.attributes.flexAccess = rawUser.attributes.flexAccess.toString()
    }

    if (rawUser.attributes && rawUser.attributes.groups) {
      rawUser.attributes.groups = JSON.stringify(rawUser.attributes.groups)
    }

    rawUser.attributes = Object.keys(rawUser.attributes).reduce((acc, curr) => {
      if (schemas.attribute[curr]) {
        acc.push({
          Name: schemas.attribute[curr],
          Value: rawUser.attributes[curr]
        })
      }
      return acc
    }, [])

    rawUser = Object.keys(rawUser).reduce((acc, curr) => {
      if (schemas.user[curr]) {
        acc[schemas.user[curr]] = rawUser[curr]
      }
      return acc
    }, {})

    return rawUser
  },
}
