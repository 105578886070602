<template>
  <div id="email-design-manager-container">
    <Header :copy="copy">
      <StepperButton
        slot="button"
        :style="{ width: '236px' }"
        @clickEvent="navToEmailDesignWizardNew"
        :disabled="false"
        :copy="copy.button"
      />
    </Header>
    <div id="table-container">
      <EmailDesignTable/>
    </div>
  </div>
</template>

<script>
import { userViews, edmHashOptions } from '@/constants'
import EmailDesignTable from '@/components/hotel-dashboard/email-design-manager/EmailDesignTable'
import Header from '@/components/common/Header'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton'

export default {
  name: 'EmailDesignLibraryContainer',
  components: {
    EmailDesignTable,
    Header,
    StepperButton
  },
  data () {
    return {
      copy: {
        title: 'Email Design Library',
        subtitle: 'Here you can view the email designs you have access to. Read-only designs are indicated in the “Access” column.',
        button: 'Create an Email Design'
      }
    }
  },
  computed: {
  },
  methods: {
    navToEmailDesignWizardNew () {
      this.$router.push({
        name: userViews.EMAIL_DESIGNS_NEW,
        params: {
          hotelId: this.$route.params.hotelId,
        },
        hash: `#${edmHashOptions.SETTINGS}`
      })
    },
  }
}
</script>

<style lang="sass">
#email-design-manager-container
  font-family: Lato !important
</style>

<style lang="sass" scoped>
#email-design-manager-container
  width: 100%
  height: auto
  background-color: white
#table-container
  width: 100%
  height: auto
</style>
