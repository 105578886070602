import { campaignStateOptions, campaignEditorHashOptions } from '@/constants'
import { Campaign, CampaignType } from '@/types'

const { SENT, PENDING } = campaignStateOptions
const { SELECT_EMAIL_DESIGN, VIEW_EMAIL_DESIGN } = campaignEditorHashOptions

export const CampaignUtils = {
  getHash (campaign: Campaign): string {
    const hash = [SENT, PENDING].indexOf(campaign.state) > -1
      ? VIEW_EMAIL_DESIGN
      : SELECT_EMAIL_DESIGN
    return `#${hash}`
  },
  isEventBased (campaign: Campaign): boolean {
    return campaign?.type === ('event' as CampaignType)
  },
  isTimeBased (campaign: Campaign): boolean {
    return campaign?.type === ('time' as CampaignType)
  }
}
