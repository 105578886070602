









import props from '@/components/icons/props'

export default {
  name: 'ActionEllipses',
  props,
}
