<template lang="html">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 35 35"
  >
    <title>{{ this.name }}</title>
    <desc id="desc">{{ this.name }}</desc>
    <path d="M30.93,10.75a1.9,1.9,0,0,0-3.48,0,4.81,4.81,0,0,0-.63,2.48,7.64,7.64,0,0,0,.2,2,2.31,2.31,0,0,0,.68,1.08l.08.07v.1L27.46,25a.45.45,0,0,0,.12.31.39.39,0,0,0,.29.13h2.61a.42.42,0,0,0,.43-.42l-.24-6.2a.41.41,0,0,0-.14-.31.46.46,0,0,0-.29-.1.44.44,0,0,0-.42.44l.23,5.74H28.32l.32-8.33a.44.44,0,0,0-.24-.39,1.15,1.15,0,0,1-.56-.83,7,7,0,0,1-.17-1.76,4.05,4.05,0,0,1,.5-2c.36-.6.75-.73,1-.73s.65.13,1,.73a4.08,4.08,0,0,1,.51,2A7,7,0,0,1,30.54,15a1.2,1.2,0,0,1-.58.84.4.4,0,0,0-.22.24.4.4,0,0,0,0,.32.43.43,0,0,0,.24.22.4.4,0,0,0,.32,0h0a2.11,2.11,0,0,0,1-1.37,7.93,7.93,0,0,0,.21-2A4.78,4.78,0,0,0,30.93,10.75Z"
    />
    <path
      d="M17.5,9A8.54,8.54,0,1,0,26,17.5,8.55,8.55,0,0,0,17.5,9Zm0,16.23a7.69,7.69,0,1,1,7.69-7.69A7.7,7.7,0,0,1,17.5,25.19Z"
    />
    <path
      d="M17.5,11.23a6.27,6.27,0,1,0,6.27,6.27A6.27,6.27,0,0,0,17.5,11.23Zm0,11.69a5.42,5.42,0,1,1,5.42-5.42A5.42,5.42,0,0,1,17.5,22.92Z"
    />
    <path
      d="M8.19,13.92V10a.42.42,0,0,0-.12-.3.43.43,0,0,0-.3-.13.37.37,0,0,0-.3.12.43.43,0,0,0-.13.3v3.16H6.24V10a.42.42,0,0,0-.12-.3.42.42,0,0,0-.3-.13.44.44,0,0,0-.31.13.42.42,0,0,0-.12.3v3.15H4.29V10a.42.42,0,0,0-.12-.3.41.41,0,0,0-.29-.13h0a.43.43,0,0,0-.4.43V14a7,7,0,0,0,.32,1.53,2,2,0,0,0,.56.81l.08.07v.1L4.09,25a.45.45,0,0,0,.12.31.41.41,0,0,0,.29.13H7.11A.42.42,0,0,0,7.54,25l-.32-8.59.08-.07a2.15,2.15,0,0,0,.57-.81A8,8,0,0,0,8.18,14S8.19,13.94,8.19,13.92Zm-.92.37a5.14,5.14,0,0,1-.2.91,1,1,0,0,1-.47.62.45.45,0,0,0-.24.4l.32,8.32H5l.32-8.33A.44.44,0,0,0,5,15.82a1,1,0,0,1-.47-.62,5.09,5.09,0,0,1-.2-.91L4.31,14h3Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDining2',
  data () {
    return {
      name: 'icon dining 2'
    }
  },
}
</script>

<style lang="css">
</style>
