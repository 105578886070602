<template>
  <div
    v-if="show"
    id="email-design-wizard-container"
    ref="email-design-wizard-container"
    class="md-content-item"
  >
    <Modal
      :active="showModal"
      :copy="copy.modal"
      @leave="handleLeave"
      @stay="closeModal"
    />
    <WizardHeader @clickEvent="handleBack" :showBack="!FROM_ECM">
      <template slot="header">
        <span>{{ headerCopy }}</span>
      </template>
      <template v-if="IS_READONLY" slot="caption">
        <span v-if="IS_READONLY" ref="view-only-copy">{{ copy.viewOnly }}</span>
      </template>
      <template slot="button-container">
        <!-- TODO wrap in one el -->
        <StepperButton
          v-if="!FROM_ECM"
          ref="save-and-exit-button"
          :copy="copy.buttons.saveAndExit"
          :disabled="false"
          :inverse="true"
          @clickEvent="saveAndExit"
        />
        <StepperButton
          ref="save-button"
          :style="{ width: saveButtonConfig.width }"
          :copy="saveButtonConfig.copy"
          :disabled="false"
          @clickEvent="save({ initialSave: false })"
        />
      </template>
    </WizardHeader>
    <div :style="{ backgroundColor: 'white', height: 'auto', width: '100%' }">
      <md-tabs md-sync-route md-alignment="fixed">
        <template slot="md-tab" slot-scope="{ tab }">
          <span class="tab-title lato-font">{{ tab.label }}</span>
        </template>

        <md-tab
          :id="edmHashOptions.SETTINGS"
          :md-label="copy.leftColTitle"
          :to="{ hash: `#${edmHashOptions.SETTINGS}`, query: $route.query }"
        >
          <DesignSettings
            v-if="isHash(edmHashOptions.SETTINGS)"
            :showSaveInfo="emailBuilderTabDisabled"
          />
        </md-tab>

        <md-tab
          :id="edmHashOptions.BUILDER"
          :md-disabled="emailBuilderTabDisabled"
          :md-label="copy.centerColTitle"
          :to="{ hash: `#${edmHashOptions.BUILDER}`, query: $route.query }"
        >
          <EmailBuilder
            v-if="isHash(edmHashOptions.BUILDER)"
            :preview="IS_READONLY"
            :save="save"
          />
        </md-tab>

        <md-tab
          :id="edmHashOptions.TEST"
          :md-disabled="emailTestTabDisabled"
          :md-label="copy.rightColTitle"
          :to="{ hash: `#${edmHashOptions.TEST}`, query: $route.query }"
        >
          <EmailTest :hasChanges="hasChanges" v-if="isHash(edmHashOptions.TEST)"/>
        </md-tab>
      </md-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { cloneDeep, isEqual } from 'lodash'
import DesignSettings from '@/components/hotel-dashboard/email-design-manager/wizard/design-settings/DesignSettings'
import { steps, tabs, userViews, edmHashOptions, campaignEditorHashOptions } from '@/constants'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton'
import EmailBuilder from '@/components/hotel-dashboard/email-design-manager/wizard/EmailBuilder'
import EmailTest from '@/components/hotel-dashboard/email-design-manager/wizard/EmailTest'
import Modal from '@/components/common/Modal'
import WizardHeader from '@/components/common/WizardHeader'

const MESSAGES = 'messages'

export default {
  name: 'EmailDesignWizard',
  components: {
    DesignSettings,
    EmailBuilder,
    EmailTest,
    StepperButton,
    Modal,
    WizardHeader,
  },
  props: {
    editing: {
      type: Boolean,
      required: true,
      default: () => false
    },
  },
  data () {
    return {
      edmHashOptions,
      showModal: false,
      show: false,
      showSaveModal: false,
      originalDesign: undefined,
      steps,
      tabs,
      copy: {
        modal: {
          title: 'Are you sure you want to leave?',
          body: 'Any unsaved changes will be lost.',
          leave: 'Leave without saving',
          stay: 'Stay on this page'
        },
        header: {
          create: 'Create a New Email Design',
          edit: 'Edit Email Design'
        },
        leftColTitle: 'Design Settings',
        centerColTitle: 'Email Builder',
        rightColTitle: 'Test Email',
        buttons: {
          saveAndExit: 'Save and Exit',
          saveDesign: 'Save Design',
          saveAndReturn: 'Save And Return To Campaign Editor'
        },
        viewOnly: 'View-Only'
      },
    }
  },
  computed: {
    ...mapState('edm', [
      'pageInfo',
      'emailDesign',
    ]),
    ...mapGetters('edm', [
      'GET_EMAIL_DESIGN',
      'IS_READONLY',
    ]),
    ...mapGetters([
      'FROM_ECM',
      'CREATE_COPY'
    ]),
    headerCopy () {
      return this.editing ? this.copy.header.edit : this.copy.header.create
    },
    saveButtonConfig () {
      return this.FROM_ECM
        ? {
          copy: this.copy.buttons.saveAndReturn,
          width: '300px'
        }
        : {
          copy: this.copy.buttons.saveDesign,
          width: '140px'
        }
    },
    shallowOriginalDesign () {
      return {
        name: this.originalDesign.name,
        emailSubject: this.originalDesign.emailSubject,
        readAccessHotelIds: this.originalDesign.readAccessHotelIds,
        type: this.originalDesign.type
      }
    },
    shallowEmailDesign () {
      return {
        name: this.emailDesign.name,
        emailSubject: this.emailDesign.emailSubject,
        readAccessHotelIds: this.emailDesign.readAccessHotelIds,
        type: this.emailDesign.type
      }
    },
    hasChanges () {
      return this.hasShallowChanges || this.hasJsonChanges
    },
    hasShallowChanges () {
      return !isEqual(this.shallowOriginalDesign, this.shallowEmailDesign)
    },
    hasJsonChanges () {
      return !isEqual(this.originalDesign.json, this.emailDesign.json)
    },
    emailBuilderTabDisabled () {
      const { name, emailSubject, type } = this.originalDesign
      if (!name || !emailSubject || !type) {
        return true
      }
      return false
    },
    emailTestTabDisabled () {
      return !this.originalDesign.htmlUrl
    }
  },
  methods: {
    ...mapActions('edm', [
      'CREATE_OR_UPDATE_EMAIL_DESIGN',
      'FETCH_EMAIL_DESIGNS',
    ]),
    ...mapMutations('edm', [
      'RESET_EMAIL_DESIGN',
      'SET_EMAIL_DESIGN',
      'SET_NAME',
    ]),
    ...mapMutations(MESSAGES, [
      'CLEAR_MESSAGES'
    ]),
    handleLeave () {
      this.handleResetEmailDesign()
      this.navToEmailDesignsTable()
    },
    handleBack () {
      if (this.hasChanges) {
        this.showModal = true
      } else {
        this.navToEmailDesignsTable()
      }
    },
    closeModal () {
      this.showModal = false
    },
    isHash (id) {
      return this.$route.hash === `#${id}`
    },
    setOriginalDesign () {
      this.originalDesign = cloneDeep(this.emailDesign)
    },
    handleResetEmailDesign () {
      if (!this.FROM_ECM) {
        this.RESET_EMAIL_DESIGN()
      }
    },
    async setup () {
      this.handleResetEmailDesign()
      if (this.editing) {
        const id = this.$route.params.emailDesignId
        // TODO extract to action
        if (!this.GET_EMAIL_DESIGN({ id })) {
          await this.FETCH_EMAIL_DESIGNS({ id })
        }
        const design = this.GET_EMAIL_DESIGN({ id })
        this.SET_EMAIL_DESIGN(design)
        const response = await this.$store.dispatch(
          'edm/FETCH_EMAIL_STRING',
          { url: design.htmlUrl }
        )
        this.$store.commit('edm/SET_EMAIL_DESIGN_HTML', response.data)
      }
      this.setOriginalDesign()
      if (this.FROM_ECM && this.CREATE_COPY) {
        this.updateDesignNameToTriggerHasChanges()
      }
    },
    navToEmailDesignsTable () {
      this.$router.push({
        name: userViews.EMAIL_DESIGNS,
        params: { ...this.$route.params }
      })
    },
    cancel () {
      if (this.hasChanges) {
        // modal will saveAndGoBack
        this.showSaveModal = true
      } else {
        this.navToEmailDesignsTable()
      }
    },
    async saveAndExit () {
      const resp = await this.createOrUpdate({ initialSave: false })
      if (!resp.error) {
        this.RESET_EMAIL_DESIGN()
        this.originalDesign = null
        this.navToEmailDesignsTable()
      }
    },
    navBackToCampaignEditor ({ emailDesignId }) {
      const name = this.$route.query.campaign
        ? userViews.CAMPAIGNS_EDIT
        : userViews.CAMPAIGNS_NEW
      return this.$router.replace({
        name,
        params: {
          hotelId: this.$route.params.hotelId,
          campaignId: this.$route.query.campaign,
        },
        hash: `#${campaignEditorHashOptions.SELECT_EMAIL_DESIGN}`,
        query: {
          fromEdm: true,
          campaign: this.$route.query.campaign,
          emailDesign: emailDesignId,
          force: true
        }
      })
    },
    async save ({ initialSave = false }) {
      const resp = await this.createOrUpdate({ initialSave })
      if (!resp.error) {
        this.setOriginalDesign()

        if (initialSave) {
          return
        }

        if (this.FROM_ECM) {
          this.show = false
          this.CLEAR_MESSAGES()
          return this.navBackToCampaignEditor({ emailDesignId: resp.data.id })
        }

        if (!this.editing) {
          // push to edit route after first save
          return this.$router.replace({
            name: userViews.EMAIL_DESIGNS_EDIT,
            params: {
              hotelId: this.$route.params.hotelId,
              emailDesignId: resp.data.id
            },
            hash: this.$route.hash
          })
        }
      }
    },
    async createOrUpdate ({ initialSave = false }) {
      const resp = await this.CREATE_OR_UPDATE_EMAIL_DESIGN({
        id: this.$route.params.emailDesignId,
        showSuccess: !initialSave
      })
      return resp
    },
    back () {
      // TODO: check if user has unsaved changes, prompt are u sure modal
      this.$router.push({
        name: userViews.EMAIL_DESIGNS,
        params: {
          hotelId: this.$route.params.hotelId
        }
      })
    },
    async syncRouteHashToActiveTab () {
      if (!this.$route.hash) {
        await this.$router.replace({
          name: this.$route.name,
          params: this.$route.params,
          hash: `#${edmHashOptions.SETTINGS}`
        })
      }
    },
    updateDesignNameToTriggerHasChanges () {
      const now = Date.now()
      this.SET_NAME(`${this.originalDesign.name}-copy-${now}`)
    }
  },
  async mounted () {
    await this.setup()
    await this.syncRouteHashToActiveTab()
    this.show = true
  },
  async beforeRouteUpdate (to, from, next) {
    if (to.params.emailDesignId !== from.params.emailDesignId) {
      await this.setup()
      this.show = true
    }
    next()
  },
  beforeDestroy () {
    this.RESET_EMAIL_DESIGN()
  },
}
</script>

<style lang="sass" scoped>
#email-design-wizard-container
  width: 100%
  height: auto
  padding: 10px
</style>

<style lang="sass">
#email-design-wizard-container
  font-family: Lato !important
  // targets disabled tabs; set by vue mat based on click
  a.router-link-active
    background-color: white

  .md-tabs.md-theme-default .md-tabs-navigation
    @apply bg-gray-100

  .md-tabs-navigation.md-elevation-0
    display: flex
    justify-content: space-around
    width: 100%
    > a
      min-width: 33.33%
      height: 55px
      border: none
    .tab-title
      color: black
      font-weight: 600
      font-size: 16px
      text-transform: none !important

  #footer-button-container
    height: 100px

  .md-tabs .md-tabs-indicator
    background-color: transparent !important

</style>
