<template>
  <div id="redemption-activate-container" class="laas-oprm-stepper-content">
    <div class="md-body-2 laas-oprm-body-text-color">
      {{ copy.description }}
    </div>
    <md-switch v-model="active"></md-switch>
    <div class="status-text laas-bold">{{ this.active ? 'Yes, Activate' : 'No, Keep Inactive' }}</div>
    <div class="laas-oprm-helper-text">
      {{ copy.toggleDescription }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'RedemptionActivate',
  data () {
    return {
      copy: {
        description: 'Would you like to activate this reward? Activating this reward means that this reward will be live and offered to customers.',
        toggleDescription: 'If you do not want this reward to go live yet, please leave the toggle as “No, Keep Inactive”. You can control this toggle at any time from the home screen.'
      }
    }
  },
  computed: {
    active: {
      get: function () {
        return this.$store.state.oprm.reward.active
      },
      set: function (value) {
        this.$store.commit('oprm/SET_REWARD_FIELD', {
          field: 'active',
          value
        })
      }
    }
  },
  methods: {}
}
</script>

<style lang="sass">
#redemption-activate-container
  .status-text
    display: inline-block
    padding-bottom: 15px
    vertical-align: middle
</style>
