import { render, staticRenderFns } from "./WorkflowManagerContainer.vue?vue&type=template&id=167f4aad&scoped=true&"
import script from "./WorkflowManagerContainer.vue?vue&type=script&lang=ts&"
export * from "./WorkflowManagerContainer.vue?vue&type=script&lang=ts&"
import style0 from "./WorkflowManagerContainer.vue?vue&type=style&index=0&id=167f4aad&lang=sass&scoped=true&"
import style1 from "./WorkflowManagerContainer.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "167f4aad",
  null
  
)

export default component.exports