<template>
  <div id="custom-stepper-header" class="laas-custom-stepper-header md-stepper-header md-active">
    <div class="md-stepper-number">
      <template>{{ number }}</template>
    </div>

    <div class="md-stepper-text stepper-text-container">
      <span class="md-stepper-label">{{ label }}</span>
      <span
        v-if="meta"
        class="laas-required"
        ref="meta"
      >
        {{ meta }}
      </span>
    </div>
  </div>
</template>

<script>
/**
 * this component is a stripped down tweak of the md-step component's header
 * meant to be used in the expanded edit reward view. we remove a lot of functionality
 * as well as the click and hover effects (by rendering as a div and not an md-button component)
 */
export default {
  name: 'MdStepperHeader',
  props: {
    index: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    meta: {
      type: String,
      required: false
    }
  },
  computed: {
    number () {
      return this.index + 1
    }
  }
}
</script>

<style lang="sass">
#custom-stepper-header
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  align-content: flex-start
  padding: 0 30px 0 24px
  height: 36px

.stepper-text-container
  display: inline-block !important
</style>
