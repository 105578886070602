<template>
  <div id='oprm-wizard-reward-expiration-step'>
    <WizardStep :editing="editing" :reverseOrderOnMobile="true">
      <CustomStepHeader slot="step-header" :index="index" :label="'Reward Expiration'" :meta="meta"/>
      <RedemptionExpiration :editing="editing" v-if="HOTEL_GROUP_IS_RETAIN" slot="left-content"/>
      <CustomStepButtons
        slot="button-group"
        :disabled="IS_REDEMPTION_EXPIRATION_DISABLED"
        :index="index"
      />
    </WizardStep>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import WizardStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/WizardStep'
import RedemptionExpiration from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/RedemptionExpiration'
import CustomStepHeader from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepHeader'
import CustomStepButtons from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepButtons'

export default Vue.extend({
  name: 'RedemptionExpirationStep',
  components: {
    WizardStep,
    CustomStepHeader,
    CustomStepButtons,
    RedemptionExpiration,
  },
  props: {
    editing: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    meta: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters(['HOTEL_GROUP_IS_RETAIN']),
    ...mapGetters('oprm', [
      'IS_REDEMPTION_EXPIRATION_DISABLED'
    ]),
  },
})
</script>
