<template>
  <svg
    id="icon-flight"
    viewBox="0 0 400.156 400.155"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.flight}">

    <title id="title">flight icon</title>
    <desc id="desc">flight icon</desc>

    <g>
      <path d="M396.31,49.545c6.088-18.271,
      4.949-32.354-3.432-42.255c-9.897-8.376-23.983-9.514-42.258-3.434
      c-18.273,6.091-34.643,16.368-49.111,30.833l-45.679,45.683L65.973,
      34.978c-3.046-0.953-5.901-0.192-8.564,2.282L20.862,73.805c-2.091,
      2.096-2.95,4.665-2.568,7.71c0.571,3.049,2.19,5.33,4.854,6.854l145.038,
      79.656l-73.948,73.948L38.85,226.845c-0.38-0.192-1.139-0.288-2.282-0.288
      c-2.666,0-4.853,0.855-6.567,2.57L2.594,256.819c-1.903,2.279-2.758,
      4.66-2.568,7.132c0.378,3.045,1.615,5.235,3.711,6.57l71.946,53.957l53.959,
      71.944c1.714,2.098,3.999,3.329,6.854,3.716h0.571c2.666,0,4.853-0.855,
      6.567-2.566l27.406-27.404c2.474-2.669,3.236-5.619,2.286-8.854l-15.133
      -55.388l73.947-73.953L311.8,377.019c1.14,2.475,3.138,3.997,5.995,
      4.569c0.568,0.191,1.235,0.284,1.999,0.284c2.279,0,4.09-0.572,
      5.421-1.712l36.552-27.407c3.23-2.663,4.373-5.801,3.426-9.418l-45.68
      -198.711l45.967-45.965C379.942,84.188,390.219,67.816,396.31,49.545z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconFlight',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
