<template>
  <div id="oprm-wizard-text-display-step">
    <WizardStep :editing="editing">
      <CustomStepHeader
        slot="step-header"
        :index="index"
        :label="'Text Display'"
      />
      <TextDisplay slot="left-content" />
      <TextDisplayPreview slot="right-content" />
      <CustomStepButtons
        slot="button-group"
        :disabled="IS_TEXT_DISPLAY_DISABLED"
        :index="index"
      />
    </WizardStep>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WizardStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/WizardStep'
import TextDisplay from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/TextDisplay'
import TextDisplayPreview from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/TextDisplayPreview'
import CustomStepHeader from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepHeader'
import CustomStepButtons from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepButtons'

export default {
  name: 'TextDisplayStep',
  components: {
    WizardStep,
    CustomStepHeader,
    TextDisplay,
    TextDisplayPreview,
    CustomStepButtons
  },
  props: {
    editing: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('oprm', [
      'IS_TEXT_DISPLAY_DISABLED'
    ]),
  }
}
</script>
