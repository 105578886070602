import { Theme, TailwindConfig } from '@/types/tailwind.ts'

export { Theme as TailwindTheme }
export { TailwindConfig }

export namespace StatusIndicator {
  export interface Config {
      dimension: string;
      fontSize: string;
      fontColor: string;
  }

  export const ACTIVE = 'active'
  export const PENDING = 'pending'
  export const INACTIVE = 'inactive'
}

export namespace CustomStepper {
  export interface StepHeader {
    name: string;
    isRetain: boolean;
  }
  export type StepHeaders = Array<StepHeader>
}

export type ExpiryType = 'ac' | 'ne' | 'di' | 'st' | 'sd'

export namespace UserManagement {
  export type ProvidedFunctions = 'Users__toggleShowAddUser' | 'Users__createNewMerchant'
}

/**
 * products
 */
export type ProductType =
  | 'cn' // convert
  | 'rn' // retain
  | 'le' // lite

/**
 * navigation configurations
 */
export interface AdminToggleConfig {
  copy: string;
  icon: string;
  routeName: string;
}

export interface ParamKey {
  key: string;
  fallbackGetter: string;
}

export interface Link {
  copy: string;
  description?: string; // similar to a tagline
  routeName?: string;
  show?: () => boolean;
  disabled?: () => boolean;
  children?: Link[];
}

export interface MenuLink extends Link {
  menuRef?: string;
  style?: Record<string, string | number>;
}

export interface DrawerLink extends Link {
  icon: string[];
}

export interface NavConfig {
  idParam?: ParamKey;
  topMenuLinks: MenuLink[];
  links: DrawerLink[];
  adminToggles: AdminToggleConfig[];
  currentApp: string;
  showCopyHeader: boolean;
}

export namespace ErrorHandler {
  export type Key =
    | 'incorrectPassword'
    | 'userDoesNotExist'
    | 'enterAValidUrl'
    | 'algorithmNameIsTaken'

  export interface CopyConfig {
    message: string;
    friendlyMessage: string;
  }

  export type CopyConfigMap = {
    [k in Key]: CopyConfig
  }
}

export type ErrorObject = { message: string }

/**
 * Campaign & ECM
 */

export type CampaignTrigger =
  // type: event
  | 'Booking Created'
  | 'Booking Updated'
  | 'Booking Cancelled'
  | 'Profile Completion'
  | 'Membership Anniversary'
  | 'Birthdate'
  // type: time
  | 'Scheduled Send'
  | 'Send Immediately'

export type CampaignState =
  | 'sent'
  | 'scheduled'
  | 'draft'
  | 'pending'
  | 'active'
  | 'inactive'
  | 'error no sender'
  | 'error empty segment'
  | 'error sending'

export type CampaignType =
  | 'event'
  | 'time'

export interface DagBase {
  readonly id: number;
  readonly uuid: string;
  parent: number | void;
  readonly created?: string;
  readonly modified?: string;
  ctaLink: string | void;
  ctaRewardNotBeforeTime: string | void;
  campaignId: number | void;
  triggerTypeId: number | void;
  param: string | void;
  rewardAlgoId: number | void;
  emailDesignId: number | void;
  emailDesignName: string | void;
  workflowEnabled: boolean;
  operator: NodeOperator | void;
}

export interface Dag extends DagBase {
  children: Dag[];
}

export type DagMap = { [k: string]: Dag }

export interface Workflow extends DagBase {
  children: number[];
}

export type WorkflowMap = { [k: string]: Workflow }

/**
 * used as type for activeNode
 * TODO - refactor Dag/Workflow/NodeTemplate
 */
export type NodeTemplate = {
  children: number[];
  parent: void | number;
  ctaLink: void | string;
  ctaRewardNotBeforeTime: void | string;
  ctaRewardExpirationTime: void | string;
  campaignId: void | number;
  param: void | string;
  rewardAlgoId: void | string;
  state: string;
  emailDesignId: void | number;
  triggerTypeId: void | number;
  eventType: void | number;
  displayName: void | string;
  isPromotion: void | boolean;
}

export type CampaignCategory =
  | 'email_and_offer'
  | 'email_only'
  | 'offer_only'
  | 'email_design_test'
  | 'other'

export type Campaign = {
  id: number | void;
  uuid: string;
  name: string;
  hotelId: number | void;
  emailDesignId: number | void;
  segmentIds: number | void;
  enabled: boolean | void;
  modified: string;
  state: CampaignState;
  active: boolean; // can only be true for event-based campaigns
  type: CampaignType;
  dag: Dag;
  param: string | void;
  category: CampaignCategory;
}

export type CampaignEditorHashOption =
  | 'select-email-design'
  | 'view-email-design'

// campaign triggers
export type CampaignEventConfig = {
  id: number;
  logic: string;
  name: string;
  type: CampaignType;
  category: string;
  copy: string;
  copyForOperatorNot: string;
}

export type Operation = 'test' | 'launch' | 'deactivate'

/**
 * Email Design & EDM
 */

export type EmailDesignType =
  | 'tp' // template
  | 'su' // single-use

export type EmailDesign = {
  id: number | void;
  name: string;
  emailSubject: string;
  hotelId: number | void;
  modified: string;
  created: string;
  readAccessHotelIds: number[];
  htmlUrl: string;
  html: string;
  json: JSON;
  type: EmailDesignType;
  rewardsDisplayLimit: number | void;
}

export type Segment = {
  id: number | void;
  name: string;
  hotel: number| void;
  description: string;
  definition: string;
  definitionType: string;
  lastRunTime: string;
  created: string;
  modified: string;
}

/**
 * Component Library
 */

// Button
export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'default'

export type ButtonSize =
  | 'compact'
  | 'normal'
  | 'round'
  | 'round-small'

export type RadioButtonSize =
  | 'small'
  | 'medium'
  | 'large'

export namespace Promo {
  export type Category =
    | 'entertainment'
    | 'travel'
    | 'shopping'
    | 'healthAndWellness'
    | 'foodAndBeverage'
    | 'tours'

  export type CategoryMap = {
    [k in Category]: string;
  }

  export type CategoryIcon = {
    component: Vue.Component;
    category: Category;
  }
}

export type RedemptionMethod =
  | 're' // reusable code
  | 'su' // single use code
  | 'vo' // e-voucher
  | 'lp' // landing page

export type OprmRedemptionMethod =
  | 're' // reusable code
  | 'lp' // redemption link
  | 'ip' // tap to redeem

export type RewardDivision =
  | 'lo'
  | 'na'
  | 'op'

export type DestinationGeotarget = {
  hashid: string;
  name: string;
}

export type CreativeConfig = {
  identifier: string | void;
  thumbnailText: string | void;
  thumbnailImage: string | void;
  promotedIcon: string | void;
  promotedText: string | void;
}

export type Reward = {
  id?: number | void;
  uuid?: string | void;
  active: boolean;
  created?: string | void;
  modified?: string | void;
  // thumbnailImage?: string | void; - only on outgoing payload
  promotionCategory: Promo.Category | void;
  destinationGeotargets: DestinationGeotarget[];
  termsAndConditions: string | void;
  redemptionMethod: OprmRedemptionMethod;
  redemptionInstructions: string | void;
  cost: string;
  value: string;
  division: RewardDivision;
  merchant: { id: number; name: string };
  // merchantName: string | void; - not necessary
  creativeConfigs: CreativeConfig[];
  onPropertyHotels: string[]; // hotel api names
  redemptionUrl: string | void;
  reusablePromoCode: string | void;
  expiryType: ExpiryType;
  seasonal: boolean;
  seasonStart: string | void;
  seasonEnd: string | void;
  redemptionExpiration: string | void;
  requiresNotice: boolean;
  specificExpirationDate: string | void;
}

export type ElementRect = {
  left: number;
  top: number;
  width: number;
  height: number;
}

export type OptsStats = {
  height: number;
  invertVertically: boolean;
  invertHorizontally: boolean;
}

export type RewardType = 'onProperty' | 'marketPlace'

// Table Component
export type TableColumn = {
  key: string;
  name: string;
  sortable: boolean;
  width?: number;
  alignment?: string;
}

export type InitialTab = {
  disabled: boolean;
  title: boolean;
  ref: string | void;
}

// Tabs Component
export type Tab = InitialTab & { ref: string }

export type XPosition = 'left' | 'right'
export type OptionsXPosition = { [k in XPosition]?: string | void }

export type YPosition = 'top' | 'bottom'
export type OptionsYPosition = { [k in YPosition]?: string | void }

export type AutocompleteUtilMethod = 'toLowerCase' | 'toString' | string

export type ToLowerCase = {
  (arg: string): string;
}

export type ToString = {
  (arg: number): string;
}

export type Fux = {
  channel: string;
  context: string;
  funnel_ux: string;
  hotel: number;
  toLowerCase: ToLowerCase;
  toString: ToString;
  uuid: string;
}

export type AutocompleteObject = {
  [k in AutocompleteUtilMethod]: ToLowerCase | ToString | string | number | boolean | null | undefined;
}

/**
 * @note the whitelistIdsParamId and excludeIdsParamId
 * are the id numbers of the param objects that are
 * saved/updated in db.
 *
 * see store.ram.getters.ALGO_PAYLOAD.
 *
 * param object interface:
 * id: number
 * key: string
 * value_type: string
 * value: string (comma delimited list of numbers)
 */

export type RewardAlgo = {
  identifier: string;
  maxRewardSelections: number | void;
  onPropertyRewards: number | void;
  marketPlaceRewards: number | void;
  params: string[];
  hotelId: number | void;
  whitelistIds: number[];
  whitelistIdsParamId: number;
  excludeIds: number[];
  excludeIdsParamId: number;
}

export type PageInfo = {
  page: number;
  pageSize: number;
  total: number | undefined;
  index: number;
}

export type Resp = {
  data: unknown;
  error: unknown | void;
}

export type Hotel = {
  active: boolean;
  campaignCost: string;
  cradleId: number;
  hotelCode: string;
  hotelGroupHotelIds: number[];
  hotelGroupId: number;
  id: number;
  isVanityProperty: boolean;
  name: string;
  productType: string;
  senderEmail: string;
}

// used by DagContainer & NodeTree components
export type RegisteredNode = {
  ref: string;
  depth: number;
  el: HTMLElement;
  children: number;
  childrenContainer: HTMLElement;
}

export type NodeOperator =
  | 'no' // has a booking
  | 'nt' // does not have a booking

export type ComponentParam = {
  id?: number;
  required: boolean;
  field_name: string;
  field_value: unknown;
  field_category: string;
  field_type: string;
  default_value?: string | number; // enriched after fetching
  __temporary_component_id__?: number; // enriched after fetching
}

export type TemplateFieldPermission = {
  id: number;
  required: boolean;
  field_value: unknown;
  field_name: string;
  category: string; // field_category on component param
  field_type: string;
  default_value?: string | number;
  field: number;
}

export type InitialComponentParam = {
  id?: number;
  required: boolean;
  field_name: string;
  field_value: unknown;
  field_category: string;
  field_type: string;
  default_value?: string | number; // enriched after fetching
  __temporary_component_id__?: number; // enriched after fetching
}

export type UTMEventName = 'Has UTM Medium' | 'Has UTM Campaign' | 'Has UTM Source'

export type EventName = 'Has Workflow Event' | 'Has Booking Channel' | 'Has Rate Type'

export enum WorkflowOfferType {
  BOOKING = 'Booking',
  MILESTONE = 'Milestone',
  PROMOTION = 'Promotion',
}

export type DeptKey = 'CSM' | 'AM' | 'Design' | 'Product' | 'Engineering' | 'Client'
export type DeptValue = 'csm' | 'am' | 'design' | 'product' | 'eng' | 'client'
