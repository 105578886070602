<template>
  <div id="status-container">
    <span id="status-icon" :style="{ backgroundColor: config.circleColor }"/>
    <span>{{ copy.entity }} is {{ config.copy }}</span>
  </div>
</template>

<script>
import { colors } from '@/constants'

export default {
  name: 'Status',
  props: {
    active: {
      type: Boolean,
      required: true,
      default: () => false
    },
    copy: {
      type: Object,
      required: true,
      default: () => ({
        active: 'Active',
        inactive: 'Inactive',
        entity: 'Property'
      })
    },
  },
  computed: {
    config () {
      let circleColor
      let copy

      if (this.active) {
        copy = this.copy.active
        circleColor = colors.enabled
      } else {
        copy = this.copy.inactive
        circleColor = colors.error
      }

      return { copy, circleColor }
    },
  }
}
</script>

<style lang="sass" scoped>
#status-container
  font-size: 11px
  @apply text-gray-400
#status-icon
  height: 8px
  width: 8px
  border-radius: 50%
  display: inline-block
  margin-right: 4px
</style>
