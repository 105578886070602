














































































































import moment from 'moment'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import {
  userViews,
  campaignStateOptions,
  campaignMergeTags,
  campaignEditorHashOptions,
  emailDesignTypes,
  statusColors,
} from '@/constants'
import { cloneDeep, isEqual } from 'lodash'
import { CampaignUtils } from '@/utils/campaign'
import WizardHeader from '@/components/common/WizardHeader.vue'
import CampaignSettings from '@/components/hotel-dashboard/campaign-manager/CampaignSettings.vue'
import Button from '@/components/button/Button.vue'
import Modal from '@/components/common/Modal.vue'
import { Campaign, CampaignEditorHashOption, EmailDesign, ButtonType } from '@/types'

/**
 * content in left col is driven by url hash
 */

const { SENT, SCHEDULED, DRAFT, PENDING } = campaignStateOptions
const { SELECT_EMAIL_DESIGN, VIEW_EMAIL_DESIGN } = campaignEditorHashOptions
const { TEMPLATE } = emailDesignTypes
const ECM = 'ecm'
const EDM = 'edm'
const MESSAGES = 'messages'

export type ComponentConfig = {
  component?: Vue.Component;
  props?: Record<string, unknown>;
}

export type ComponentConfigs = {
  [k in CampaignEditorHashOption]?: ComponentConfig;
}

export type ThirdButtonConfig = {
  copy: string;
  width: number;
  type: ButtonType;
  class?: string;
  clickEvent: () => void;
}

export default {
  name: 'CampaignEditor',
  components: {
    WizardHeader,
    Button,
    Modal,
    SelectEmailDesign: () => import('@/components/hotel-dashboard/campaign-manager/SelectEmailDesign.vue'),
    EmailBuilder: () => import('@/components/hotel-dashboard/email-design-manager/wizard/EmailBuilder.vue'),
    CampaignSettings,
  },
  props: {
    editing: {
      type: Boolean,
      required: true,
      default: () => false
    }
  },
  data () {
    return {
      statusColors,
      mergeTags: campaignMergeTags,
      showExitModal: false,
      showReviewModal: false,
      showDeactivateModal: false,
      originalCampaign: undefined,
      show: false,
      emailDesignHtml: undefined,
      createNewRewardAlgo: false,
      copy: {
        errors: {
          invalidRewardAlgo: 'You must choose a reward algorithm for this email design.',
          invalidCtaLink: 'You must set a CTA link url for this email design.',
          missingScheduleDatetime: 'You must schedule a date and time to send campaign.',
          invalidSegments: 'You must include at least one segment to save campaign.',
          saveAsTemplate: 'You must copy and save template as a single use email design before saving.',
          noDesignSelected: 'Please select a design for your campaign.',
          invalidTrigger: 'Please select an event trigger for your campaign.',
          invalidType: 'Please select a campaign type.'
        },
        success: {
          scheduled: 'Campaign has been scheduled.',
          sent: 'Campaign has been sent.',
          launched: 'Campaign has been launched.',
        },
        modal: {
          deactivate: {
            body: 'Deactivate this campaign?',
            leave: 'No',
            stay: 'Yes',
          },
          exit: {
            title: 'Are you sure you want to leave?',
            body: 'Any unsaved changes will be lost.',
            leave: 'Leave without saving',
            stay: 'Stay on this page'
          },
          send: {
            title: 'Confirm Send',
            body: 'Your campaign has been checked by our system and is ready to be sent!',
            leave: 'Cancel',
            stay: 'Send Now'
          },
          schedule: {
            title: 'Confirm Schedule',
            body: 'Your campaign has been checked by our system and is ready to be scheduled!',
            leave: 'Cancel',
            stay: 'Schedule Now'
          },
          event: {
            title: 'Confirm Launch Campaign',
            body: 'Your campaign has been checked by our system and is ready to be launched! You may deactivate it at any time.',
            leave: 'Cancel',
            stay: 'Launch Now'
          }
        },
        header: {
          new: 'Create New Campaign',
          editing: 'Edit Campaign',
          view: 'View Campaign',
        },
        caption: 'View-Only',
        buttons: {
          second: 'Save',
          confirmDetailsAndLaunch: 'Confirm Details & Launch',
          deactivate: 'Deactivate',
        }
      }
    }
  },
  watch: {
    '$store.ecm.campaign.dag.emailDesignId': (newValue, oldValue) => {
      console.log('[WATCH] $store.edm.campaign.dag.emailDesignId', oldValue, '>>', newValue)
      // if this changes we want to fetch that email design to check its json for merge tags
      this.handleEmailDesign()
    }
  },
  computed: {
    ...mapState(ECM, [
      'campaign',
      'triggerTypes',
      'dag',
      'sentDateTime'
    ]),
    ...mapGetters(ECM, [
      'GET_CAMPAIGN',
      'CAMPAIGNS_LIST', // FIXME remove
      'IS_EVENT_BASED',
      'CAMPAIGN_TRIGGER_IS_SEND_IMMEDIATELY',
      'SENT_DATETIME'
    ]),
    ...mapGetters(EDM, [
      'GET_EMAIL_DESIGN',
      'GET_REQUIRES_CTA_LINK',
      'GET_REQUIRES_REWARD_ALGO',
    ]),
    ...mapGetters(['FROM_EDM', 'FROM_RAM']),
    content (): ComponentConfigs {
      return {
        [SELECT_EMAIL_DESIGN]: {
          component: 'SelectEmailDesign',
          props: { editing: this.editing }
        },
        [VIEW_EMAIL_DESIGN]: {
          component: 'EmailBuilder',
          props: { preview: true, fromEcm: true }
        },
      }
    },
    contentComponent (): ComponentConfig {
      if (typeof this.$route.hash !== 'string') {
        console.warn('Cannot find component for hash:', this.$route.hash)
        return {}
      }
      const hash = this.parseHash()
      if (hash) {
        return this.content[hash]
      }
      return {}
    },
    hasChanges (): boolean {
      return !isEqual(this.originalCampaign, this.campaign)
    },
    headerCopy (): string {
      if (this.isSent) return this.copy.header.view
      return this.editing ? this.copy.header.editing : this.copy.header.new
    },
    showCaption (): boolean {
      return this.isSent || this.isScheduled || this.isPending
    },
    html (): string {
      const emailDesign = this.GET_EMAIL_DESIGN({ id: this.campaign.dag.emailDesignId }) as EmailDesign | void
      if (!emailDesign || typeof emailDesign.html !== 'string') {
        return ''
      }
      return emailDesign.html
    },
    ctaLinkIsValid (): boolean {
      if (this.GET_REQUIRES_CTA_LINK({ id: this.campaign.dag.emailDesignId })) {
        return Boolean(this.campaign.dag.ctaLink)
      }
      return true
    },
    rewardAlgoIsValid (): boolean {
      if (this.GET_REQUIRES_REWARD_ALGO({ id: this.campaign.dag.emailDesignId })) {
        return Boolean(this.campaign.dag.rewardAlgoId)
      }
      return true
    },
    showSentDatetimeCopy (): boolean {
      if (this.IS_EVENT_BASED) {
        return false
      }
      return Boolean(this.campaign.dag.param)
    },
    scheduleIsValid (): boolean {
      if (this.IS_EVENT_BASED) {
        return true
      }
      return Boolean(this.campaign.dag.param)
    },
    segmentIdsAreValid (): boolean {
      if (this.IS_EVENT_BASED) {
        return true
      }
      return this.campaign.segmentIds.length > 0
    },
    triggerIsValid (): boolean {
      return Boolean(this.campaign.dag?.triggerTypeId)
    },
    typeIsValid (): boolean {
      return Boolean(this.campaign.type)
    },
    hasEmailDesignId (): boolean {
      return Boolean(this.campaign.dag.emailDesignId)
    },
    isPending (): boolean {
      return this.campaign.state === PENDING
    },
    isSent (): boolean {
      return this.campaign.state === SENT
    },
    isScheduled (): boolean {
      return this.campaign.state === SCHEDULED
    },
    isDraft (): boolean {
      return this.campaign.state === DRAFT
    },
    isActive (): boolean {
      return this.campaign.active
    },
    /**
     * @note there is a method called getIsScheduledToSendNow that can be used
     * to get the value and when we do not want it to be dynamically updated.
     */
    isScheduledToSendNow (): boolean {
      if (this.IS_EVENT_BASED) {
        return false
      }
      if (!this.campaign.dag?.param || this.isSent) {
        return false
      }
      // datetime prior to now is considered ready to send now
      const dt = moment(this.campaign.dag.param)
      const now = moment()
      return now.isAfter(dt)
    },
    thirdButtonConfig (): ThirdButtonConfig {
      if (this.editing && (this.isScheduled || this.isActive)) {
        return {
          copy: this.copy.buttons.deactivate,
          width: 240,
          type: 'default',
          clickEvent: this.renderDeactivateModal
        }
      }
      return {
        copy: this.copy.buttons.confirmDetailsAndLaunch,
        width: 220,
        type: 'default',
        class: 'font-bold',
        clickEvent: this.reviewAndSend
      }
    },
    selectedEmailDesign (): EmailDesign | void {
      return this.GET_EMAIL_DESIGN({ id: this.campaign.dag.emailDesignId })
    },
    reviewModalCopy (): Record<string, string> {
      if (this.IS_EVENT_BASED) {
        return this.copy.modal.event
      }
      return this.getIsScheduledToSendNow() ? this.copy.modal.send : this.copy.modal.schedule
    },
    successMessage (): string {
      if (this.IS_EVENT_BASED) {
        return this.copy.success.launched
      }
      return this.getIsScheduledToSendNow()
        ? this.copy.success.sent
        : this.copy.success.scheduled
    },
    wizardHeaderStyle (): object {
      // override header breakpoints since we want this to overflow #SW-2614
      return {
        flexDirection: 'row !important',
        alignItems: 'center !important'
      }
    }
  },
  methods: {
    ...mapActions(MESSAGES, [
      'ADD_ERROR',
      'ADD_SUCCESS',
      'CLEAR_ERRORS'
    ]),
    ...mapMutations(MESSAGES, [
      'CLEAR_MESSAGES',
    ]),
    ...mapMutations(ECM, [
      'RESET_CAMPAIGN',
      'SET_CAMPAIGN',
      'SET_SENT_DATETIME'
    ]),
    ...mapMutations(EDM, [
      'SET_EMAIL_DESIGN',
      'RESET_EMAIL_DESIGN'
    ]),
    ...mapActions(ECM, [
      'FETCH_CAMPAIGNS',
      'FETCH_TRIGGER_TYPES',
      'CREATE_OR_UPDATE_CAMPAIGN',
      'TOGGLE_CAMPAIGN_ACTIVE',
      'HANDLE_SEND_IMMEDIATELY_SCHEDULE_DATETIME'
    ]),
    ...mapActions(EDM, [
      'FETCH_EMAIL_DESIGNS',
      'FETCH_EMAIL_STRING_AND_MERGE',
    ]),
    parseHash (): string | void {
      return this.$route.hash?.replace('#', '')
    },
    async setup (): Promise<void> {
      if (!this.FROM_EDM && !this.FROM_RAM) {
        this.RESET_CAMPAIGN()
      }

      if (Object.values(this.triggerTypes).length === 0) {
        this.FETCH_TRIGGER_TYPES()
      }

      const { campaignId } = this.$route.params

      if (this.editing) {
        if (!this.GET_CAMPAIGN({ id: campaignId })) {
          // TODO if we fail to fetch detail we need
          // to push back to table
          await this.FETCH_CAMPAIGNS({ id: campaignId })
        }
        const campaign = this.GET_CAMPAIGN({ id: campaignId })
        this.SET_CAMPAIGN(campaign)
        this.SET_SENT_DATETIME(campaign)
        this.HANDLE_SEND_IMMEDIATELY_SCHEDULE_DATETIME()
      }

      if (this.campaign.dag.emailDesignId) {
        await this.handleEmailDesign()
      }
      this.setOriginalCampaign()
    },
    async handleEmailDesign (): Promise<void> {
      await this.fetchEmailDesign()
      await this.fetchHtmlString()
    },
    async fetchEmailDesign (): Promise<void> {
      if (!this.GET_EMAIL_DESIGN({ id: this.campaign.dag.emailDesignId })) {
        await this.FETCH_EMAIL_DESIGNS({ id: this.campaign.dag.emailDesignId })
      }
    },
    async fetchHtmlString (): Promise<void> {
      if (!this.selectedEmailDesign.htmlUrl) {
        return
      }
      await this.FETCH_EMAIL_STRING_AND_MERGE({
        id: this.campaign.dag.emailDesignId,
        url: this.selectedEmailDesign.htmlUrl
      })
    },
    setOriginalCampaign (): void {
      this.originalCampaign = cloneDeep(this.campaign)
    },
    navToCampaignsTable (): void {
      this.$router.push({
        name: userViews.CAMPAIGNS,
        params: {
          hotelId: this.$route.params.hotelId
        }
      })
    },
    navToCreateNewRewardAlgo (): void {
      this.$router.push({
        name: userViews.REWARD_ALGOS_WIZARD_NEW,
        params: {
          hotelId: this.$route.params.hotelId
        }
      })
    },
    navToNew (): void {
      this.$router.push({
        name: userViews.CAMPAIGNS_NEW,
        params: {
          hotelId: this.$route.params.hotelId
        }
      })
    },
    navToEdit (campaignId) {
      this.$router.replace({
        name: userViews.CAMPAIGNS_EDIT,
        params: {
          hotelId: this.$route.params.hotelId,
          campaignId: campaignId
        },
        hash: this.$route.hash
      })
    },
    handleBack (): void {
      if (this.hasChanges) {
        this.showExitModal = true
      } else {
        this.navToCampaignsTable()
      }
    },
    handleLeave (): void {
      this.RESET_CAMPAIGN()
      this.createNewRewardAlgo ? this.navToCreateNewRewardAlgo() : this.navToCampaignsTable()
    },
    handleCreateNewRewardAlgo (): void {
      if (this.hasChanges) {
        this.createNewRewardAlgo = true
        this.showExitModal = true
      } else {
        this.navToCreateNewRewardAlgo()
      }
    },
    closeExitModal (): void {
      this.showExitModal = false
    },
    async createOrUpdate ({ active, showSuccess }: { active: boolean; showSuccess: boolean }): Promise<{ error: string | void }> {
      if (this.selectedEmailDesign.type === TEMPLATE) {
        return this.ADD_ERROR(new Error(this.copy.errors.saveAsTemplate))
      }

      const resp = await this.CREATE_OR_UPDATE_CAMPAIGN({
        active,
        showSuccess,
        id: this.$route.params.campaignId,
      })
      return resp
    },
    checkAndDisplayErrors (): Array<{ message: string }> {
      const errors = []
      if (!this.rewardAlgoIsValid) {
        errors.push({ message: this.copy.errors.invalidRewardAlgo })
      }
      if (!this.ctaLinkIsValid) {
        errors.push({ message: this.copy.errors.invalidCtaLink })
      }
      if (!this.segmentIdsAreValid) {
        errors.push({ message: this.copy.errors.invalidSegments })
      }
      if (!this.scheduleIsValid) {
        errors.push({ message: this.copy.errors.missingScheduleDatetime })
      }
      if (!this.typeIsValid) {
        errors.push({ message: this.copy.errors.invalidType })
      }
      if (!this.triggerIsValid) {
        errors.push({ message: this.copy.errors.invalidTrigger })
      }
      if (errors.length > 0) {
        errors.forEach((error) => this.ADD_ERROR(error))
      }
      return errors
    },
    checkAndDisplayErrorsOnSave (): Array<{ message: string }> {
      const errors = []
      if (!this.hasEmailDesignId) {
        errors.push({ message: this.copy.errors.noDesignSelected })
      }
      if (!this.typeIsValid) {
        errors.push({ message: this.copy.errors.invalidType })
      }
      if (!this.triggerIsValid) {
        errors.push({ message: this.copy.errors.invalidTrigger })
      }
      if (errors.length > 0) {
        errors.forEach((error) => this.ADD_ERROR(error))
      }
      return errors
    },
    async save ({ active, showSuccess }: { active: boolean; showSuccess: boolean }): Promise<{ error: string | void }> {
      const errors = this.checkAndDisplayErrorsOnSave()
      if (errors.length > 0) {
        return
      }
      const resp = await this.createOrUpdate({ active, showSuccess })

      if (!this.editing && !resp.error) {
        const campaignId = resp.data.data.id
        this.navToEdit(campaignId)
      }

      if (!resp.error) {
        await this.setup()
      }
      return resp
    },
    async deactivate (): Promise<void> {
      const resp = await this.TOGGLE_CAMPAIGN_ACTIVE({
        id: this.campaign.id,
        active: false,
        detail: true
      })
      if (!resp.error) {
        this.ADD_SUCCESS({ message: 'Your campaign has been deactivated.' })
      }
      this.setOriginalCampaign()
      this.closeDeactivateModal()
    },
    renderDeactivateModal (): void {
      this.showDeactivateModal = true
    },
    closeDeactivateModal (): void {
      this.showDeactivateModal = false
    },
    reviewAndSend (): void {
      const errors = this.checkAndDisplayErrors()
      if (errors.length > 0) {
        return
      }
      this.showReviewModal = true
    },
    async saveAndLaunch (): Promise<void> {
      const resp = await this.save({ active: true, showSuccess: false })

      if (!resp.error) {
        this.ADD_SUCCESS({ message: this.successMessage })
      }
      return resp
    },
    async handleSaveAndLaunch (): Promise<void> {
      const resp = await this.saveAndLaunch()
      this.closeReviewModal()
      if (!resp.error) {
        this.navToCampaignsTable()
      }
    },
    getScheduleDatetime (campaign: Campaign): string {
      if (this.IS_EVENT_BASED) {
        return ''
      }
      if (!campaign.dag.param) return ''
      return moment(campaign.dag.param).format('ddd DD MMM YYYY, LT') + ' UTC'
    },
    showSentDateTime (): string {
      return moment(this.sentDateTime).format('ddd DD MMM YYYY, LT') + ' UTC'
    },
    getHash: CampaignUtils.getHash,
    closeReviewModal (): void {
      this.showReviewModal = false
    },
    fillHash (): void {
      if (!this.$route.hash) {
        const hash = this.getHash(this.campaign)
        this.$router.replace({
          ...this.$route,
          hash,
        })
      }
    },
    /**
     * @note there is a computed property that returns the same boolean value
     * that can be used for instances where we want provide dynamic behavior/logic.
     */
    getIsScheduledToSendNow (): boolean {
      return this.isScheduledToSendNow
    },
  },
  async mounted () {
    await this.setup()
    this.fillHash()
    this.show = true
  },
  async beforeRouteUpdate (to, from, next) {
    if (to.params.campaignId !== from.params.campaignId) {
      await this.setup()
      this.show = true
    }
    next()
  },
}
