<template>
  <button
    class="laas-stepper-button"
    :class="[
      inverse ? 'laas-stepper-button-inverse' : 'laas-stepper-button-default',
      outline ? 'laas-stepper-button-outline' : null
    ]"
    :style="{ width: `${width}px` }"
    @click="$emit('clickEvent')"
    :disabled="disabled"
  >
    <span v-html="copy"></span>
  </button>
</template>

<script>
export default {
  name: 'StepperButton',
  props: {
    width: {
      type: Number,
      required: false,
      default: () => 140
    },
    disabled: {
      type: Boolean,
      required: true,
      default: true,
    },
    copy: {
      type: String,
      required: false,
      default: 'Next'
    },
    inverse: {
      // will invert the color scheme
      type: Boolean,
      required: false,
      default: false
    },
    outline: {
      // will add outline, ideal w/ inverse
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="sass" scoped>
.laas-stepper-button
  height: 45px
  border-radius: 5px
  border-color: transparent
  font-size: 16px
  font-weight: bold
  font-stretch: normal
  font-style: normal
  line-height: normal
  letter-spacing: normal
  text-align: center
  color: #ffffff
  cursor: pointer

.laas-stepper-button-inverse
  background-color: transparent
  color: black
.laas-stepper-button-inverse:hover
  @apply bg-gray-100

.laas-stepper-button-outline
  @apply border-gray-800
  @apply border-2
  @apply border-solid

.laas-stepper-button-default
  @apply bg-gray-800
.laas-stepper-button-default:hover
  @apply bg-gray-500
.laas-stepper-button-default:disabled
  @apply bg-gray-400
  @apply opacity-50
</style>
