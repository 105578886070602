export const COMPONENT_FORM_EVENT = 'componentFormEvent'
export const COMPONENT_DELETE_EVENT = 'componentDeleteEvent'
export const COMPONENT_TOGGLE_ACTIVE = 'componentToggleActive'

export const eventKeys = {
  PROMPT_EXIT_EVENT: 'sw-editor-prompt-exit-event',
}

export const dockOpts = {
  LEFT: 'left',
  RIGHT: 'right',
}

export const CTX_URL_MAPPING = {
  hp: 'homepageHost',
  be: 'bookingEngineHost',
  cp: 'bookingEngineHost',
}

export const TEMPLATE_TYPES = {
  expanded: 'Expanded',
  minimized: 'Minimized',
  banner: 'Banner',
  bonus: 'Bonus',
  // 'hidden': 'Hidden'
}
