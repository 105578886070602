
















import SvgClose from '@/components/icons/SvgClose.vue'

export default {
  name: 'Tag',
  components: { SvgClose },
  props: {
    styles: {
      type: Object,
      required: false,
      default: () => ({})
    },
    classes: {
      type: Array,
      required: false,
      default: () => ([])
    }
  }
}
