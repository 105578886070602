<template>
  <svg
    id="icon-parking"
    viewBox="0 0 45.95 45.95"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.parking}">

    <title id="title">parking icon</title>
    <desc id="desc">parking icon</desc>

    <g>
      <path d="M22.568,14.98c-1.166,0-1.935,0.112-2.378,0.225v7.48c0.506,0.113,
        1.1,0.15,1.927,0.15c3.045,0,4.924-1.542,4.924-4.134C27.042,16.37,25.424,
        14.98,22.568,14.98z"/>
      <path d="M40.001,0H5.949C2.942,0,0.506,2.437,0.506,5.443v35.065c0,3.006,
        2.437,5.442,5.443,5.442h34.052c3.007,0,5.442-2.437,5.442-5.442V5.443C45.444,
        2.436,43.008,0,40.001,0z M42.09,39.635c0,1.637-1.326,2.961-2.961,
        2.961H6.759c-1.636,0-2.962-1.326-2.962-2.961V6.379c0-1.635,1.326-2.961,
        2.962-2.961h32.369c1.636,0,2.961,1.326,2.961,2.961L42.09,39.635L42.09,39.635z"/>
      <path d="M39.128,5.507H6.759c-0.482,0-0.874,0.39-0.874,0.873v33.256c0,0.48,
        0.391,0.873,0.874,0.873h32.369c0.481,0,0.873-0.392,0.873-0.873V6.379C40.001,
        5.897,39.61,5.507,39.128,5.507z M30.395,24.566c-1.953,1.841-4.857,2.668-8.241,
        2.668c-0.751,0-1.394-0.038-1.964-0.113v9.082h-5.633v-25.02c1.772-0.301,
        4.236-0.527,7.732-0.527c3.534,0,6.046,0.677,7.739,2.031c1.615,1.277,2.703,
        3.383,2.703,5.863C32.731,21.033,31.901,23.139,30.395,24.566z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconParking',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
