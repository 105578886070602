<template>
  <div id="reward-algo-name">
    <div id="name-input-container">
      <div class="text-gray-600">
        <span>{{ copy.body }}</span>
      </div>
      <Textfield
        v-model="ramName"
        required="true"
        :placeholder="copy.placeholder"
      />
    </div>
  </div>
</template>

<script>
import Textfield from '@/components/textfield/Textfield.vue'

export default {
  name: 'RamName',
  components: {
    Textfield
  },
  data () {
    return {
      copy: {
        body: 'Write a clear and brief explanation of the reward algorithm, eg On-property Offer 8, Select 2.',
        placeholder: 'Your Algorithm Name'
      }
    }
  },
  computed: {
    ramName: {
      get () {
        return this.$store.state.ram.algo.identifier
      },
      set (value) {
        this.$store.commit('ram/SET_ALGO_IDENTIFIER', value)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  #name-input-container
    max-width: 550px
</style>
