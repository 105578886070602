import { userTypes, createEnvs } from '@/constants'
import { sortByEmail } from '@/utils/misc'
import { UserParser } from '@/parsers'

const CREATE_ENV = createEnvs[process.env.VUE_APP_MODE]

export const getters = {
  usersList (state) {
    return Object.values(state.usersObject).sort(sortByEmail)
  },
  hotelAccessDropdown (state, getters, rootState, rootGetters) {
    const newHotel = [{
      id: 0,
      name: 'Create a new hotel',
      hotel_code: 'newHotel',
      hotel_group_id: null
    }]
    return newHotel.concat(rootGetters['hotel/hotelsList'])
  },
  hotelCognitoMetadata (state, getters, rootState) {
    return {
      0: {
        id: 0,
        name: 'Account Pending',
        hotelCode: 'newHotel',
        hotelGroupId: null
      },
      ...rootState.hotel.cradleHotelsObj
    }
  },
  getHotelById (state, getters) {
    return (id) => getters.hotelCognitoMetadata[id]
  },
  getHotelNames (state, getters) {
    return (user) => {
      return user.hotelIds.map(id => {
        return getters.getHotelById(id) && getters.getHotelById(id).name
      })
    }
  },
  getHotelPayloadById (state, getters) {
    return (id) => {
      const hotel = getters.getHotelById(id)
      return {
        hotel_code: hotel.hotelCode,
        name: hotel.name,
        id: hotel.id
      }
    }
  },
  /**
    * NOTE username is forced to lowercase on creation
    * creates the payload required by lambda fn 'DASH_ADD_USER'
      email: String
      type: String
      hotelPropertyIds: Array
      groupPropertyIds: Array<number>
      merchantIds: Array
    */
  getCreateUserPayload (_, getters) {
    return (newUser) => {
      let hotelsPayload
      let groupsPayload = []
      let hssEditorAccess = newUser.hssEditorAccess
      let reportingAccess = newUser.reportingAccess
      let oprmAccess = newUser.oprmAccess
      let edmAccess = newUser.edmAccess
      let rewardAlgoAccess = newUser.rewardAlgoAccess
      let flexAccess = newUser.flexAccess

      if (newUser.type === userTypes.HOTEL) {
        hotelsPayload = []
        newUser.hotelPropertyIds.forEach(id => {
          const hotelPayload = getters.getHotelPayloadById(id)
          hotelsPayload.push(hotelPayload)
        })
      } else if (newUser.type === userTypes.GROUP) {
        hotelsPayload = null
        const groups = getters.getGroupsPayload(newUser.groupPropertyIds)
        groupsPayload = groups
      } else if (newUser.type === userTypes.ADMIN) {
        hotelsPayload = null
        hssEditorAccess = true // redundant
        reportingAccess = true
        oprmAccess = true
        edmAccess = true
        rewardAlgoAccess = true
        flexAccess = true
      } else if (newUser.type === userTypes.MERCHANT) {
        // merchant type breaks from old pattern and just includes
        // merchantIds in user creation
        hotelsPayload = null
      }

      const email = newUser.email.toLowerCase()

      const payload = UserParser.toRepresentation({
        username: email,
        attributes: {
          hssEditorAccess,
          reportingAccess,
          oprmAccess,
          edmAccess,
          rewardAlgoAccess,
          flexAccess,
          customProfile: {
            email,
            createEnv: CREATE_ENV,
            type: newUser.type,
            hotels: hotelsPayload,
            merchantIds: newUser.merchantIds
          },
          groups: groupsPayload
        }
      })

      return payload
    }
  },
  getHotelsById (state, getters) {
    return (hotelIds) => {
      return hotelIds.map(id => getters.getHotelById(id))
    }
  },
  getGroupById (state, getters, rootState) {
    return (id) => {
      if (Object.keys(rootState.hotel.hotelGroupsObj).includes(String(id))) {
        return rootState.hotel.hotelGroupsObj[id]
      } else {
        return { name: 'tmp' }
      }
    }
  },
  getUserByEmail (state) {
    return (email) => {
      return state.usersObject[email]
    }
  },
  getGroupsPayload (_, getters) {
    return (groupIds) => {
      return groupIds.map(id => {
        const group = getters.getGroupById(id)
        return {
          id: group.id,
          name: group.name
        }
      })
    }
  }
}
