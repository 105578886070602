export interface TailwindConfig {
  theme: Theme;
  variants: { [key: string]: Variant[] };
  future: CorePlugins;
  purge: any[];
  plugins: any[];
  experimental: Experimental;
  target: string;
  prefix: string;
  important: boolean;
  separator: string;
  corePlugins: CorePlugins;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CorePlugins {
}

export interface Experimental {
  applyComplexClasses: boolean;
}

export interface Theme {
  fontFamily: FontFamily;
  screens: Screens;
  colors: BackgroundColor;
  spacing: { [key: string]: string };
  backgroundColor: BackgroundColor;
  backgroundImage: BackgroundImage;
  gradientColorStops: BackgroundColor;
  backgroundOpacity: { [key: string]: string };
  backgroundPosition: Position;
  backgroundSize: BackgroundSize;
  borderColor: BackgroundColor;
  borderOpacity: { [key: string]: string };
  borderRadius: BorderRadius;
  borderWidth: Width;
  boxShadow: BoxShadow;
  container: CorePlugins;
  cursor: Cursor;
  divideColor: BackgroundColor;
  divideOpacity: { [key: string]: string };
  divideWidth: Width;
  fill: Fill;
  flex: Flex;
  flexGrow: FlexGrowClass;
  flexShrink: FlexGrowClass;
  fontSize: { [key: string]: string };
  fontWeight: FontWeight;
  height: { [key: string]: string };
  inset: Inset;
  letterSpacing: LetterSpacing;
  lineHeight: LineHeight;
  listStyleType: ListStyleType;
  margin: { [key: string]: string };
  maxHeight: MaxHeight;
  maxWidth: { [key: string]: string };
  minHeight: MinHeight;
  minWidth: MinWidth;
  objectPosition: Position;
  opacity: { [key: string]: string };
  order: GridColumnEnd;
  padding: { [key: string]: string };
  placeholderColor: BackgroundColor;
  placeholderOpacity: { [key: string]: string };
  space: { [key: string]: string };
  stroke: Fill;
  strokeWidth: { [key: string]: string };
  textColor: BackgroundColor;
  textOpacity: { [key: string]: string };
  width: { [key: string]: string };
  zIndex: ZIndex;
  gap: { [key: string]: string };
  gridTemplateColumns: GridColumnEnd;
  gridColumn: { [key: string]: string };
  gridColumnStart: GridColumnEnd;
  gridColumnEnd: GridColumnEnd;
  gridTemplateRows: Grid;
  gridRow: GridRow;
  gridRowStart: Grid;
  gridRowEnd: Grid;
  transformOrigin: TransformOrigin;
  scale: { [key: string]: string };
  rotate: Rotate;
  translate: { [key: string]: string };
  skew: Skew;
  transitionProperty: TransitionProperty;
  transitionTimingFunction: TransitionTimingFunction;
  transitionDuration: { [key: string]: string };
  transitionDelay: { [key: string]: string };
  animation: Animation;
  keyframes: Keyframes;
}

export interface Animation {
  none: string;
  spin: string;
  ping: string;
  pulse: string;
  bounce: string;
}

export interface BackgroundColor {
  transparent: string;
  current: string;
  black: string;
  white: string;
  gray: { [key: string]: string };
  red: { [key: string]: string };
  orange: { [key: string]: string };
  yellow: { [key: string]: string };
  green: { [key: string]: string };
  teal: { [key: string]: string };
  blue: { [key: string]: string };
  indigo: { [key: string]: string };
  purple: { [key: string]: string };
  pink: { [key: string]: string };
  brand: string;
  base: string;
  brown: { [key: string]: Brown };
  default?: string;
}

export enum Brown {
  A78764 = '#A78764',
  C2A483 = '#c2a483',
}

export interface BackgroundImage {
  none: string;
  'gradient-to-t': string;
  'gradient-to-tr': string;
  'gradient-to-r': string;
  'gradient-to-br': string;
  'gradient-to-b': string;
  'gradient-to-bl': string;
  'gradient-to-l': string;
  'gradient-to-tl': string;
}

export interface Position {
  bottom: string;
  center: string;
  left: string;
  'left-bottom': string;
  'left-top': string;
  right: string;
  'right-bottom': string;
  'right-top': string;
  top: string;
}

export interface BackgroundSize {
  auto: string;
  cover: string;
  contain: string;
}

export interface BorderRadius {
  none: string;
  sm: string;
  default: string;
  md: string;
  lg: string;
  full: string;
}

export interface Width {
  '0': string;
  '2': string;
  '4': string;
  '8': string;
  default: string;
}

export interface BoxShadow {
  xs: string;
  sm: string;
  default: string;
  md: string;
  lg: string;
  xl: string;
  '2xl': string;
  inner: string;
  outline: string;
  none: string;
}

export interface Cursor {
  auto: string;
  default: string;
  pointer: string;
  wait: string;
  text: string;
  move: string;
  'not-allowed': string;
}

export interface Fill {
  current: string;
}

export interface Flex {
  '1': string;
  auto: string;
  initial: string;
  none: string;
}

export interface FlexGrowClass {
  '0': string;
  default: string;
}

export interface FontFamily {
  sans: string[];
  body: string[];
}

export interface FontWeight {
  hairline: string;
  thin: string;
  light: string;
  normal: string;
  medium: string;
  semibold: string;
  bold: string;
  extrabold: string;
  black: string;
}

export interface GridColumnEnd {
  '1': string;
  '2': string;
  '3': string;
  '4': string;
  '5': string;
  '6': string;
  '7': string;
  '8': string;
  '9': string;
  '10': string;
  '11': string;
  '12': string;
  '13'?: string;
  auto?: string;
  none?: string;
  first?: string;
  last?: string;
}

export interface GridRow {
  auto: string;
  'span-1': string;
  'span-2': string;
  'span-3': string;
  'span-4': string;
  'span-5': string;
  'span-6': string;
}

export interface Grid {
  '1': string;
  '2': string;
  '3': string;
  '4': string;
  '5': string;
  '6': string;
  '7'?: string;
  auto?: string;
  none?: string;
}

export interface Inset {
  '0': string;
  auto: string;
}

export interface Keyframes {
  spin: Spin;
  ping: Ping;
  pulse: Pulse;
  bounce: { [key: string]: Bounce };
}

export interface Bounce {
  transform: string;
  animationTimingFunction: string;
}

export interface Ping {
  '75%, 100%': The75100;
}

export interface The75100 {
  transform: string;
  opacity: string;
}

export interface Pulse {
  '50%': The50;
}

export interface The50 {
  opacity: string;
}

export interface Spin {
  to: To;
}

export interface To {
  transform: string;
}

export interface LetterSpacing {
  tighter: string;
  tight: string;
  normal: string;
  wide: string;
  wider: string;
  widest: string;
}

export interface LineHeight {
  '3': string;
  '4': string;
  '5': string;
  '6': string;
  '7': string;
  '8': string;
  '9': string;
  '10': string;
  none: string;
  tight: string;
  snug: string;
  normal: string;
  relaxed: string;
  loose: string;
}

export interface ListStyleType {
  none: string;
  disc: string;
  decimal: string;
}

export interface MaxHeight {
  full: string;
  screen: string;
}

export interface MinHeight {
  '0': string;
  full: string;
  screen: string;
}

export interface MinWidth {
  '0': string;
  full: string;
}

export interface Rotate {
  '0': string;
  '45': string;
  '90': string;
  '180': string;
  '-180': string;
  '-90': string;
  '-45': string;
}

export interface Screens {
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

export interface Skew {
  '0': string;
  '3': string;
  '6': string;
  '12': string;
  '-12': string;
  '-6': string;
  '-3': string;
}

export interface TransformOrigin {
  center: string;
  top: string;
  'top-right': string;
  right: string;
  'bottom-right': string;
  bottom: string;
  'bottom-left': string;
  left: string;
  'top-left': string;
}

export interface TransitionProperty {
  none: string;
  all: string;
  default: string;
  colors: string;
  opacity: string;
  shadow: string;
  transform: string;
}

export interface TransitionTimingFunction {
  linear: string;
  in: string;
  out: string;
  'in-out': string;
}

export interface ZIndex {
  '0': string;
  '10': string;
  '20': string;
  '30': string;
  '40': string;
  '50': string;
  auto: string;
}

export enum Variant {
  Focus = 'focus',
  Hover = 'hover',
  Responsive = 'responsive',
}
