<template lang="html">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    id="icon-wellness-1"
    data-name="Layer 1"
    viewBox="0 0 35 35"
    role="img"
  >
  <title>{{ this.name }}</title>
  <desc id="desc">{{ this.name }}</desc>
  <path
    d="M4.7,8.86l.1.36c.08.27,2,6.77,5.83,8.33a4.4,4.4,0,0,0,1.67.33A5.27,5.27,0,0,0,15,17.1a.36.36,0,0,0,.14-.5.37.37,0,0,0-.51-.14,4.06,4.06,0,0,1-3.67.4c-3-1.23-4.83-6.13-5.28-7.5,3.76-1.07,6.52-.88,8.18.56a6.32,6.32,0,0,1,1.79,4.86,15.36,15.36,0,0,0-5.45-3.42.37.37,0,0,0-.48.21.37.37,0,0,0,.21.48c3.29,1.29,5.64,3.18,6.8,5.45a6.58,6.58,0,0,1,.79,2.92h0v6.26a.36.36,0,0,0,.37.37.37.37,0,0,0,.37-.37V20.42a4.63,4.63,0,0,1,1.71-3.18h0a13.83,13.83,0,0,1,5.41-2.82.37.37,0,0,0,.25-.46.38.38,0,0,0-.47-.25A15.7,15.7,0,0,0,20,16.2a4,4,0,0,1,1.15-2.95c1.45-1.4,4.28-1.69,8.19-.83-.48,1.13-2,4.39-4.59,5.48a4.59,4.59,0,0,1-3.83-.12.37.37,0,0,0-.49.19.38.38,0,0,0,.19.49A5.94,5.94,0,0,0,23,19a5,5,0,0,0,2-.43c3.38-1.45,5.07-6.11,5.14-6.3l.14-.4-.4-.09c-4.45-1-7.58-.73-9.3.92a5,5,0,0,0-1.34,4.11,6.76,6.76,0,0,0-1.33,1.8,8,8,0,0,0-.56-1.42,9.07,9.07,0,0,0-1.07-1.64c.08-.79.31-4.2-2-6.2-1.92-1.67-5-1.87-9.25-.6Z"
  />
  </svg>
</template>

<script>
export default {
  name: 'IconWellness1',
  data () {
    return {
      name: 'icon wellness 1'
    }
  }
}
</script>

<style lang="css">
</style>
