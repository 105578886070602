import Vue from 'vue'

export const mutations = {
  SET_PROMOTIONS_OBJECT (state, promotions) {
    state.promotionsObj = promotions
  },
  SET_MERCHANTS_OBJECT (state, merchants) {
    state.merchantsObj = merchants
  },
  SET_MERCHANT_ID (state, id) {
    console.log('[SET_MERCHANT_ID]', id)
    state.merchantId = id
  },
  SET_PROMOTION (state, promotion) {
    Vue.set(state.promotionsObj, promotion.uuid, promotion)
  },
  SET_REGIONS_LIST (state, regions) {
    if (state.regionsList.length > 0 && regions.length === 0) {
      return
    }
    console.log('SET_REGIONS_LIST', regions)
    state.regionsList = regions
  },
  SET_BOOKING_ENGINES_OBJ (state, bookingEngines) {
    state.bookingEnginesObj = bookingEngines
  },
  SET_PROMOTIONS_FETCH_SUCCESS (state, bool) {
    state.promotionsFetchSuccess = bool
  },
}
