









































































import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Table from '@/components/table/Table.vue'
import RadioButton from '@/components/radio-button/RadioButton.vue'
import { NodeTemplate } from '@/types'
import { MODULE_STRINGS } from '@/constants'

const { RAM, ECM } = MODULE_STRINGS

/**
 * component uses props over provide/inject because its being
 * passed as slot to Modal and is not considered a direct descendant
 * of WorkflowManagerContainer
 */

export default {
  name: 'AlgoTableEmbed',
  components: {
    Table,
    RadioButton,
  },
  data () {
    return {
      columns: [
        {
          key: 'id',
          name: '',
          sortable: false,
          alignment: 'center',
          width: '30',
        },
        {
          key: 'identifier',
          name: 'Name',
          sortable: true,
          alignment: 'flex-start',
          width: '130',
        },
        {
          key: 'onPropertyRewards',
          name: 'On Property',
          sortable: true,
          alignment: 'flex-start',
          width: '60',
        },
        {
          key: 'marketPlaceRewards',
          name: 'Marketplace',
          sortable: true,
          alignment: 'flex-start',
          width: '60',
        },
        {
          key: 'maxRewardSelections',
          name: 'Rewards Selectable',
          sortable: true,
          alignment: 'flex-start',
          width: '90',
        },
      ],
      pgSize: 3,
      copy: {
        emptyState: 'No entries found'
      }
    }
  },
  computed: {
    ...mapGetters(RAM, [
      'ALGOS_LIST',
    ]),
    ...mapState(RAM, [
      'algosFetchSuccess',
      'pageInfo',
    ]),
    ...mapState(ECM, [
      'campaign',
      'activeNode',
    ]),
    isChecked (): (id: string) => boolean {
      return (id) => (this.activeNode as NodeTemplate).rewardAlgoId === id
    },
  },
  mounted (): void {
    this.fetchPaginatedAlgos()
  },
  methods: {
    ...mapMutations(ECM, [
      'SET_ACTIVE_NODE_ALGO_ID',
    ]),
    ...mapMutations(RAM, [
      'CLEAR_ALGOS',
      'SET_PAGE',
      'SET_PAGE_SIZE'
    ]),
    ...mapActions(RAM, [
      'FETCH_ALGOS'
    ]),
    async fetchPaginatedAlgos (): Promise<void> {
      const { page, pageSize } = this.pageInfo
      await this.FETCH_ALGOS({ page, pageSize })
    },
    updatePage (pageNumber: number) {
      this.SET_PAGE(pageNumber)
      this.fetchPaginatedAlgos()
    },
    updatePageSize (pageSize: number) {
      this.SET_PAGE_SIZE(pageSize)
      this.fetchPaginatedAlgos()
    },
  },
}
