










































































import Vue from 'vue'
import { steps } from '@/constants'
import { CustomStepper } from '@/types'
import { mapGetters } from 'vuex'
/**
 * this component will render an md-stepper if creating new reward
 * or a div that looks like an expanded stepper if editing an existing reward.
 */
const SCROLL_TOP_EVENT = 'scrollTop'

export default Vue.extend({
  name: 'CustomStepper',
  inject: ['Dashboard_scrollTop'],
  props: {
    editing: {
      required: true,
      type: Boolean,
    },
    stepHeaders: {
      required: true,
      type: Array as () => CustomStepper.StepHeaders,
      default: () => ([])
    },
  },
  provide () {
    return {
      CustomStepper_nextStep: this.nextStep,
      CustomStepper_setStepDone: this.setStepDone,
      CustomStepper_navConfig: this.navConfig,
      CustomStepper_previousStep: this.previousStep
    }
  },
  data () {
    return {
      steps,
      stepState: {}, // this is filled by mount function after determining number of step slots exist
      activeStep: 'step1',
      copy: {
        onlyRetainUser: 'Only accessible by Retain users',
        retainFeature: '(Retain Feature)'
      }
    }
  },
  computed: {
    ...mapGetters(['HOTEL_GROUP_IS_RETAIN', 'IS_ADMIN']),
    dynamicStep: {
      get (): Record<string, string> {
        return this.stepState
      },
      set (stepKey: string): void {
        this.stepState[stepKey] = true
      }
    },
    stepsList (): string[] {
      return Object.values(this.steps as Record<string, string>).slice(0, this.stepHeaders.length)
    },
    isActive () {
      return (idx) => {
        return this.getStepStr(idx) === this.activeStep
      }
    }
  },
  mounted () {
    this.setUpStepState()
  },
  updated () {
    // making sure retain user only stepper buttons are disabled
    const retainStepper = (document.querySelector('.md-stepper-retain-disabled button') as HTMLButtonElement)
    if (retainStepper) {
      retainStepper.disabled = true
    }
  },
  methods: {
    navConfig (idx: number): Record<'previous' | 'next', string> {
      const previous = this.stepsList[idx - 1]
      const next = this.stepsList[idx + 1]
      return {
        previous,
        next
      }
    },
    setActiveStep (step: string): void {
      this.activeStep = step
      this.Dashboard_scrollTop()
    },
    setStepDone (idx: number): void {
      const step = this.stepsList[idx]
      this.stepState[step] = true
    },
    nextStep (): void {
      const activeIdx = this.stepsList.indexOf(this.activeStep)
      const { next } = this.navConfig(activeIdx)
      if (next) {
        const step = this.handleNextStep(activeIdx + 1)
        this.setActiveStep(step)
      } else {
        this.handleDone()
      }
    },
    handleNextStep (nextIdx): string {
      // if user != retainUser && next step is for retainUser
      // this skips next steps until non retain user stepper is found
      const nextStep = this.stepHeaders[nextIdx]
      if (nextStep.isRetain && !this.HOTEL_GROUP_IS_RETAIN) {
        return this.handleNextStep(nextIdx + 1)
      } else {
        return this.getStepStr(nextIdx)
      }
    },
    previousStep (): void {
      const activeIdx = this.stepsList.indexOf(this.activeStep)
      const step = this.handlePreviousStep(activeIdx - 1)
      this.setActiveStep(step)
    },
    handlePreviousStep (prevIdx): string {
      const prevStep = this.stepHeaders[prevIdx]
      if (prevStep.isRetain && !this.HOTEL_GROUP_IS_RETAIN) {
        return this.handlePreviousStep(prevIdx - 1)
      } else {
        return this.getStepStr(prevIdx)
      }
    },
    isDisabled (stepHeader: CustomStepper.StepHeader): boolean {
      if (stepHeader.isRetain) {
        return !this.HOTEL_GROUP_IS_RETAIN
      } else {
        return false
      }
    },
    handleDone (): void {
      // needs to be wrapped in function outside of 'nextStep'
      // for event to be emitted from this component
      // if the function is invoked by child w/ dep injection
      this.$emit('done')
    },
    setUpStepState (): void {
      Object.keys(this.$slots).forEach((slotKey) => {
        this.stepState[slotKey] = false
      })
    },
    getStepStr (idx: number): string {
      return `step${idx + 1}`
    },
    scrollTop (): void {
      this.$emit(SCROLL_TOP_EVENT)
    },
    retainFeatureCopy (stepHeader: CustomStepper.StepHeader): string {
      return this.IS_ADMIN && stepHeader.isRetain ? this.copy.retainFeature : ''
    }
  },
})
