

















































































































import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import Table from '@/components/table/Table.vue'
import RadioButton from '@/components/radio-button/RadioButton.vue'
import { algoRewardOverrideOpts, userViews } from '@/constants'
import { TableColumn, Reward, RewardType } from '@/types'
import { ON_PROPERTY_REWARDS_LIST, MARKETPLACE_REWARDS_LIST } from '@/store/modules/ram/index.js'

export type RewardRequestConfig = {
  onProperty: boolean;
  marketPlace: boolean;
}
/**
 * @note the traditional use of v-model on RadioButton is not implemented
 * in this component due to the requires of the logic for always offering
 * and never offering specific rewards for the reward algo.
 */

export default {
  name: 'OverrideRewardsContainer',
  components: {
    Table,
    RadioButton,
  },
  props: {
    rewardType: {
      type: String as () => RewardType,
      required: true
    },
    excludeCampaignRewards: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  data () {
    return {
      algoRewardOverrideOpts,
      show: false,
      labels: {
        name: 'Reward Name',
        exclude: 'Never Offer',
        noChange: 'Laasie AI',
        whitelist: 'Always Offer',
        emptyState: 'No rewards yet'
      },
      copy: {
        whitelistDisabled: 'Please ask your CSM to use this option',
        emptyState: `
        <span>
          You don't have any on-property rewards set up yet.
        </span>
        <br/>
        <span>
          Visit the <span style="text-decoration:underline;cursor:pointer">Reward Manager</span> to make one.
        </span>
      `,
      }
    }
  },
  computed: {
    ...mapGetters([
      'IS_ADMIN'
    ]),
    ...mapState('ram', [
      'rewardsPageInfo',
      'marketplaceRewardsPageInfo',
      'rewardsFetchSuccess'
    ]),
    ...mapGetters('ram', [
      ON_PROPERTY_REWARDS_LIST,
      MARKETPLACE_REWARDS_LIST,
      'IS_WHITELISTED',
      'IS_EXCLUDED',
    ]),
    isDisabled (): boolean {
      if (this.rewardType === 'marketPlace' as RewardType) {
        return !this.IS_ADMIN
      }
      return false
    },
    /**
     * supports on-property OR marketplace rewards but not both.
     */
    rewards (): Reward[] {
      let getter
      if (this.rewardType === 'onProperty') {
        getter = ON_PROPERTY_REWARDS_LIST
      } else if (this.rewardType === 'marketPlace') {
        getter = MARKETPLACE_REWARDS_LIST
      } else {
        console.log('<OverrideRewardsContainer> Neither reward type is selected for filtering.')
        return []
      }

      if (!getter) {
        console.log(`<OverrideRewardsContainer> Failed to return getter: ${getter}`)
        return []
      }

      return this[getter]
    },
    columns (): TableColumn[] {
      return [
        // key is irrelevant, using custom slot
        { key: 'reward-name', name: 'Reward Name', sortable: false, alignment: 'flex-start' },
        { key: 'never-offer', name: 'Never Offer', sortable: false, alignment: 'center', width: 90 },
        { key: 'laasie-ai', name: 'Laasie AI', sortable: false, alignment: 'center', width: 90 },
        { key: 'always-offer', name: 'Always Offer', sortable: false, alignment: 'center', width: 90 },
      ]
    },
    paginationInfo (): { pageSize: number; total: number | void } {
      const opts = {
        onProperty: 'rewardsPageInfo',
        marketPlace: 'marketplaceRewardsPageInfo'
      } as Record<RewardType, string>
      const key = opts[this.rewardType]
      if (!key) {
        return {
          pageSize: 0,
          total: 0
        }
      }
      return this[key]
    }
  },
  mounted (): void {
    this.fetchRewards()
  },
  methods: {
    ...mapMutations('ram', [
      'SET_REWARDS_PAGE',
      'SET_REWARDS_PAGE_SIZE',
      'SET_MARKETPLACE_REWARDS_PAGE',
      'SET_MARKETPLACE_REWARDS_PAGE_SIZE',
    ]),
    ...mapActions('ram', [
      'UPDATE_REWARD_OVERRIDE',
      'FETCH_ALGO_REWARDS',
      'FETCH_MARKETPLACE_ALGO_REWARDS',
    ]),
    updateStatus (rewardId, status) {
      this.UPDATE_REWARD_OVERRIDE({ rewardId, status })
    },
    rewardName (reward) {
      const rcc = this.getCreativeConfig(reward)
      if (!rcc.promotedText) return ''
      return this.truncateRewardName(rcc.promotedText)
    },
    truncateRewardName (promotedText) {
      if (promotedText.length < 51) return promotedText
      return `${promotedText.substr(0, 51)} ...`
    },
    getCreativeConfig (reward) {
      if (reward.creativeConfigs && reward.creativeConfigs.length > 0) {
        return reward.creativeConfigs[0]
      }
      return {
        promotedText: undefined,
        rewardThumbnail: undefined
      }
    },
    rewardThumbnail (reward) {
      const rcc = this.getCreativeConfig(reward)
      return rcc.thumbnailImage
    },
    updatePage ({ page }: { page: number }): void {
      const opts = {
        marketPlace: 'SET_MARKETPLACE_REWARDS_PAGE',
        onProperty: 'SET_REWARDS_PAGE'
      } as Record<RewardType, string>

      const commit = opts[this.rewardType]
      if (commit) {
        this[commit](page)
        this.fetchRewards()
      }
    },
    updatePageSize (pageSize: number): void {
      const opts = {
        marketPlace: 'SET_MARKETPLACE_REWARDS_PAGE_SIZE',
        onProperty: 'SET_REWARDS_PAGE_SIZE'
      } as Record<RewardType, string>

      const commit = opts[this.rewardType]
      if (commit) {
        this[commit](pageSize)
        this.fetchRewards()
      }
    },
    navToOprmWizardNew () {
      this.$router.push({
        name: userViews.OP_REWARDS_WIZARD_NEW,
        params: { ...this.$route.params }
      })
    },
    async fetchRewards (): Promise<void> {
      const opts = {
        onProperty: 'FETCH_ALGO_REWARDS',
        marketPlace: 'FETCH_MARKETPLACE_ALGO_REWARDS'
      } as Record<RewardType, string>
      const action = opts[this.rewardType]
      if (!action) return
      this[action]({ excludeCampaignRewards: this.excludeCampaignRewards })
    },
  },

}
