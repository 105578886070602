<template>
  <div id="oprm-wizard-image-display-step">
    <WizardStep :editing="editing">
      <CustomStepHeader
        slot="step-header"
        :index="index"
        :label="'Image Display'"
      />
      <ImageDisplay slot="left-content" />
      <ImageDisplayPreview slot="right-content" />
      <CustomStepButtons
        slot="button-group"
        :disabled="IS_IMAGE_DISPLAY_DISABLED"
        :index="index"
      />
    </WizardStep>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WizardStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/WizardStep'
import ImageDisplay from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/ImageDisplay'
import ImageDisplayPreview from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/ImageDisplayPreview'
import CustomStepHeader from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepHeader'
import CustomStepButtons from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepButtons'

export default {
  name: 'ImageDisplayStep',
  components: {
    WizardStep,
    CustomStepHeader,
    ImageDisplay,
    ImageDisplayPreview,
    CustomStepButtons,
  },
  props: {
    editing: {
      required: true,
      type: Boolean,
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('oprm', [
      'IS_IMAGE_DISPLAY_DISABLED'
    ]),
  }
}
</script>
