<template lang="html">
  <div id="merchant-dashboard">
    <Dashboard
      v-if="ready"
      :navConfig="navConfig"
      :dropdownComponent="dropdownComponent"
    />
  </div>
</template>

<script>
import Dashboard from '@/components/dashboard/Dashboard'
import MerchantsDropdown from '@/components/dropdown/MerchantsDropdown'
import { merchantNavConfig } from '@/config/nav/merchantNavConfig'

export default {
  name: 'MerchantDashboard',
  components: {
    Dashboard,
  },
  data () {
    return {
      ready: false,
      navConfig: merchantNavConfig,
    }
  },
  computed: {
    dropdownComponent () {
      return MerchantsDropdown
    },
  },
  methods: {
    fillIdParam () {
      this.$router.push({
        name: this.$route.name,
        params: {
          id: this.$store.state.merchant.merchantId
        }
      })
    },
  },
  async created () {
    await this.$store.dispatch('merchant/FETCH_MERCHANTS')
    // initialView does not know which merchant id to nav to
    if (!this.$route.params.id && this.$store.state.merchant.merchantId) {
      this.fillIdParam()
    }
    this.ready = true
  },
  beforeRouteUpdate (to, from, next) {
    // created hook will trigger this hook before it sets :id
    if (to.params.id) {
      next()
    }
    if (!this.$route.params.id && this.$store.state.merchant.merchantId) {
      this.fillIdParam()
    }
    // for back button where MerchantPicker is out of sync
    if (to.params.id !== this.$store.state.merchant.merchantId) {
      this.$store.dispatch('merchant/SET_MERCHANT_ID', { id: to.params.id })
    }
    next()
  }
}
</script>

<style lang="sass" scoped>
</style>
