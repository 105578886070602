<template>
  <div id="app">
    <div v-if="loading" class="overlay">
      <fade-loader id="loader" :loading="true" :color="spinner.color"></fade-loader>
      <div id="overlay-white" class="overlay"></div>
    </div>
    <router-view/>
  </div>
</template>

<script>
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'

export default {
  name: 'app',
  components: {
    FadeLoader,
  },
  data: () => ({
    spinner: {
      color: '#000000'
    }
  }),
  computed: {
    loading () {
      return this.$store.state.isLoading
    },
  },
  mounted () {
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.$store.dispatch('MANAGE_TOKEN')
      }
    })
  }
}
</script>

<style lang='scss'>
  @import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
  @import '~@vue-material-styles';
  @import "~vue-material/dist/theme/engine"; // Import the theme engine
  @include md-register-theme("default", (
    primary: #000000, // The primary color of your brand
    accent: #c2a483, // The secondary color of your brand
  ));
  @import "~vue-material/dist/theme/all"; // Apply the theme

  #app {
    background-color: transparent;
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Lato', 'sans-serif';
    color: black;
  }

  iframe {
    overflow: hidden;
  }

  #looker {
    width: 100%;
    border: 0;
    padding: 10px 5px;
  }

  #overlay-white {
    background: white;
  }

  .overlay {
    background: transparent;
    opacity: .95;
    width: 100%;
    height: 100vh;
    z-index: 8;
    top: 0;
    left: 0;
    position: fixed;
  }

  #loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 11;
  }

  .laas-italic {
    font-style: italic
  }
  .laas-bold {
    font-weight: bold
  }
  // FIXME: rename this, used outside of oprm
  .laas-oprm-body-text-color {
    color: #4e4e4e;
  }

  .laas-caption {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    padding: 4px 0px
  }

  .laas-error {
    color: red
  }

  .laas-section-title {
    padding-bottom: 8px;
    @media only screen and (max-width: 970px) {
      max-width: 550px;
      text-align: justify;
    }
  }

  .laas-disabled {
    opacity: .4;
  }

  // keeps snackbar on top of side drawer and <Modal>
  .md-snackbar {
    z-index: 99999 !important
  }
</style>

<style lang="sass">
@import 'components/navigation/menu-styles'

.md-dialog-overlay
  z-index: 99998 !important
.md-dialog, .md-dialog-container
  z-index: 99999 !important

.laas-select-field
  margin-right: 20px
  background-color: #d8d8d8
  border-radius: 3px
  height: 50px
  .md-menu
    input
      height: 100%
      padding-left: 10px
.help-text
  margin-bottom: 20px

.lato-font
  font-family: Lato, Helvetica, sans-serif !important

.error-modal-item
  display: flex
  font-size: 12px
  flex-direction: row
  height: auto
  max-height: 400px
  margin-top: 10px
  padding: 10px
  height: auto
  background-color: #FF7575
  width: auto
  border-radius: 2px
  div
    padding-left: 20px
    padding-right: 20px
.error-modal-item:hover
  cursor: default

.laas-action-icon
  cursor: pointer

// send test email box
.test-send-email-wrapper
  margin: 20px 0
  border: solid 1px #d9d9d9
  padding: 20px 25px

.laas-oprm-helper-text
  @apply text-gray-300
  font-size: 12px
  line-height: normal

.laas-required
  @apply text-gray-300
  padding-left: 6px !important
  font-size: 12px
  font-weight: 400
  letter-spacing: 0

.laas-back-icon
  @apply bg-gray-100 rounded-md
  height: 36px
  width: 36px
  margin-right: 25px
  .md-icon.md-theme-default.md-icon-font
    color: black
    height: 36px
    width: 36px
  &:hover
    cursor: pointer
    background-color: rgba(34, 34, 80, 0.2)
</style>
