<template>
  <div
    id="email-design-table"
    class="mb-12"
  >
    <div
      v-if="!emailDesignsFetchSuccess"
      class="progress-spinner"
    >
      <md-progress-spinner
        class="md-accent"
        md-mode="indeterminate"
      />
    </div>
    <md-table
      v-if="emailDesignsFetchSuccess"
      ref="email-design-table"
      v-model="SORTED_EMAIL_DESIGNS_LIST"
    >
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
      >
        <md-table-cell :md-label="labels.action">
          <span
            class="laas-action-icon"
            @click="navToEmailDesignWizardEdit(item)"
          >
            <md-icon>{{ getIcon(item) }}</md-icon>
          </span>
        </md-table-cell>

        <md-table-cell :md-label="labels.name">
          <span :style="{ textTransform: 'capitalize' }">
            {{ item.name }}
          </span>
        </md-table-cell>

        <md-table-cell :md-label="labels.type">
          <span>{{ getType(item.type) }}</span>
        </md-table-cell>

        <md-table-cell :md-label="labels.updated">
          <span>
            {{ getUpdatedOn(item.modified) }}
          </span>
        </md-table-cell>

        <md-table-cell :md-label="labels.access">
          <span>{{ getAccess(item) }}</span>
        </md-table-cell>
        <md-table-cell :md-label="null">
          <md-menu
            id="operations-menu"
            :md-close-on-click="true"
            :md-close-on-select="true"
            md-size="small"
            md-direction="bottom-start"
            :md-offset-x="-90"
            :md-offset-y="2"
          >
            <md-button
              md-menu-trigger
              class="md-icon-button"
            >
              <md-icon>{{ icons.operations }}</md-icon>
            </md-button>
            <md-menu-content>
              <md-menu-item
                class="lato-font operations-menu-item"
                @click="saveAsNew(item.id)"
              >
                <span class="operations-menu-item-text">
                  {{ copy.operations.save }}
                </span>
              </md-menu-item>
              <md-menu-item
                class="lato-font operations-menu-item"
                @click="navToEmailDesignWizardTestTab(item)"
              >
                <span class="operations-menu-item-text">
                  {{ copy.operations.test }}
                </span>
              </md-menu-item>
            </md-menu-content>
          </md-menu>
        </md-table-cell>
      </md-table-row>

      <div class="empty-list-wrapper">
        <md-table-empty-state :md-label="copy.empty">
          <StepperButton
            :style="{ width: '236px' }"
            :disabled="false"
            :copy="copy.emptyStateButton"
            @clickEvent="navToEmailDesignWizardNew"
          />
        </md-table-empty-state>
      </div>

      <md-table-pagination
        slot="md-table-pagination"
        :md-page-size="pageInfo.pageSize"
        :md-page="pageInfo.page"
        :md-total="pageInfo.total"
        @update:mdPage="updatePage"
        @update:mdPageSize="updatePageSize"
      />
    </md-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton.vue'
import Paginator from '@/components/common/Paginator'
import { userViews, edmHashOptions, emailDesignTypes } from '@/constants'
import { TableHeaderUtils } from '@/utils/table-headers'

export default {
  name: 'EmailDesignTable',
  components: {
    'md-table-pagination': Paginator, // override vue material component
    StepperButton,
  },
  data () {
    return {
      copy: {
        empty: 'No email designs.',
        emptyStateButton: 'Create an Email Design',
        access: {
          readOnly: 'Read-only',
          edit: 'Edit'
        },
        operations: {
          save: 'Save As New',
          test: 'Test This Email',
        },
      },
      icons: {
        readOnly: 'remove_red_eye',
        edit: 'edit',
        operations: 'more_vert',
      },
      labels: {
        action: 'Action',
        name: 'Email Design',
        type: 'Design Type',
        updated: 'Updated On',
        access: 'Access'
      }
    }
  },
  computed: {
    ...mapState('edm', [
      'pageInfo',
      'emailDesignsFetchSuccess'
    ]),
    ...mapGetters('edm', [
      'SORTED_EMAIL_DESIGNS_LIST',
      'GET_EMAIL_DESIGN',
    ]),
    ...mapGetters('hotel', [
      'hotelByCradleId'
    ]),
  },
  mounted () {
    this.fetchPaginatedEmailDesigns()
  },
  methods: {
    ...mapMutations('edm', [
      'SET_PAGE',
      'SET_PAGE_SIZE',
      'RESET_EMAIL_DESIGN',
    ]),
    ...mapActions('edm', [
      'FETCH_EMAIL_DESIGNS',
      'CREATE_OR_UPDATE_EMAIL_DESIGN',
      'SET_EMAIL_DESIGN_FOR_COPY'
    ]),
    getUpdatedOn: TableHeaderUtils.getUpdatedOn,
    getName: TableHeaderUtils.getName,
    async saveAsNew (id) {
      await this.SET_EMAIL_DESIGN_FOR_COPY({ id, hotelId: this.$route.params.hotelId })
      const resp = await this.CREATE_OR_UPDATE_EMAIL_DESIGN({ id: null })
      this.RESET_EMAIL_DESIGN()
      if (resp.data) {
        this.$router.push({
          name: userViews.EMAIL_DESIGNS_EDIT,
          params: {
            ...this.$route.params,
            emailDesignId: resp.data.id
          }
        })
      }
    },
    getType: TableHeaderUtils.getType,
    getIcon (emailDesign) {
      return this.getIsReadonly(emailDesign) ? this.icons.readOnly : this.icons.edit
    },
    getAccess (emailDesign) {
      return this.getIsReadonly(emailDesign) ? this.copy.access.readOnly : this.copy.access.edit
    },
    getIsReadonly (emailDesign) {
      const legacyId = this.$route.params.hotelId
      const hotel = this.$store.state.hotel.cradleHotelsObj[legacyId]
      return emailDesign.hotelId !== hotel.cradleId &&
        emailDesign.type === emailDesignTypes.TEMPLATE &&
        emailDesign.readAccessHotelIds.indexOf(hotel.cradleId) > -1
    },
    async fetchPaginatedEmailDesigns () {
      const { page, pageSize } = this.pageInfo
      await this.FETCH_EMAIL_DESIGNS({ page, pageSize })
    },
    updatePage (pageNumber) {
      this.SET_PAGE(pageNumber)
      this.fetchPaginatedEmailDesigns()
    },
    updatePageSize (pageSize) {
      this.SET_PAGE_SIZE(pageSize)
      this.fetchPaginatedEmailDesigns()
    },
    navToEmailDesignWizardNew () {
      this.$router.push({
        name: userViews.EMAIL_DESIGNS_NEW,
        params: {
          hotelId: this.$route.params.hotelId,
        },
        hash: `#${edmHashOptions.SETTINGS}`
      })
    },
    navToEmailDesignWizardEdit (emailDesign) {
      const route = {
        name: userViews.EMAIL_DESIGNS_EDIT,
        params: {
          hotelId: this.$route.params.hotelId,
          emailDesignId: emailDesign.id
        },
        hash: `#${edmHashOptions.SETTINGS}`
      }
      if (this.getIsReadonly(emailDesign)) {
        route.hash = `#${edmHashOptions.BUILDER}`
      }
      this.$router.push(route)
    },
    navToEmailDesignWizardTestTab (emailDesign) {
      this.$router.push({
        name: userViews.EMAIL_DESIGNS_EDIT,
        params: {
          hotelId: this.$route.params.hotelId,
          emailDesignId: emailDesign.id
        },
        hash: `#${edmHashOptions.TEST}`
      })
    }
  },
  async beforeRouteUpdate (to, from, next) {
    await this.fetchPaginatedEmailDesigns()
    next()
  }
}
</script>

<style lang="sass">
@import '../../navigation/menu-styles'

#operations-menu
.md-menu-content-container
  cursor: pointer
  width: 130px !important
.operations-menu-item
  font-color: #999
  &:hover
    background-color: #E6E6E6
.operations-menu-item-text
  font-size: 12px !important
</style>

<style lang="sass" scoped>
#email-design-table
.progress-spinner
  text-align: center
  margin: 20px 0

.md-table-head-container
  height: 36px
  padding: 5px 0
</style>
