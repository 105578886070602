// button type
export const PRIMARY = 'primary'
export const SECONDARY = 'secondary'
export const DEFAULT = 'default'
// sizes
export const NORMAL = 'normal'
export const COMPACT = 'compact'
export const ROUND = 'round'
export const ROUND_SMALL = 'round-small'
// width
export const AUTO = 'auto'
