import moment from 'moment'
import { emailDesignTypes } from '@/constants'
import { EmailDesignType } from '@/types'

export const TableHeaderUtils = {
  getType (type: EmailDesignType): string {
    const types = {
      [emailDesignTypes.TEMPLATE]: 'Template',
      [emailDesignTypes.SINGLE_USE]: 'Single Use'
    }
    return types[type]
  },
  getUpdatedOn (timestamp: string): string {
    return moment(timestamp).format('D MMM YYYY, LT')
  },
  getName (identifier: string): string {
    return identifier.replace(/-/g, ' ')
  },
}
