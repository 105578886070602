import Vue from 'vue'

export default {
  SET_LOOKER_EMBED_URLS: (state, data) => Vue.set(state, 'lookerEmbedUrls', { ...state.lookerEmbedUrls, ...data }),
  SET_USER_PROFILE: (state, userProfile) => Vue.set(state, 'userProfile', userProfile),
  SET_PRIMARY_HOTEL: (state, primaryHotel) => {
    console.log('[SET_PRIMARY_HOTEL]', primaryHotel)
    Vue.set(state, 'primaryHotel', primaryHotel)
  },
  SET_SHOW_SPLIT_TEST: (state, data) => Vue.set(state, 'showSplitTest', data),
  SET_USER_IS_AUTH: (state, data) => Vue.set(state, 'userIsAuth', data),
  SET_AUTH_ERROR: (state, data) => Vue.set(state, 'authError', data),
  SET_HOTELS_MAP: (state, data) => Vue.set(state, 'hotelsMap', data),
  SET_IS_LOADING: (state, data) => Vue.set(state, 'isLoading', data),
  SET_CHANGE_PASSWORD_ERROR: (state, data) => Vue.set(state, 'changePasswordError', data),
  SET_CONFIRM_PASSWORD_ERROR: (state, data) => Vue.set(state, 'confirmPasswordError', data),
  SET_RECOVER_PASSWORD_ERROR: (state, data) => Vue.set(state, 'recoverPasswordError', data),
  SHOW_SESSION_REFRESH_MODAL: (state, data) => Vue.set(state, 'showSessionRefreshModal', data),
  SET_DRAWER_EXPANDED_STATE: (state, data) => Vue.set(state, 'drawerExpandedState', data)
}
