






































import { mapState, mapMutations } from 'vuex'
import Select from '@/components/select/Select.vue'
import { labelClasses } from '@/components/hotel-dashboard/reward-algorithms-manager/wizard/shared/label-classes'
import { getRangeOptions } from '@/utils/dropdown-utils'
import { menuStyles } from '@/constants'

export default {
  name: 'RewardsOffered',
  components: {
    Select,
  },
  data () {
    return {
      menuStyles,
      labelClasses,
      copy: {
        body: 'Select the number of rewards that are shown to a guest as part of their booking.'
      }
    }
  },
  computed: {
    ...mapState('ram', [
      'algo'
    ]),
    options (): Array<{ label: string; value: number }> {
      return getRangeOptions(0, 30)
    },
    onPropertyRewards: {
      get () {
        return this.algo.onPropertyRewards
      },
      set (value) {
        this.SET_ALGO_ON_PROPERTY_REWARDS(value)
      }
    },
    marketPlaceRewards: {
      get () {
        return this.algo.marketPlaceRewards
      },
      set (value): void {
        this.SET_ALGO_MARKETPLACE_REWARDS(value)
      }
    },
  },
  methods: {
    ...mapMutations('ram', [
      'SET_ALGO_ON_PROPERTY_REWARDS',
      'SET_ALGO_MARKETPLACE_REWARDS'
    ])
  }
}
