<template lang="html">
  <svg
    id="icon-gift"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.gift}">

    <title id="title">gift icon</title>

    <g>
      <path d="M922,300.7H617c39.8-22.5,78.2-46.5,85.4-50.9c0.9-0.6,1.7-1.2,2.5-1.9c27.7-23.9,42.5-49.7,44-76.5c1.4-24.6-8.7-49.3-29.1-71.4c-13.1-14.2-36.4-22.4-49.1-26
        c-16.7-4.8-54.4-0.5-65.9,4.7c-54,24.3-85.5,84.3-103.5,137.1C482,164.9,452,116.4,430.6,85.2c-14.2-20.8-47.2-50.5-59.6-57.4
        c-34.3-19.1-65.7-22.9-93.4-11.1c-46.5,19.8-61.9,76.6-62.8,80.6c-16.9,95.1,84.7,164.6,163.1,203.4H78c-11.3,0-20.5,9.2-20.5,20.5v201.6
        c0,11.3,9.2,20.5,20.5,20.5h33.8v426.3c0,11.3,9.2,20.5,20.5,20.5h735.4c11.3,0,20.5-9.2,20.5-20.5V543.2H922
        c11.3,0,20.5-9.2,20.5-20.5V321.2C942.5,309.9,933.3,300.7,922,300.7L922,300.7z M621.2,116.3c4.9-1.8,20.5-3.4,30.7-3.4c3.3,0,6,0.2,7.5,0.6
        c15.8,4.5,26.7,10.6,30.2,14.4c12.9,14,19.1,27.9,18.3,41.3c-0.8,15-10.5,30.8-28.7,46.8
        c-33.9,21.2-122.2,74.4-153.9,84.8h-3.2C530.7,248.6,559.4,144.1,621.2,116.3L621.2,116.3z M254.7,106c0.1-0.4,11.1-39.8,38.9-51.7
        c5.2-2.2,11-3.4,17.1-3.4c11.9,0,25.4,4.2,40.3,12.5c6.7,3.8,34.9,28.8,45.8,44.8c24.4,35.7,79.1,122.6,82.6,190.5
        c-1.3-0.7-2.6-1.4-4.1-1.8C408.4,275.4,238.3,198.7,254.7,106L254.7,106z M98.5,341.7h381v160.6h-381V341.7L98.5,341.7z M152.8,543.2h326.7V949H152.8
        V543.2L152.8,543.2z M847.2,949H520.5V543.2h326.7V949L847.2,949z M901.5,502.3h-381V341.7h381V502.3L901.5,502.3z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconGift',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>

<style lang="sass" scoped>
svg
  path
    fill: inherit !important
</style>
