import Vue from 'vue'
import router from '@/router'
import axios from 'axios'
import endpoints from '@/config/endpoints'
import QueryString from 'query-string'
import { sortByFieldDesc } from '@/utils/misc'
import { pagination } from '@/store/utils/pagination'
import { EmailDesignParser } from '@/parsers'
import { ParserUtils } from '@/parsers/utils'
import { emailDesignTypes, userViews, campaignMergeTags } from '@/constants'
import { unlayerSampleJSON } from '@/constants/unlayer-editor'
import { cloneDeep } from 'lodash'
import { v4 as uuid4 } from 'uuid'
import { TableHeaderUtils } from '@/utils/table-headers'

const { TEMPLATE, SINGLE_USE } = emailDesignTypes

const ID = 'id'
const POST = 'post'
const PATCH = 'patch'
const { CTA_LINK, REWARDS_BLOCK, REDEMPTION_REWARDS_BLOCK } = campaignMergeTags

export const BASE_EMAIL_DESIGN = {
  id: undefined,
  name: '',
  emailSubject: '',
  hotelId: undefined,
  modified: '',
  created: '',
  readAccessHotelIds: [],
  html: '<html>{{{unsubscribe}}}</html>',
  htmlUrl: '',
  json: unlayerSampleJSON,
  type: TEMPLATE,
  rewardsDisplayLimit: null,
}

export default {
  namespaced: true,
  state: {
    testEmailRecipients: '',
    emailDesignsFetchSuccess: false,
    emailDesign: cloneDeep(BASE_EMAIL_DESIGN),
    emailDesigns: {},
    pageInfo: {
      page: 1,
      pageSize: 10,
      total: undefined,
      index: 0,
    }
  },
  getters: {
    SENDER_EMAIL (state, getters, rootState) {
      const hotelId = rootState.route.params.hotelId
      const hotel = rootState.hotel.cradleHotelsObj[hotelId]
      if (hotel) {
        return hotel.senderEmail
      }
    },
    SORTED_EMAIL_DESIGNS_LIST (state) {
      return Object.values(state.emailDesigns).sort(sortByFieldDesc('modified'))
    },
    EMAIL_DESIGNS_EMPTY (state, getters) {
      return getters.SORTED_EMAIL_DESIGNS_LIST.length === 0
    },
    GET_EMAIL_DESIGN_PAYLOAD (state, _, __, rootGetters) {
      return () => {
        const payload = new FormData()
        payload.append('id', state.emailDesign.id)
        payload.append('name', state.emailDesign.name)
        payload.append('email_subject', state.emailDesign.emailSubject)
        payload.append('hotel', state.emailDesign.hotelId || rootGetters.CRADLE_HOTEL_ID)
        payload.append(
          'template_html',
          new Blob([state.emailDesign.html], { type: 'text/html' }),
          uuid4()
        )
        payload.append('template_json', JSON.stringify(state.emailDesign.json, null, 1))
        payload.append('template_type', state.emailDesign.type)
        state.emailDesign.readAccessHotelIds.forEach(id => {
          payload.append('read_access_hotels', id)
        })
        if (typeof state.emailDesign.rewardsDisplayLimit === 'number') {
          payload.append('rewards_display_limit', state.emailDesign.rewardsDisplayLimit)
        }
        return payload
      }
    },
    GET_EMAIL_DESIGN (state) {
      return ({ id }) => state.emailDesigns[id]
    },
    IS_READONLY (state, getters, rootState, rootGetters) {
      if (rootState.route.name === userViews.EMAIL_DESIGNS_EDIT) {
        return state.emailDesign.hotelId !== rootGetters.CRADLE_HOTEL_ID && state.emailDesign.type === TEMPLATE
      }
      if (rootState.route.name === userViews.EMAIL_DESIGNS_NEW) {
        return state.emailDesign.hotelId !== rootGetters.CRADLE_HOTEL_ID &&
               state.emailDesign.type === TEMPLATE &&
               state.emailDesign.readAccessHotelIds.indexOf(rootGetters.CRADLE_HOTEL_ID) > -1
      }
    },
    REQUIRES_CTA_LINK (state) {
      if (!state.emailDesign) return false
      const html = state.emailDesign.html
      if (typeof html !== 'string') return false
      return html.indexOf(CTA_LINK) > -1
    },
    REQUIRES_REWARD_ALGO (state) {
      if (!state.emailDesign) return false
      const html = state.emailDesign.html
      if (typeof html !== 'string') return false
      return html.indexOf(REWARDS_BLOCK) > -1
    },
    GET_REQUIRES_CTA_LINK (state, getters) {
      return ({ id }) => {
        const email = getters.GET_EMAIL_DESIGN({ id })
        if (!email || typeof email.html !== 'string') return false
        return email.html.indexOf(CTA_LINK) > -1
      }
    },
    GET_REQUIRES_REWARD_ALGO (state, getters) {
      return ({ id }) => {
        const email = getters.GET_EMAIL_DESIGN({ id })
        if (!email || typeof email.html !== 'string') return false
        return email.html.indexOf(REWARDS_BLOCK) > -1
      }
    },
    GET_REQUIRES_REDEMPTION_REWARD_ALGO (state, getters) {
      return ({ id }) => {
        const email = getters.GET_EMAIL_DESIGN({ id })
        if (!email || typeof email.html !== 'string') return false
        return email.html.indexOf(REDEMPTION_REWARDS_BLOCK) > -1
      }
    },
  },
  mutations: {
    ...pagination.mutations,
    SET_TEST_EMAIL_RECIPIENTS (state, emails) {
      state.testEmailRecipients = emails
    },
    SET_EMAIL_DESIGNS_FETCH_SUCCESS (state, bool) {
      state.emailDesignsFetchSuccess = bool
    },
    SET_EMAIL_DESIGNS (state, designs) {
      state.emailDesigns = designs
    },
    /**
     * checkout actions.MERGE_EMAIL_DESIGNS_WITHOUT_OVERRIDE
     */
    MERGE_EMAIL_DESIGN (state, design) {
      Vue.set(state.emailDesigns, design.id, design)
    },
    SET_EMAIL_DESIGN (state, design) {
      state.emailDesign = design
    },
    RESET_EMAIL_DESIGN (state) {
      state.emailDesign = cloneDeep(BASE_EMAIL_DESIGN)
    },
    RESET_EMAIL_DESIGNS (state) {
      state.emailDesigns = {}
    },
    SET_NAME (state, name) {
      state.emailDesign.name = name
    },
    SET_SUBJECT (state, subject) {
      state.emailDesign.emailSubject = subject
    },
    SET_TYPE (state, type) {
      state.emailDesign.type = type
    },
    SET_READ_ACCESS_HOTEL_IDS (state, ids) {
      state.emailDesign.readAccessHotelIds = ids
    },
    SET_EMAIL_DESIGN_JSON (state, json) {
      Vue.set(state.emailDesign, 'json', json)
    },
    UPDATE_EMAIL_DESIGN_JSON (state, { id, json }) {
      Vue.set(state.emailDesigns[id], 'json', json)
    },
    SET_EMAIL_DESIGN_HTML (state, html) {
      Vue.set(state.emailDesign, 'html', html)
    },
    SET_EMAIL_DESIGN_HOTEL_ID (state, id) {
      Vue.set(state.emailDesign, 'hotelId', id)
    },
    UPDATE_EMAIL_DESIGN_HTML (state, { id, html }) {
      Vue.set(state.emailDesigns[id], 'html', html)
    },
    SET_REWARDS_DISPLAY_LIMIT (state, limit) {
      Vue.set(state.emailDesign, 'rewardsDisplayLimit', limit)
    },
    SET_PAGE (state, page) {
      state.pageInfo.page = page
    },
    SET_PAGE_SIZE (state, pageSize) {
      state.pageInfo.pageSize = pageSize
    },
    SET_PAGE_INDEX (state, index) {
      state.pageInfo.index = index
    }
  },
  actions: {
    MERGE_EMAIL_DESIGNS_WITHOUT_OVERRIDE ({ state, commit }, designs) {
      Object.entries(designs).forEach(([key, value]) => {
        if (!state.emailDesigns[key]) {
          commit('MERGE_EMAIL_DESIGN', value)
        }
      })
    },
    async COPY_TEMPLATE_EMAIL_DESIGN ({ commit, getters, dispatch, rootGetters }, { id }) {
      const emailDesign = getters.GET_EMAIL_DESIGN({ id })
      const errors = []
      if (!emailDesign) {
        errors.push(`Could not find email design: ${id}`)
      } else if (emailDesign.type !== TEMPLATE) {
        errors.push(`Cannot copy email design of type: ${TableHeaderUtils.getType(emailDesign.type)}`)
      }
      if (errors.length > 0) {
        errors.forEach((error) => dispatch('messages/ADD_ERROR', new Error(error), { root: true }))
        return
      }

      const copy = cloneDeep(emailDesign)
      delete copy.id
      copy.type = SINGLE_USE
      copy.hotelId = rootGetters.CRADLE_HOTEL_ID

      const resp = await dispatch('FETCH_EMAIL_STRING', { url: emailDesign.htmlUrl })
      if (!resp.error) {
        commit('SET_EMAIL_DESIGN', {
          ...copy,
          html: resp.data
        })
      }
    },
    async SEND_TEST_EMAIL ({ rootState, state, dispatch }, { nodeId }) {
      const templateId = state.emailDesign.id
      const endpoint = `${endpoints.cradle.emailTemplateRoute}${templateId}/test-email/`
      const emailArr = state.testEmailRecipients.split(',')
      const cleanEmailsArr = emailArr.map(email => email.trim())
      const payload = {
        to_emails: cleanEmailsArr,
        campaign_node_id: nodeId
      }

      let response
      try {
        response = await axios.post(
          endpoint,
          payload,
          {
            headers: {
              Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
            }
          }
        )
      } catch (err) {
        const rawErrorMessage = JSON.stringify(err.response.data)
        let prettyError
        // these are handled individually vs eventually being passed
        // to HANDLE_ERRORS because of ambiguous err responses
        if (rawErrorMessage.includes('to_emails')) {
          prettyError = 'To Emails: Enter a valid email address.'
        } else if (rawErrorMessage.includes('From email not found')) {
          prettyError = 'From email not found. Please contact your CSM for help.'
        } else {
          prettyError = rawErrorMessage
        }
        return dispatch('messages/ADD_ERROR', new Error(prettyError), { root: true })
      }
      dispatch('messages/ADD_SUCCESS', { message: 'Test email successfully sent.' }, { root: true })
      console.log('[SEND_TEST_EMAIL]', response)
    },
    async SET_EMAIL_DESIGN_FOR_COPY ({ dispatch, commit, getters }, { id, hotelId }) {
      const emailDesign = { ...getters.GET_EMAIL_DESIGN({ id }) }
      emailDesign.name = `copy-${Date.now()}-${emailDesign.name}`
      emailDesign.template_type = SINGLE_USE
      const emailString = await dispatch('FETCH_EMAIL_STRING', { url: emailDesign.htmlUrl })
      emailDesign.html = emailString.data
      delete emailDesign.id
      commit('SET_EMAIL_DESIGN', emailDesign)
      commit('SET_EMAIL_DESIGN_HOTEL_ID', hotelId)
    },
    /**
     *
     * @param {override} boolean - true will replace state.emailDesigns after fetch, false will
     * only merge new email design not currently in state.emailDesigns.it is useful to disable the override
     * when the `html` is populated on an email design and you do not want to override when fetching
     * more email designs.
     */
    async FETCH_EMAIL_DESIGNS ({ dispatch, commit, rootState }, { id, page, pageSize, search, nextUrl, fetchAll = false, override = true, templateType }) {
      commit('SET_EMAIL_DESIGNS_FETCH_SUCCESS', false)
      const queryParams = {
        page: page,
        page_size: pageSize,
        hotel__legacy_id: rootState.route.params.hotelId,
        template_type: templateType
      }
      if (search) queryParams.search = search
      const queryString = QueryString.stringify(queryParams)

      let endpoint = id
        ? `${endpoints.cradle.emailTemplateRoute}${id}/`
        : `${endpoints.cradle.emailTemplateRoute}?${queryString}`
      if (nextUrl) {
        endpoint = nextUrl.replace('http', 'https')
      }
      console.log('[FETCH_EMAIL_DESIGNS] endpoint', endpoint)

      let response
      let error

      try {
        response = await axios.get(endpoint, {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
          }
        })
      } catch (err) {
        error = err.response.data
      }
      console.log('[FETCH_EMAIL_DESIGNS]', error || response)

      if (error) {
        dispatch('messages/ADD_ERROR', error, { root: true })
        return router.push({
          name: userViews.EMAIL_DESIGNS,
          params: { ...rootState.route.params }
        })
      } else {
        let rawList
        if (response.data.results) {
          rawList = response.data.results
        } else {
          rawList = [response.data]
        }
        const parsedEmailDesigns = ParserUtils.getParsedObject({
          rawList,
          parser: EmailDesignParser.parseEmailDesign,
          indexKey: ID,
        })

        if (override) {
          commit('SET_EMAIL_DESIGNS', parsedEmailDesigns)
        } else {
          dispatch('MERGE_EMAIL_DESIGNS_WITHOUT_OVERRIDE', parsedEmailDesigns)
        }

        if (response.data.count) {
          commit('SET_PAGE_TOTAL', response.data.count)
        }
        commit('SET_EMAIL_DESIGNS_FETCH_SUCCESS', true)
        if (fetchAll && response.data.next) {
          await dispatch('FETCH_EMAIL_DESIGNS', { nextUrl: response.data.next, fetchAll: true })
        }
      }
    },
    async CREATE_OR_UPDATE_EMAIL_DESIGN (
      { rootState, state, getters, commit, dispatch },
      {
        id,
        showSuccess = false
      }
    ) {
      let endpoint
      let method

      if (!id) {
        endpoint = `${endpoints.cradle.emailTemplateRoute}`
        method = POST
      } else {
        endpoint = `${endpoints.cradle.emailTemplateRoute}${id}/`
        method = PATCH
      }

      const payload = getters.GET_EMAIL_DESIGN_PAYLOAD()

      let response
      let error
      try {
        response = await axios[method](
          endpoint,
          payload,
          {
            headers: {
              Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
              'Content-Type': 'multipart/form-data'
            }
          }
        )
      } catch (err) {
        error = err.response.data
        dispatch('messages/ADD_ERROR', error, { root: true })
      }
      console.log('[CREATE_OR_UPDATE_EMAIL_DESIGN]', response || error)

      if (!error) {
        const emailDesign = EmailDesignParser.parseEmailDesign(response.data)
        commit('MERGE_EMAIL_DESIGN', {
          // want to retain the 'html' key/value pair that's not on the incoming email design
          ...state.emailDesign,
          ...emailDesign
        })

        if (showSuccess) {
          dispatch(
            'messages/ADD_SUCCESS',
            { message: 'Email design was saved.' },
            { root: true }
          )
        }
      }

      return {
        error,
        data: response && response.data
      }
    },
    async FETCH_EMAIL_STRING_AND_MERGE ({ dispatch, getters, commit }, { id, url }) {
      const response = await dispatch('FETCH_EMAIL_STRING', { url })
      if (!response.error) {
        const emailDesign = getters.GET_EMAIL_DESIGN({ id })
        const newEmailDesign = {
          ...emailDesign,
          html: response.data
        }
        commit('MERGE_EMAIL_DESIGN', newEmailDesign)
      }
    },
    async FETCH_EMAIL_STRING ({ dispatch }, { url }) {
      let response
      let error

      console.log('[FETCH_EMAIL_STRING] url', url)

      try {
        response = await axios.get(url)
      } catch (err) {
        error = err.response?.data
      }
      console.log('[FETCH_EMAIL_STRING]', error || response)
      if (error) {
        dispatch('messages/ADD_ERROR', error, { root: true })
      }
      return {
        error,
        data: response && response.data
      }
    }
  }
}
