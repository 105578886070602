var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-size-100",attrs:{"id":"on-property-rewards-wizard-content-container"}},[_c('CustomStepper',{attrs:{"editing":_vm.editing,"step-headers":_vm.stepHeaders},on:{"done":_vm.handleDone},scopedSlots:_vm._u([{key:_vm.steps.STEP_1,fn:function(ref){
var slotScope = ref.slotScope;
return [_c('CategoryStep',{attrs:{"index":slotScope.idx,"editing":_vm.editing}})]}},{key:_vm.steps.STEP_2,fn:function(ref){
var slotScope = ref.slotScope;
return [_c('TextDisplayStep',{attrs:{"index":slotScope.idx,"editing":_vm.editing}})]}},{key:_vm.steps.STEP_3,fn:function(ref){
var slotScope = ref.slotScope;
return [_c('ImageDisplayStep',{attrs:{"index":slotScope.idx,"editing":_vm.editing}})]}},{key:_vm.steps.STEP_4,fn:function(ref){
var slotScope = ref.slotScope;
return [_c('PropertiesStep',{attrs:{"index":slotScope.idx,"editing":_vm.editing}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }