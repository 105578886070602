







import Vue from 'vue'
import { trim } from 'lodash'

export default Vue.extend({
  name: 'Label',
  props: {
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    classes: {
      type: Array,
      default: () => [],
      required: false,
    }
  },
  computed: {
    defaultSlot (): boolean {
      return Array.isArray(this.$slots.default) &&
        this.$slots.default[0]
    },
    appendAsterik (): boolean {
      if (!this.defaultSlot?.text) {
        return false
      }
      // prevents a single asterik from rendering
      return this.defaultSlot.text?.length > 0 && this.required
    },
    defaultSlotText (): string {
      return `${trim(this.defaultSlot?.text)}${this.appendAsterik ? '*' : ''}`
    },
  },
})
