<template>
  <span :class="{ hovering }" class="icons-container">
    <span
      v-if="showClose"
      class="close-icon-container icon-container close-container"
      @click="$emit('clickClose')"
      :style="{ paddingRight: iconPadding }"
    >
      <SvgClose id="icons-container-close" :width="width" :fill="fill"/>
    </span>
    <span
      v-if="showDownArrow"
      @click="$emit('clickDown')"
      class="angle-down-icon-container icon-container down-container"
    >
      <SvgAngleDown id="icons-container-angle-down" :width="width" :fill="fill" />
    </span>
  </span>
</template>

<script>
import SvgClose from '@/components/icons/SvgClose.vue'
import SvgAngleDown from '@/components/icons/SvgAngleDown.vue'

export default {
  name: 'IconsContainer',
  components: {
    SvgClose,
    SvgAngleDown,
  },
  props: {
    showClose: {
      type: Boolean,
      default: false,
      required: false,
    },
    showDownArrow: {
      type: Boolean,
      default: true,
      required: false,
    },
    hovering: {
      type: Boolean,
      default: false,
      required: false,
    },
    fill: {
      type: String,
      default: 'black',
      required: false
    },
  },
  data () {
    return {
      width: 9,
    }
  },
  computed: {
    iconPadding () {
      return this.showClose && !this.showDownArrow ? '17px' : ''
    }
  }
}
</script>

<style lang="sass" scoped>
.icons-container
  font-size: 14px
  background-color: inherit
  @apply flex flex-row justify-center items-center

.icon-container
  @apply flex flex-row items-start
  @apply h-full w-auto
  @apply cursor-pointer

.hovering
  @apply bg-gray-125 text-gray-400

.close-container
  padding-top: 17px
  @apply px-2

.down-container
  padding-top: 18px
  @apply pl-2
</style>
