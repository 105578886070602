<template>
  <svg
    id="icon-museum"
    viewBox="0 0 148.508 148.508"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.museum}">

    <title id="title">museum icon</title>
    <desc id="desc">museum icon</desc>

    <g>
      <polygon points="25.656,55.841 37.73,55.841 39.542,111.381 24.148,111.381"/>
      <polygon points="15.394,114.4 133.115,114.4 133.115,120.738 142.17,
        120.738 142.17,129.794 148.508,129.794 148.508,136.133 0,136.133 0,
        129.794 6.338,129.794 6.338,120.738 15.394,120.738"/>
      <polygon points="139.15,39.541 74.253,12.375 9.357,39.541 9.357,43.466 139.15,43.466"/>
      <rect x="21.732" y="46.484" width="105.042" height="6.035"/>
      <polygon points="110.475,55.841 122.547,55.841 124.359,111.381 108.965,111.381"/>
      <polygon points="82.102,55.841 94.477,55.841 96.289,111.381 80.592,111.381"/>
      <polygon points="54.029,55.841 66.103,55.841 67.914,111.381 52.218,111.381"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconMuseum',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
