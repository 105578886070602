import { FunnelUxParser, CradleHotelParser } from '@/parsers'
import { ParserUtils } from '@/parsers/utils'
import { ctxOpts } from '@/constants'
import { TEMPLATE_TYPES } from '@/components/hotel-dashboard/editor/constants'
import { sortByName } from '@/utils/misc'

export const getters = {
  fuxSubset: (state) => ({ context, channel }) => {
    return state.fuxs && state.fuxs.filter(fux => fux.channel === channel && fux.context === context)
  },
  // excludes given fux
  fuxSubsetHasEnabled: (state, getters) => ({ context, channel, uuid }) => {
    return getters.fuxSubset({ context, channel })
      .some(fux => fux.uuid !== uuid && fux.enabled)
  },
  allFuxObjects: (state) => {
    const funnelUxObj = ParserUtils.getParsedObject({
      rawList: state.fuxs,
      parser: FunnelUxParser.parseFux,
      indexKey: 'uuid',
    })
    return funnelUxObj || {}
  },
  fuxDetail: (state, getters) => (fuxUuid) => {
    return getters.allFuxObjects[fuxUuid]
  },
  fuxContext: (state, getters) => (fuxUuid) => {
    return getters.fuxDetail(fuxUuid) && getters.fuxDetail(fuxUuid).context
  },
  fuxChannel: (state, getters) => (fuxUuid) => {
    return getters.fuxDetail(fuxUuid) && getters.fuxDetail(fuxUuid).channel
  },
  contextFilter: () => (context, allowedCtx) => {
    const allowedCtxMap = {
      hb: [ctxOpts.HOMEPAGE, ctxOpts.BOOKING_ENGINE],
      hp: [ctxOpts.HOMEPAGE],
      be: [ctxOpts.BOOKING_ENGINE],
      cp: [ctxOpts.CONFIRMATION_PAGE],
    }
    const allowedCtxList = allowedCtxMap[allowedCtx]
    return allowedCtxList ? allowedCtxList.includes(context) : true
  },
  filteredTemplateOpts: (state, getters) => (channel, context, templateType) => {
    const templateObjs = state.allTemplateOpts.filter(template => {
      const channelFilter = template.channel_type === 'al' || template.channel_type === channel
      const allowContext = getters.contextFilter(context, template.allowed_context)
      return channelFilter && template.template_type === templateType && allowContext
    })
    // dedup on template name. its already sorted by channel desc
    return templateObjs.filter((template, position, arr) => {
      return arr.map(mapTemplate => mapTemplate.name).indexOf(template.name) === position
    })
  },
  fieldList: (state) => (templateName, channel) => {
    return state.templateFields[channel][templateName] || []
  },
  requiredFieldList: (state, getters) => (templateName, channel) => {
    return getters.fieldList(templateName, channel).filter(
      f => f.required
    )
  },
  fieldDefaults: (state, getters) => (templateName, channel) => {
    const defaults = getters.fieldList(templateName, channel).map(field => {
      switch (field.field_type) {
        case 'bool':
          field.field_value = (field.default_value === 'true')
          break
        case 'int':
          field.field_value = parseInt(field.default_value) || '' // check NaN
          break
        case 'float':
          field.field_value = parseFloat(field.default_value) || '' // check NaN
          break
        default:
          field.field_value = field.default_value
          break
      }

      return field
    })
    return defaults
  },
  bookingEngineNames (state) {
    return Object.keys(state.bookingEnginesObj).sort()
  },
  hotelGroupList (state) {
    return Object.values(state.hotelGroupsObj).sort(sortByName)
  },
  hotelPayload: () => {
    return ({ hotel }) => CradleHotelParser.inverseParseHotel(hotel)
  },
  cleanDefaultTemplateData: (state, getters) => (context, channel, td) => {
    const templateData = { ...td }
    templateData.context = context
    templateData.channel = channel
    templateData.hotel = state.cradleHotelObj.id
    templateData.hcs.forEach(hc => {
      hc.hotel = state.cradleHotelObj.apiName
      hc.context = context
      hc.identifier = getters.autogenDesignName(context, channel, hc.template.name)

      if (hc.template.template_type === 'expanded') {
        templateData.funnel_ux = hc.identifier
      }
    })
    return templateData
  },
  autogenDesignName: (state) => (context, channel, templateName) => {
    const name = [
      state.cradleHotelObj.apiName,
      templateName,
      context,
      channel,
      new Date().toISOString()
    ].join('-')
    return name
  },
  templateDropdown: () => (configuredTemplateTypes, context) => {
    // 1. exclude any template types that have been configured already
    // 2. exclude booking engine template types from other contexts
    const beTemplateTypes = ['banner', 'bonus']
    const allowedTemplates = Object.keys(TEMPLATE_TYPES)
      .filter(tt => {
        return !configuredTemplateTypes.includes(tt) &&
        !(context !== 'be' && beTemplateTypes.includes(tt))
      })
      .reduce((obj, key) => {
        obj[key] = TEMPLATE_TYPES[key]
        return obj
      }, {})
    return allowedTemplates
  },
  hotelsList: (state) => Object.values(state.cradleHotelsObj).sort(sortByName),
  hotelObjsByCradleId: (state) => {
    const byCradleId = {}
    Object.values(state.cradleHotelsObj).forEach(hotel => {
      byCradleId[hotel.cradleId] = hotel
    })
    return byCradleId
  },
  hotelByCradleId: (getters) => (cradleId) => getters.hotelObjsByCradleId[cradleId],
  defaultTemplateConfig: (state, getters) => (context) => {
    switch (context) {
      case 'cp':
        return {
          expanded: getters.getTemplateByName('Kyoto'),
          minimized: getters.getTemplateByName('RectangleIcon')
        }
      default:
        return {
          expanded: getters.getTemplateByName('Toronto'),
          minimized: getters.getTemplateByName('RectangleIcon')
        }
    }
  },
  getTemplateByName: (state) => templateName => {
    return state.allTemplateOpts.filter(t => t.name === templateName)[0] || {}
  },
  getDefaultTemplateData: (state) => context => {
    return state.defaultTemplateData[context]
  }
}
