<template lang="html">
  <div id="confirm-container">
    <div class="center md-headline">
      Set New Password
    </div>

    <div v-if="errorMsg" id="show-error">
      <span>
        {{ errorMsg }}
      </span>
    </div>

    <form id="confirm-form" @submit.prevent="submit" @focus="show = true">
      <div id="extra-info">
        Check your email for a verification code and enter below.
      </div>

      <md-field>
        <label>Email</label>
        <md-input id="email-input"
                  :md-toggle-password="false"
                  v-model="username"
                  type="text"
                  required
                  ></md-input>
     </md-field>

     <md-field>
       <label>Verification Code</label>
       <md-input id="verification-code-input"
                 :md-toggle-password="false"
                 v-model="code"
                 type="text"
                 required
                 ></md-input>
      </md-field>

      <!-- passwords -->
      <md-field>
        <label>New Password</label>
        <md-input id="new-password-input"
                  :md-toggle-password="false"
                  v-model="newPw"
                  type="password"
                  required
                  ></md-input>
      </md-field>

      <md-field>
        <label>Confirm New Password</label>
        <md-input id="confirm-password-input"
                  :md-toggle-password="false"
                  v-model="confirmNewPw"
                  type="password"
                  required
                  ></md-input>
      </md-field>

      <div id="password-details">
        * Password must have 8 characters, at least one number and one lowercase letter.
      </div>

      <md-card-actions>
        <md-button type="submit" class="" id="reset-btn">Reset</md-button>
      </md-card-actions>
    </form>

    <div id="help" class="center">
      Having trouble? Please contact your account manager.
    </div>
  </div>
</template>

<script>
const codeMismatchException = 'Sorry, the verification code did not work.'
const passwordMismatchException = 'The passwords you entered did not match. Try again.'
const invalidPasswordException = `The password you entered is invalid.
  Please make sure your new password has at least 8 characters and
  includes at least one number and one lowercase letter.`

export default {
  name: 'ConfirmContainer',
  components: {},
  data () {
    return {
      username: '',
      code: '',
      newPw: '',
      confirmNewPw: '',
      show: false,
      errorMsg: '',
      errorMessages: {
        InvalidParameterException: invalidPasswordException,
        CodeMismatchException: codeMismatchException,
        passwordMismatchException: passwordMismatchException
      }
    }
  },
  methods: {
    async submit () {
      const { username, code, newPw, confirmNewPw } = this
      if (newPw === confirmNewPw) {
        await this.$store.dispatch('CONFIRM_PASSWORD', { username, code, newPw })

        if (this.$store.state.confirmPasswordError) {
          this.errorMsg = this.$store.state.confirmPasswordError.message
          this.code = ''
          this.newPw = ''
          this.confirmNewPw = ''
        } else {
          this.$router.push({
            path: '/',
            query: { reset: 'success' }
          })
        }
      } else {
        this.errorMsg = this.errorMessages.passwordMismatchException
        this.newPw = ''
        this.confirmNewPw = ''
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  button
    background-color: black

  #confirm-container
    top: 50%
    width: 400px
    margin: 100px auto
    padding: 30px 0
    background-color: white

    #help, #password-details
      font-size: 12px

    #extra-info
      font-size: 14px
      text-align: center
      padding: 20px

    #show-error
      font-size: 14px
      color: #bf0000
      text-align: center
      padding: 20px
      margin-bottom: 20px

    .center
      text-align: center

    a
      text-decoration: underline
      color: black
    a:hover
      text-decoration: none
      color: #175276

    #reset-btn
      color: white

  #confirm-form
    margin: 0px 0px 20px 0px
    padding: 0px 40px

  #valid-container
    text-align: center
    margin: 0px 0px
    padding: 10px

  .valid
    background-color: #DAFAC0

  .invalid
    background-color: #FFADAC
</style>
