<template lang="html">
  <div id="reset-container">
    <div class="center md-headline">Reset Password</div>

    <div v-if="errorMsg" id="show-error">
      <span>
        {{ errorMsg }}
      </span>
    </div>

    <form id="reset-form" @submit.prevent="submit" @focus="show = true">
      <md-field>
        <label>Email</label>
        <md-input :md-toggle-password="false"
                   v-model="username"
                   type="text"
                   required></md-input>
      </md-field>
      <md-field>
        <label>Temporary Password</label>
        <md-input :md-toggle-password="false"
                   v-model="temporaryPw"
                   type="password"
                   required></md-input>
      </md-field>

      <md-field>
        <label>New Password</label>
        <md-input :md-toggle-password="false"
                   v-model="newPw"
                   type="password"
                   required
                   @focus="show = true"
                   ></md-input>
     </md-field>

     <md-field>
        <label>Confirm New Password</label>
        <md-input :md-toggle-password="false"
                   v-model="confirmPw"
                   type="password"
                   required
                   @focus="show = true"
                   ></md-input>
      </md-field>

      <div id="password-details">
        * Password must have 8 characters, at least one number and one lowercase letter.
      </div>

      <!--  :disabled -->
      <!--  add condition to disable the button-->
      <div class="auth-view-button-container">
        <button type="submit" class="dark-button">
          Reset
        </button>
      </div>
    </form>

    <div id="help" class="center">
      Having trouble? Please contact your account manager.
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResetContainer',
  data () {
    return {
      username: null,
      temporaryPw: null,
      newPw: null,
      confirmPw: null,
      show: false,
      errorMsg: ''
    }
  },
  methods: {
    // ensures new password fields match before attempting to call cognito
    // will clear form & display error if they do not match
    submit () {
      const passwordsMatch = this.newPw === this.confirmPw

      if (passwordsMatch) {
        this.changePassword()
      } else {
        this.resetForm({ errorMsg: 'Passwords do not match.' })
      }
    },
    // will attempt auth and nav to login container if successful or display error
    async changePassword () {
      const username = this.username.trim().toLowerCase()
      const { temporaryPw, newPw } = this

      await this.$store.dispatch('CHANGE_PASSWORD', {
        username,
        temporaryPw,
        newPw
      })

      if (this.$store.state.changePasswordError) {
        this.resetForm({ errorMsg: this.$store.state.changePasswordError.message })
      } else {
        this.$router.push({
          path: '/',
          query: { reset: 'success' }
        })
      }
    },
    // resets form values and can set the error message displayed on form
    resetForm ({ errorMsg }) {
      this.temporaryPw = null
      this.newPw = null
      this.confirmPw = null
      this.errorMsg = errorMsg
    }
  }
}
</script>

<style lang="sass" scoped>
  @import ../navigation/menu-styles

  #reset-container
    @apply rounded-md
    top: 50%
    width: 400px
    margin: 100px auto
    padding: 30px 0
    background-color: white

    #help, #password-details
      font-size: 12px

    #show-error
      font-size: 14px
      color: #bf0000
      text-align: center
      padding: 20px
      margin-bottom: 20px

    .center
      text-align: center

    a
      text-decoration: underline
      color: black
    a:hover
      text-decoration: none
      color: #175276

  #reset-form
    margin: 0px 0px 20px 0px
    padding: 0px 40px

  #valid-container
    text-align: center
    margin: 0px 0px
    padding: 10px

  .valid
    background-color: #DAFAC0

  .invalid
    background-color: #FFADAC
</style>
