<template>
  <div
    v-if="show"
    id="reward-algorithms-wizard"
    class="md-content-item lato-font pb-12 mb-12"
  >
    <WizardHeader @clickEvent="cancel">
      <template slot="header">
        <span>{{ headerCopy }}</span>
      </template>
    </WizardHeader>
    <div id="stepper-container">
      <div
        id="header-container"
        class="w-100 flex pl-6 mb-4"
      >
        <span
          v-if="description"
          id="description-copy"
        >
          {{ description }}
        </span>
      </div>
      <CustomStepper
        :editing="true"
        :step-headers="stepHeaders"
      >
        <template v-slot:[steps.STEP_1]="{ slotScope }">
          <WizardStep
            :editing="true"
            :reverse-order-on-mobile="false"
          >
            <CustomStepHeader
              slot="step-header"
              :index="slotScope.idx"
              :label="stepHeaders[0].name"
              :meta="copy.required"
            />
            <RamName
              slot="left-content"
              :index="slotScope.idx"
            />
          </WizardStep>
        </template>
        <template v-slot:[steps.STEP_2]="{ slotScope }">
          <WizardStep
            :editing="true"
            :reverse-order-on-mobile="false"
          >
            <CustomStepHeader
              slot="step-header"
              :index="slotScope.idx"
              :label="stepHeaders[1].name"
              :meta="copy.required"
            />
            <RewardsOffered
              slot="left-content"
              :index="slotScope.idx"
            />
          </WizardStep>
        </template>
        <template v-slot:[steps.STEP_3]="{ slotScope }">
          <WizardStep
            :editing="true"
            :reverse-order-on-mobile="false"
          >
            <CustomStepHeader
              slot="step-header"
              :index="slotScope.idx"
              :label="stepHeaders[2].name"
              :meta="copy.required"
            />
            <SelectableRewards
              slot="left-content"
              class="ram-sub-component"
              :index="slotScope.idx"
            />
          </WizardStep>
        </template>
        <template v-slot:[steps.STEP_4]="{ slotScope }">
          <WizardStep
            :editing="true"
            :reverse-order-on-mobile="false"
          >
            <CustomStepHeader
              slot="step-header"
              :index="slotScope.idx"
              :label="stepHeaders[3].name"
            />
            <div
              slot="left-content"
              class="flex flex-col"
              :style="{ maxWidth: '600px' }"
            >
              <Tabs>
                <Tab title="On-Property Rewards">
                  <OverrideRewardsContainer
                    :reward-type="'onProperty'"
                    :exclude-campaign-rewards="excludeCampaignRewards"
                  />
                </Tab>
                <Tab title="Marketplace Rewards">
                  <OverrideRewardsContainer
                    :reward-type="'marketPlace'"
                    :exclude-campaign-rewards="excludeCampaignRewards"
                  />
                </Tab>
              </Tabs>
            </div>
            <div
              slot="right-content"
              class="copy-container"
              :index="slotScope.idx"
            >
              <div
                class="pb-3"
                v-html="copy.overrideRewards"
              />
            </div>
          </WizardStep>
        </template>
      </CustomStepper>
    </div>

    <div id="footer">
      <div
        id="button-container"
        class="md-layout-item md-small-size-100 md-size-60"
      >
        <StepperButton
          ref="footer-cancel-button"
          :copy="copy.buttons.cancel"
          :disabled="false"
          :inverse="true"
          @clickEvent="cancel"
        />
        <StepperButton
          ref="footer-create-update-button"
          :copy="buttonCopy"
          :disabled="isDisabled"
          :width="178"
          @clickEvent="createOrUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'
import { steps, userViews } from '@/constants'
import RamName from '@/components/hotel-dashboard/reward-algorithms-manager/wizard/RamName'
import RewardsOffered from '@/components/hotel-dashboard/reward-algorithms-manager/wizard/RewardsOffered'
import SelectableRewards from '@/components/hotel-dashboard/reward-algorithms-manager/wizard/SelectableRewards'
import OverrideRewardsContainer from '@/components/hotel-dashboard/reward-algorithms-manager/wizard/OverrideRewardsContainer'
import CustomStepper from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/CustomStepper'
import WizardStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/WizardStep'
import CustomStepHeader from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepHeader'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton.vue'
import { CampaignUtils } from '@/utils/campaign'
import WizardHeader from '@/components/common/WizardHeader'
import Tabs from '@/components/tabs/Tabs.vue'
import Tab from '@/components/tabs/Tab.vue'
import { cloneDeep, isEqual } from 'lodash'
import endpoints from '@/config/endpoints'
/**
 * NOTE - regardless of the editing prop's value, we always set the
 * editing prop on the CustomStepper to render an expanded UI
 */

export default {
  name: 'RewardAlgorithmsWizard',
  components: {
    WizardStep,
    CustomStepper,
    CustomStepHeader,
    StepperButton,
    RamName,
    RewardsOffered,
    SelectableRewards,
    OverrideRewardsContainer,
    WizardHeader,
    Tabs,
    Tab,
  },
  props: {
    editing: {
      type: Boolean,
      required: true,
      default: () => false
    },
    endpoint: {
      type: String,
      required: true,
    },
    indexRouteName: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
  },
  data () {
    return {
      tab: 1,
      position: 'center',
      duration: 4000,
      originalAlgo: undefined,
      show: false,
      steps, // expose constants
      stepHeaders: [
        { name: 'Algorithm Name', isRetain: false },
        { name: 'Number of Rewards Offered', isRetain: false },
        { name: 'Number of Selectable Rewards', isRetain: false },
        { name: 'Reward Offer Customization', isRetain: false }
      ],
      getHash: CampaignUtils.getHash,
      copy: {
        header: {
          edit: 'Edit Algorithm',
          create: 'Create New Algorithm'
        },
        buttons: {
          update: 'Update Algorithm',
          create: 'Create Algorithm',
          cancel: 'Cancel',
          updateAndReturn: 'Update Algorithm & Return To Campaign'
        },
        createAlgorithm: 'Create Algorithm',
        cancel: 'Cancel',
        overrideRewards: `
          <p class="mb-2">
            Laasie's <strong>personalization engine</strong> determines the best set of rewards to offer your guests based on their booking preferences and user profile.
          </p>
          <p class="mb-2">
            If you would like to override our AI, you can configure individual rewards so that they are <strong>always offered</strong> or <strong>never offered</strong>.
          </p>

        `,
        required: 'Required'
      }
    }
  },
  computed: {
    ...mapState('ram', [
      'pageInfo',
      'algo'
    ]),
    ...mapGetters('ram', [
      'GET_ALGO',
      'ALGOS_EMPTY',
      'IS_DISABLED',
      'ALGO_PAYLOAD',
      'TOTAL_REWARDS_OFFERED',
    ]),
    ...mapGetters([
      'FROM_ECM',
    ]),
    ...mapState('ecm', [
      'campaign'
    ]),
    ...mapState('edm', [
      'emailDesign'
    ]),
    headerCopy () {
      return this.editing ? this.copy.header.edit : this.copy.header.create
    },
    buttonCopy () {
      if (this.FROM_ECM) {
        return this.copy.buttons.updateAndReturn
      }
      return this.editing ? this.copy.buttons.update : this.copy.buttons.create
    },
    isDisabled () {
      if (this.editing && !isEqual(this.originalAlgo, this.algo)) {
        return false
      }
      return this.IS_DISABLED
    },
    excludeCampaignRewards () {
      return this.endpoint === endpoints.cradle.hotelRewardAlgorithmRoute
    }
  },
  watch: {
    TOTAL_REWARDS_OFFERED (totalRewardsOffered) {
      if (this.algo.maxRewardSelections > totalRewardsOffered) {
        this.SET_ALGO_SELECTABLE_LIMIT(null)
      }
    },
  },
  beforeDestroy () {
    this.RESET_STATE() // FIXME - reset the single entity, not the list
  },
  async mounted () {
    await this.setup()
    this.show = true
  },
  methods: {
    ...mapMutations('ram', [
      'SET_ALGO',
      'SET_ALGO_SELECTABLE_LIMIT'
    ]),
    ...mapMutations('messages', [
      'CLEAR_MESSAGES'
    ]),
    ...mapActions('ram', [
      'FETCH_ALGOS',
      'CREATE_OR_UPDATE_ALGO',
      'RESET_STATE'
    ]),
    ...mapActions('messages', [
      'ADD_ERROR',
    ]),
    async setup () {
      // hydrate algos if this view is initial page view
      this.RESET_STATE()
      if (this.ALGOS_EMPTY) await this.fetchPaginatedAlgos()
      if (this.editing) {
        const algo = this.GET_ALGO({ id: this.$route.params.algoId })
        console.log('[RewardAlgorithmsWizard] (setup) algo:', algo)
        this.SET_ALGO(algo)
        this.originalAlgo = cloneDeep(this.algo)
      }
    },
    async fetchPaginatedAlgos () {
      const { page, pageSize } = this.pageInfo
      await this.FETCH_ALGOS({
        page,
        pageSize,
        endpoint: this.endpoint
      })
    },
    cancel () {
      this.CLEAR_MESSAGES()
      this.RESET_STATE()
      this.$router.push({
        name: this.indexRouteName,
        params: {
          hotelId: this.$route.params.hotelId,
        }
      })
    },
    createOrUpdate () {
      this.CLEAR_MESSAGES()
      if (this.TOTAL_REWARDS_OFFERED === 0) {
        this.ADD_ERROR(
          new Error('The total number on-property rewards and marketplace rewards must be greater than zero (0).')
        )
        return
      }

      const nextRouteObject = this.FROM_ECM
        ? {
          name: userViews.CAMPAIGNS_EDIT,
          params: {
            hotelId: this.$route.params.hotelId,
            campaignId: this.$store.state.ecm.campaign.id
          },
          hash: this.getHash(this.campaign),
          query: {
            fromRam: true,
          }
        }
        : {
          name: this.indexRouteName,
          params: { hotelId: this.$route.params.hotelId }
        }
      this.CREATE_OR_UPDATE_ALGO({
        id: this.$route.params.algoId,
        nextRoute: nextRouteObject,
        endpoint: this.endpoint
      })
    }
  },
  async beforeRouteUpdate (to, from, next) {
    this.show = false
    await this.setup()
    this.show = true
    next()
  },

}
</script>

<style lang="sass">
  // tweaking class from WizardStep
  .laas-oprm-step-container
    padding: 0 24px 0px 60px !important

  // FIXME remove when replacing vuemat table
  #reward-algorithms-wizard
    .md-table-head
      z-index: 0 !important
</style>

<style lang="sass" scoped>
#reward-algorithms-wizard
  width: 100%
  height: auto
  color: black

#header
  font-size: 18px
  padding: 10px 0px 14px 10px

#footer
  padding-top: 10px
  padding-bottom: 20px

#stepper-container, #footer
  background-color: white

#stepper-container
  padding-top: 16px

.copy-container
  padding-right: 44px
  max-width: 600px
.copy-container-header
  font-size: 18px
  font-weight: bold
#override-rewards-subheader
  font-size: 14px
  font-weight: bold

#button-container
  display: flex
  justify-content: space-between
  padding: 4px 60px

#header-container
  max-width: 550px
</style>
