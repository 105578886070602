





























































import MerchantModal from '@/components/util/MerchantModal.vue'
import { mapGetters, mapActions } from 'vuex'
import OnPropertyRewardsTable from '@/components/hotel-dashboard/on-property-rewards-manager/OnPropertyRewardsTable.vue'

export default {
  name: 'OnPropertyBusinessesTable',
  components: {
    MerchantModal,
    OnPropertyRewardsTable
  },
  async mounted () {
    await this.FETCH_MERCHANTS({ oprList: true, fetchAll: true })
    this.setupListState()
    this.show = true
  },
  data () {
    return {
      show: false,
      showModal: false,
      modalError: '',
      editMerchant: undefined,
      listState: {
        // [merchant.id]: Boolean
        // don't know how many merchants at mount so have to populate
        // this object before rendering list
      },
      selectedMerchant: '',
      copy: {
        searchByBusinessPlaceholder: 'Search by business name...'
      }
    }
  },
  computed: {
    ...mapGetters('oprm', ['MERCHANTS', 'MERCHANT_IDS']),
    merchantNames (): Array<string> {
      return this.MERCHANTS.map(m => m.name)
    }
  },
  methods: {
    ...mapActions('oprm', ['FETCH_MERCHANTS']),
    setupListState (): void {
      this.listState = this.MERCHANTS.reduce((acc, merchant) => {
        acc[merchant.id] = false
        return acc
      }, {})
    },
    isActiveListItem (merchantId) {
      return this.listState[merchantId]
    },
    setEditMerchant (merchant): void {
      this.editMerchant = merchant
    },
    openModal (): void {
      this.showModal = true
    },
    closeModal (): void {
      this.showModal = false
      this.setEditMerchant(null)
    },
    showItem (merchantName: string): boolean {
      return this.selectedMerchant === merchantName || this.selectedMerchant === ''
    }
  }
}
