<template>
  <svg
    id="icon-shuttle"
    viewBox="0 0 469.483 469.484"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.shuttle}">

    <title id="title">shuttle icon</title>
    <desc id="desc">shuttle icon</desc>

    <g>
      <path d="M94.497,283.208c-25.8,0-46.715,20.916-46.715,46.716s20.915,
        46.716,46.715,46.716c25.801,0,46.716-20.916,46.716-46.716S120.298,
        283.208,94.497,283.208z M94.497,349.639c-10.871,0-19.715-8.844-19.715
        -19.715s8.844-19.716,19.715-19.716c10.871,0,19.716,8.845,19.716,
        19.716S105.368,349.639,94.497,349.639z"/>
      <path d="M374.983,283.208c-25.801,0-46.716,20.916-46.716,46.716s20.915,
        46.716,46.716,46.716c25.8,0,46.715-20.916,46.715-46.716S400.783,
        283.208,374.983,283.208z M374.983,349.639c-10.871,0-19.716-8.844
        -19.716-19.715s8.845-19.716,19.716-19.716s19.715,8.845,19.715,
        19.716S385.854,349.639,374.983,349.639z"/>
      <path d="M460.759,210.405l-41.021-18.904l-45.109-90.358l-13.42-8.3H15l-15,
        15v215.496l15,15h18.666c-0.57-3.365-0.883-6.816-0.883-10.34c0-34.03,
        27.685-61.715,61.715-61.715c34.03,0,61.716,27.686,61.716,61.715c0,
        3.523-0.313,6.976-0.883,10.34h158.821c-0.57-3.365-0.883-6.816-0.883
        -10.34c0-34.03,27.686-61.715,61.716-61.715c34.029,0,61.715,27.686,
        61.715,61.715c0,3.523-0.312,6.976-0.883,10.34h18.666l15-15v-99.311
        L460.759,210.405z M111.49,202.825l-52.414,0.071v-62.715h52.414
        V202.825z M207.492,202.825l-52.414,0.071v-62.715h52.414V202.825z
        M254.078,202.898v-62.717h78.649l31.256,62.606L254.078,202.898z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconShuttle',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
