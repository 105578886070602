import endpoints from '@/config/endpoints'
import QueryString from 'query-string'
import axios from 'axios'
import { sortByName, createAutocompleteObject } from '@/utils/misc'
import { ParserUtils } from '@/parsers/utils'
import { CradleHotelParser } from '@/parsers'

export const state = {
  fuxList: [],
  designSetList: [],
  designSetNameTaken: false,
  fuxPreviewCtx: undefined,
  fuxChannel: undefined,
  bulkEditHotelIds: [],
  designBulkEdit: {
    id: undefined,
    name: undefined,
    fux: {
      fux_id: undefined,
      fux_mab_enabled: false
    }
  },
  design: {
    fux_uuid: undefined,
    hotel_ids: [],
    hotel_group_ids: [],
    design_set_name: undefined,
    mab_enabled: false
  }
}

const initDesignState = {
  fux_uuid: undefined,
  hotel_ids: [],
  hotel_group_ids: [],
  design_set_name: undefined,
  mab_enabled: false
}

const initBulkEditState = {
  id: undefined,
  name: undefined,
  fux: {
    fux_id: undefined,
    fux_mab_enabled: false
  }
}

export const getters = {
  MODIFIED_HOTEL_NAMES: (state, getters, rootState, rootGetters) => (hotelIds) => {
    const hotelNamesArr = hotelIds.map(hotelId => rootGetters['hotel/hotelObjsByCradleId'][hotelId].name)
    return [...new Set(hotelNamesArr)]
  },
  MODIFIED_GROUP_NAMES (state, getters, rootState, rootGetters) {
    const hotelGroupList = rootGetters['hotel/hotelGroupList']
    console.log(hotelGroupList)
    return state.design.hotel_group_ids.map(groupId => {
      const idx = hotelGroupList.findIndex(group => group.id === groupId)
      return hotelGroupList[idx].name
    })
  },
  DESIGN_SET_AUTOCOMPLETE_LIST (state) {
    const opts = state.designSetList.map(fux => {
      return createAutocompleteObject({ data: fux, key: 'name' })
    })
    return opts.sort(sortByName)
  },
  FUX_AUTOCOMPLETE_LIST (state) {
    const opts = state.fuxList.map(fux => {
      return createAutocompleteObject({ data: fux, key: 'funnel_ux' })
    })
    return opts.sort(sortByName)
  },
  SAVE_BUTTON_ENABLED (state) {
    return state.designBulkEdit.id &&
      state.designBulkEdit.name &&
      state.designBulkEdit.fux.fux_id
  },
  DUPLICATION_BUTTON_ENABLED (state) {
    const isHotelIdsNotEmpty = state.design.hotel_ids?.length > 0
    const isHotelGroupIdsNotEmpty = state.design.hotel_group_ids?.length > 0

    return Boolean(state.design.fux_uuid && state.design.design_set_name && (isHotelIdsNotEmpty || isHotelGroupIdsNotEmpty))
  }
}

export const mutations = {
  SET_FUX_TO_DUPLICATE (state, fuxUuid) {
    state.design.fux_uuid = fuxUuid
  },
  SET_FUX_LIST (state, fuxList) {
    state.fuxList = fuxList
  },
  SET_DESIGN_SET_LIST (state, designSetList) {
    state.designSetList = designSetList
  },
  SET_DESIGN_NAME (state, setName) {
    state.design.design_set_name = setName
  },
  SET_FUX_PREVIEW_CTX (state, previewCtx) {
    state.fuxPreviewCtx = previewCtx
  },
  SET_FUX_CHANNEL (state, channel) {
    state.fuxChannel = channel
  },
  UPDATE_MAB_ENABLED (state, status) {
    state.design.mab_enabled = status
  },
  SET_BULK_EDIT_HOTEL_IDS (state, ids) {
    state.bulkEditHotelIds = ids
  },
  RESET_DESIGN_DUPLICATION (state) {
    state.design = initDesignState
  },
  RESET_BULK_EDIT (state) {
    state.designBulkEdit = initBulkEditState
  },
  SET_DESIGN_NAME_TAKEN (state, status) {
    state.designSetNameTaken = status
  },
  SET_HOTEL_GROUP_IDS (state, ids) {
    state.design.hotel_group_ids = ids
  },
  SET_HOTEL_IDS (state, ids) {
    state.design.hotel_ids = ids
  },
  UPDATE_BULK_EDIT_MAB_ENABLED (state, status) {
    state.designBulkEdit.fux.fux_mab_enabled = status
  },
  SET_BULK_EDIT_DESIGN_SET_ID (state, id) {
    state.designBulkEdit.id = id
  },
  SET_BULK_EDIT_FUX_ID (state, uuid) {
    state.designBulkEdit.fux.fux_id = uuid
  },
  SET_BULK_EDIT_NAME (state, name) {
    state.designBulkEdit.name = name
  },
}

export const actions = {
  async SUBMIT_DESIGN_DUPLICATE ({ state, rootState, dispatch }) {
    let response
    let error
    const duplicatePayload = state.design
    // the expected payload has either an array of hotel_group_ids or hotel_ids,
    // but not both, so delete one or the other when its length is > 0
    if (duplicatePayload.hotel_ids.length > 0) {
      delete duplicatePayload.hotel_group_ids
    } else {
      delete duplicatePayload.hotel_ids
    }
    try {
      response = await axios.post(
        endpoints.cradle.duplicateFuxRoute,
        duplicatePayload, {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
          }
        })
    } catch (err) {
      error = err.response.data
      dispatch('messages/ADD_ERROR', error, { root: true })
      return err
    }

    console.log('[SUBMIT DESIGN DUPLICATE]', response)
    dispatch('messages/ADD_SUCCESS', { message: 'Bulk duplication made succesfully.' }, { root: true })
    return response
  },
  async SUBMIT_BULK_EDIT ({ state, rootState, dispatch }) {
    let response
    let error
    const bulkEditPayload = state.designBulkEdit
    const designSetId = state.designBulkEdit.id
    const endpoint = `${endpoints.cradle.designSetRoute}${designSetId}/`

    try {
      response = await axios.patch(
        endpoint,
        bulkEditPayload, {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
          }
        })
    } catch (err) {
      error = err.response.data
      dispatch('messages/ADD_ERROR', error, { root: true })
      return err
    }

    console.log('[SUBMIT_BULK_EDIT]', response)
    dispatch('messages/ADD_SUCCESS', { message: 'Bulk edit made succesfully.' }, { root: true })
    return response
  },
  async FETCH_FUXES ({ commit, rootState, dispatch }, { searchTerm }) {
    const queryString = QueryString.stringify({
      search: searchTerm,
      fields: 'funnel_ux,uuid,design_set_name,context,hotel,channel'
    })
    const endpoint = `${endpoints.cradle.fuxRoute}?${queryString}`
    let response
    let error
    try {
      response = await axios.get(endpoint, {
        headers: {
          Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
        }
      })
    } catch (err) {
      error = err.response.data
      dispatch('messages/ADD_ERROR', error, { root: true })
      return err
    }

    console.log('[FETCH FUXES]', response.data)
    commit('SET_FUX_LIST', response.data.results)
  },
  async FETCH_DESIGN_SETS ({ commit, rootState, dispatch }, { searchTerm }) {
    const queryString = QueryString.stringify({
      search: searchTerm,
      fields: 'id,name,fux,hotel_ids'
    })
    const endpoint = `${endpoints.cradle.designSetRoute}?${queryString}`
    let response
    let error
    try {
      response = await axios.get(endpoint, {
        headers: {
          Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
        }
      })
    } catch (err) {
      error = err.response.data
      dispatch('messages/ADD_ERROR', error, { root: true })
      return err
    }

    commit('SET_DESIGN_SET_LIST', response.data.results)
  },
  async FETCH_HOTEL_DETAIL ({ commit, rootGetters, dispatch }, hotelId) {
    console.log(`[FETCH_HOTEL_DETAIL]: hotelId: ${hotelId}`)
    const endpoint = endpoints.cradle.hotelRoute + `?legacy_id=${hotelId}`
    let response
    let error
    try {
      response = await axios.get(endpoint, {
        headers: {
          Authorization: 'Bearer ' + rootGetters.TOKEN
        }
      })
    } catch (err) {
      error = err.response.data
      dispatch('messages/ADD_ERROR', error, { root: true })
      return err
    }
    console.log('[FETCH_HOTEL_DETAIL]', response)
    const hotelsObj = ParserUtils.getParsedObject({
      rawList: response.data.results || response.data,
      parser: CradleHotelParser.parseHotel,
      indexKey: 'legacyId',
    })
    const hotel = Object.values(hotelsObj)[0]
    commit('hotel/SET_CRADLE_HOTEL_OBJ', hotel, { root: true })
    return { error: undefined }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
