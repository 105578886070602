<template>
  <div id="promotions-container">
    <Header :copy="{ title: copy.title }">
      <StepperButton
        slot="button"
        :style="{ width: '236px' }"
        @clickEvent="navToPromotionsWizard"
        :disabled="false"
        :copy="copy.button"
      />
    </Header>
    <PromotionsTable />
  </div>
</template>

<script>
/**
  * contains:
  * table to render promotions
  * button to create new promotion
  * header component
  */
import PromotionsTable from '@/components/merchant-dashboard/PromotionsTable'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton'
import Header from '@/components/common/Header'

import { merchantViews } from '@/constants'
export default {
  name: 'PromotionsContainer',
  components: {
    PromotionsTable,
    StepperButton,
    Header,
  },
  data () {
    return {
      copy: {
        title: 'Promotions Manager',
        button: 'Create Promotion',
      }
    }
  },
  provide () {
    return {
      PromotionsContainer_navToPromotionsWizard: this.navToPromotionsWizard
    }
  },
  methods: {
    navToPromotionsWizard () {
      this.$router.push({
        name: merchantViews.MERCHANT_PROMOTIONS_WIZARD_NEW,
        params: {
          id: this.$route.params.id
        }
      })
    },
    async fetchPromotions () {
      if (!this.$store.state.isLoading) this.$store.dispatch('SET_IS_LOADING', true)
      await this.$store.dispatch('merchant/FETCH_PROMOTIONS')
      this.$store.dispatch('SET_IS_LOADING', false)
    },
  },
  created () {
    this.fetchPromotions()
  },
}
</script>

<style lang="sass" scoped>
@import '../navigation/menu-styles'

#promotions-container
  width: 100%
  height: auto
  background-color: white
</style>
