





















































































import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton.vue'
import Header from '@/components/common/Header.vue'
import Table from '@/components/table/Table.vue'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { userViews, } from '@/constants'
import { Campaign, PageInfo } from '@/types'
import WorkflowStatus from '@/components/hotel-dashboard/workflow-manager/WorkflowStatus.vue'
import { getMetricsRoute } from '@/utils/workflows'

const ACTIVE = 'Active'
const INACTIVE = 'Not Active'
const ECM = 'ecm'
const MESSAGES = 'messages'

export type WorkflowStatusStyle = {
  (arg: Campaign): {
    color: '#00C82C' | '';
    marginRight: string;
  };
}

export type WorkflowStatus = {
  (workflow: Campaign): 'Active' | 'Not Active';
}

export default {
  name: 'WorkflowTableContainer',
  components: {
    StepperButton,
    Header,
    Table,
    WorkflowStatus,
  },
  data () {
    return {
      columns: [
        {
          key: 'name',
          name: 'Name',
          sortable: false,
          alignment: 'flex-start',
          width: '300',
        },
        {
          key: 'status',
          name: '',
          sortable: false,
          alignment: 'center',
          width: '100',
        },
        {
          key: 'edit',
          name: '',
          sortable: false,
          alignment: 'center',
          width: '50',
        },
        {
          key: 'report',
          name: '',
          sortable: false,
          alignment: 'center',
          width: '50'
        }
      ],
      copy: {
        title: 'Workflow Manager',
        button: '<span class="pr-2">+</span>Create Workflow'
      },
    }
  },
  computed: {
    ...mapState(ECM, [
      'campaignFetchSuccess',
      'pageInfo'
    ]),
    ...mapGetters(ECM, [
      'WORKFLOWS_LIST',
      'GET_CAMPAIGN_TYPE',
    ]),
    getStatus (): WorkflowStatus {
      return (workflow: Campaign) => {
        return workflow.active ? ACTIVE : INACTIVE
      }
    },
    statusStyle (): WorkflowStatusStyle {
      return (workflow: Campaign) => {
        return {
          color: workflow.active ? '#00C82C' : '',
          marginRight: '10px'
        }
      }
    }
  },
  async mounted (): Promise<void> {
    this.SET_PAGE(1)
    this.SET_PAGE_INDEX(0)
    this.CLEAR_CAMPAIGNS()
    this.fetchPaginatedWorkflows()
  },
  beforeRouteUpdate (to, from, next): void {
    this.SET_PAGE(1)
    this.SET_PAGE_INDEX(0)
    this.CLEAR_CAMPAIGNS()
    this.fetchPaginatedWorkflows()
    next()
  },
  methods: {
    ...mapMutations(ECM, [
      'SET_PAGE',
      'SET_PAGE_SIZE',
      'SET_PAGE_INDEX',
      'CLEAR_CAMPAIGNS',
    ]),
    ...mapActions(ECM, [
      'FETCH_CAMPAIGNS',
      'FETCH_TRIGGER_TYPES',
      'TOGGLE_CAMPAIGN_ACTIVE'
    ]),
    ...mapActions(MESSAGES, [
      'ADD_ERROR',
      'ADD_SUCCESS',
      'CLEAR_ERRORS'
    ]),
    async toggleWorkflowActiveState (workflow: Campaign): Promise<void> {
      const resp = await this.TOGGLE_CAMPAIGN_ACTIVE({
        id: workflow.id,
        active: workflow.active
      })

      if (!resp.error) {
        const newWorkflowState = resp.data.data.active ? ACTIVE : INACTIVE
        this.ADD_SUCCESS({ message: `Your workflow is now ${newWorkflowState}.` })
      }
    },
    navToNewWorkflow (): void {
      console.log('nav to new workflow')
      this.$router.push({
        name: userViews.WORKFLOWS_NEW,
        params: {
          hotelId: this.$route.params.hotelId,
        }
      })
    },
    navToEditWorkflow (workflow: Campaign): void {
      console.log('nav to edit workflow', workflow)
      this.$router.push({
        name: userViews.WORKFLOWS_EDIT,
        params: {
          hotelId: this.$route.params.hotelId,
          workflowId: workflow.id,
        }
      })
    },
    navToMetrics (workflow: Campaign): void {
      this.$router.push({
        name: getMetricsRoute(workflow.category),
        params: {
          hotelId: this.$route.params.hotelId,
          workflowId: workflow.id
        }
      })
    },
    async fetchPaginatedWorkflows (): Promise<void> {
      const { page, pageSize } = this.pageInfo
      await this.FETCH_CAMPAIGNS({ page, pageSize, workflows: true })
    },
    updatePage (pageInfo: PageInfo): void {
      this.SET_PAGE(pageInfo.page)
      this.SET_PAGE_INDEX(pageInfo.index)
      this.fetchPaginatedWorkflows()
    },
    updatePageSize (pageSize): void {
      this.SET_PAGE_SIZE(pageSize)
      this.fetchPaginatedWorkflows()
    }
  },
}
