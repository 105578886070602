<template>
  <div>
    <div>{{ copy.body }}</div>
    <md-switch v-model="mabEnabled"> {{ mabStatusCopy }} </md-switch>
    <div class="laas-italic">{{ affectedProperties }}</div>
    <md-content id="selected-container" class="md-scrollbar">
      <div
        class="modified-list-item"
        v-for="name in modifiedNames"
        :key="name"
      >
        {{ name }}
      </div>
    </md-content>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'MabEnable',
  data () {
    return {
      copy: {
        body: 'Set the MAB of all funnel UX\'s in this design set',
        affectedProperties: 'Affected Properties',
        mabEnable: 'MAB Enable All',
        mabDisable: 'MAB Disable All'
      }
    }
  },
  computed: {
    ...mapState('designDuplication', [
      'designBulkEdit',
      'bulkEditHotelIds',
      'bulkEditSaveSuccess'
    ]),
    ...mapGetters('designDuplication', [
      'MODIFIED_HOTEL_NAMES'
    ]),
    modifiedNames () {
      return this.MODIFIED_HOTEL_NAMES(this.bulkEditHotelIds)
    },
    mabStatusCopy () {
      return this.designBulkEdit.fux.fux_mab_enabled
        ? this.copy.mabEnable
        : this.copy.mabDisable
    },
    mabEnabled: {
      get () {
        return this.designBulkEdit.fux.fux_mab_enabled
      },
      set () {
        return this.$store.commit('designDuplication/UPDATE_BULK_EDIT_MAB_ENABLED',
          !this.designBulkEdit.fux.fux_mab_enabled
        )
      }
    },
    affectedProperties () {
      return this.designBulkEdit.id ? `${this.copy.affectedProperties}: (${this.modifiedNames.length})` : ''
    }
  },
  watch: {
    bulkEditSaveSuccess (to, from) {
      if (to === true && from === false) this.$store.commit('designDuplication/SET_BULK_EDIT_HOTEL_IDS', [])
    }
  }
}
</script>

<style scoped lang="sass">
.modified-list-item
  color: black
</style>
