const MESA_SELF_SERVE_PROMOTION_ROUTE = '/api/v1/rewards/self-serve-promotion/'
const MESA_OPRM_PROMOTION_ROUTE = '/api/v1/rewards/self-serve-promotion-paged/'
const MESA_MARKETPLACE_PROMOTION_ROUTE = '/api/v1/rewards/self-serve-promotion-read-only/'
const MESA_SELF_SERVE_MERCHANT_ROUTE = '/api/v1/rewards/self-serve-merchant/'
const MESA_OPRM_MERCHANT_ROUTE = '/api/v1/rewards/self-serve-merchant-paged/'
const MESA_REGIONS_ROUTE = '/api/v1/rewards/regions'
const MESA_BOOKING_ENGINES_ROUTE = '/api/v1/rewards/merchant-booking-engine/'
const CRADLE_HOTEL_ROUTE = '/api/v1/hotels/dash/'
const CRADLE_FUX_ROUTE = '/api/v1/hotels/dash/fux/'
const CRADLE_DUPLICATE_FUX_ROUTE = '/api/v1/hotels/dash/design-set/fux/duplicate/'
const CRADLE_DESIGN_SET_ROUTE = '/api/v1/hotels/dash/design-set/'
const CRADLE_TEMPLATE_ROUTE = '/api/v1/hotels/dash/templates/'
const CRADLE_EMAIL_MARKETING_REWARD_ALGORITHM_ROUTE = '/api/v1/hotels/email-marketing-algorithm/'
const CRADLE_HOTEL_REWARD_ALGORITHM_ROUTE = '/api/v1/hotels/dash/hotel-reward-algorithm/'
const CRADLE_HOTEL_REWARD_ALGORITHM_BULK_SHARE_UPDATE_ROUTE = '/api/v1/hotels/dash/hotel-reward-algorithm/bulk-update/'
const CRADLE_EMAIL_TEMPLATE_ROUTE = '/api/v1/email-marketing/template/'
const CRADLE_SEGMENT_ROUTE = '/api/v1/email-marketing/segment/'
const EDITOR_IFRAME = 'editor-iframe.html'
export default {
  mesa: {
    selfServePromotionRoute: `${process.env.VUE_APP_MESA_HOST}${MESA_SELF_SERVE_PROMOTION_ROUTE}`,
    oprmPromotionRoute: `${process.env.VUE_APP_MESA_HOST}${MESA_OPRM_PROMOTION_ROUTE}`,
    marketplacePromotionRoute: `${process.env.VUE_APP_MESA_HOST}${MESA_MARKETPLACE_PROMOTION_ROUTE}`,
    regionsRoute: `${process.env.VUE_APP_MESA_HOST}${MESA_REGIONS_ROUTE}`,
    bookingEnginesRoute: `${process.env.VUE_APP_MESA_HOST}${MESA_BOOKING_ENGINES_ROUTE}`,
    selfServeMerchantRoute: `${process.env.VUE_APP_MESA_HOST}${MESA_SELF_SERVE_MERCHANT_ROUTE}`,
    oprmMerchantRoute: `${process.env.VUE_APP_MESA_HOST}${MESA_OPRM_MERCHANT_ROUTE}`
  },
  cradle: {
    hotelRoute: `${process.env.VUE_APP_CRADLE_HOST}${CRADLE_HOTEL_ROUTE}`,
    fieldRoute: `${process.env.VUE_APP_CRADLE_HOST}/api/v1/hotels/dash/fields/`,
    fuxRoute: `${process.env.VUE_APP_CRADLE_HOST}${CRADLE_FUX_ROUTE}`,
    duplicateFuxRoute: `${process.env.VUE_APP_CRADLE_HOST}${CRADLE_DUPLICATE_FUX_ROUTE}`,
    designSetRoute: `${process.env.VUE_APP_CRADLE_HOST}${CRADLE_DESIGN_SET_ROUTE}`,
    hotelGroupsRoute: `${process.env.VUE_APP_CRADLE_HOST}${CRADLE_HOTEL_ROUTE}hotel-groups/`,
    bookingEnginesRoute: `${process.env.VUE_APP_CRADLE_HOST}${CRADLE_HOTEL_ROUTE}booking-engines/`,
    templateRoute: `${process.env.VUE_APP_CRADLE_HOST}${CRADLE_TEMPLATE_ROUTE}`,
    emailMarketingRewardAlgorithmRoute: `${process.env.VUE_APP_CRADLE_HOST}${CRADLE_EMAIL_MARKETING_REWARD_ALGORITHM_ROUTE}`,
    hotelRewardAlgorithmRoute: `${process.env.VUE_APP_CRADLE_HOST}${CRADLE_HOTEL_REWARD_ALGORITHM_ROUTE}`,
    hotelRewardAlgorithmBulkShareUpdate: `${process.env.VUE_APP_CRADLE_HOST}${CRADLE_HOTEL_REWARD_ALGORITHM_BULK_SHARE_UPDATE_ROUTE}`,
    emailTemplateRoute: `${process.env.VUE_APP_CRADLE_HOST}${CRADLE_EMAIL_TEMPLATE_ROUTE}`,
    segmentRoute: `${process.env.VUE_APP_CRADLE_HOST}${CRADLE_SEGMENT_ROUTE}`,
    // event based emails
    eventType: `${process.env.VUE_APP_CRADLE_HOST}/api/v1/email-marketing/event-campaign/event-type/`,
    eventCampaign: `${process.env.VUE_APP_CRADLE_HOST}/api/v1/email-marketing/event-campaign/`,
    eventNodes: `${process.env.VUE_APP_CRADLE_HOST}/api/v1/email-marketing/event-campaign/event-node/`,
    eventNodeFile: `${process.env.VUE_APP_CRADLE_HOST}/api/v1/email-marketing/event-campaign/event-node-file/`
  },
  valley: {
    flexRoute: `${process.env.VUE_APP_VALLEY_HOST}/api/v1/transactions/flex/`,
    flexRoute2: `${process.env.VUE_APP_VALLEY_HOST}/api/v2/transactions/flex/`

  },
  editor: {
    iframeSrc: ({ port }) => `${process.env.VUE_APP_DOMAIN}/${EDITOR_IFRAME}`.replace('__PORT__', port)
  }
}
