import { render, staticRenderFns } from "./DrawerItem.vue?vue&type=template&id=5509d935&scoped=true&"
import script from "./DrawerItem.vue?vue&type=script&lang=ts&"
export * from "./DrawerItem.vue?vue&type=script&lang=ts&"
import style0 from "./DrawerItem.vue?vue&type=style&index=0&id=5509d935&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5509d935",
  null
  
)

export default component.exports