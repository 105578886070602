import roles from '@/roles'
import { lookerConfig } from '@/config'
import { userTypes, productTypes } from '@/constants'
import { getHotelId } from '@/lib/looker/embedPayloadHelpers.ts'

const FROM_ECM = 'fromEcm'
const FROM_EDM = 'fromEdm'
const FROM_RAM = 'fromRam'
const CREATE_COPY = 'createCopy'

export default {
  /**
   * product type
   */
  IS_LITE (state) {
    return Boolean(state.primaryHotel && state.primaryHotel.productType === productTypes.LITE)
  },
  IS_RETAIN (state) {
    return Boolean(state.primaryHotel && state.primaryHotel.productType === productTypes.RETAIN)
  },
  IS_CONVERT (state) {
    return Boolean(state.primaryHotel && state.primaryHotel.productType === productTypes.CONVERT)
  },
  IS_HOTEL_RETAIN: (state, getters) => {
    return getters.IS_HOTEL_USER && getters.IS_RETAIN
  },
  /**
   * property type
   */
  IS_VANITY_PROPERTY: (state) => {
    console.log('[IS_VANITY_PROPERTY]', state.primaryHotel)
    return Boolean(state.primaryHotel && state.primaryHotel.isVanityProperty)
  },
  /**
   * reporting menu links
   */
  SHOW_GROUP_REPORTING: (state, getters) => {
    if (getters.IS_RETAIN || getters.IS_LITE) return false
    return (getters.IS_ADMIN || getters.IS_GROUP_USER) &&
      getters.IS_CONVERT
  },
  SHOW_RETAIN_REPORTING: (state, getters) => {
    if (getters.IS_CONVERT || getters.IS_LITE) return false
    return (getters.IS_ADMIN || getters.IS_GROUP_USER) &&
      getters.IS_RETAIN
  },
  /**
   * user types
   */
  IS_ADMIN: state => {
    return Boolean(state.userProfile && state.userProfile.type === userTypes.ADMIN)
  },
  IS_HOTEL_USER: state => {
    return Boolean(state.userProfile && state.userProfile.type === userTypes.HOTEL)
  },
  IS_GROUP_USER: state => {
    return Boolean(state.userProfile && state.userProfile.type === userTypes.GROUP)
  },

  INITIAL_VIEW: state => () => {
    const { userProfile } = state
    const role = userProfile && roles[userProfile.type]
    if (role) {
      return role.initialView(userProfile)
    } else {
      return { view: '404' }
    }
  },
  VIEW_ALLOWED: state => view => {
    const { userProfile } = state
    const role = userProfile && roles[userProfile.type]
    if (role) {
      return role.views({ view, userProfile })
    }
    return false
  },
  LOOKER_EMBED_URL: state => {
    console.log('[LOOKER_EMBED_URL getter] state.route.name', state.route.name)
    return () => {
      if (state.route && state.route.name) {
        const url = state.lookerEmbedUrls[state.route.name] // string | object
        if (!url) return

        if (typeof url === 'string') {
          return url
        }

        const productType = state.primaryHotel ? state.primaryHotel.productType : '' // get retain or convert here

        return productType ? url[productType] : undefined
      }
    }
  },
  // FIXME we can remove `route` from arguments
  // and reference state.route
  LOOKER_SSO_PAYLOAD: (state, _, rootState) => (route) => {
    let id
    let hotelGroupId
    if (state.userProfile.type === userTypes.GROUP) {
      id = [state.primaryHotel.hotelGroupId] // backward compatibility with dash_validate_user
      hotelGroupId = state.primaryHotel.hotelGroupId
    } else if (state.userProfile.type === userTypes.HOTEL) {
      id = state.primaryHotel.id
      hotelGroupId = state.primaryHotel.hotelGroupId
    } else if (state.userProfile.type === userTypes.MERCHANT) {
      id = rootState.merchant.merchantId
      hotelGroupId = 0
    } else {
      id = userTypes.ADMIN
      hotelGroupId = state.primaryHotel.hotelGroupId
    }
    console.log('LOOKER_SSO_PAYLOAD hotelGroupId', hotelGroupId)

    const hotelId = getHotelId({
      route,
      primaryHotel: state.primaryHotel,
      cradleHotelsObj: state.hotel.cradleHotelsObj
    })
    console.log('LOOKER_SSO_PAYLOAD hotelId', hotelId)

    const merchantId = rootState.merchant.merchantId || 0
    console.log('LOOKER_SSO_PAYLOAD merchantId', merchantId)

    const customerId = state.route.query && state.route.query.customer_id
    console.log('LOOKER_SSO_PAYLOAD customerId', customerId)

    const workflowId = state.route.params?.workflowId
    console.log('LOOKER_SSO_PAYLOAD workflowId', workflowId)

    const payload = {
      user: {
        id, // this is used in dash_validate_user.validate() to ensure the user has access per cognito attributes + user type
        type: state.userProfile.type,
        username: state.userProfile.email,
        cognito_uuid: state.authController?.user?.username
      },
      hotel_id: hotelId.toString(), // used as attr-filter in looker embed
      merchant_id: merchantId.toString(), // used as attr-filter in looker embed
      hotel_group_ids: hotelGroupId.toString(), // used as attr-filter in looker embed
      embed_domain: lookerConfig.embedDomain,
      customer_id: customerId,
      workflow_id: workflowId
    }

    return payload
  },
  SHOW_CTRLEXP_PAYLOAD: state => () => {
    const payload = {
      hotel_id: state.primaryHotel.id.toString()
    }
    return payload
  },
  TOKEN: (state) => {
    return state.authController.getCognitoToken()
  },
  HOTEL_ID: state => state.primaryHotel && state.primaryHotel.id,
  CRADLE_HOTEL_ID: state => state.primaryHotel && state.primaryHotel.cradleId,
  HOTEL_ID_ALLOWED: state => id => {
    if (state.hotel) {
      return Object.keys(state.hotel.cradleHotelsObj).indexOf(id) > -1
    }
  },
  HOTEL_IS_LIVE: state => state.primaryHotel && state.primaryHotel.active,
  PRODUCT_TYPE (state) {
    return state.primaryHotel && state.primaryHotel.productType
  },
  HOTEL_GROUP_IS_RETAIN: (state, getters) => {
  /**
   * admins: always true
   * hotel: check hotel group productType on primay hotel
   * group: same as hotel (can only be associated with one group at a time)
   */
    if (getters.IS_ADMIN) return true
    return state.primaryHotel && state.primaryHotel.productType === productTypes.RETAIN
  },
  FROM_ECM (state) {
    if (typeof state.route.query[FROM_ECM] === 'string') {
      return state.route.query[FROM_ECM] === 'true'
    }
    return state.route.query[FROM_ECM]
  },
  FROM_EDM (state) {
    if (typeof state.route.query[FROM_EDM] === 'string') {
      return state.route.query[FROM_EDM] === 'true'
    }
    return state.route.query[FROM_EDM]
  },
  FROM_RAM (state) {
    if (typeof state.route.query[FROM_RAM] === 'string') {
      return state.route.query[FROM_RAM] === 'true'
    }
    return state.route.query[FROM_RAM]
  },
  CREATE_COPY (state, getters, rootState) {
    if (typeof rootState.route.query[CREATE_COPY] === 'string') {
      return rootState.route.query[CREATE_COPY] === 'true'
    }
    return rootState.route.query[CREATE_COPY]
  },
  HSS_ACCESS (state, getters) {
    return getters.IS_ADMIN || state.userProfile.hssEditorAccess
  },
  REPORTING_ACCESS (state, getters) {
    return getters.IS_ADMIN || state.userProfile.reportingAccess
  },
  EDM_ACCESS (state, getters) {
    return getters.IS_ADMIN || state.userProfile.edmAccess
  },
  OPRM_ACCESS (state, getters) {
    return getters.IS_ADMIN || state.userProfile.oprmAccess
  },
  REWARD_ALGO_ACCESS (state, getters) {
    return getters.IS_ADMIN || state.userProfile.rewardAlgoAccess
  },
  FLEX_ACCESS (state, getters) {
    return getters.IS_ADMIN || state.userProfile.flexAccess
  },
  LOYALTY_PORTAL_URL (state) {
    if (state.primaryHotel?.loyaltyPortalUrl) {
      let baseUrl = state.primaryHotel.loyaltyPortalUrl

      /**
       * will look for local storage override in development mode.
       * developer can use this to point at local version of loyalty portal.
       */
      if (process.env.VUE_APP_MODE === 'development') {
        // TODO may not be supported on safari
        const override = window.localStorage.getItem('loyalty_portal_url')
        if (override) {
          baseUrl = override
        }
      }

      // loyalty portal redirects from '/' to '/home'
      const url = new URL('home', baseUrl)
      url.searchParams.append('editor', true)
      url.searchParams.append('dash_env', process.env.VUE_APP_MODE)
      if (process.env.VUE_APP_MODE === 'development') {
        url.searchParams.append('dev_port', window.location.port)
      }
      url.searchParams.append('app', 'lp')
      url.searchParams.append('hotel_id', state.primaryHotel.id)

      return url.href
    }

    return ''
  }
}
