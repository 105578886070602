


































































































































import Paginator from '@/components/common/Paginator.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import Button from '@/components/button/Button.vue'
import ActionEllipses from '@/components/icons/ActionEllipses.vue'
import Modal from '@/components/common/Modal.vue'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { userViews, campaignStateOptions, statusColors, campaignEditorHashOptions, menuStyles, operations, campaignTypeOptions } from '@/constants'
import { TableHeaderUtils } from '@/utils/table-headers'
import moment from 'moment'
import { CampaignUtils } from '@/utils/campaign'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton.vue'
import { Campaign, Operation, CampaignState } from '@/types'

const ECM = 'ecm'
const { VIEW_EMAIL_DESIGN } = campaignEditorHashOptions
const {
  DRAFT,
  SCHEDULED,
  ACTIVE,
  SENT,
  ERROR_NO_SENDER,
  ERROR_EMPTY_SEGMENT,
  ERROR_SENDING
} = campaignStateOptions
const { TIME, EVENT } = campaignTypeOptions

export type OperationOption = {
  label: Operation;
  // invoked on click event
  method: (arg: Campaign) => null;
  // used to filter out options that will not be rendered
  show: (arg: Campaign) => boolean;
}

export default {
  name: 'CampaignTable',
  components: {
    'md-table-pagination': Paginator,
    StepperButton,
    Modal,
    Dropdown,
    Button,
    ActionEllipses
  },
  data () {
    return {
      // set ID for modal to use in request to toggle campaign.active
      deactivateId: undefined,
      statusColors,
      showDeactivateModal: false,
      copy: {
        emptyStateButton: 'Create Campaign',
        empty: 'No Campaigns.',
        modal: {
          deactivate: {
            body: 'Deactivate this campaign?',
            leave: 'No',
            stay: 'Yes',
          },
        },
      },
      menuStyles,
      icons: {
        readOnly: 'remove_red_eye',
        edit: 'edit'
      },
      labels: {
        action: 'Action',
        name: 'Campaign Name',
        trigger: 'Trigger',
        status: 'Status',
        updated: 'Updated On',
        access: 'Access'
      },
      actionsIconWidth: 3,
      operations: {
        label: 'Operations',
        options: [
          {
            label: operations.TEST,
            method: this.navToTestSection,
            show: () => true
          },
          {
            label: operations.LAUNCH,
            method: this.navToEdit,
            show: this.showLaunchToggle
          },
          {
            label: operations.DEACTIVATE,
            method: this.setupDeactivateModal,
            show: this.showDeactivateToggle
          }
        ] as OperationOption[],
        button: {
          type: 'default',
          width: 'auto',
          size: 'round',
        }
      }
    }
  },
  computed: {
    ...mapState(ECM, [
      'campaignFetchSuccess',
      'pageInfo'
    ]),
    ...mapGetters(ECM, [
      'CAMPAIGNS_LIST',
      'GET_CAMPAIGN_TYPE',
      'GET_CAMPAIGN_TRIGGER'
    ]),
    actionsColor () {
      return this.$theme.colors.gray['800']
    },
    dropdownOptionsStyles () {
      return {
        height: '1.5em'
      }
    }
  },
  async mounted (): Promise<void> {
    this.FETCH_TRIGGER_TYPES()
    this.fetchPaginatedCampaigns()
  },
  async beforeRouteUpdate (to, from, next): Promise<void> {
    await Promise.all([
      this.FETCH_TRIGGER_TYPES(),
      this.fetchPaginatedCampaigns(),
    ])
    next()
  },
  methods: {
    ...mapMutations(ECM, [
      'SET_PAGE',
      'SET_PAGE_SIZE',
    ]),
    ...mapActions(ECM, [
      'FETCH_CAMPAIGNS',
      'FETCH_TRIGGER_TYPES',
      'TOGGLE_CAMPAIGN_ACTIVE'
    ]),
    ...mapActions('messages', [
      'ADD_SUCCESS'
    ]),
    isEventBased: CampaignUtils.isEventBased,
    async deactivate (): Promise<void> {
      const resp = await this.TOGGLE_CAMPAIGN_ACTIVE({ id: this.deactivateId, active: false })
      if (!resp.error) {
        this.ADD_SUCCESS({ message: 'Your campaign is deactivated.' })
      }
      this.closeDeactivateModal()
      this.setDeactivateId(null)
    },
    renderDeactivateModal (): void {
      this.showDeactivateModal = true
    },
    closeDeactivateModal (): void {
      this.showDeactivateModal = false
    },
    getUpdatedOn: TableHeaderUtils.getUpdatedOn as () => string,
    getName: TableHeaderUtils.getName as () => string,
    getHash: CampaignUtils.getHash as () => string,
    getScheduleDatetime (campaign: Campaign): string {
      if (this.isEventBased(campaign)) {
        return ''
      }
      if (!campaign.dag?.param) return ''
      return moment(campaign.dag.param).format('ddd DD MMM YYYY, LT')
    },
    getIcon (campaign: Campaign): string | null {
      if (campaign.type === EVENT) {
        return this.icons.edit
      } else if (campaign.type === TIME) {
        return [DRAFT, SCHEDULED].indexOf(campaign.state) > -1
          ? this.icons.edit
          : this.icons.readOnly
      } else {
        return null
      }
    },
    navToEdit (campaign: Campaign): void {
      this.$router.push({
        hash: this.getHash(campaign),
        name: userViews.CAMPAIGNS_EDIT,
        params: {
          hotelId: this.$route.params.hotelId,
          campaignId: campaign.id
        }
      })
    },
    navToNew (): void {
      this.$router.push({
        name: userViews.CAMPAIGNS_NEW,
        params: {
          hotelId: this.$route.params.hotelId
        }
      })
    },
    updatePage (pageNumber: number): void {
      this.SET_PAGE(pageNumber)
      this.fetchPaginatedCampaigns()
    },
    updatePageSize (pageSize: number): void {
      this.SET_PAGE_SIZE(pageSize)
      this.fetchPaginatedCampaigns()
    },
    async fetchPaginatedCampaigns (): Promise<void> {
      const { page, pageSize } = this.pageInfo
      await this.FETCH_CAMPAIGNS({ page, pageSize })
    },
    navToTestSection (campaign: Campaign): void {
      this.$router.push({
        name: userViews.CAMPAIGNS_EDIT,
        params: {
          hotelId: this.$route.params.hotelId,
          campaignId: campaign.id,
        },
        hash: `#${VIEW_EMAIL_DESIGN}`,
        query: { fromCampaignTestTable: true }
      })
    },
    getStatusColor (campaign: Campaign): string {
      const state = this.getCampaignState(campaign)
      return this.statusColors[state]
    },
    showDeactivateToggle (campaign: Campaign): boolean {
      if ([DRAFT, SENT].includes(campaign.state)) {
        return false
      }
      return campaign.active
    },
    showLaunchToggle (campaign: Campaign): boolean {
      if (campaign.type === TIME) {
        return false
      }
      return !campaign.active
    },
    /**
     * event based campaign is either ACTIVE or DRAFT.
     */
    getStatus (campaign: Campaign): CampaignState {
      if (campaign.type === TIME) {
        return campaign.state
      }
      return campaign.active ? ACTIVE : DRAFT
    },
    getCampaignState (campaign: Campaign): string {
      let state
      if (campaign.type === EVENT) {
        state = campaign.active ? ACTIVE : DRAFT
      } else if (campaign.type === TIME) {
        state = campaign.state
      } else if ([ERROR_NO_SENDER, ERROR_EMPTY_SEGMENT, ERROR_SENDING].includes(campaign.state)) {
        state = campaign.state
      } else {
        console.error(`Cannot get state for campaign type: ${campaign.type} `)
        state = ''
      }
      return state
    },
    setDeactivateId (id: number | void): void {
      this.deactivateId = id
    },
    getTrigger (campaign: Campaign): string {
      const id = campaign.dag?.triggerTypeId
      if (!id) return ''
      const trigger = this.GET_CAMPAIGN_TRIGGER({ id })
      if (!trigger) return ''
      return trigger.copy || ''
    },
    setupDeactivateModal (campaign: Campaign): void {
      this.setDeactivateId(campaign.id)
      this.renderDeactivateModal(campaign)
    },
    /**
     * filter the list of options by first executing the `show` function in each config.
     * provide Dropdown with a filtered list because using conditional rendering
     * on the option slot does not keep the option's outer contents from rendering.
     */
    getOptions (campaign: Campaign): OperationOption[] {
      return this.operations.options.filter(option => {
        return option.show && option.show(campaign)
      })
    }
  },
}
