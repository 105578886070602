import {
  CampaignState,
  CampaignType,
  CampaignTrigger,
  Operation,
  UTMEventName
} from '@/types'

// do not add 0 to this list
export const FALSY = [undefined, null, '']

export const appTypes = {
  ADMIN: 'admin',
  DASHBOARD: 'dashboard',
  MERCHANT: 'merchant',
}

export const userTypes = {
  ADMIN: 'admin',
  GROUP: 'group',
  HOTEL: 'hotel',
  MERCHANT: 'merchant',
}

export const cognitoGroups = {
  admin: 'admin',
  hotel: 'hotels',
  group: 'hotels',
  merchant: 'merchant'
}

// values must be valid as url routes/params
// NOTE: for hotel dashboard
export const userViews = {
  DASHBOARD: 'dashboard', // index
  ACCOUNT: 'account',
  SPLIT_TESTS: 'split-tests',
  GROUP: 'group',
  DESIGN: 'design',
  EDITOR: 'editor',
  EDITOR_NEW: 'editor-new',
  FUNNEL_UXS: 'funnel-uxs',
  OP_REWARDS: 'on-property-rewards',
  OP_REWARDS_WIZARD_NEW: 'on-property-rewards-new',
  OP_REWARDS_WIZARD_EDIT: 'on-property-rewards-edit',
  REWARD_ALGOS: 'email-marketing-reward-algorithms',
  REWARD_ALGOS_WIZARD_NEW: 'email-marketing-reward-algorithms-new',
  REWARD_ALGOS_WIZARD_EDIT: 'email-marketing-reward-algorithms-edit',
  HOTEL_REWARD_ALGOS: 'hotel-reward-algorithms',
  HOTEL_REWARD_ALGOS_WIZARD_NEW: 'hotel-reward-algorithms-new',
  HOTEL_REWARD_ALGOS_WIZARD_EDIT: 'hotel-reward-algorithms-edit',
  EMAIL_DESIGNS: 'email-designs',
  EMAIL_DESIGNS_NEW: 'email-designs-new',
  EMAIL_DESIGNS_EDIT: 'email-designs-edit',
  CAMPAIGNS: 'campaigns',
  CAMPAIGNS_NEW: 'campaigns-new',
  CAMPAIGNS_EDIT: 'campaigns-edit',
  WORKFLOWS: 'workflows',
  WORKFLOWS_NEW: 'workflows-new',
  WORKFLOWS_EDIT: 'workflows-edit',
  WORKFLOWS_METRICS_EMAIL_AND_OFFER: 'workflows-metrics-email-and-offer',
  WORKFLOWS_METRICS_EMAIL_ONLY: 'workflows-metrics-email-only',
  WORKFLOWS_METRICS_OFFER_ONLY: 'workflows-metrics-offer-only',
  WORKFLOWS_METRICS_EMAIL_DESIGN_TEST: 'workflows-metrics-email-design-test',
  FLEX_NEW: 'flex-new',
  FLEX_REPORTING: 'flex-reporting',
  // v2
  REWARD_METRICS: 'reward-metrics',
  HOMEPAGE: 'dashboard',
  REVENUE_METRICS: 'revenue-metrics',
  PROGRAM_ENGAGEMENT: 'program-engagement',
  UPCOMING_BOOKINGS: 'upcoming-bookings',
  ROI: 'roi',
  ON_DEMAND_METRICS: 'on-demand-metrics',
  // retain reporting
  GROWTH_SCALE: 'growth-scale',
  PROGRAM_IMPACT: 'program-impact',
  PROGRAM_DEMOGRAPHICS: 'program-demographics',
  LOYALTY_MEMBERS: 'loyalty-members',
  MEMBER_PROFILE: 'member-profile',
  LOYALTY_TRAFFIC: 'loyalty-traffic',

  // external links
  LOYALTY_PORTAL_EDITOR: 'loyalty-portal-editor',
}

// values must be valid as url routes/params
export const merchantViews = {
  MERCHANT: 'merchant', // index
  MERCHANT_PROMOTIONS_WIZARD: 'merchant-promotion',
  MERCHANT_PROMOTIONS: 'merchant-promotions',
  MERCHANT_PROMOTIONS_WIZARD_NEW: 'merchant-promotions-new',
  MERCHANT_PROMOTIONS_WIZARD_EDIT: 'merchant-promotions-edit',
  MERCHANT_ANALYTICS: 'merchant-analytics',
  MERCHANT_ACCOUNT: 'merchant-account',
  MERCHANT_ACCOUNT_NEW: 'merchant-account-new',
}

export const adminViews = {
  ADMIN: 'admin',
  USERS: 'users',
  BULK_UPDATE: 'bulk-update',
  BULK_DESIGN_DUPLICATION: 'bulk-design-duplication',
  BULK_DESIGN_EDITOR: 'bulk-design-editor',
}

export const createEnvs = {
  development: 'dev',
  production: 'prod',
  test: 'test'
}

export const steps = {
  STEP_1: 'step1',
  STEP_2: 'step2',
  STEP_3: 'step3',
  STEP_4: 'step4',
  STEP_5: 'step5',
  STEP_6: 'step6',
  STEP_7: 'step7'
}

export const tabs = {
  TAB_LEFT: 'tab-left',
  TAB_CENTER: 'tab-center',
  TAB_RIGHT: 'tab-right'
}

export const ctxOpts = {
  HOMEPAGE: 'hp',
  BOOKING_ENGINE: 'be',
  CONFIRMATION_PAGE: 'cp',
}

export const algoRewardOverrideOpts = {
  WHITELIST: 'whitelist',
  EXCLUDE: 'exclude'
}

export const emailDesignTypes = {
  TEMPLATE: 'tp',
  SINGLE_USE: 'su'
}

export const edmHashOptions = {
  SETTINGS: 'settings',
  BUILDER: 'builder',
  TEST: 'test'
}

export const bddHashOptions = {
  DUPLICATION: 'duplication',
  EDITOR: 'editor',
}

export const campaignEditorHashOptions = {
  SELECT_EMAIL_DESIGN: 'select-email-design',
  VIEW_EMAIL_DESIGN: 'view-email-design'
}

export const SCHEDULED_SEND = 'Scheduled Send' as CampaignTrigger
export const SEND_IMMEDIATELY = 'Send Immediately' as CampaignTrigger

export const campaignStateOptions = {
  SENT: 'sent',
  SCHEDULED: 'scheduled',
  DRAFT: 'draft',
  PENDING: 'pending',
  ERROR_NO_SENDER: 'error no sender',
  ERROR_EMPTY_SEGMENT: 'error empty segment',
  ERROR_SENDING: 'error sending',
  /**
   * active will never be the value of campaign.state
   * it is needed for rendering the status of an event-based email
   * which contains a boolean `active` field
   */
  ACTIVE: 'active',
} as Record<string, CampaignState>

export const campaignTypeOptions = {
  TIME: 'time',
  EVENT: 'event'
} as Record<string, CampaignType>

export const operations = {
  TEST: 'test',
  LAUNCH: 'launch',
  DEACTIVATE: 'deactivate'
} as Record<string, Operation>

// TODO: refactor both colors & status colors
export const colors = {
  enabled: '#6DD400',
  inactive: '#CDCDCD', // gray-200
  pending: '#FFAA46',
  error: '#F00',
}

export const statusColors = {
  [campaignStateOptions.DRAFT]: '#d8d8d8',
  [campaignStateOptions.SCHEDULED]: '#f7b500',
  [campaignStateOptions.ACTIVE]: colors.enabled,
  [campaignStateOptions.PENDING]: '#f7b500',
  [campaignStateOptions.SENT]: colors.enabled,
  [campaignStateOptions.ERROR_NO_SENDER]: colors.error,
  [campaignStateOptions.ERROR_EMPTY_SEGMENT]: colors.error,
  [campaignStateOptions.ERROR_SENDING]: colors.error,
}

export const campaignMergeTags = {
  CTA_LINK: '{{cta_link}}',
  REWARDS_BLOCK: '{{#each rewards}}',
  REDEMPTION_REWARDS_BLOCK: '{{#each redemption_rewards}}',
}

export const pageSizeOptions = [
  { id: 10, label: '10' },
  { id: 20, label: '20' },
  { id: 50, label: '50' },
  { id: 100, label: '100' },
]

export const productTypes = {
  RETAIN: 'rn',
  LITE: 'le',
  CONVERT: 'ct',
}

export const menuStyles = {
  height: 45,
}

export const OPTIONS_POSITIONS = {
  RIGHT: 'right',
  LEFT: 'left'
}

export const INPUT_TYPES = {
  BUTTON: 'button',
  CHECKBOX: 'checkbox',
  COLOR: 'color',
  DATE: 'date',
  DATETIME: 'datetime-local',
  EMAIL: 'email',
  FILE: 'file',
  HIDDEN: 'hidden',
  IMAGE: 'image',
  MONTH: 'month',
  NUMBER: 'number',
  PASSWORD: 'password',
  RADIO: 'radio',
  RANGE: 'range',
  RESET: 'reset',
  SEARCH: 'search',
  SUBMIT: 'submit',
  TEL: 'tel',
  TEXT: 'text',
  TIME: 'time',
  URL: 'url',
  WEEK: 'week',
}

export const MODULE_STRINGS = {
  ECM: 'ecm',
  RAM: 'ram',
}

export const OFFER_TYPE_DEFAULT_COPY_MAP = {
  Birthdate: 'Your Birthday Reward',
  'Membership Anniversary': 'Your Anniversary Reward',
  'Profile Completion': 'Profile Completion Offer',
  'Account Confirmed': 'Account Confirmed Reward',
  'Customer Enrolls': 'Your Welcome Offers',
  'Number of Bookings Milestone': 'You\'ve hit a booking milestone!',
  'Number of Room Nights Milestone': 'You\'ve hit a room night milestone!',
  'Guest Lifetime Value Milestone': 'You\'ve hit a spending milestone!',
  'Number of Unique Hotel Stays Milestone': 'You\'ve hit a unique hotel stay milestone!',
}

export const UTM_EVENT_NAMES: UTMEventName[] = [
  'Has UTM Medium',
  'Has UTM Campaign',
  'Has UTM Source',
]

export const MILESTONE_NAMES = {
  BOOKING: 'Number of Bookings Milestone',
  ROOM_NIGHTS: 'Number of Room Nights Milestone',
  LTV: 'Guest Lifetime Value Milestone',
  UHS: 'Number of Unique Hotel Stays Milestone'
}

export const BOOKING_TRIGGER_WITH_SECTION = {
  SELECT_SPECIFIC_REWARD: 'Selected a Specific Reward'
}

export const RELEASE_TYPE_OPTIONS = {
  AUTO_RELEASE: 'au',
  AUTO_SELECT: 'as',
  FLEX_RELEASE: 'fx'
}
