<template>
  <md-dialog :md-active.sync="active">
    <div id="oprm-toggle-reward-modal-container">
      <div id="oprm-modal-title" class="md-subheading laas-bold">
        {{ this.copy.title }}
      </div>
      <div class="md-body-1">
        {{ this.copy.body1 }}
        <span class="warning-color">
          {{ this.copy.body2 }}
        </span>
      </div>
      <div class="body-3 md-body-1" v-html="this.copy.body3"></div>
      <div id="oprm-modal-btn-container">
        <div
          ref="cancel-button"
          class="oprm-modal-button laas-bold"
          @click="cancel"
        >
          {{ this.copy.cancelButton }}
        </div>
        <div
          ref="submit-button"
          class="oprm-modal-button laas-bold warning-color"
          @click="submit"
        >
          {{ this.copy.submitButton }}
        </div>
      </div>
    </div>
  </md-dialog>
</template>

<script>
export default {
  name: 'ToggleRewardModal',
  props: {
    reward: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      active: true,
      copy: {
        title: this.reward.active ? 'Deactivate Reward' : 'Activate Reward',
        body1: this.reward.active
          ? 'Deactivating this reward means that this reward will no longer be displayed to any future customers.'
          : 'Activating this reward means that this reward will be live and offered to customers.',
        body2: this.reward.active
          ? 'However, you are expected to honor the fulfillment of the reward for existing bookings that have already been offered the reward.'
          : '',
        body3: this.reward.active
          ? 'If you cannot fulfill your existing reward orders, please reach out to your account manager at <b><u>support@laasie.ai</u></b> so they can help you find a replacement.'
          : 'If you have any questions about activating your reward, please reach out to your account manager at <b><u>support@laasie.ai</u></b>',
        cancelButton: this.reward.active ? 'No, keep active' : 'No, Keep Inactive ',
        submitButton: this.reward.active ? 'I understand, please deactivate' : 'I understand, please activate'
      }
    }
  },
  methods: {
    async submit () {
      const reward = {
        uuid: this.reward.uuid,
        properties: {
          active: !this.reward.active
        }
      }

      this.$store.dispatch('oprm/CREATE_OR_UPDATE_REWARD', reward)
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="sass">
#oprm-toggle-reward-modal-container
  width: 500px
  height: auto
  font-family: Lato !important
  padding: 36px
  padding-bottom: 15px
  text-align: left
  .md-field
    margin: 36px 0 24px

  #oprm-modal-title
    padding-bottom: 14px

  #oprm-modal-btn-container
    min-height: 34px
    margin-top: 14px

  .oprm-modal-button
    padding: 8px
    font-family: inherit
    text-transform: uppercase
    font-size: 12px
    font-stretch: normal
    font-style: normal
    line-height: normal
    letter-spacing: normal
    text-align: right
    color: #4e4e4e
    border-radius: 3px
    cursor: pointer
    &:hover
      background-color: #eee

  .warning-color
    color: #e02020

  .body-3
    margin-top: 14px
</style>
