


















export default {
  name: 'ExpandingList',
  props: {
    list: {
      required: true,
      type: Array,
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    sortedList (): string[] {
      const _list = [...this.list]
      return _list.sort()
    },
    firstThreeOfList (): string[] {
      return this.sortedList.slice(0, 3)
    },
    listCount (): number {
      return this.sortedList.length
    },
    hasAdditionalItems (): boolean {
      return this.sortedList.length > 3
    },
    showAdditionalItems (): boolean {
      return this.sortedList.length > 3 && this.expanded === true
    },
    additionalItems (): string[] {
      return this.sortedList.slice(3)
    },
    toggleText (): string {
      return this.expanded ? 'View Less' : `View All (${this.listCount})`
    }
  },
  methods: {
    toggleExpanded (): void {
      this.expanded = !this.expanded
    },
  },
}
