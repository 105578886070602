var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"tabs__container",style:({ width: _vm.computedWidth, height: _vm.computedHeight })},[_c('div',{ref:"tabs-navigation",staticClass:"tabs__navigation relative"},[_c('div',{ref:"titles-container",staticClass:"tabs__titles-container relative flex flex-row flex-no-wrap items-stretch h-full z-auto overflow-hidden"},[_c('div',{ref:"slider",staticClass:"tabs__slider",style:({
          left: '0px',
          width: '0px',
          bottom: '0px',
          height: '4px',
        })}),_c('div',{ref:"slider-rail",staticClass:"tabs__slider-rail"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPrev),expression:"showPrev"}],staticClass:"tabs__button-container"},[_c('Button',{attrs:{"width":25,"size":"round-small","type":"default"},on:{"click":function($event){_vm.tabGroupIdx--}}},[_c('span',{staticClass:"tabs__button-icon"},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.prev}})],1)])],1),_vm._l((_vm.tabs),function(tab,idx){return _c('div',{key:("tab-title-key-" + idx),ref:("tab-title-" + idx),refInFor:true,staticClass:"tabs__title cursor-pointer flex flex-shrink-0 items-center text-sm",class:[tab.disabled ? 'cursor-not-allowed' : null],style:({ height: '100%' }),on:{"click":function($event){$event.preventDefault();return _vm.setTab(idx)}}},[_c('span',{class:[].concat( _vm.titleClasses(idx) ),style:({
            opacity: _vm.titleOpacity(tab)
          })},[_vm._v(" "+_vm._s(_vm.getTitle(tab.title))+" ")])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNext),expression:"showNext"}],staticClass:"tabs__button-container absolute right-0"},[_c('Button',{attrs:{"width":25,"size":"round-small","type":"default"},on:{"click":function($event){_vm.tabGroupIdx++}}},[_c('span',{staticClass:"tabs__button-icon",attrs:{"id":"next"}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.next}})],1)])],1)],2)]),_c('div',{staticClass:"tabs__slot-container"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }