

















































import Vue from 'vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import { storageUtils } from '@/utils/storage'
import DashboardDrawer from '@/components/navigation/DashboardDrawer.vue'
import VerticalDivider from '@/components/dashboard/VerticalDivider.vue'
import Menu from '@/components/navigation/Menu.vue'
import vSelect from 'vue-select'
import Snackbar from '@/components/messages/Snackbar.vue'
import { menuStyles } from '@/constants'

Vue.component('v-select', vSelect)

export default {
  name: 'Dashboard',
  components: {
    DashboardDrawer,
    VerticalDivider,
    Menu,
    Snackbar
  },
  provide () {
    return {
      Dashboard_scrollToContentBottom: this.scrollToContentBottom,
      Dashboard_scrollTop: this.scrollTop,
      Dashboard_getContentHeight: this.getContentHeight,
      Dashboard_getContentWidth: this.getContentWidth,
    }
  },
  props: {
    navConfig: {
      type: Object,
      required: true,
      default: () => ({
        topMenuLinks: [],
        links: [],
        adminToggles: [],
      }),
    },
    dropdownComponent: {
      type: Object,
      required: false,
      default: null
    },
    statusComponent: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: function () {
    return {
      windowInnerHeight: (window?.innerHeight || null) as number | void,
      timeoutId: undefined as number | void,
      expanded: false,
      refLabels: {
        content: 'content',
        contentContainer: 'content-container'
      },
      copy: {
        sessionRefreshModal: {
          title: 'Session is about to expire.',
          confirmButton: 'Continue Session',
          cancelButton: 'End Session',
        }
      },
      storageKey: 'dashboard-content-expanded',
      icons: {
        expander: ['fas', 'angle-left'],
        minimizer: ['fas', 'angle-right'],
      },
    }
  },
  computed: {
    ...mapState([
      'showSessionRefreshModal',
    ]),
    contentStyles () {
      return {
        backgroundColor: this.$route.meta?.backgroundColor || '',
        height: this.windowInnerHeight ? `${this.windowInnerHeight - menuStyles.height}px` : 'auto'
      }
    },
    storageConfig () {
      return {
        key: this.storageKey,
        storage: 'sessionStorage'
      }
    },
    drawerStyle () {
      let zIndex = 40
      let width = '270px'
      let paddingLeft = '15px'

      if (this.expanded) {
        zIndex = 10
        width = '0px'
        paddingLeft = '0px'
      }
      return {
        zIndex,
        width,
        paddingLeft
      }
    },
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.setWindowInnerHeight()
  },
  // FIXME - the boolean value is reversed - should be called minimized
  beforeMount () {
    const expanded = this.setupExpanded({
      ...this.storageConfig,
      initialValue: false
    })
    this.expanded = expanded
    this.SET_DRAWER_EXPANDED_STATE(expanded)
  },
  beforeRouteLeave (to, from, next) {
    this.CLEAR_MESSAGES()
    next()
  },
  beforeRouteUpdate (to, from, next) {
    this.CLEAR_MESSAGES()
    next()
  },
  methods: {
    ...mapMutations([
      'SET_DRAWER_EXPANDED_STATE'
    ]),
    ...mapMutations('messages', [
      'CLEAR_MESSAGES'
    ]),
    ...mapActions([
      'REFRESH_SESSION',
    ]),
    handleResize (): void {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(this.setWindowInnerHeight, 500)
    },
    setWindowInnerHeight (): void {
      console.log('[setWindowInnerHeight]', window?.innerHeight)
      this.windowInnerHeight = window?.innerHeight
    },
    getContentEl (): HTMLElement | Record<string, number> {
      const content = this.$refs[this.refLabels.contentContainer]
      if (!content) {
        console.warn('Cannot find content container using ref:', this.refLabels.contentContainer)
        return {
          offsetHeight: null,
          offsetWidth: null,
        }
      } else {
        return content
      }
    },
    getContentHeight (): number | void {
      return this.getContentEl().offsetHeight
    },
    getContentWidth (): number | void {
      return this.getContentEl().offsetWidth
    },
    scrollToContentBottom (): void {
      const content: HTMLElement | undefined = this.$refs[this.refLabels.content]
      if (content) content.scrollTop = content.scrollHeight
    },
    scrollTop () {
      const content = this.$refs[this.refLabels.content]
      if (content) content.scrollTop = 25
    },
    logout () {
      this.$store.dispatch('LOGOUT_USER')
    },
    toggleExpanded () {
      this.expanded = !this.expanded
      this.storeExpanded({
        ...this.storageConfig,
        value: this.expanded
      })
    },
    storeExpanded: storageUtils.set,
    setupExpanded: storageUtils.getWithInitialValue,
  },
}
