










import props from '@/components/icons/props'

export default {
  name: 'AddWithArrow',
  props,
  data () {
    return {
      style: {
        '--color': this.color,
        '--hoverColor': this.hoverColor,
      },
    }
  },
}
