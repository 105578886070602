import jwtDecode from 'jwt-decode'
import PostMessageUtils from '@/lib/PostMessageUtils'
import endpoints from '@/config/endpoints'
import URLSearchParams from 'url-search-params'
import { userViews } from '@/constants'

const EDITOR_IFRAME_ID = 'sw-editor-iframe'
const IFRAME_CONTAINER_ID = 'sw-editor-iframe-container'
const DOCKING_EVENT_KEY = 'sw-editor-iframe-docking-event'

export const EditorUtils = {
  addIframeListener () {
    PostMessageUtils.listenForPostMessage(
      window,
      DOCKING_EVENT_KEY,
      this._updateDockingPosition
    )
    PostMessageUtils.listenForPostMessage(
      window,
      'sw_update_url',
      event => {
        const url = window.location.href + event
        window.location.href = url
      }
    )
    PostMessageUtils.listenForPostMessage(
      window,
      'sw_editor_exit',
      () => {
        window.onbeforeunload = undefined
        history.pushState({}, '', window.location.href)
        window.location = this.getBaseUrl(process.env)
      }
    )
    PostMessageUtils.listenForPostMessage(
      window,
      'sw_editor_has_changes',
      () => {
        window.onbeforeunload = function () {
          return 'You have unsaved changes. Are you sure you?'
        }
      }
    )
    PostMessageUtils.listenForPostMessage(
      window,
      'sw_editor_minimized',
      (event) => {
        const iframeContainer = document.querySelector('#sw-editor-iframe-container')
        iframeContainer.style.height = '100px'
        iframeContainer.style.width = '100px'
        if (event.dockPosition === 'left') {
          iframeContainer.style.right = 'auto'
        } else {
          iframeContainer.style.right = '0'
        }
      }
    )
    PostMessageUtils.listenForPostMessage(
      window,
      'sw_editor_expanded',
      (event) => {
        const iframeContainer = document.querySelector('#sw-editor-iframe-container')
        iframeContainer.style.height = 'calc(100% - 30px)'
        iframeContainer.style.width = '420px'
        if (event.dockPosition === 'left') {
          iframeContainer.style.right = 'calc(100% - 420px)'
        } else {
          iframeContainer.style.right = '0'
        }
      }
    )
  },

  insertIframe () {
    try {
      const iframeContainer = document.createElement('div')
      iframeContainer.id = IFRAME_CONTAINER_ID

      Object.assign(iframeContainer.style, {
        backgroundColor: 'transparent',
        width: '420px',
        position: 'fixed',
        right: 'calc(100% - 420px)',
        top: '0',
        height: 'calc(100% - 30px)',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        padding: '0px',
        margin: '0px',
        zIndex: 2147483647,
        'transition-property': 'right',
        'transition-duration': '.5s',
        'transition-timing-function': 'ease-in-out'
      })

      const target = document.querySelector('body') || document.querySelector('head')
      target.parentElement.insertBefore(iframeContainer, target)

      const iframe = document.createElement('iframe')
      iframe.id = EDITOR_IFRAME_ID

      const iframeBase = endpoints.editor.iframeSrc({
        port: this._getDashPortParam()
      })
      console.log('[insertIframe] iframeBase', iframeBase)
      iframe.src = this.getIframeSrc(iframeBase)
      iframe.frameBorder = 0
      iframe.height = '100%'
      iframe.allowtransparency = 'allowtransparency'
      iframe.allowfullscreen = 'true'
      Object.assign(iframeContainer.style, {
        flexGrow: 1,
        margin: 0,
        padding: 0,
        position: 'fixed',
        border: '0px'
      })
      iframeContainer.appendChild(iframe)
    } catch (err) {
      console.log('[INSERT IFRAME] err', err)
    }
  },

  /* callback used by postMessage listener */
  _updateDockingPosition (event) {
    console.log('[EDITOR BOOT] updateDockingPosition', event)
    const iframeContainer = document.getElementById(IFRAME_CONTAINER_ID)
    if (!iframeContainer) {
      console.warn('[updateDockingPosition] missing iframe container div')
      return
    }
    if (event === 'left') {
      iframeContainer.style.right = 'calc(100% - 420px)'
    } else if (event === 'right') {
      iframeContainer.style.right = '0'
    } else {
      console.warn('[updateDockingPosition] invalid dock position')
    }
  },

  _getDashPortParam (search = window.location.search) {
    const params = new URLSearchParams(search)
    return params.get('dev_port') || window.localStorage?.getItem('sw_dev_port')
  },

  /**
    * will append the current env of dashboard. can use this as override
    * to inject staging or production `editorBoot` into client site.
    * @param {String} base - url to build off of - client site
    * @param {String} fuxUuid - optional uuid of fux when editing
    * @param {String} context
    * @param {String} channel - mb/dt
    * @param {String} lassieHotelId - legacy hotelId
    * @return {String} url with necessary query params
    */
  buildUrl ({ base, fuxUuid, context, channel, hotelId }) {
    const url = new URL(base)

    url.searchParams.append('sw_editor', true)
    url.searchParams.append('context', context)
    url.searchParams.append('channel', channel)
    url.searchParams.append('laasieHotelId', hotelId)
    url.searchParams.append('dash_env', process.env.VUE_APP_MODE)
    if (fuxUuid) {
      url.searchParams.append('sw_funnelux', fuxUuid)
    }
    if (process.env.VUE_APP_MODE === 'development') {
      url.searchParams.append('dev_port', window.location.port)
    }

    return url
  },

  getUserType ({ token }) {
    const decoded = jwtDecode(token)
    const profile = decoded['custom:profile']
    return profile && JSON.parse(profile).type
  },

  exitEditorUrl (legacyId) {
    const baseUrl = this.getBaseUrl(process.env)
    const url = new URL(baseUrl)

    url.pathname = `/${userViews.DASHBOARD}/${legacyId}/funnel-ux`

    return url
  },

  getBaseUrl (env) {
    let baseUrl = env.VUE_APP_DOMAIN

    if (env.VUE_APP_MODE === 'development') {
      baseUrl = baseUrl.replace('__PORT__', window.location.port)
    }

    return baseUrl
  },

  getEditorParams (search = window.location.search) {
    const params = new URLSearchParams(search)
    return {
      app: params.get('app'),
      hotelId: params.get('hotel_id'),
    }
  },

  exitEditorLoyaltyPortalUrl (hotelId) {
    const baseUrl = this.getBaseUrl(process.env)
    const url = new URL(baseUrl)
    url.pathname = `/${userViews.DASHBOARD}/${hotelId}/`

    return url
  },

  /**
     * @important we will get the params from localStorage
     * rather than the search params string. this is necessary
     * when a client site removes our params from the url
     * and prevents the editor from working.
     */
  getIframeSrc (baseUrl = '', search = window.location.search, localStorage = window.localStorage) {
    let searchParams = ''

    const editor = localStorage?.getItem('sw_editor')

    if (editor) {
      const params = new URLSearchParams({
        sw_editor: editor,
        context: localStorage.getItem('sw_context'),
        channel: localStorage.getItem('sw_channel'),
        laasieHotelId: localStorage.getItem('laasieHotelId'),
        sw_funnelux: localStorage.getItem('sw_funnelux'),
        dev_port: localStorage.getItem('sw_dev_port'),
        dash_env: localStorage.getItem('sw_dash_env')
      })
      searchParams = `?${params.toString()}`
    } else {
      searchParams = search
    }

    const src = `${baseUrl}${searchParams}`
    console.log('[getIframeSrc] src:', src)

    return src
  }
}
