<template>
  <div id="edm-test-container">
    <Info
      v-if="hasChanges"
      :copy="copy.saveWarning"
      :icon="icon"
    />
    <div :class="{ 'laas-disabled': hasChanges }">
      <div class="edm-section">
        {{ copy.body }}
      </div>
      <div class="laas-bold">
        {{ copy.fromSender }}
      </div>
      <div>
        {{ SENDER_EMAIL }}
      </div>
      <div class="laas-caption">
        {{ copy.fromSenderDesc }}
      </div>
      <div class="test-send-email-wrapper">
        <div class="laas-bold">
          {{ copy.sendTestHeader }}
        </div>
        <md-field>
          <md-input
            required
            v-model="testEmailRecipients"
            :placeholder="copy.sendTestPlaceholder"
            :disabled="hasChanges">
          </md-input>
        </md-field>
        <div class="laas-caption laas-italic">
          {{ copy.sendTestDesc }}
        </div>
        <div class="mt-1">
          <Button
            :disabled="sendTestEmailDisabled"
            @click="sendTestEmail"
          >
            {{ copy.sendTestButton }}
          </Button>
        </div>
      </div>
      <div>
        <div class="edm-section">
          {{ copy.footer1 }}
        </div>
        <div class="edm-section">
          {{ copy.footer2 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Info from '@/components/common/Info'
import Button from '@/components/button/Button.vue'

export default {
  name: 'EmailTest',
  components: {
    Info,
    Button,
  },
  props: {
    hasChanges: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      icon: ['fas', 'exclamation-triangle'],
      copy: {
        body: 'Test your email before sending it to your recipients.',
        fromSender: 'From Sender',
        fromSenderDesc: 'This is your default sender. If you would like to change it, please contact your CSM.',
        sendTestHeader: 'Send a Test Email',
        sendTestPlaceholder: 'To Address',
        sendTestDesc: 'Enter up to 10 email addresses, separated by commas.',
        sendTestButton: 'Send Test Message',
        footer1: 'Please note that the test email WILL NOT display any dynamic reward content if you are using our custom blocks.',
        footer2: 'Please build a campaign in the Campaign tab with this design if you would like to view a test email containing dynamic reward content.',
        saveWarning: 'Please save your design before testing it.',
        missingSenderErr: 'It looks like your sender email hasn\'t been set up, please contact your account manager'
      },
    }
  },
  computed: {
    ...mapGetters('edm', [
      'SENDER_EMAIL',
    ]),
    testEmailRecipients: {
      get () {
        return this.$store.state.edm.testEmailRecipients
      },
      set (value) {
        this.$store.commit('edm/SET_TEST_EMAIL_RECIPIENTS', value)
      }
    },
    sendTestEmailDisabled () {
      return (this.testEmailRecipients.length === 0 || this.hasChanges) || !this.SENDER_EMAIL
    },
  },
  methods: {
    sendTestEmail () {
      this.$store.dispatch('edm/SEND_TEST_EMAIL', { campaignId: undefined })
    }
  },
  mounted () {
    if (!this.SENDER_EMAIL) {
      this.$store.dispatch('messages/ADD_ERROR', new Error(this.copy.missingSenderErr))
    }
  }
}
</script>

<style scoped lang="sass">
@import '../../../navigation/menu-styles'

#edm-test-container
  padding-left: 32px
  padding-top: 26px
  height: 800px
  width: 540px

  .edm-section
    padding: 10px 0px

#dismiss-button.md-button.md-theme-default
  color: white
</style>
