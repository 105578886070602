












import { helperText } from '@/components/props/index'

export default {
  name: 'HelperText',
  props: {
    helperText
  },
}
