


















import { mapState, mapMutations } from 'vuex'

export default {
  name: 'AdvancedNotice',
  props: {

  },
  data () {
    return {
      copy: {
        title: 'Does this reward require 48 hours of advanced notice?',
        footer: 'For on-property rewards that require additional time to prepare, we recommend enabling this feature. Advanced notice rewards are not selectable for guests who are selecting rewards less than 48 hours before their check-in date.'
      }
    }
  },
  computed: {
    ...mapState('oprm', [
      'reward'
    ]),
    toggleCopy (): string {
      return this.active ? 'Yes' : 'No'
    },
    active: {
      get (): boolean {
        return this.reward.requiresNotice
      },
      set (value: boolean): void {
        this.SET_REWARD_FIELD({ field: 'requiresNotice', value })
      }
    }
  },
  methods: {
    ...mapMutations('oprm', [
      'SET_REWARD_FIELD'
    ]),
  }
}
