<template lang="html">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    id="icon entertainment"
    data-name="icon entertainment"
    viewBox="0 0 35 35"
  >
    <title>{{ this.name }}</title>
    <path
      d="M10.07,27a.45.45,0,0,0,.22.23.42.42,0,0,0,.31,0L29,20.12a.41.41,0,0,0,.23-.22.44.44,0,0,0,0-.32l-1.55-4.15a.43.43,0,0,0-.21-.23.44.44,0,0,0-.32,0l-.54.21a1.31,1.31,0,0,1-1,0,1.38,1.38,0,0,1-.7-.76,1.34,1.34,0,0,1,.77-1.75l.54-.21a.43.43,0,0,0,.24-.54L24.93,8a.41.41,0,0,0-.22-.23.37.37,0,0,0-.31,0L6,14.88l-.07,0h0a.43.43,0,0,0-.17.51l1.55,4.15a.43.43,0,0,0,.21.23.4.4,0,0,0,.32,0l.54-.21a1.36,1.36,0,0,1,.48-.09,1.45,1.45,0,0,1,.54.11,1.38,1.38,0,0,1,.7.76,1.43,1.43,0,0,1,0,1,1.32,1.32,0,0,1-.74.71l-.55.21a.42.42,0,0,0-.23.54ZM9.6,22.91a2.13,2.13,0,0,0,1.19-1.15,2.2,2.2,0,0,0,.05-1.67,2.17,2.17,0,0,0-1.13-1.23,2.08,2.08,0,0,0-1.64,0l-.16.06v0L6.65,15.52h0l3.54-1.38v0L11,16.22a.43.43,0,0,0,.22.24.37.37,0,0,0,.31,0,.41.41,0,0,0,.24-.54L11,13.83h0l13.3-5.16v0L25.56,12l-.15.07a2.2,2.2,0,0,0-1.25,2.82,2.14,2.14,0,0,0,1.15,1.23,2.07,2.07,0,0,0,1.62,0l.16-.06v0l1.25,3.33h0L15,24.65v0l-.77-2.07a.45.45,0,0,0-.21-.24.37.37,0,0,0-.31,0l-.06,0a.42.42,0,0,0-.19.53L14.26,25h0l-3.55,1.38v0L9.44,23Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconEntertainment',
  data () {
    return {
      name: 'icon entertainment'
    }
  },
}
</script>

<style lang="css">
</style>
