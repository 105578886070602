import state from '@/store/root/state'
import mutations from '@/store/root/mutations'
import getters from '@/store/root/getters'
import actions from '@/store/root/actions'

export default {
  state,
  mutations,
  getters,
  actions
}
