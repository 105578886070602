





























































































import Vue from 'vue'
import { expiryTypes } from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/constants'
import { ExpiryType } from '@/types'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import moment from 'moment'

export default Vue.extend({
  name: 'RedemptionExpiration',
  components: {
    Datetime
  },
  props: {
    editing: {
      type: Boolean,
      required: true,
    }
  },
  data () {
    return {
      redemptionExpiryVal: '',
      copy: {
        description: 'We recommend using the expiration type “After Check-Out” for on-property rewards.',
        expiry: {
          title: 'When does this reward expire?',
          helpText: {
            [expiryTypes.AFTER_CHECK_OUT]:
              'Reward will expire after check-out day.',
            [expiryTypes.NO_EXPIRATION]: 'Reward will not expire.',
            [expiryTypes.FROM_DISPATCH]: 'Reward will expire x number of days after check-in.',
            [expiryTypes.SEE_TERMS]: 'See instructions and terms for expiration',
            [expiryTypes.SET_EXPIRATION_DATE]: 'Reward will expire on a specific day set by the user.'
          } as Record<ExpiryType, string>,
        },
        daysAfterCheckin: {
          title: 'How many days after check-in?',
        },
        expiryOptions: {
          [expiryTypes.AFTER_CHECK_OUT]: 'After Check-Out',
          [expiryTypes.NO_EXPIRATION]: 'Never Expires',
          [expiryTypes.FROM_DISPATCH]: 'Days After Check-In',
          [expiryTypes.SEE_TERMS]: 'See Terms',
          [expiryTypes.SET_EXPIRATION_DATE]: 'Set Expiration Date'
        } as Record<ExpiryType, string>,
        required: 'Required',
        placeholder: {
          setExpirationDate: 'Select an expiration date'
        },
        expirationDateWarning: {
          setExpirationDate: 'Updating the type to “Set Expiration Date” and/or adjusting the expiration date will modify the settings for both existing and future reward offers.',
          modifyExpirationDate: `
            Modifying to this reward expiration type will change the settings for future reward offers, NOT past or existing offers.
            If you need to change the expiration type on all reward offers past and present, please reach out to your CSM.
          `
        }
      },
      userTimezone: moment.tz.guess(), // ie 'America/New_York' - required for datetime
    }
  },
  computed: {
    redemptionExpiry: {
      get (): ExpiryType | undefined {
        return this.$store.state.oprm.reward.expiryType
      },
      set (value): void {
        this.$store.commit('oprm/SET_REWARD_FIELD', {
          field: 'expiryType',
          value
        })
      }
    },
    redemptionDaysAfterCheckin: {
      get (): string | undefined {
        return this.$store.state.oprm.reward.redemptionExpiration
      },
      set (value): void {
        this.$store.commit('oprm/SET_REWARD_FIELD', {
          field: 'redemptionExpiration',
          value
        })
      }
    },
    isDaysAfterCheckin (): boolean {
      return this.redemptionExpiry === expiryTypes.FROM_DISPATCH
    },
    isSetExpirationDate (): boolean {
      return this.redemptionExpiry === expiryTypes.SET_EXPIRATION_DATE
    },
    datetime: {
      get (): string | void {
        return this.$store.state.oprm.reward.specificExpirationDate
      },
      set (value: string): void {
        if (value) {
          const formatted = moment.utc(value).format('YYYY-MM-DD')
          this.$store.commit('oprm/SET_REWARD_FIELD', {
            field: 'specificExpirationDate',
            value: formatted
          })
        }
      }
    },
    expirationDateWarningCopy (): string {
      let copy
      if (this.redemptionExpiry === expiryTypes.SET_EXPIRATION_DATE) {
        copy = this.copy.expirationDateWarning.setExpirationDate
      } else {
        if (this.redemptionExpiryVal === expiryTypes.SET_EXPIRATION_DATE) {
          copy = this.copy.expirationDateWarning.modifyExpirationDate
        }
      }
      return copy
    }
  },
  mounted () {
    if (this.editing) {
      this.redemptionExpiryVal = this.$store.state.oprm.reward.redemptionExpiry
    }
  },
})
