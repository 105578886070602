<template>
  <md-dialog
    :md-click-outside-to-close="false"
    :md-close-on-esc="false"
    :md-active.sync="active"
  >
    <div id="confirmation-modal-container">
      <div id="confirmation-modal-title" class="md-subheading laas-bold">
        {{ copy.title }}
      </div>
      <div class="md-body-1">
        {{ bodyCopy }}
      </div>
      <div class="confirmation-list-container">
        <ul>
          <li
            class="confirmation-list-item laas-bold"
            v-for="name in modifiedNames"
            :key="name">
            {{ name }}
          </li>
        </ul>
      </div>
      <div class="confirmation-total-counter">
        {{ copy.total }} ( {{ this.modifiedNames.length }} )
      </div>
      <div class="modal-footer laas-caption laas-italic">
        {{ copy.footer }}
      </div>
      <div id="confirmation-modal-btn-container">
        <div
          class="confirmation-modal-button"
          @click="closeModal">
          {{ copy.cancel }}
        </div>
        <div
          class="confirmation-modal-button confirmation-modal-button-done laas-bold"
          @click="confirmSubmit">
          {{ copy.confirm }}
        </div>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'DuplicationConfirmationModal',
  data () {
    return {
      active: true,
      count: 0,
      copy: {
        title: 'Confirm Duplication',
        footer: 'Are you sure you want to proceed?',
        total: 'Total',
        cancel: 'CANCEL',
        confirm: 'CONFIRM'
      }
    }
  },
  computed: {
    ...mapGetters('designDuplication', [
      'MODIFIED_GROUP_NAMES',
      'MODIFIED_HOTEL_NAMES'
    ]),
    ...mapState('designDuplication', ['design']),
    bodyCopy () {
      return `This will duplicate and MAB ${this.design.mab_enabled ? 'enable' : 'disable'} the funnel UX on the properties/groups listed below:`
    },
    modifiedNames () {
      return this.MODIFIED_GROUP_NAMES.length > 0
        ? this.MODIFIED_GROUP_NAMES
        : this.MODIFIED_HOTEL_NAMES(this.design.hotel_ids)
    }
  },
  methods: {
    async confirmSubmit () {
      const resp = await this.$store.dispatch('designDuplication/SUBMIT_DESIGN_DUPLICATE')
      this.closeModal()
      if (!resp.err) {
        this.$emit('duplicationsuccess')
      }
    },
    closeModal () {
      this.$emit('closeDuplicationModal')
    }
  }
}
</script>

<style scoped lang="sass">
#confirmation-modal-container
  width: 350px
  height: auto
  font-family: Lato !important
  padding: 36px
  padding-bottom: 15px
  text-align: justify
  .md-field
    margin: 36px 0 24px

  #confirmation-modal-title
    padding-bottom: 6px

  #confirmation-modal-btn-container
    min-height: 34px
    display: flex
    flex-direction: row
    justify-content: flex-end

  .confirmation-modal-button
    padding: 10px
    font-family: inherit
    text-transform: uppercase
    font-size: 12px
    font-stretch: normal
    font-style: normal
    line-height: normal
    letter-spacing: normal
    text-align: right
    color: #4e4e4e
    border-radius: 3px
    cursor: pointer
    &:hover
      background-color: #eee

  .confirmation-total-counter
    margin-top: 5px
    font-size: 11px

  .confirmation-modal-button-done
    margin-right: -10px

  .confirmation-list-container
    height: 100px
    overflow: scroll
    background-color: #e9e9e9
    ul
      list-style-type: none
      margin: 0
      padding: 15px

  .confirmation-list-item
    color: black

  .modal-footer
    color: black
</style>
