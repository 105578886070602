import AWS from 'aws-sdk'
import { lambdaConfig } from '@/config'

export default class LambdaUtils {
  /**
    * Calls given Lambda function with given payload.
    * Resolves responses or errors with same object structure.
    * @param {String} functionName name of Lambda function
    * @param {Object} payload an object that will be stringify'd and sent
    */
  static invokeFn ({ functionName, payload }) {
    return new Promise((resolve) => {
      // dev site & local dev env will invoke 'development' alias of lambda fns
      const params = {
        FunctionName: functionName,
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: JSON.stringify(payload),
      }

      const lambda = new AWS.Lambda(lambdaConfig)

      lambda.invoke(params, (err, data) => {
        // errors thrown in lambda function, not necessarily from cognito
        if (err) {
          console.log('lambdainvoke err', err)
          resolve({
            error: err,
            data: undefined
          })
        }

        const parsedData = JSON.parse(data.Payload)
        // lambda can return a cognito error in the data block
        if (parsedData.errorMessage) {
          console.log('parsedData error:', parsedData)
          resolve({
            error: parsedData,
            data: undefined
          })
        }

        resolve({
          error: undefined,
          data: parsedData,
        })
      })
    })
  }
}
