<template lang="html">
  <svg
    id="icon-gift"
    x="0px"
    y="0px"
    viewBox="0 0 30 26"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    role="img"
    :style="{width: icon.gift}"
  >

    <title id="title">icon square gift</title>

    <g fill-rule="evenodd">
      <path d="M20.522 3h-4.119H21h-.478zM9.477 3H9h.537-.06z" />
      <g>
        <path
          d="M18.874 12.246h9.132V5.668h-9.132v6.578zm0 12.558h6.697V13.442h-6.697v11.362zm-6.698 0h5.48V5.668h-5.48v19.136zM1.218 12.246h9.74V5.668h-9.74v6.578zm2.435 12.558h7.306V13.442H3.653v11.362zM7.549 1.841c.183-.18.487-.3.853-.3 1.035 0 2.679.778 4.992 2.931H8.767c-.426-.06-1.035-.24-1.522-.837-.244-.359-.304-1.316.304-1.794zm14.551 0c.61.478.548 1.495.305 1.794a2.342 2.342 0 0 1-1.461.837H16.256c3.105-2.93 5.053-3.289 5.844-2.631zm1.218 2.631c.06-.06.06-.12.122-.12.609-.837.548-2.511-.548-3.408-.974-.837-3.288-1.435-8.037 3.289C10.106-.491 7.732.107 6.82.943c-1.096.898-1.157 2.632-.487 3.41.06.06.06.119.122.119H0v8.97h2.435V26h24.353V13.442h2.436v-8.97h-5.906z"
          mask="url(#b)"
        />
        <path
          d="M16.256 4.472h2.618-2.618zM8.645 5.668h.609zM20.335 5.668H21.005z"
          mask="url(#b)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconSquareGift',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>

<style lang="sass" scoped>
svg
  path
    fill: inherit !important
</style>
