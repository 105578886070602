<template>
  <svg
  id="icon-bellhop"
  viewBox="0 0 503.607 503.607"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  version="1.1"
  role="img">

  <title id="title">bellhop icon</title>
  <desc id="desc">bellhop icon</desc>
    <g transform="translate(1 1)">
      <g>
        <g>
          <path d="M494.213,368.311h-75.541v-16.787h58.754c5.036,0,8.393-3.357,8.393-8.393c0-133.893-98.884-238.828-226.623-243.247
            v-16.95h33.574c5.036,0,8.393-3.357,8.393-8.393s-3.357-8.393-8.393-8.393h-83.934c-5.036,0-8.393,3.357-8.393,8.393
            s3.357,8.393,8.393,8.393h33.574v16.95C114.671,104.303,15.787,209.238,15.787,343.131c0,5.036,3.357,8.393,8.393,8.393h58.754
            v16.787H7.393c-5.036,0-8.393,3.357-8.393,8.393v50.361c0,5.036,3.357,8.393,8.393,8.393h486.82c5.036,0,8.393-3.357,8.393-8.393
            v-50.361C502.607,371.669,499.249,368.311,494.213,368.311z M250.803,116.508c120.026,0,214.033,94.846,218.23,218.23h-58.754
            H91.328H32.574C36.77,211.354,131.616,116.508,250.803,116.508z M99.721,351.525h302.164v16.787H99.721V351.525z M485.82,418.672
            H15.787v-33.574h75.541h318.951h75.541V418.672z"/>
          <path d="M218.908,153.439c4.197-0.839,7.554-5.875,6.715-10.072c-0.839-4.197-5.875-7.554-10.072-6.715
            c-75.541,13.43-135.974,68.826-157.797,146.046c-0.839,4.197,1.679,9.233,5.875,10.072c0.839,0,1.679,0,2.518,0
            c3.357,0,6.715-2.518,7.554-5.875C93.846,217.229,149.243,166.03,218.908,153.439z"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconBellhop'
}
</script>

<style lang="sass" scoped>
svg
  path
    fill: inherit !important
</style>
