<template>
  <svg
  id="icon-plus"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  version="1.1"
  role="img">

  <title id="title">plus icon</title>
  <desc id="desc">plus icon</desc>
    <path d="m23,10h-8.5c-0.3,0-0.5-0.2-0.5-0.5v-8.5c0-0.6-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1v8.5c0,0.3-0.2,0.5-0.5,0.5h-8.5c-0.6,0-1,0.4-1,1v2c0,0.6 0.4,1 1,1h8.5c0.3,0 0.5,0.2 0.5,0.5v8.5c0,0.6 0.4,1 1,1h2c0.6,0 1-0.4 1-1v-8.5c0-0.3 0.2-0.5 0.5-0.5h8.5c0.6,0 1-0.4 1-1v-2c0-0.6-0.4-1-1-1z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPlus'
}
</script>

<style lang="sass" scoped>
svg
  path
    fill: inherit !important
</style>
