<template>
  <svg
    id="icon-discount"
    viewBox="0 0 440.994 440.994"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.discount}">

    <title id="title">discount icon</title>
    <desc id="desc">discount icon</desc>

    <g>
      <path d="M274.61,249.183c-5.044,0-8.816,3.714-10.618,5.929c-4.025,4.951-6.336,
        12.059-6.336,19.499s2.311,14.548,6.336,19.5c1.802,2.214,5.574,5.928,10.618,
        5.928s8.816-3.714,10.618-5.928c4.025-4.952,6.336-12.06,6.336-19.5s-2.311
        -14.548-6.336-19.499C283.428,252.896,279.654,249.183,274.61,249.183z"/>
      <path d="M166.384,191.81c5.044,0,8.816-3.714,10.618-5.928c4.026-4.952,6.336
        -12.059,6.336-19.5c0-7.441-2.31-14.547-6.336-19.499c-1.802-2.214-5.574-5.928
        -10.618-5.928c-5.044,0-8.816,3.714-10.618,5.928c-4.026,4.952-6.336,12.058-6.336,19.499
        c0,7.441,2.31,14.548,6.336,19.5C157.567,188.097,161.34,191.81,166.384,191.81z"/>
      <path d="M435.289,209.037l-53.984-40.794l19.699-64.751c1.312-4.31,0.525-8.984
        -2.123-12.628c-2.647-3.643-6.854-5.833-11.356-5.916l-67.672-1.235l-22.065
        -63.997c-1.469-4.262-4.855-7.587-9.143-8.979c-4.283-1.393-8.979-0.692-12.675,
        1.892l-55.475,38.814L165.02,12.627c-3.693-2.583-8.387-3.285-12.674-1.892
        c-4.289,1.393-7.672,4.718-9.143,8.979l-22.064,63.996l-67.672,1.236c-4.502,
        0.083-8.707,2.272-11.355,5.916c-2.648,3.644-3.436,8.319-2.125,12.628l19.701,
        64.75L5.705,209.037C2.111,211.751,0,215.995,0,220.497c0,4.503,2.111,8.746,
        5.705,11.461l53.984,40.794L39.99,337.502c-1.311,4.31-0.523,8.984,2.125,12.628
        c2.648,3.643,6.854,5.833,11.355,5.916l67.672,1.236l22.064,63.996c1.469,4.262,
        4.854,7.587,9.141,8.979c4.285,1.394,8.98,0.692,12.674-1.893l55.475-38.814l55.477,
        38.815c2.443,1.709,5.324,2.595,8.236,2.595c1.488,0,2.986-0.23,4.438-0.703c4.286
        -1.393,7.672-4.718,9.141-8.979l22.063-63.996l67.675-1.237c4.502-0.083,8.707
        -2.272,11.354-5.916c2.648-3.644,3.437-8.318,2.125-12.628l-19.701-64.749l53.984
        -40.795c3.594-2.715,5.705-6.958,5.705-11.461S438.883,211.75,435.289,209.037
        z M166.384,121.01c20.38,0,36.901,20.314,36.901,45.374c0,25.061-16.521,45.375
        -36.901,45.375c-20.38,0-36.901-20.314-36.901-45.375C129.482,141.324,146.004,
        121.01,166.384,121.01z M164.038,320.178c-2.307,3.209-6.047,5.125-10.005,5.125
        c-2.581,0-5.059-0.8-7.163-2.312c-5.509-3.957-6.771-11.658-2.813-17.167l132.9
        -185.01c2.306-3.209,6.046-5.125,10.005-5.125c2.58,0,5.058,0.8,7.162,2.312
        c5.509,3.957,6.771,11.658,2.813,17.167L164.038,320.178z M274.61,319.983c-20.38,
        0-36.901-20.313-36.901-45.375c0-25.059,16.521-45.373,36.901-45.373s36.9,20.314,
        36.9,45.373C311.512,299.669,294.99,319.983,274.61,319.983z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconDiscount',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
