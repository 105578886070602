<template>
  <div id="image-display-preview-container">
    <div class="md-body-2 laas-bold laas-section-title">
      {{ copy.title }}
    </div>
    <div
      id="image-display-preview-title"
      class="md-body laas-oprm-body-text-color laas-section-title"
    >
      {{ copy.body }}
    </div>
    <div id="preview">
      <div id="preview-content">
        <div
          v-if="showPlaceholderIcon"
          id="preview-icon-tile"
        >
          <div id="preview-icon-content">
            <md-icon>image</md-icon>
          </div>
        </div>

        <img
          v-if="!showPlaceholderIcon"
          id="image"
          :src="IMAGE_PREVIEW"
          alt="image-preview"
        >

        <div
          id="preview-name"
          class="preview-copy"
        >
          <div :class="{ 'active-preview': !!merchant }">
            {{ nameCopy }}
          </div>
        </div>
        <div
          id="preview-description"
          class="preview-copy"
        >
          <div :class="{ 'active-preview': !!description }">
            {{ descriptionCopy }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ImageDisplayPreview',
  data () {
    return {
      copy: {
        title: 'Image Display Preview',
        body: 'In some designs, customers will see your business name, a more detailed reward description and a relevant image. This would appear in a Laasie Incentive Engine widget on a webpage.',
        placeholder: {
          name: 'Your Business Name',
          description: 'Your Reward Description'
        }
      }
    }
  },
  computed: {
    ...mapGetters('oprm', [
      'IMAGE_PREVIEW'
    ]),
    showPlaceholderIcon () {
      return !this.IMAGE_PREVIEW
    },
    merchant () {
      return this.$store.state.oprm.reward.merchant
    },
    nameCopy () {
      if (!this.merchant) return this.copy.placeholder.name
      return this.merchant.name
    },
    description () {
      return this.$store.state.oprm.reward.creativeConfigs[0].thumbnailText
    },
    descriptionCopy () {
      return this.description || this.copy.placeholder.description
    }
  }
}
</script>

<style lang="sass" scoped>
#image-display-preview-title
  text-align: left
  max-width: 350px
#preview
  width: 350px
  height: 272px
  border-radius: 3px
  background-color: #f8f8f8
  margin-top: 14px
  display: flex
  align-items: center
  justify-content: center
#preview-content
  width: 298px
  height: 227px
  border-radius: 2.6px
  border: solid 0.5px #979797
  background-color: #ffffff
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
#preview-icon-tile
  width: 130px
  height: 77px
  background-color: #d8d8d8
  display: flex
  align-items: center
  justify-content: center
  margin-bottom: 8px

.preview-copy
  width: 193px
  font-stretch: normal
  font-style: italic
  line-height: normal
  letter-spacing: normal
  text-align: center
  color: #979797
  font-size: 15px
  font-weight: normal
  padding: 6px 4px 0px 4px
#preview-name
  font-weight: bold
#image
  max-width: 150px
  max-height: 80px

.active-preview
  color: black
  font-style: normal
</style>
