



























































































import { mapGetters, mapState } from 'vuex'
import { Hotel } from '@/types'

/**
 * this component will render all properties for an admin user.
 * if the hotel list length is > 20, a text field is rendered
 * allowing the user to filter the list of hotels by name
 */

export default {
  name: 'Properties',
  data () {
    return {
      filterTerm: undefined,
      selectAll: false,
      copy: {
        title:
          'On which properties do you want this reward to be shown? Select all.',
        placeholder: 'Click to see properties…',
        selectedProperties: 'Selected Properties',
        footer:
          'Don’t see the property you want? Contact your customer success manager and we’ll try to help!',
        required: 'Required',
        selectAll: 'Select All',
        buttons: {
          previous: 'Previous',
          done: 'Done'
        },
        noResults: "Sorry, we couldn't find a hotel with that name."
      }
    }
  },
  computed: {
    ...mapGetters('oprm', [
      'HOTEL_OPTS',
      'GET_HOTEL_NAME',
      'IS_PROPERTIES_DISABLED'
    ]),
    ...mapState('oprm', [
      'reward',
      'fetchHotelsSuccess'
    ]),
    ...mapState([
      'primaryHotel',
    ]),
    ...mapGetters([
      'IS_ADMIN',
    ]),
    hotelOpts (): Hotel[] {
      return this.HOTEL_OPTS.filter(hotel => hotel.hotelCode !== this.primaryHotel.hotelCode)
    },
    filteredHotelOpts (): object[] {
      let filteredOpts = []

      if (this.filterTerm) {
        const lowerterm = this.filterTerm.toLowerCase()
        filteredOpts = this.hotelOpts.filter((hotel: Hotel): boolean | string => {
          return typeof (hotel.name === 'string') &&
            hotel.name.toLowerCase().replace(' ', '').indexOf(lowerterm) > -1
        })
      } else {
        filteredOpts = this.hotelOpts
      }

      return [this.primaryHotel, ...filteredOpts]
    },
    hotels: {
      get (): string[] {
        return this.reward.onPropertyHotels // hotel api names
      },
      set (value): void {
        const field = 'onPropertyHotels'
        this.$store.commit('oprm/SET_REWARD_FIELD', { field, value })
      }
    },
    selectedPropertiesCopy (): string {
      return this.hotels
        ? `${this.copy.selectedProperties}: (${this.hotels.length})`
        : ''
    },
    showFilter (): boolean {
      return this.HOTEL_OPTS.length > 20
    },
    showNoResults (): boolean {
      return (
        this.filterTerm &&
        this.filterTerm.length > 0 &&
        this.hotelOpts.length === 0
      )
    },
  },
  watch: {
    selectAll (newValue) {
      const field = 'onPropertyHotels'
      let value
      if (newValue === true) {
        value = this.HOTEL_OPTS.map((hotel) => hotel.hotelCode)
      } else {
        value = []
      }
      this.$store.commit('oprm/SET_REWARD_FIELD', { field, value })
    }
  },
}
