<template>
  <md-dialog :md-active.sync="active">
    <div id="oprm-modal-container">
      <div id="oprm-modal-title" class="md-subheading laas-bold">
        {{ getTitle }}
      </div>
      <div class="md-body-3 laas-bold" :class="{ 'warning-body-font': this.editing }">
        {{ getBody }}
      </div>
      <md-field :style="{ marginTop: editing ? '36px' : '0px' }">
        <md-input v-model="merchantNameInput" maxLength="40" md-counter="40"></md-input>
      </md-field>
      <div class="laas-caption laas-italic">
        {{ this.copy.footer }}
      </div>
      <div id="oprm-modal-btn-container">
        <div class="oprm-modal-button" @click="cancel">Cancel</div>
        <div class="oprm-modal-button oprm-modal-button-done laas-bold" @click="submit">Done</div>
      </div>
    </div>
  </md-dialog>
</template>

<script>
export default {
  name: 'MerchantModal',
  props: {
    merchant: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      merchantNameInput: undefined,
      active: true,
      copy: {
        creating: {
          title: 'Create a New Business',
          body: 'What is your business name?',
        },
        editing: {
          title: 'Rename Business',
          body: 'WARNING: Changing the business name will affect all rewards associated with this business.',
        },
        footer: 'This is configured separately from selecting the hotel property so that you have the option to display a specific name of a service (eg. restaurant) within the property. You can still enter your hotel name if you wish.',
      }
    }
  },
  mounted () {
    this.merchantNameInput = this.merchant && this.merchant.name
  },
  computed: {
    getTitle () {
      return this.editing
        ? this.copy.editing.title
        : this.copy.creating.title
    },
    getBody () {
      return this.editing
        ? this.copy.editing.body
        : this.copy.creating.body
    },
    editing () {
      return this.merchant && this.merchant.id
    }
  },
  methods: {
    async submit () {
      const merchant = {
        name: this.merchantNameInput,
        id: this.merchant.id
      }
      const resp = await this.$store.dispatch('oprm/CREATE_MERCHANT', merchant)
      if (resp.error) {
        // close the modal and handle error with snackbar from action
        this.cancel()
      } else {
        this.$emit('merchantmodalsuccess', merchant)
      }
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="sass">
#oprm-modal-container
  width: 350px
  height: auto
  font-family: Lato !important
  padding: 36px
  padding-bottom: 15px
  text-align: justify
  .md-field
    margin: 36px 0 24px

  #oprm-modal-title
    padding-bottom: 6px

  #oprm-modal-btn-container
    min-height: 34px
    display: flex
    flex-direction: row
    justify-content: flex-end

  .oprm-modal-button
    padding: 10px
    font-family: inherit
    text-transform: uppercase
    font-size: 12px
    font-stretch: normal
    font-style: normal
    line-height: normal
    letter-spacing: normal
    text-align: right
    color: #4e4e4e
    border-radius: 3px
    cursor: pointer
    &:hover
      background-color: #eee

  .oprm-modal-button-done
    margin-right: -10px

  .warning-body-font
    font-weight: normal
    color: #e02020
    font-style: italic
    font-family: Lato !important
    height: 30px
</style>
