<template>
  <div id="drawer-container" :style="styles">
    <div id="drawer-wrapper">
      <div id="drawer-header">
        <slot name="header"/>
      </div>
      <div id="drawer-content">
        <div id="dropdown-container">
          <slot name="dropdown"/>
        </div>
        <div id="links-container" class="">
          <slot name="links"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Drawer',
  props: {
    styles: {
      type: Object,
      required: true,
      default: () => ({})
    },
  }
}
</script>

<style lang="sass" scoped>
#drawer-container
  @apply bg-white
  @apply h-screen
  @apply pl-6
  @apply overflow-hidden
  @apply box-border
  @apply rounded-sm
  @apply flex
  @apply flex-col
  transition: width .3s ease-in-out, padding .3s ease-in-out
  border-radius: 2px
  position: absolute

#drawer-wrapper
  width: 250px
  @apply flex-grow

#drawer-header
  font-size: 11px
  color: #404040
  @apply pt-4
  @apply pb-1
  @apply pr-1

#drawer-content
  @apply h-full
  @apply w-full
  overflow-y: hidden

#links-container
  // @apply mt-4
  @apply min-w-full
  @apply pl-2
</style>
