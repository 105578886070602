




































































import Label from '@/components/label/Label.vue'
import HelperText from '@/components/helper-text/HelperText.vue'
import IconsContainer from '@/components/select/IconsContainer.vue'
import { helperText } from '@/components/props/index'
import { INPUT_TYPES } from '@/constants'
// import numeral from 'numeral'

export type CharCountStyle = {
  justifyContent: string;
}

export default {
  name: 'Textfield',
  components: {
    Label,
    HelperText,
    IconsContainer,
  },
  inheritAttrs: false,
  props: {
    helperText,
    value: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
      required: false,
      validator: (value) => value.length <= 45
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    showClearAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputType: {
      type: String,
      required: false,
      default: INPUT_TYPES.TEXT,
      validator (value: string): boolean {
        return Object.values(INPUT_TYPES).includes(value)
      },
    },
    onInput: {
      type: [Function, undefined],
      required: false,
      default: undefined
    },
    charCountLimit: {
      type: Number,
      required: false,
      validator: (value) => typeof value === 'number' && value > 0
    },
    onkeypress: {
      type: [Function, undefined],
      required: false,
      default: undefined
    }
  },
  data () {
    return {
      INPUT_TYPES,
      showClose: false,
      hovering: false,
    }
  },
  computed: {
    listeners (): object {
      return {
        ...this.$listeners,
        input: this.handleInput,
        blur: this.handleBlur,
        focus: this.handleFocus,
      }
    },
    charCounter (): string {
      return `${this.valueLength} / ${this.charCountLimit}`
    },
    classes (): string[] {
      return [
        'default-input',
        this.active ? 'active-input' : 'disabled-input'
      ]
    },
    disabled (): boolean {
      return this.$attrs.disabled
    },
    readonly (): boolean {
      return this.$attrs.readonly
    },
    active (): boolean {
      return !this.disabled && !this.readonly
    },
    hasValue (): boolean {
      return this.value && this.value.length > 0
    },
    valueLength (): number {
      return this.value?.length || 0
    },
    showCharCount (): boolean {
      return typeof this.charCountLimit === 'number' && this.charCountLimit > 0
    },
    charCountStyles (): CharCountStyle {
      const alignment = this.showCharCount && !this.helperText ? 'flex-end' : 'space-between'
      return {
        justifyContent: alignment
      }
    }
  },
  methods: {
    clearInput (): void {
      this.showClose = false
      this.$emit('input', null)
    },
    handleBlur (): void {
      if (this.hasValue) {
        this.showClose = true
      }
    },
    handleFocus (): void {
      this.showClose = false
    },
    handleInput (event: Event): void {
      const target = event.target as HTMLInputElement
      this.$emit('input', target.value)
    }
  },
}
