import numeral from 'numeral'
import { OPTIONS_POSITIONS } from '@/constants'
import { AutocompleteObject, Fux, ElementRect, XPosition, OptionsYPosition, OptionsXPosition } from '@/types'

export const toCurrency = (value: string | number): string => {
  const type = typeof value

  const parseOptions = {
    string: (v: string) => v.split('.')[0],
    number: (v: string) => parseInt(v)
  }
  const parser = parseOptions[type]
  const sanitizedValue = parser ? parser(value) : 0

  return numeral(sanitizedValue).format('$0,0')
}

export const sortByName = (
  first: Record<string, string>,
  second: Record<string, string>
): number => {
  if (first.name < second.name) return -1
  if (first.name > second.name) return 1
  return 0
}

export const sortByFieldDesc = (field: string) => (
  first: Record<string, string>,
  second: Record<string, string>,
) => {
  if (first[field] > second[field]) return -1
  if (first[field] < second[field]) return 1
  return 0
}

export const sortByEmail = (
  first: Record<string, string>,
  second: Record<string, string>,
) => {
  if (first.email < second.email) return -1
  if (first.email > second.email) return 1
  return 0
}

export const sortByMethod = (func: { (arg: { [k: string]: string }): string }) => {
  return (
    first: Record<string, string>,
    second: Record<string, string>,
  ): number => {
    if (func(first) < func(second)) return -1
    if (func(first) > func(second)) return 1
    return 0
  }
}

export const sortByPriority = (
  first: Record<string, string>,
  second: Record<string, string>,
) => {
  if (first.priority < second.priority) return -1
  if (first.priority > second.priority) return 1
  return 0
}

export const sortByFieldName = (
  first: Record<string, string>,
  second: Record<string, string>,
) => {
  if (first.field_name < second.field_name) return -1
  if (first.field_name > second.field_name) return 1
  return 0
}

/**
  * flips the key/values
  * @param {Object} obj
  * @return {Object}
  */
export const reverseObject = (obj: Record<string, string>) => Object.keys(obj)
  .reduce((acc, key): Record<string, string> => {
    acc[obj[key]] = key
    return acc
  }, {})

// doesn't handle function and date properties well
export const payloadDeepCopy = (obj: Record<string, string | number | boolean>) => JSON.parse(JSON.stringify(obj))

/**
  * creates FormData instance from object
  * @param {object} obj
  * @return {FormData}
  */
export const createFormData = (obj: Record<string, string | Blob>): FormData => {
  const formData = new FormData()
  Object.keys(obj).forEach(key => formData.append(key, obj[key]))
  return formData
}

// enrich autocoplete objects https://github.com/vuematerial/vue-material/issues/1243
export const createAutocompleteObject = (
  { data, key }: { data: Fux; key: string }
): AutocompleteObject => {
  return {
    ...data,
    toLowerCase: () => data[key].toLowerCase(),
    toString: () => data[key]
  }
}

export const getElementRect = (el: HTMLElement): ElementRect => {
  const rect = el.getBoundingClientRect()
  if (!rect) {
    console.warn('cannot get control ref rect')
    return {
      left: 0,
      top: 0,
      width: 0,
      height: 0,
    }
  }
  return {
    left: rect.x,
    top: rect.y,
    width: rect.width,
    height: rect.height,
  }
}

export const getOptionsYPosition = (
  optsHeight: number,
  container: HTMLElement,
  inverted: boolean,
  offsetHeight: number,
): OptionsYPosition => {
  const PADDING = 10
  if (container?.clientHeight) {
    const coordinates = getElementRect(container)
    const yCoordinate = Math.ceil(coordinates.top)
    const newYCoordinate = inverted
      ? yCoordinate - optsHeight - offsetHeight - PADDING
      : yCoordinate + (coordinates.height + PADDING) - offsetHeight
    return {
      top: `${newYCoordinate.toString()}px`
    }
  }
  return null
}

export const getOptionsXPosition = (
  optsContainer: HTMLElement,
  position: XPosition,
  optionsEdge: boolean,
): OptionsXPosition => {
  let newPosition
  if (position) {
    newPosition = position
  } else {
    newPosition = optionsEdge === true ? OPTIONS_POSITIONS.RIGHT : OPTIONS_POSITIONS.LEFT
  }
  const coordinates = getElementRect(optsContainer)
  const xCoordinate = Math.ceil(coordinates.left)
  const widthToRightSideOfControl = Math.ceil(coordinates.width) + xCoordinate
  const newXCoordinate = newPosition === OPTIONS_POSITIONS.RIGHT
    ? window?.innerWidth - widthToRightSideOfControl
    : xCoordinate

  return {
    [newPosition]: `${newXCoordinate.toString()}px`
  }
}

export const addHcsStylePriority = (fieldName: string) => {
  const styleFieldOrder = {
    top: 'aa',
    bottom: 'ab',
    left: 'ac',
    right: 'ad',
    height: 'ae',
    minimized_height: 'af',
    width: 'ag',
  }
  if (styleFieldOrder[fieldName]) return styleFieldOrder[fieldName]
  if (fieldName.includes('color')) return 'ah'
  if (fieldName.includes('header')) return 'ai'
  if (fieldName.includes('font')) return 'aj'
  if (fieldName.includes('enable')) return 'az'
  return 'ay'
}

export const addHcsCopyPriority = (fieldName: string) => {
  if (fieldName.includes('header')) return 'ba'
  if (fieldName.includes('body')) return 'bb'
  return 'bz'
}

export const addHcsComponentPriority = (fieldType: string) => {
  if (fieldType === 'str') return 'ca'
  if (fieldType === 'bool') return 'cb'
  return 'cz'
}

export const generateHcsPriority = (hcsParam: Record<string, string>[]) => {
  hcsParam.forEach(function (hcs) {
    if (hcs.field_category === 'style') {
      hcs.priority = addHcsStylePriority(hcs.field_name)
    }
    if (hcs.field_category === 'copy') {
      hcs.priority = addHcsCopyPriority(hcs.field_name)
    }
    if (hcs.field_category === 'component') {
      hcs.priority = addHcsComponentPriority(hcs.field_type)
    }
  })
  // return hcs sorted by field name alphabetically
  return hcsParam.sort(sortByFieldName)
}
