import Vue from 'vue'

export const mutations = {
  SET_USERS_OBJECT (state, users) {
    state.usersObject = users
  },
  SET_HOTELS_OBJECT (state, hotels) {
    state.hotelsObject = hotels
  },
  ADD_HOTEL_OBJECT (state, hotel) {
    Vue.set(state.hotelsObject, hotel.id, hotel)
  },
  SET_GROUPS_OBJECT (state, groups) {
    state.groupsObject = groups
  },
  SET_EMAIL_HOVER (state, { email, bool }) {
    Vue.set(state.usersObject[email], 'hoverEmail', bool)
  }
}
