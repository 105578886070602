const keys = {
  SET_PAGE: 'SET_PAGE',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_PAGE_TOTAL: 'SET_PAGE_TOTAL',
  SET_PAGE_INFO: 'SET_PAGE_INFO'
}

export const pagination = {
  keys,
  mutations: {
    [keys.SET_PAGE] (state, page) {
      state.pageInfo.page = page
    },
    [keys.SET_PAGE_SIZE] (state, pageSize) {
      state.pageInfo.pageSize = pageSize
    },
    [keys.SET_PAGE_TOTAL] (state, total) {
      state.pageInfo.total = total
    },
    [keys.SET_PAGE_INFO] (state, pageInfo) {
      state.pageInfo = pageInfo
    },
  }
}
