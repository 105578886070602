<template>
  <div
    id="looker-wrapper"
    ref="looker"
    class="md-layout-item"
  >
    <iframe
      v-if="LOOKER_EMBED_URL()"
      id="looker"
      ref="lookerIframe"
      :src="LOOKER_EMBED_URL()"
      class="mb-12 pb-12"
      scrolling="no"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Looker',
  computed: {
    ...mapGetters([
      'LOOKER_EMBED_URL',
    ])
  },
  methods: {
    ...mapActions([
      'FETCH_LOOKER_EMBED_URLS'
    ]),
  },
  async created () {
    this.FETCH_LOOKER_EMBED_URLS(this.$route)
  },
  beforeMount () {
    window.addEventListener('message', (event) => {
      try {
        const data = JSON.parse(event.data)
        if (data.type === 'page:properties:changed') {
          const looker = this.$refs.lookerIframe
          if (looker) looker.style.height = data.height + 60 + 'px'
        }
      } catch (_) {
        // do not print or throw
      }
    })
  },
  // created() is called every time now that were are using routes
  // async beforeRouteUpdate (to, from, next) {
  //   console.log('[Looker] beforeRouteUpdate')
  //   await this.FETCH_LOOKER_EMBED_URLS(to.name)
  //   next()
  // }
}
</script>

<style lang="sass" scoped>
</style>
