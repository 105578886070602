<template>
  <div
    id="reward-algorithms-container"
    :style="{ width: '100%', height: 'auto', backgroundColor: 'white' }"
    class="md-content-item"
  >
    <Header :copy="{ title: copy.title, subtitle: copy.subtitle }">
      <StepperButton
        id="new-algo-btn"
        slot="button"
        :disabled="false"
        :copy="copy.button"
        @clickEvent="navToAlgoWizardNew"
      />
    </Header>
    <div :style="{ height: 'auto', width: '100%' }">
      <RewardAlgorithmsTable />
    </div>
  </div>
</template>

<script>
import { userViews } from '@/constants'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton.vue'
import RewardAlgorithmsTable from '@/components/hotel-dashboard/reward-algorithms-manager/RewardAlgorithmsTable'
import Header from '@/components/common/Header'

export default {
  name: 'RewardAlgorithmsContainer',
  components: {
    StepperButton,
    RewardAlgorithmsTable,
    Header
  },
  data () {
    return {
      show: false,
      copy: {
        title: 'Campaign Reward AI',
        subtitle: 'As a premiere hotel partner, we showcase your rewards via an algorithm to high-spending travel consumers who book at your hotel.',
        button: 'New Algorithm'
      }
    }
  },
  methods: {
    navToAlgoWizardNew () {
      this.$router.push({
        name: userViews.REWARD_ALGOS_WIZARD_NEW,
        params: {
          hotelId: this.$route.params.hotelId
        }
      })
    }
  }
}
</script>

<style lang="sass">
#reward-algorithms-container
  font-family: Lato !important
  color: black
</style>
