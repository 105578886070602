/**
 * @important
 * verifyAuthAndView references this import to
 * allow for better mocking and testing capabilities.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as routerModule from './index.js'
import Vue from 'vue'
import Vuex from 'vuex'
import Router from 'vue-router'
import store from '@/store'

import HotelDashboard from '@/components/dashboard/HotelDashboard'
import MerchantDashboard from '@/components/dashboard/MerchantDashboard'
import AdminDashboard from '@/components/dashboard/AdminDashboard'
import LoginContainer from '@/components/login/LoginContainer'
import ResetContainer from '@/components/login/ResetContainer'
import RecoverContainer from '@/components/login/RecoverContainer'
import ConfirmContainer from '@/components/login/ConfirmContainer'
import NotFoundContainer from '@/components/util/NotFoundContainer'
import Looker from '@/components/looker/Looker'
import Users from '@/components/admin/Users'
import PromotionsContainer from '@/components/merchant-dashboard/PromotionsContainer'
import PromotionsWizardContainer from '@/components/merchant-dashboard/PromotionsWizard/PromotionsWizardContainer'
import AccountContainer from '@/components/merchant-dashboard/AccountContainer'
import FuxHome from '@/components/hotel-dashboard/funnel-ux/FuxHome'
import BulkUpdateContainer from '@/components/hotel-dashboard/bulk-update/BulkUpdateContainer'
import BulkDesignDuplication from '@/components/hotel-dashboard/bulk-update/bulk-duplicate/BulkDesignDuplication'
import BulkDesignEditor from '@/components/hotel-dashboard/bulk-update/bulk-edit/BulkDesignEditor'
import OnPropertyRewardsContainer from '@/components/hotel-dashboard/on-property-rewards-manager/OnPropertyRewardsContainer'
import OnPropertyRewardsWizardContainer from '@/components/hotel-dashboard/on-property-rewards-manager/OnPropertyRewardsWizardContainer'
import RewardAlgorithmsContainer from '@/components/hotel-dashboard/reward-algorithms-manager/RewardAlgorithmsContainer'
import RewardAlgorithmsWizard from '@/components/hotel-dashboard/reward-algorithms-manager/wizard/RewardAlgorithmsWizard'
import EmailDesignLibraryContainer from '@/components/hotel-dashboard/email-design-manager/EmailDesignLibraryContainer'
import EmailDesignWizard from '@/components/hotel-dashboard/email-design-manager/wizard/EmailDesignWizard'
import CampaignManagerContainer from '@/components/hotel-dashboard/campaign-manager/CampaignManagerContainer'
import CampaignEditor from '@/components/hotel-dashboard/campaign-manager/CampaignEditor'
import WorkflowTableContainer from '@/components/hotel-dashboard/workflow-manager/WorkflowTableContainer'
import WorkflowManagerContainer from '@/components/hotel-dashboard/workflow-manager/WorkflowManagerContainer'
import HotelRewardAlgorithmsTable from '@/components/hotel-dashboard/reward-algorithms-manager/HotelRewardAlgorithmsTable'
import FlexWizard from '@/components/hotel-dashboard/flex/FlexWizard'

import { userViews, adminViews, merchantViews, appTypes } from '@/constants'
import { getGroupHotelIds } from '@/lib/looker/embedPayloadHelpers.ts'
import resolveConfig from 'tailwindcss/resolveConfig'
import * as tailwindConfig from '../../tailwind.config'
import endpoints from '@/config/endpoints'
import RewardAlgorithmsWizardCopy from '@/components/hotel-dashboard/reward-algorithms-manager/wizard/shared/copy'

const themeConfig = resolveConfig(tailwindConfig)

Vue.use(Router)
Vue.use(Vuex)

export const hotelDashboardComponents = {
  content: Looker,
}

/** either allows use to move to next route or denies them.
  * user must have valid session AND access to route set in roles config
  * if user is not auth'd, will attempt to verify session.
  * denial will push user back to login.
  */
export const verifySession = async (_store = store) => {
  if (!_store.state.userIsAuth) {
    const session = await _store.state.authController.verifySession()
    if (session && session.isValid) {
      _store.dispatch('MANAGE_TOKEN')
      await _store.dispatch('SET_USER_PROFILE')
      _store.commit('SET_USER_IS_AUTH', true)
    }
  }
}

export const setRedirect = (to, _window = window) => {
  _window.sessionStorage && _window.sessionStorage.setItem('redirect', to.path)
}

export const removeRedirect = (_window = window) => {
  _window.sessionStorage && _window.sessionStorage.removeItem('redirect')
}

/**
  * if user auths & view allowed, will change route.
  * overrideEnv should be used to bypass the early
  * return for unit tests.
  * the early return should be used for unrelated
  * unit tests and e2e tests.
  */
export const verifyAuthAndView = async (to, _, next, _store = store, overrideEnv = false) => {
  await routerModule.verifySession()
  /**
   * allow tests integrated with the router to bypass
   */
  if (!overrideEnv && process.env.NODE_ENV === 'test') {
    return next()
  }

  /**
   * the user is not authenticated. store the
   * path and redirect after they authenticate.
   * this handles the root path or nested paths.
   */
  if (!_store.state.userIsAuth) {
    routerModule.setRedirect(to)
    next('/')
    return
  }

  /**
   * the user is authenticated but cannot access
   * this view. clean up any stale redirect data.
   */
  if (!_store.getters.VIEW_ALLOWED(to.name)) {
    routerModule.removeRedirect()
    next('/404')
    return
  }

  /**
   * the user is authenticated and can access
   * the view. clean up any stale redirect data.
   */
  routerModule.removeRedirect()
  next()
}

export const getHotelIds = () => {
  return getGroupHotelIds({
    isGroupUser: store.getters.IS_GROUP_USER,
    isAdminUser: store.getters.IS_ADMIN,
    hotel: store.state.primaryHotel
  })
}

/**
  * routes are matched in order they are evaluated. bang operator as last route
  * is used to catch a route that is never matched.
  *
  * NOTE layout settings to be configured on routes can be added to the meta
  * object of each route.
  * NOTE child routes do not inherit their parent's meta object
  */
export const routerConfig = {
  mode: 'history',
  saveScrollPosition: true,
  routes: [
    {
      name: '404',
      path: '/404',
      component: NotFoundContainer
    },
    {
      path: '/',
      component: LoginContainer
    },
    {
      path: '/login',
      component: LoginContainer
    },
    {
      path: '/reset',
      component: ResetContainer
    },
    {
      path: '/recover',
      component: RecoverContainer
    },
    {
      path: '/confirm',
      component: ConfirmContainer
    },
    {
      path: '/dashboard/:hotelId?',
      name: 'dashboard',
      redirect: to => ({
        name: userViews.HOMEPAGE,
        params: to.params,
      }),
      component: HotelDashboard,
      beforeEnter: verifyAuthAndView,
      children: [
        {
          name: userViews.HOMEPAGE,
          path: '',
          components: {
            content: () => import('@/components/dashboard/Homepage')
          },
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
          }
        },
        {
          name: userViews.REWARD_METRICS,
          path: userViews.REWARD_METRICS,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
            looker: {
              hotelId: getHotelIds,
            }
          }
        },
        {
          name: userViews.REVENUE_METRICS,
          path: userViews.REVENUE_METRICS,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
          }
        },
        {
          name: userViews.PROGRAM_ENGAGEMENT,
          path: userViews.PROGRAM_ENGAGEMENT,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
          }
        },
        {
          name: userViews.ROI,
          path: userViews.ROI,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
          }
        },
        /**
         * retain reporting
         */
        {
          name: userViews.GROWTH_SCALE,
          path: userViews.GROWTH_SCALE,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
            looker: {
              hotelId: getHotelIds,
            },
          }
        },
        {
          name: userViews.PROGRAM_IMPACT,
          path: userViews.PROGRAM_IMPACT,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
            looker: {
              hotelId: getHotelIds,
            },
          }
        },
        {
          name: userViews.PROGRAM_DEMOGRAPHICS,
          path: userViews.PROGRAM_DEMOGRAPHICS,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
            looker: {
              hotelId: getHotelIds,
            },
          }
        },
        {
          name: userViews.LOYALTY_MEMBERS,
          path: userViews.LOYALTY_MEMBERS,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
            looker: {
              hotelId: getHotelIds,
            },
          }
        },
        {
          name: userViews.UPCOMING_BOOKINGS,
          path: userViews.UPCOMING_BOOKINGS,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
            looker: {
              hotelId: getHotelIds,
            },
          }
        },
        {
          name: userViews.MEMBER_PROFILE,
          path: userViews.MEMBER_PROFILE,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
            looker: {
              hotelId: getHotelIds,
            },
          },
          beforeEnter (to, from, next) {
            // customer_id query param required for route
            if (to.query.customer_id) {
              next()
            } else {
              next({ name: '404' })
            }
          }
        },
        {
          name: userViews.LOYALTY_TRAFFIC,
          path: userViews.LOYALTY_TRAFFIC,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
            looker: {
              hotelId: getHotelIds,
            },
          }
        },
        {
          name: userViews.ACCOUNT,
          path: userViews.ACCOUNT,
          props: {
            content: () => {
              return {
                initFormInput: store.getters['hotel/currentHotelObj'],
              }
            }
          },
          components: {
            content: () => import('@/components/dashboard/AccountContainer')
          },
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
          }
        },
        {
          name: userViews.SPLIT_TESTS,
          path: userViews.SPLIT_TESTS,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
          }
        },
        {
          name: userViews.DESIGN,
          path: userViews.DESIGN,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
          }
        },
        {
          path: 'funnel-ux',
          name: userViews.FUNNEL_UXS,
          components: {
            content: FuxHome
          },
          beforeEnter: verifyAuthAndView,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
          },
        },
        {
          name: userViews.OP_REWARDS,
          path: 'on-property-rewards',
          components: {
            content: OnPropertyRewardsContainer
          },
          beforeEnter: verifyAuthAndView,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: false
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        {
          name: userViews.OP_REWARDS_WIZARD_EDIT,
          path: 'on-property-rewards/:rewardUuid/edit',
          components: {
            content: OnPropertyRewardsWizardContainer
          },
          props: {
            content: {
              editing: true
            }
          },
          beforeEnter: verifyAuthAndView,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: false
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        {
          name: userViews.OP_REWARDS_WIZARD_NEW,
          path: 'on-property-rewards/new',
          components: {
            content: OnPropertyRewardsWizardContainer
          },
          props: {
            content: {
              editing: false
            }
          },
          beforeEnter: verifyAuthAndView,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: false
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        {
          name: userViews.GROUP,
          path: userViews.GROUP,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: false
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
          }
        },
        {
          name: userViews.REWARD_ALGOS,
          path: 'campaign-reward-algorithms',
          components: {
            content: RewardAlgorithmsContainer
          },
          props: {
            content: {
              editing: false,
              endpoint: endpoints.cradle.emailMarketingRewardAlgorithmRoute,
            }
          },
          beforeEnter: verifyAuthAndView,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        // edit
        {
          name: userViews.REWARD_ALGOS_WIZARD_EDIT,
          path: 'campaign-reward-algorithms/:algoId/edit',
          components: {
            content: RewardAlgorithmsWizard
          },
          props: {
            content: {
              editing: true,
              endpoint: endpoints.cradle.emailMarketingRewardAlgorithmRoute,
              indexRouteName: userViews.REWARD_ALGOS,
              description: RewardAlgorithmsWizardCopy.emailMarketingRewardAlgoithms.description
            }
          },
          beforeEnter: verifyAuthAndView,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        // new
        {
          name: userViews.REWARD_ALGOS_WIZARD_NEW,
          path: 'campaign-reward-algorithms/new',
          components: {
            content: RewardAlgorithmsWizard
          },
          props: {
            content: {
              editing: false,
              endpoint: endpoints.cradle.emailMarketingRewardAlgorithmRoute,
              indexRouteName: userViews.REWARD_ALGOS,
              description: RewardAlgorithmsWizardCopy.emailMarketingRewardAlgoithms.description
            }
          },
          beforeEnter: verifyAuthAndView,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        // email design library
        {
          name: userViews.EMAIL_DESIGNS,
          path: 'email-designs',
          components: {
            content: EmailDesignLibraryContainer
          },
          props: {
            content: {
              editing: false
            }
          },
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        {
          name: userViews.EMAIL_DESIGNS_EDIT,
          path: 'email-designs/:emailDesignId/edit',
          components: {
            content: EmailDesignWizard
          },
          props: {
            content: {
              editing: true
            }
          },
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: false
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        {
          name: userViews.EMAIL_DESIGNS_NEW,
          path: 'email-designs/new',
          components: {
            content: EmailDesignWizard
          },
          props: {
            content: {
              editing: false
            }
          },
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: false
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        // campaign manager
        {
          name: userViews.CAMPAIGNS,
          path: 'campaigns',
          components: {
            content: CampaignManagerContainer
          },
          props: {
            content: {
              editing: false
            }
          },
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        {
          name: userViews.CAMPAIGNS_NEW,
          path: 'campaign/new',
          components: {
            content: CampaignEditor
          },
          props: {
            content: {
              editing: false
            }
          },
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: false
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        {
          name: userViews.CAMPAIGNS_EDIT,
          path: 'campaign/:campaignId/edit',
          components: {
            content: CampaignEditor
          },
          props: {
            content: {
              editing: true
            }
          },
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: false
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        // workflows
        {
          name: userViews.WORKFLOWS,
          path: userViews.WORKFLOWS,
          components: {
            content: WorkflowTableContainer
          }
        },
        {
          name: userViews.WORKFLOWS_NEW,
          path: 'workflows/new',
          components: {
            content: WorkflowManagerContainer
          },
          props: {
            content: {
              editing: false
            }
          },
          meta: {
            backgroundColor: themeConfig.theme.colors.gray['25']
          },
        },
        {
          name: userViews.WORKFLOWS_EDIT,
          path: 'workflows/:workflowId/edit',
          components: {
            content: WorkflowManagerContainer
          },
          props: {
            content: {
              editing: true
            }
          },
          meta: {
            backgroundColor: themeConfig.theme.colors.gray['25']
          },
        },
        {
          name: userViews.WORKFLOWS_METRICS_EMAIL_AND_OFFER,
          path: `workflows/:workflowId/${userViews.WORKFLOWS_METRICS_EMAIL_AND_OFFER}`,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
            looker: {
              hotelId: getHotelIds,
              requiresWorkflowId: true
            },
          }
        },
        {
          name: userViews.WORKFLOWS_METRICS_EMAIL_ONLY,
          path: `workflows/:workflowId/${userViews.WORKFLOWS_METRICS_EMAIL_ONLY}`,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
            looker: {
              hotelId: getHotelIds,
              requiresWorkflowId: true
            },
          }
        },
        {
          name: userViews.WORKFLOWS_METRICS_OFFER_ONLY,
          path: `workflows/:workflowId/${userViews.WORKFLOWS_METRICS_OFFER_ONLY}`,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
            looker: {
              hotelId: getHotelIds,
              requiresWorkflowId: true
            },
          }
        },
        {
          name: userViews.WORKFLOWS_METRICS_EMAIL_DESIGN_TEST,
          path: `workflows/:workflowId/${userViews.WORKFLOWS_METRICS_EMAIL_DESIGN_TEST}`,
          components: hotelDashboardComponents,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
            looker: {
              hotelId: getHotelIds,
              requiresWorkflowId: true
            },
          }
        },
        // reward algos listed under Embedded Designs
        {
          name: userViews.HOTEL_REWARD_ALGOS,
          path: 'reward-algorithms',
          components: {
            content: HotelRewardAlgorithmsTable
          },
          props: {
            content: {
              editing: false,
              endpoint: endpoints.cradle.hotelRewardAlgorithmRoute
            }
          },
          beforeEnter: verifyAuthAndView,
          meta: {
          // deprecated
          // app: appTypes.DASHBOARD,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        // edit
        {
          name: userViews.HOTEL_REWARD_ALGOS_WIZARD_EDIT,
          path: 'reward-algorithms/:algoId/edit',
          components: {
            content: RewardAlgorithmsWizard
          },
          props: {
            content: {
              editing: true,
              endpoint: endpoints.cradle.hotelRewardAlgorithmRoute,
              indexRouteName: userViews.HOTEL_REWARD_ALGOS,
              description: RewardAlgorithmsWizardCopy.hotelRewardAlgorithms.description
            }
          },
          beforeEnter: verifyAuthAndView,
          meta: {
            // app: appTypes.DASHBOARD,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        // new
        {
          name: userViews.HOTEL_REWARD_ALGOS_WIZARD_NEW,
          path: 'reward-algorithms/new',
          components: {
            content: RewardAlgorithmsWizard
          },
          props: {
            content: {
              editing: false,
              endpoint: endpoints.cradle.hotelRewardAlgorithmRoute,
              indexRouteName: userViews.HOTEL_REWARD_ALGOS,
              description: RewardAlgorithmsWizardCopy.hotelRewardAlgorithms.description
            }
          },
          beforeEnter: verifyAuthAndView,
          meta: {
            // app: appTypes.DASHBOARD,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        {
          name: userViews.FLEX_NEW,
          path: 'flex/new',
          components: {
            content: FlexWizard
          },
          beforeEnter: verifyAuthAndView,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        {
          name: userViews.ON_DEMAND_METRICS,
          path: userViews.ON_DEMAND_METRICS,
          components: hotelDashboardComponents,
          meta: {
            layout: {},
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              },
            },
          }
        },
      ]
    },
    {
      path: '/admin',
      name: adminViews.ADMIN,
      component: AdminDashboard,
      redirect: '/admin/users', // TODO replace w/ admin home v2
      beforeEnter: verifyAuthAndView,
      children: [
        {
          name: adminViews.BULK_UPDATE,
          path: 'bulk-update',
          components: {
            content: BulkUpdateContainer
          },
          beforeEnter: verifyAuthAndView,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: false
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        {
          name: adminViews.BULK_DESIGN_DUPLICATION,
          path: 'bulk-update/duplicate',
          components: {
            content: BulkDesignDuplication
          },
          beforeEnter: verifyAuthAndView,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: false
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        {
          name: adminViews.BULK_DESIGN_EDITOR,
          path: 'bulk-update/edit',
          components: {
            content: BulkDesignEditor
          },
          beforeEnter: verifyAuthAndView,
          meta: {
            app: appTypes.DASHBOARD,
            layout: {
              dropdown: false
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.MERCHANT,
                ]
              }
            }
          },
        },
        {
          name: adminViews.USERS,
          path: adminViews.USERS,
          components: {
            content: Users,
            dropdown: undefined,
          },
          meta: {
            app: appTypes.ADMIN,
            layout: {
              dropdown: false,
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.DASHBOARD,
                  appTypes.MERCHANT,
                ]
              },
            },
          },
        },
      ]
    },
    {
      name: merchantViews.MERCHANT,
      path: '/merchant/',
      // TODO replace w/ merchant home v2
      redirect: to => ({
        name: merchantViews.MERCHANT_PROMOTIONS,
        params: to.params
      }),
    },
    {
      path: '/merchant/new/',
      beforeEnter: verifyAuthAndView,
      component: MerchantDashboard,
      meta: {
        layout: {
          dropdown: false,
        },
      },
      children: [
        {
          name: merchantViews.MERCHANT_ACCOUNT_NEW,
          path: '',
          components: {
            content: AccountContainer
          },
          props: {
            content: () => ({
              initFormInput: {}
            })
          },
          meta: {
            layout: {
              dropdown: false,
            },
          },
        }
      ]
    },
    {
      /* mdb will handle initial nav w/o :id */
      path: '/merchants/:id?',
      name: 'merchant',
      component: MerchantDashboard,
      beforeEnter: verifyAuthAndView,
      meta: {
        layout: {
          dropdown: true,
        },
      },
      children: [
        {
          name: merchantViews.MERCHANT_PROMOTIONS_WIZARD,
          path: 'promotions-wizard',
          components: {
            content: PromotionsWizardContainer,
          },
          meta: {
            app: appTypes.MERCHANT,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.DASHBOARD,
                ]
              },
            },
          },
        },
        {
          name: merchantViews.MERCHANT_PROMOTIONS,
          path: 'promotions',
          components: {
            content: PromotionsContainer,
          },
          meta: {
            app: appTypes.MERCHANT,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.DASHBOARD,
                ]
              },
            },
          },
        },
        {
          name: merchantViews.MERCHANT_PROMOTIONS_WIZARD_NEW,
          path: 'promotion/new',
          components: {
            content: PromotionsWizardContainer,
          },
          meta: {
            app: appTypes.MERCHANT,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.DASHBOARD,
                ]
              },
            },
          },
        },
        {
          name: merchantViews.MERCHANT_PROMOTIONS_WIZARD_EDIT,
          path: 'promotion/:uuid/edit',
          components: {
            content: PromotionsWizardContainer,
          },
          props: {
            content: route => ({
              promotionObj: store.getters['merchant/promotionObj']({ uuid: route.params.uuid })
            }),
          },
          meta: {
            app: appTypes.MERCHANT,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.DASHBOARD,
                ]
              },
            },
          },
        },
        {
          name: merchantViews.MERCHANT_ANALYTICS,
          path: 'activity',
          components: {
            content: Looker,
          },
          meta: {
            app: appTypes.MERCHANT,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.DASHBOARD,
                ]
              },
            },
          },
        },
        {
          name: merchantViews.MERCHANT_ACCOUNT,
          path: 'account',
          components: {
            content: AccountContainer,
          },
          props: {
            content: () => ({
              initFormInput: store.getters['merchant/merchantInView']
            })
          },
          meta: {
            app: appTypes.MERCHANT,
            layout: {
              dropdown: true
            },
            nav: {
              admin: {
                dashboardToggles: [
                  appTypes.ADMIN,
                  appTypes.DASHBOARD,
                ]
              },
            },
          },
        },
      ]
    },
    {
      path: '/logout',
      name: 'logout',
      async beforeEnter (_, __, next) {
        await store.dispatch('LOGOUT_USER')
        next('/')
      },
    },

    /**
     * use beforeEnter hooks to navigate to external links
     * to avoid render logic to display <router-link> or <a>
     */
    {
      path: `/${userViews.LOYALTY_PORTAL_EDITOR}`,
      name: userViews.LOYALTY_PORTAL_EDITOR,
      beforeEnter () {
        if (store.getters.LOYALTY_PORTAL_URL) {
          console.log('store.getters.LOYALTY_PORTAL_URL', store.getters.LOYALTY_PORTAL_URL)
          window.location.href = store.getters.LOYALTY_PORTAL_URL
        } else {
          return false
        }
      }
    },
    {
      path: '*',
      redirect: { name: '404' }
    },
  ]
}

export default new Router(routerConfig)
