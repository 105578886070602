










import { Route } from 'vue-router'
import { isEqual } from 'lodash'
import { Tab, InitialTab } from '@/types'

export default {
  name: 'Tab',
  inject: [
    'Tabs__getIdx',
    'Tabs__isActive',
    'Tabs__registerTab',
    'Tabs__updateTab',
    'Tabs__tabIdx'
  ],
  props: {
    /**
     * must be unique on each instance used in Tabs
     */
    title: {
      type: String,
      required: true
    },
    /**
     * TODO - route to match on
     */
    to: {
      type: Object as () => Route,
      required: false,
      default: () => ({ hash: undefined })
    },
    /**
     * prevent user from selecting as active tab
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data () {
    return {
    }
  },
  computed: {
    tab (): InitialTab {
      return {
        title: this.title,
        disabled: this.disabled,
        ref: undefined, // will be added by parent when registering
      }
    },
    show (): boolean {
      return this.Tabs__isActive(this.tab)
    },
  },
  watch: {
    tab: {
      deep: true,
      handler (newValue: Tab, oldValue: Tab) {
        if (!isEqual(newValue, oldValue)) {
          const idx = this.Tabs__getIdx(oldValue)
          this.Tabs__updateTab(idx, newValue)
        }
      }
    }
  },
  mounted (): void {
    this.Tabs__registerTab(this.tab)
  },
}
