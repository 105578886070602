import PostMessageUtils from '@/lib/PostMessageUtils'
import { dockOpts } from '@/components/hotel-dashboard/editor/constants'
import { EditorUtils } from '@/editor/utils'
import { userTypes } from '@/constants'

const DOCKING_EVENT_KEY = 'sw-editor-iframe-docking-event'

export default {
  namespaced: true,
  state: {
    minimized: false,
    dockPosition: dockOpts.LEFT,
    showDialog: false,
    isLoading: false,
    app: undefined, // can be undefined for smart-widget but will be `lp` for loyalty portal
  },
  getters: {
    userType: (state, getters, rootState) => {
      const token = rootState.authController.getCognitoToken()
      if (token) {
        return EditorUtils.getUserType({ token })
      }
    },
    isAdmin: (state, getters) => getters.userType === userTypes.ADMIN,
    isHotel: (state, getters) => getters.userType === userTypes.HOTEL,
    dockPosition: (state) => state.dockPosition,
  },
  mutations: {
    SET_MINIMIZED (state, bool) {
      console.log('[SET_MINIMIZED', bool)
      state.minimized = bool
    },
    SET_DOCK_POSITION (state, pos) {
      state.dockPosition = pos
    },
    SHOW_DIALOG (state, bool) {
      state.showDialog = bool
    },
    SET_IS_LOADING (state, bool) {
      state.isLoading = bool
    },
    SET_APP (state, app) {
      console.log('[SET_APP]', app)
      state.app = app
    },
  },
  actions: {
    SET_MINIMIZED ({ commit }, bool) {
      commit('SET_MINIMIZED', bool)
    },
    SET_DOCK_POSITION ({ commit }, pos) {
      if (Object.values(dockOpts).includes(pos)) {
        commit('SET_DOCK_POSITION', pos)
        PostMessageUtils.sendPostMessage(
          window.parent.window, // smart widget window
          { [DOCKING_EVENT_KEY]: pos }
        )
      } else {
        console.warn('invalid dock position:', pos)
      }
    },
    SHOW_DIALOG ({ commit }, bool) {
      commit('SHOW_DIALOG', bool)
    },
    SET_IS_LOADING ({ commit }, bool) {
      commit('SET_IS_LOADING', bool)
    },
    EXIT_EDITOR () {
      PostMessageUtils.sendPostMessage(
        window.parent.window,
        { sw_editor_exit: true }
      )
    },
    async LOGOUT_USER_BACK_TO_DASHBOARD ({ rootState, dispatch }) {
      await rootState.authController.logout()
      dispatch('EXIT_EDITOR')
    },
  }
}
