const BRAND_LIGHT_COLOR = '#505050'
const BRAND_COLOR = '#333333'
// brand dark

// default light
const DEFAULT_COLOR = '#F0EFF4'
const DEFAULT_DARK_COLOR = '#D7D5E2'

const MODAL_GRAY_BACKDROP = 'rgba(0, 0, 0, 0.5)'

module.exports = {
  future: {
    // removeDeprecatedGapUtilities: true,
    // purgeLayersByDefault: true,
  },
  purge: [],
  theme: {
    fontFamily: {
      sans: ['"Lato"', 'Arial', 'sans-serif'],
      body: ['"Lato"', 'Arial', 'sans-serif']
    },
    extend: {
      fontSize: {
        xs: ['12px', '16px'],
        sm: ['14px', '20px'],
        base: ['16px', '24px'],
        md: ['17px', '24px'],
        lg: ['20px', '28px'],
        xl: ['24px', '32px'],
      },
      colors: {
        brandLight: BRAND_LIGHT_COLOR,
        brand: BRAND_COLOR,
        // brandDark
        // defaultLight
        default: DEFAULT_COLOR,
        defaultDark: DEFAULT_DARK_COLOR,
        modalGrayBackdrop: MODAL_GRAY_BACKDROP,
        gray: {
          20: '#F7F7F7',
          25: '#F9F9F9',
          100: DEFAULT_COLOR,
          125: '#E3E3E7',
          150: DEFAULT_DARK_COLOR,
          200: '#CDCDCD',
          300: '#929194',
          400: '#707070',
          500: '#656565',
          600: '#545454',
          // 700: '', not used currently
          790: BRAND_LIGHT_COLOR,
          800: BRAND_COLOR,
          900: '#111111'
        },
        brown: {
          200: '#c2a483',
          400: '#A78764'
        },
        orange:{
          500: '#FFAA46',
        },
        green: {
          500: '#6DD400'
        },
      }
    }
  },
  variants: {},
  plugins: [],
  experimental: {
    applyComplexClasses: true
  }
}
