



























































































































import DropdownOptions from '@/components/dropdown/options/DropdownOptions.vue'
import Label from '@/components/label/Label.vue'
import ClickOutside from 'vue-click-outside'
import { PRIMARY, DEFAULT } from '@/components/dropdown/constants'
import { computedWidth } from '@/utils/computedWidth'
import IconsContainer from '@/components/select/IconsContainer.vue'
import HelperText from '@/components/helper-text/HelperText.vue'
import { helperText } from '@/components/props/index'
import { OptsStats, ElementRect, OptionsXPosition, OptionsYPosition } from '@/types'
import { getOptionsYPosition, getOptionsXPosition, getElementRect } from '@/utils/misc'
import { OPTIONS_POSITIONS } from '@/constants'
import { throttle } from 'lodash'
import { findOptionsMatch } from '@/utils/dropdown-utils'

export type Option = Record<'label' | 'value' | string, string | number>

export type SelectedEvent =
  | Option
  | Array<Option>
  | undefined

export default {
  name: 'Dropdown',
  directives: {
    ClickOutside,
  },
  components: {
    DropdownOptions,
    Label,
    IconsContainer,
    HelperText,
  },
  provide () {
    return {
      /**
       * provide a utility for DropdownOptions to get the container rect
       * in order to pass to the viewport edge utils
       */
      injected__getParentRect: this.getContainerRect,
    }
  },
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      validator (value) {
        if ([undefined, null].includes(value)) {
          return true
        }
        return ['primary', 'default'].includes(value)
      },
      default: undefined
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    tabindex: {
      type: Number,
      default: 0,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    toggleMethod: {
      type: String,
      default: 'click',
      required: false,
      validator (value) {
        return ['click', 'hover'].includes(value)
      }
    },
    position: {
      type: String,
      default: '',
      required: false,
      validator (value) {
        return [OPTIONS_POSITIONS.LEFT, OPTIONS_POSITIONS.RIGHT, ''].includes(value)
      }
    },
    /**
     * set the width of the dropdown control.
     * use a number for a fixed width or css width values
     */
    controlWidth: {
      type: [Number, String],
      default: 'max-content',
      required: false,
      validator (value) {
        if (typeof value === 'number') {
          return value > 0
        }
        return ['max-content', 'fill'].includes(value)
      }
    },
    /**
     * use Number for fixed width, max-content for the width to be dynamic
     * based on widest option provided or fill to take up the width
     * of the dropdown-container
     */
    optionsWidth: {
      type: [Number, String],
      required: false,
      validator (value) {
        if (typeof value === 'number') {
          return value > 0
        }
        return ['max-content', 'fill'].includes(value)
      },
      default: ''
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    labelKey: {
      type: String,
      required: false,
      default: 'label'
    },
    labelClasses: {
      type: Array,
      required: false,
      default: () => ([])
    },
    /**
     * dropdown option inline classes
     */
    optionsClasses: {
      type: Array,
      required: false,
      validator (value): boolean {
        return value.every(item => typeof item === 'string')
      },
      default: () => ([])
    },
    /**
     * dropdown option inline styles
     */
    optionsStyles: {
      type: Object,
      default: () => ({}),
      required: false
    },
    /**
     * when form is true the selected item will render as the label.
     * when form is false the label will always show regardless of the selected item.
     */
    form: {
      type: Boolean,
      required: false,
      default: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    offsetHeight: {
      type: Number,
      required: false,
      default: 0,
    },
    helperText,
  },
  data () {
    return {
      throttledHandleScroll: undefined,
      selected: undefined as SelectedEvent,
      show: false,
      optionsY: { top: '0px' } as OptionsYPosition,
      optionsX: { left: '0px' } as OptionsXPosition,
      optionsContainerWidth: '0px' as string,
      inverted: false,
      // used to manage computed properties that depend upon
      // ref'd els being mounted
      isMounted: false,
      icons: {
        dropdown: ['fas', 'angle-down'],
      },
      optionsEdge: false,
      refLabels: {
        control: 'control',
        dropdownContainer: 'dropdown-container',
        optionsContainer: 'options-container'
      }
    }
  },
  computed: {
    iconFill (): string {
      return this.type === PRIMARY ? 'white' : 'black'
    },
    /**
     * without a specific width set for the control the component
     * will default to `fill-content`
     */
    containerClasses (): string[] {
      const classes = [
        this.hasControlWidth ? null : 'fill-content'
      ]
      return classes
    },
    hasControlWidth (): boolean {
      return typeof this.controlWidth === 'number'
    },
    computedWidth (): string {
      return computedWidth(this.controlWidth)
    },
    /**
     * a null return value is used by template logic to apply the
     * 'fill-content' class. a class is used to encapsulate the necessary
     * prefixes fr `-webkit-fill-available` across browsers.
     */
    computedControlWidth (): string | null {
      const X_PADDING = 24
      if (this.hasControlWidth) {
        return `${this.controlWidth - X_PADDING}px`
      }
      if (this.optionsWidth === 'max-content') {
        return this.optionsWidth
      }
      return null
    },
    useSlot (): boolean {
      return !this.type
    },
    typeClasses (): string[] {
      const opts = {
        [PRIMARY]: [
          'type--primary'
        ],
        [DEFAULT]: [
          'type--default'
        ]
      }
      if (this.type) {
        return opts[this.type]
      }
      return []
    },
    selectedItem (): string {
      // if not being used for form, always show the label
      if (!this.form) {
        return this.label
      }
      return this.selected?.label || this.label || this.placeholder || ''
    }
  },
  mounted (): void {
    this.throttledHandleScroll = throttle(this.handleScroll, 1000)
    if (this.multiple) {
      this.selected = []
    }
    this.isMounted = true
  },
  methods: {
    handleScroll (e): void {
      const match = findOptionsMatch(e)
      if (!match) {
        this.show = false
      }
    },
    getContainerRect (): ElementRect {
      const container = this.$refs[this.refLabels.dropdownContainer]
      return getElementRect(container)
    },
    async handleReady (optsStats: OptsStats): Promise<void> {
      const dropdownContainer = this.$refs[this.refLabels.dropdownContainer]

      this.setInverted(optsStats.invertVertically)
      this.setOptionsEdge(optsStats.invertHorizontally)

      this.optionsY = getOptionsYPosition(
        optsStats.height,
        dropdownContainer,
        this.inverted,
        this.offsetHeight
      )
      this.optionsX = getOptionsXPosition(
        dropdownContainer,
        this.position,
        this.optionsEdge
      )
      this.optionsContainerWidth = this.getComputedOptionsWidth()
    },
    getComputedOptionsWidth (): string | null {
      if (this.hasControlWidth) {
        return this.computedWidth
      }
      if (this.optionsWidth === 'max-content') {
        // account for x padding on control
        return this.optionsWidth
      }
      if (this.optionsWidth === 'fill') {
        const dropdownContainer = this.$refs[this.refLabels.dropdownContainer]
        return `${getElementRect(dropdownContainer).width}px`
      }
      if (typeof this.optionsWidth === 'number') {
        return computedWidth(this.optionsWidth)
      }
      return null
    },
    setInverted (value: boolean): void {
      this.inverted = value
    },
    getControlHeight (): number {
      let height
      if (this.isMounted) {
        height = this.getClientHeight()
      }
      return height || 0
    },
    getClientHeight (): number | undefined {
      return this.$refs[this.refLabels.control]?.clientHeight
    },
    setOptionsEdge (value: boolean): void {
      this.optionsEdge = value
    },
    handleClickOutside (): void {
      this.show = false
    },
    handleClick (): void {
      if (this.disabled) return
      if (this.toggleMethod === 'click') this.toggleOptions()
    },
    handleHover (): void {
      if (this.disabled) return
      if (this.toggleMethod === 'hover') this.toggleOptions()
    },
    toggleOptions (): void {
      this.show = !this.show
    },
    emitSelected (option: Record<string, string|number|object|boolean>): void {
      /**
       * Selected event emitted when option is selected.
       *
       * @event selected
       * @property {object} option the option object
       */
      this.$emit('selected', option)
    },
    setSelected (event): void {
      this.selected = event
    }
  }
}
