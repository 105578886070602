var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-wrapper"},[_c('div',{staticClass:"table-content-wrapper"},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table-element",attrs:{"width":_vm.tableWidth}},[_c('thead',{staticClass:"table-header-container"},_vm._l((_vm.columns),function(col){return _c('th',{key:col.key,style:({
              width: ((col.width) + "px"),
              padding: _vm.colHeaderPadding
            }),on:{"click":function($event){return _vm.updateSortOn(col)}}},[_c('div',{staticClass:"flex flex-row items-center w-full",class:{ 'cursor-pointer': _vm.colSortable(col) },style:({ justifyContent: col.alignment, })},[_vm._t("colSlot",[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(col.name))])],{"col":col})],2),(_vm.colSortable(col))?_c('div',{staticClass:"arrow-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.colSortedOn(col)),expression:"colSortedOn(col)"}]},[(_vm.desc)?_c('div',[_c('ArrowDown',{attrs:{"width":_vm.sortIconWidth,"fill":_vm.sortIconColor}})],1):_c('div',[_c('ArrowUp',{attrs:{"width":_vm.sortIconWidth,"fill":_vm.sortIconColor}})],1)]),(_vm.showReducedOpacityArrow(col))?_c('div',{staticClass:"reduced-opacity"},[_c('ArrowDown',{attrs:{"width":_vm.sortIconWidth,"fill":_vm.sortIconColor}})],1):_vm._e()]):_vm._e()])}),0),(_vm.showEmptyState)?_c('td',{ref:_vm.refLabels.emptyState,style:(_vm.emptyStateStyles),attrs:{"colspan":_vm.colNumber}},[_vm._t("empty-state",[_c('span',[_vm._v(_vm._s(_vm.copy.defaultEmptyState))])])],2):_vm._e(),_c('tbody',{staticClass:"table-body-container"},_vm._l((_vm.rows),function(row){return _c('tr',{key:row.id,class:[ _vm.showOverflow ? 'show-overflow' : 'truncate-overflow' ]},[_vm._t("rows",_vm._l((_vm.columns),function(col){return _c('td',{key:col.key,style:({
                  minWidth: ((col.width) + "px"),
                  maxWidth: ((col.width) + "px"),
                  padding: _vm.colPadding
                })},[_vm._v(" "+_vm._s(row[col.key])+" ")])}),{"row":row})],2)}),0),_c('tfoot',[(_vm.showPagination)?_c('td',{staticClass:"table-footer-container",attrs:{"colspan":_vm.columns.length}},[_c('div',{staticClass:"table-paginator-container flex flex-row items-center justify-end"},[_c('span',[_vm._v(" "+_vm._s(_vm.copy.rowsPerPage)+" ")]),_c('div',{staticClass:"mx-3"},[_c('Dropdown',{attrs:{"label":_vm.pageSize.toString(),"options":_vm.options,"type":'default',"label-classes":['text-sm', 'font-normal'],"offset-height":_vm.menuStyles.height,"options-width":"fill"},on:{"selected":function($event){return _vm.updateResultsPerPage($event)}}})],1),_vm._v(" "+_vm._s(_vm.paginationCopy)+" "),_c('button',{staticClass:"page-buttons pl-8 pr-8",class:{ 'reduced-opacity': !_vm.hasPrevPage },attrs:{"disabled":!_vm.hasPrevPage},on:{"click":_vm.prevPage}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.left}})],1),_c('button',{staticClass:"page-buttons pr-2",class:{ 'reduced-opacity': !_vm.hasNextPage },attrs:{"disabled":!_vm.hasNextPage},on:{"click":_vm.nextPage}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.right}})],1)])]):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }