import {
  userTypes,
  userViews,
  merchantViews
} from '@/constants'

export const getViews = ({ userProfile }) => {
  if (!userProfile) {
    return []
  }
  let views = [
    userViews.HOMEPAGE,
    userViews.ACCOUNT,
  ]
  if (userProfile.hssEditorAccess) {
    views = [
      ...views,
      userViews.FUNNEL_UXS,
      userViews.DESIGN,
    ]
  }
  if (userProfile.oprmAccess) {
    views = [
      ...views,
      userViews.OP_REWARDS,
      userViews.OP_REWARDS_WIZARD_NEW,
      userViews.OP_REWARDS_WIZARD_EDIT,
    ]
  }
  if (userProfile.edmAccess) {
    views = [
      ...views,
      userViews.REWARD_ALGOS,
      userViews.REWARD_ALGOS_WIZARD_NEW,
      userViews.REWARD_ALGOS_WIZARD_EDIT,
      userViews.EMAIL_DESIGNS,
      userViews.EMAIL_DESIGNS_NEW,
      userViews.EMAIL_DESIGNS_EDIT,
      userViews.CAMPAIGNS,
      userViews.CAMPAIGNS_NEW,
      userViews.CAMPAIGNS_EDIT,
    ]
  }
  if (userProfile.reportingAccess) {
    if (userProfile.type === userTypes.GROUP) {
      views.push(userViews.GROUP)
      views.push(userViews.GROWTH_SCALE)
      views.push(userViews.PROGRAM_IMPACT)
      views.push(userViews.PROGRAM_DEMOGRAPHICS)
      views.push(userViews.LOYALTY_MEMBERS)
      views.push(userViews.MEMBER_PROFILE)
      views.push(userViews.LOYALTY_TRAFFIC)
    }
    views = [
      ...views,
      userViews.SPLIT_TESTS,
      userViews.PROGRAM_ENGAGEMENT,
      userViews.ROI,
      userViews.REVENUE_METRICS,
      userViews.UPCOMING_BOOKINGS,
      userViews.REWARD_METRICS,
    ]
  }
  if (userProfile.rewardAlgoAccess) {
    views = [
      ...views,
      userViews.HOTEL_REWARD_ALGOS,
      userViews.HOTEL_REWARD_ALGOS_WIZARD_NEW,
      userViews.HOTEL_REWARD_ALGOS_WIZARD_EDIT
    ]
  }
  if (userProfile.flexAccess) {
    views = [
      ...views,
      userViews.FLEX_NEW,
      userViews.FLEX_REPORTING,
      userViews.EMAIL_DESIGNS,
      userViews.EMAIL_DESIGNS_NEW,
      userViews.EMAIL_DESIGNS_EDIT,
    ]
  }
  return views
}

export default {
  [userTypes.ADMIN]: {
    views: () => true,
    initialView: () => ({ view: userViews.HOMEPAGE })
  },
  [userTypes.GROUP]: {
    views: ({ userProfile, view }) => getViews({ userProfile }).includes(view),
    initialView: () => ({ view: userViews.HOMEPAGE })
  },
  [userTypes.HOTEL]: {
    views: ({ userProfile, view }) => getViews({ userProfile }).includes(view),
    initialView: () => ({ view: userViews.HOMEPAGE })
  },
  [userTypes.MERCHANT]: {
    views: ({ userProfile, view }) => {
      if (userProfile.merchantIds.includes(0)) {
        return view === merchantViews.MERCHANT_ACCOUNT
      }
      return [
        merchantViews.MERCHANT_PROMOTIONS,
        merchantViews.MERCHANT_PROMOTIONS_WIZARD_NEW,
        merchantViews.MERCHANT_PROMOTIONS_WIZARD_EDIT,
        merchantViews.MERCHANT_ANALYTICS,
        merchantViews.MERCHANT_ACCOUNT
      ].includes(view)
    },
    initialView: (userProfile) => {
      if (userProfile.merchantIds.includes(0)) {
        console.log('new user')
        return {
          view: merchantViews.MERCHANT_ACCOUNT
        }
      }
      return {
        view: merchantViews.MERCHANT_PROMOTIONS
      }
    }
  },
}
