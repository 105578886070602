




































































import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import moment from 'moment'
import { userViews } from '@/constants'
import Paginator from '@/components/common/Paginator.vue'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton.vue'
import { RewardAlgo } from '@/types'
import { getTotalRewardsOffered } from '@/utils/reward-algos'

export default {
  name: 'RewardAlgorithmsTable',
  components: {
    'md-table-pagination': Paginator, // override vue material component
    StepperButton,
  },
  data () {
    return {
      labels: {
        edit: 'Edit',
        name: 'Reward Algorithm',
        rewardsOffered: 'Rewards Offered',
        maxRewardSelections: 'Number Selectable',
        updateTimestamp: 'Updated On'
      },
      copy: {
        empty: 'No algorithms yet',
        emptyStateButton: 'Create a New Algorithm'
      }
    }
  },
  computed: {
    ...mapState('ram', [
      'pageInfo',
      'algosFetchSuccess',
    ]),
    ...mapGetters('ram', [
      'ALGOS_LIST',
    ]),
    showEmptyState () {
      return this.algosFetchSuccess === true && this.ALGOS_LIST.length === 0
    }
  },
  methods: {
    ...mapMutations('ram', [
      'CLEAR_ALGOS',
      'SET_PAGE',
      'SET_PAGE_SIZE'
    ]),
    ...mapMutations('messages', [
      'CLEAR_MESSAGES'
    ]),
    ...mapActions('ram', [
      'FETCH_ALGOS'
    ]),
    async fetchPaginatedAlgos () {
      this.CLEAR_MESSAGES()
      const { page, pageSize } = this.pageInfo
      await this.FETCH_ALGOS({ page, pageSize })
    },
    updatePage (pageNumber) {
      this.SET_PAGE(pageNumber)
      this.fetchPaginatedAlgos()
    },
    updatePageSize (pageSize) {
      this.SET_PAGE_SIZE(pageSize)
      this.fetchPaginatedAlgos()
    },
    getUpdatedOn (modified) {
      return moment(modified).format('D MMMM YYYY, LT')
    },
    navToAlgoWizardEdit (algo) {
      this.$router.push({
        name: userViews.REWARD_ALGOS_WIZARD_EDIT,
        params: {
          hotelId: this.$route.params.hotelId,
          algoId: algo.id // FIXME need id or uuid
        }
      })
    },
    // duplicated in container
    navToAlgoWizardNew () {
      this.$router.push({
        name: userViews.REWARD_ALGOS_WIZARD_NEW,
        params: {
          hotelId: this.$route.params.hotelId
        }
      })
    },
    getRewardsOffered (item: RewardAlgo): number {
      const { onPropertyRewards, marketPlaceRewards } = item
      return getTotalRewardsOffered({
        onPropertyRewards,
        marketPlaceRewards
      }) || 0
    }
  },
  mounted () {
    this.fetchPaginatedAlgos()
  },
  async beforeRouteUpdate (to, from, next) {
    await this.fetchPaginatedAlgos()
    next()
  }
}
