import { CognitoUserParser } from '@/parsers/CognitoParsers'
import { ParserUtils } from '@/parsers/utils'
import { schemas } from '@/schemas'
import { cloneDeep } from 'lodash'

// NOTE switch adapters here
export const UserParser = CognitoUserParser

export const HotelParser = {
  /**
    * wrapper around parsedWithSchema using hotel schema
    * @param {Object} hotel
    * @return {Object} parsed hotel object
    */
  parseHotel (hotel) {
    return ParserUtils.parseWithSchema({
      schema: schemas.hotel,
      data: hotel
    })
  }
}

export const CradleCognitoHotelParser = {
  /**
    * wrapper around parsedWithSchema using hotel schema
    * @param {Object} hotel
    * @return {Object} parsed hotel object
    */
  parseHotel (hotel) {
    return ParserUtils.parseWithSchema({
      schema: schemas.cradleCognitoHotel,
      data: hotel
    })
  },
  inverseParseHotel (hotel) {
    return ParserUtils.parseWithSchemaInverse({
      schema: schemas.cradleCognitoHotel,
      data: hotel
    })
  },
}

export const CradleHotelParser = {
  /**
    * wrapper around parsedWithSchema using hotel schema
    * @param {Object} hotel
    * @return {Object} parsed hotel object
    */
  parseHotel (hotel) {
    return ParserUtils.parseWithSchema({
      schema: schemas.cradleHotel,
      data: hotel
    })
  },
  inverseParseHotel (hotel) {
    return ParserUtils.parseWithSchemaInverse({
      schema: schemas.cradleHotel,
      data: hotel
    })
  },
}

export const FunnelUxParser = {
  /**
    * wrapper around parsedWithSchema using hotel schema
    * @param {Object} hotel
    * @return {Object} parsed hotel object
    */
  parseFux (funnelUxs) {
    return ParserUtils.parseWithSchema({
      schema: schemas.funnelUx,
      data: funnelUxs
    })
  }
}

export const FunnelUxPayloadParser = {
  /**
    * @param {Object} funnel ux create/update payload
    * @return {Object} valid funnel ux payload, e.g. remove blank hc fields
    */
  parse (fuxPayload) {
    const mutable = cloneDeep(fuxPayload)
    if (mutable.hcs) {
      mutable.hcs.forEach(hc => {
        hc.hotel_component_param_set = hc.hotel_component_param_set.filter(
          (f) => f.field_value !== '' && f.field_value !== undefined
        )
      })
    }
    return mutable
  }
}

export const GroupParser = {
  /**
    * wrapper around parsedWithSchema using group schema
    * @param {Object} group
    * @return {Object} parsed group object
    */
  parseGroup (group) {
    return ParserUtils.parseWithSchema({
      schema: schemas.group,
      data: group
    })
  }
}

export const MerchantParser = {
  /**
    * wrapper around parsedWithSchema using merchant schema
    * @param {Object} merchant
    * @return {Object} parsed merchant object
    */
  parseMerchant (merchant) {
    return ParserUtils.parseWithSchema({
      schema: schemas.merchant,
      data: merchant,
    })
  },
  inverseParseMerchant (merchant) {
    return ParserUtils.parseWithSchemaInverse({
      schema: schemas.merchant,
      data: merchant
    })
  }
}

export const PromotionParser = {
  /**
    * wrapper around parsedWithSchema using promotion schema
    * @param {Object} promotion
    * @return {Object} parsed promotion object with parsed nested creative configs
    */
  _parsePromotion (promotion) {
    return ParserUtils.parseWithSchema({
      schema: schemas.promotion,
      data: promotion,
    })
  },
  _parseCreativeConfig (creativeConfig) {
    return ParserUtils.parseWithSchema({
      schema: schemas.creativeConfigs,
      data: creativeConfig,
    })
  },
  parsePromotion (promotion) {
    const parsedPromotion = PromotionParser._parsePromotion(promotion)

    // on property rewards
    if (typeof parsedPromotion.onPropertyHotels === 'string') {
      let hotels
      if (parsedPromotion.onPropertyHotels.length === 0) {
        hotels = []
      } else {
        hotels = parsedPromotion.onPropertyHotels.split(',')
      }
      parsedPromotion.onPropertyHotels = hotels
    }

    const parsedCreativeConfigs = parsedPromotion.creativeConfigs
      .map(config => PromotionParser._parseCreativeConfig(config))

    return {
      ...parsedPromotion,
      creativeConfigs: parsedCreativeConfigs,
    }
  },
  inverseParsePromotion (promotion) {
    const parsedPromotion = ParserUtils.parseWithSchemaInverse({
      schema: schemas.promotion,
      data: promotion
    })

    if (parsedPromotion.reward_creative_configs &&
      parsedPromotion.reward_creative_configs.length > 0) {
      const parsedCreativeConfigs = parsedPromotion.reward_creative_configs
        .map(config => ParserUtils.parseWithSchemaInverse({
          schema: schemas.creativeConfigs,
          data: config
        }))
      parsedPromotion.reward_creative_configs = parsedCreativeConfigs
    }

    // on property rewards
    if (parsedPromotion.onPropertyHotels) {
      parsedPromotion.on_property_hotels = parsedPromotion.on_property_hotels.join(',')
    }

    return parsedPromotion
  },
}

export const BookingEngineParser = {
  parseBookingEngine (bookingEngine) {
    return ParserUtils.parseWithSchema({
      schema: schemas.bookingEngine,
      data: bookingEngine,
    })
  },
}

const WHITELIST_IDS = 'whitelist_ids'
const EXCLUDE_IDS = 'blacklist_ids'

export const HotelRewardAlgorithmParser = {
  getOverrideIds (parsedAlgo) {
    const overrideIds = {
      whitelistIds: [],
      whitelistIdsParamId: undefined,
      excludeIds: [],
      excludeIdsParamId: undefined
    }

    const parse = (key, rawParam) => {
      if (rawParam.value_type !== 'str') {
        console.log('Cannot parse non-string to array')
        return
      }
      // incoming String '1,2,3' => [...Number]
      overrideIds[key] = rawParam.value.split(',').map(id => Number(id))
      overrideIds[`${key}ParamId`] = rawParam.id
    }

    parsedAlgo.params.forEach((param) => {
      if (param.key === WHITELIST_IDS) parse('whitelistIds', param)
      if (param.key === EXCLUDE_IDS) parse('excludeIds', param)
    })
    return overrideIds
  },
  // converts share from string '.XXX' to to number XX
  parseAlgorithm (algo) {
    let parsedAlgo = ParserUtils.parseWithSchema({
      schema: schemas.hotelRewardAlgorithm,
      data: algo
    })

    const ids = HotelRewardAlgorithmParser.getOverrideIds(parsedAlgo)
    parsedAlgo = {
      ...parsedAlgo,
      ...ids
    }

    if (typeof parsedAlgo.share === 'string') {
      let share = 0
      share = Number((parseFloat(parsedAlgo.share) * 100).toFixed(0))
      parsedAlgo.share = isNaN(share) ? 0 : share
    }
    return parsedAlgo
  }
}

export const EmailMarketingRewardAlgorithmParser = {
  getOverrideIds (parsedAlgo) {
    const overrideIds = {
      whitelistIds: [],
      whitelistIdsParamId: undefined,
      excludeIds: [],
      excludeIdsParamId: undefined
    }

    const parse = (key, rawParam) => {
      if (rawParam.value_type !== 'str') {
        console.log('Cannot parse non-string to array')
        return
      }
      // incoming String '1,2,3' => [...Number]
      overrideIds[key] = rawParam.value.split(',').map(id => Number(id))
      overrideIds[`${key}ParamId`] = rawParam.id
    }

    parsedAlgo.params.forEach((param) => {
      if (param.key === WHITELIST_IDS) parse('whitelistIds', param)
      if (param.key === EXCLUDE_IDS) parse('excludeIds', param)
    })
    return overrideIds
  },
  parseAlgorithm (algo) {
    let parsedAlgo = ParserUtils.parseWithSchema({
      schema: schemas.emailMarketingRewardAlgorithm,
      data: algo
    })
    const ids = EmailMarketingRewardAlgorithmParser.getOverrideIds(parsedAlgo)
    parsedAlgo = {
      ...parsedAlgo,
      ...ids
    }
    return parsedAlgo
  }
}

export const EmailDesignParser = {
  parseEmailDesign (emailDesign) {
    return ParserUtils.parseWithSchema({
      schema: schemas.emailDesign,
      data: emailDesign
    })
  }
}

export const CampaignParser = {
  parseCampaign (campaign) {
    return ParserUtils.parseWithSchema({
      schema: schemas.campaign,
      data: campaign
    })
  },
  parseTriggerTypes (triggerTypes) {
    return ParserUtils.parseWithSchema({
      schema: schemas.campaignEventType,
      data: triggerTypes
    })
  },
  parseNodes (nodes) {
    return ParserUtils.parseWithSchema({
      schema: schemas.individiualCampaignEventNode,
      data: nodes
    })
  },
  inverseParseNodes (nodes) {
    // should be nested as Dag
    return ParserUtils.parseWithSchema({
      schema: schemas.inverseDag,
      data: nodes
    })
  }
}

export const SegmentParser = {
  parseSegment (segment) {
    return ParserUtils.parseWithSchema({
      schema: schemas.segment,
      data: segment
    })
  }
}

export const parseGroupConfig = (resp) => {
  const params = {}
  const templateFieldPermissions = {}

  // filter for only Portal templates
  const portalComponents = []
  const otherComponents = []
  const preferenceComponents = []

  resp.data.customer_portal_component.forEach(component => {
    if (component.template?.template_type === 'Portal') {
      portalComponents.push(component)
    } else if (component.template?.template_type === 'Preference') {
      preferenceComponents.push(component)
    } else {
      otherComponents.push(component)
    }
  })

  const modifiedConfig = {
    ...resp.data,
    customer_portal_component: [...portalComponents, ...preferenceComponents]
  }
  modifiedConfig.customer_portal_component.forEach(component => {
    component.customer_portal_component_param_set.forEach(param => {
      // namespace on category
      if (!params[param.field_category]) {
        params[param.field_category] = {}
      }

      // add component id for building payload
      params[param.field_category][param.field_name] = {
        ...param,
        __temporary_component_id__: component.id,
      }
    })

    // walk through each template field permission and enrich
    component.template.template_field_permissions.forEach(permission => {
      if (!params[permission.category]) {
        params[permission.category] = {}
      }

      const paramMatchingPermissionFieldName = params[permission.category][permission.field_name]

      if (!paramMatchingPermissionFieldName) {
        console.log('[parseGroupConfig] could not find param - creating placeholder for', permission.category, permission.field_name)
        const placeholder = {
          id: undefined,
          field_value: permission.field_value,
          field_category: permission.category,
          field_name: permission.field_name,
          default_value: permission.default_value,
          required: permission.required,
          __temporary_component_id__: component.id,
          field_type: permission.field_type,
        }
        params[permission.category][permission.field_name] = placeholder
      } else {
        // enrich
        paramMatchingPermissionFieldName.default_value = permission.default_value
        paramMatchingPermissionFieldName.required = permission.required
      }

      if (!templateFieldPermissions[permission.category]) {
        templateFieldPermissions[permission.category] = {}
      }
      templateFieldPermissions[permission.category][permission.field_name] = permission
    })
  })

  console.log('[parseGroupConfig] portalComponents', portalComponents)
  console.log('[parseGroupConfig] otherComponents', otherComponents)
  console.log('[parseGroupConfig] preferenceComponents', preferenceComponents)

  return { modifiedConfig, params, templateFieldPermissions, otherComponents, preferenceComponents }
}
