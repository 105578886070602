<template>
  <md-dialog
    :md-active="active"
    :md-click-outside-to-close="false"
    :md-close-on-esc="false"
  >
    <div id="modal-container">
      <div id="modal-title" class="md-subheading laas-bold">
        {{ copy.title }}
      </div>
      <div v-if="copy.body" id="modal-body" class="md-body-1">
        {{ copy.body }}
      </div>

      <div id="modal-btn-container">
        <div
          id="modal-button-leave"
          class="modal-button"
          @click="leave"
        >
          {{ copy.leave }}
        </div>
        <div
          id="modal-button-stay"
          class="modal-button laas-bold"
          @click="stay"
        >
          {{ copy.stay }}
        </div>
      </div>
    </div>
  </md-dialog>
</template>

<script>
const LEAVE = 'leave'
const STAY = 'stay'

export default {
  name: 'Modal',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    copy: {
      type: Object,
      default: () => ({
        title: 'title',
        body: 'body',
        leave: 'leave',
        stay: 'stay'
      }),
      required: true,
    }
  },
  methods: {
    leave () {
      this.$emit(LEAVE)
    },
    stay () {
      this.$emit(STAY)
    }
  }
}
</script>

<style lang="sass" scoped>
#modal-container
  width: 370px
  height: auto
  font-family: Lato !important
  padding: 36px
  padding-bottom: 15px
  text-align: left
  .md-field
    margin: 36px 0 24px

#modal-title
  padding-bottom: 6px

#modal-btn-container
  margin-top: 14px
  min-height: 34px
  display: flex
  flex-direction: row
  justify-content: flex-end

.modal-button
  padding: 10px
  font-family: inherit
  text-transform: uppercase
  font-size: 12px
  font-stretch: normal
  font-style: normal
  line-height: normal
  letter-spacing: normal
  text-align: right
  color: #4e4e4e
  border-radius: 3px
  cursor: pointer
  &:hover
    background-color: #eee

#modal-button-stay
  margin-right: -10px

</style>
