<template>
  <div id="wysiwyg-editor">
    <wysiwyg v-model="content" :placeholder="placeholder" />
  </div>
</template>

<script>
import Vue from 'vue'
import wysiwyg from 'vue-wysiwyg'
Vue.use(wysiwyg, {
  hideModules: {
    // hide modules here
    justifyCenter: true,
    justifyRight: true
  }
})

export default {
  name: 'WysiwygEditor',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    content: {
      get () {
        return this.value
      },
      set (content) {
        this.$emit('input', content)
      }
    }
  }
}
</script>

<style lang="sass">
@import "~vue-wysiwyg/dist/vueWysiwyg.css"

#wysiwyg-editor
  .editr
    a
      color: blue
    .dashboard
      label
        display: block
        padding-bottom: 10px
      input
        pointer-events: none
        cursor: pointer
        padding-left: 3px
        margin-left: 3px
</style>
