





























































































































































































































































































































import Vue from 'vue'
import { CustomStepper as CustomStepperConfig, Hotel, EmailDesign } from '@/types'
import { mapMutations, mapState, mapGetters } from 'vuex'
import CustomStepper from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/CustomStepper.vue'
import WizardStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/WizardStep.vue'
import CustomStepHeader from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepHeader.vue'
import Info from '@/components/common/Info.vue'
import { steps, emailDesignTypes } from '@/constants'
import { TableHeaderUtils } from '@/utils/table-headers'
import { required } from 'vuelidate/lib/validators'

const { SINGLE_USE, TEMPLATE } = emailDesignTypes

export interface TypeOptions {
  name: string;
  value: string;
}

export default Vue.extend({
  name: 'DesignSettings',
  components: {
    CustomStepper,
    WizardStep,
    CustomStepHeader,
    Info,
  },
  props: {
    showSaveInfo: {
      type: Boolean,
      required: true,
    }
  },
  // @ts-ignore
  validations: {
    nameInput: {
      required,
    },
    subjectInput: {
      required,
    },
  },
  data () {
    return {
      nameInput: '', // used for vuelidate data binding
      subjectInput: '', // used for vuelidate data binding
      steps,
      selectAll: false,
      filterTerm: '',
      stepHeaders: [
        { name: 'Email Design Name', isRetain: false },
        { name: 'Email Subject', isRetain: false },
        { name: 'Email Design Type', isRetain: false },
        { name: 'Property Access', isRetain: false },
        { name: 'Rewards To Display', isRetain: false },
      ] as CustomStepperConfig.StepHeaders,
      icon: ['fas', 'info-circle'],
      copy: {
        selectedProperties: 'Selected Properties',
        selectAll: 'Select All',
        stepHeader: {
          required: 'Required',
          readonly: 'View-Only',
        },
        overview: {
          header: 'Welcome to your Email Design Editor',
          body: {
            p1: 'Create a new email design for your marketing campaigns. Click the tabs to edit different parts of your email.'
          }
        },
        placeholder: {
          name: 'Name Your Email Design',
          subject: 'Add Your Subject',
          type: 'Template',
          filter: 'Filter By Name',
          rewardsDisplayLimit: '1, 2, 3...',
        },
        body: {
          access: 'Select which properties have <b>read</b> access to this email design below. Only users with access to a selected property below will be able to duplicate or modify the email design.',
        },
        caption: {
          name: 'This name is internal only.',
          subject: 'Ths is the subject line that will appear when you send your email.',
          type: 'We typically recommend selecting \'Single Use\' unless you are creating a template for multiple properties to copy.',
        },
        help: {
          type: {
            [SINGLE_USE]: 'Single Use Designs are only editable and viewable by the property that created the design.',
            [TEMPLATE]: 'Template Designs are view-only and must be saved as a copy by properties that are given access. Only the property that created the design can edit it.'
          }
        },
        properties: {
          footer: 'Don\'t see the property you want? Contact your customer success manager and we’ll try to help!'
        },
        additionalInfo: 'Please save your design before continuing to another tab.',
        numberOfRewardsDescription: 'Set the number of rewards to display in the email. Not to be confused with the number of rewards offered which will be determined by the reward algorithm when you create your campaign.'
      },
      maxLength: {
        name: 50,
        subject: 150
      }
    }
  },
  computed: {
    ...mapState([
      'primaryHotel',
    ]),
    ...mapState('edm', [
      'emailDesign'
    ]),
    ...mapState('oprm', [
      'hotels',
      'fetchHotelsSuccess'
    ]),
    ...mapGetters([
      'CREATE_COPY',
      'CRADLE_HOTEL_ID',
      'FROM_ECM',
    ]),
    ...mapGetters('edm', [
      'IS_READONLY'
    ]),
    ...mapGetters('oprm', [
      'HOTEL_OPTS',
      'GET_HOTEL_BY_CRADLE_ID',
    ]),
    ...mapGetters([
      'IS_ADMIN'
    ]),
    stepHeaderMeta (): string {
      return this.IS_READONLY ? this.copy.stepHeader.readonly : this.copy.stepHeader.required
    },
    showFilter (): boolean {
      return this.hotelOpts.length > 20
    },
    showPropertyAccess (): boolean {
      return this.type === TEMPLATE
    },
    disableType (): boolean {
      return this.FROM_ECM && this.emailDesign.type === SINGLE_USE
    },
    typeOptions (): TypeOptions[] {
      // when user enters from ECM we remove template from options
      const options = [
        { name: 'Single Use', value: SINGLE_USE }
      ]
      if (!this.CREATE_COPY) {
        options.push({ name: 'Template ', value: TEMPLATE })
      }
      return options
    },
    typeHelpCopy (): string | undefined {
      return this.copy.help.type[this.emailDesign.type]
    },
    name: {
      get () {
        return this.emailDesign.name
      },
      set (value) {
        this.SET_NAME(value)
        this.$v.nameInput.$touch()
      }
    },
    subject: {
      get (): string | undefined {
        return this.emailDesign.emailSubject
      },
      set (value): void {
        this.SET_SUBJECT(value)
        this.$v.subjectInput.$touch()
      }
    },
    type: {
      get (): string | undefined {
        return this.emailDesign.type
      },
      set (value): void {
        this.SET_TYPE(value)
      }
    },
    propertyAccess: {
      get (): number[] {
        // prevent duplicate property names when viewing
        // a design from a property that has read access
        return [
          ...new Set([
            ...this.emailDesign.readAccessHotelIds,
            this.primaryHotel.cradleId,
            this.emailDesign.hotelId
          ])
        ]
      },
      set (value: number[]): void {
        const idsWithCurrentHotelRemoved = value.filter(id => id !== this.primaryHotel.cradleId)
        this.SET_READ_ACCESS_HOTEL_IDS(idsWithCurrentHotelRemoved)
      },
    },
    rewardsDisplayLimit: {
      get (): number | void {
        return (this.emailDesign as EmailDesign).rewardsDisplayLimit
      },
      set (value: string): void {
        const cleanValue = value[0] === '-'
          ? value.slice(1, value.length)
          : value

        this.SET_REWARDS_DISPLAY_LIMIT(parseInt(cleanValue))
      }
    },
    showNoResults (): boolean {
      return Boolean(this.filterTerm && this.filterTerm.length > 0 && this.filteredHotelOpts.length === 0)
    },
    hotelOpts (): Hotel[] {
      return this.HOTEL_OPTS.filter(hotel => hotel.cradleId !== this.CRADLE_HOTEL_ID)
    },
    filteredHotelOpts (): object[] {
      let filteredOpts = []

      if (this.filterTerm) {
        const lowerterm = this.filterTerm.toLowerCase()
        filteredOpts = this.hotelOpts.filter((hotel: Hotel): boolean | string => {
          return typeof (hotel.name === 'string') &&
            hotel.name.toLowerCase().replace(' ', '').indexOf(lowerterm) > -1
        })
      } else {
        filteredOpts = this.hotelOpts
      }
      return [this.primaryHotel, ...filteredOpts]
    },
    selectedPropertiesCopy (): string | undefined {
      return this.propertyAccess
        ? `${this.copy.selectedProperties}: (${this.propertyAccess.length})`
        : ''
    },
    disableSelectAll (): boolean {
      if (this.IS_ADMIN) return true
      return this.fetchHotelsSuccess === false
    },
    rewardsDisplayLimitValid (): boolean {
      if (typeof this.rewardsDisplayLimit === 'number') {
        return this.rewardsDisplayLimit > 0
      }
      return false
    }
  },
  watch: {
    type (newValue) {
      if (newValue === TEMPLATE && this.hotelOpts.length === 0) {
        this.$store.dispatch('oprm/FETCH_HOTELS', { fetchAll: true, pageSize: 50 })
      }
    },
    selectAll (newValue) {
      let value
      if (newValue === true) {
        value = this.hotelOpts
          .map(hotel => hotel.cradleId)
          .filter(id => id !== this.currentHotelCradleId)
      } else {
        value = []
      }
      console.log('[watcher] selectAll - final value', value)
      this.SET_READ_ACCESS_HOTEL_IDS(value)
    },
    // used for vuelidate data binding
    name (newValue) {
      this.nameInput = newValue
    },
    subject (newValue) {
      this.subjectInput = newValue
    }
  },
  mounted () {
    if (this.type === TEMPLATE) {
      this.$store.dispatch('oprm/FETCH_HOTELS', { fetchAll: true, pageSize: 50 })
    }
  },
  methods: {
    ...mapMutations('edm', [
      'SET_NAME',
      'SET_SUBJECT',
      'SET_TYPE',
      'SET_READ_ACCESS_HOTEL_IDS',
      'SET_REWARDS_DISPLAY_LIMIT',
    ]),
    isHotelDisabled (hotel: Hotel): boolean {
      return hotel.cradleId === this.currentHotelCradleId || this.IS_READONLY
    },
    getType: TableHeaderUtils.getType as () => string,
    getHotelName (id: number): object | null {
      const hotel = this.GET_HOTEL_BY_CRADLE_ID(id)
      return hotel ? hotel.name : null
    }
  },
})
