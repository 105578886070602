<template>
  <div
    id="on-property-rewards-wizard-content-container"
    class="md-size-100"
  >
    <CustomStepper
      :editing="editing"
      :step-headers="stepHeaders"
      @done="handleDone"
    >
      <template v-slot:[steps.STEP_1]="{ slotScope }">
        <CategoryStep
          :index="slotScope.idx"
          :editing="editing"
        />
      </template>
      <template v-slot:[steps.STEP_2]="{ slotScope }">
        <TextDisplayStep
          :index="slotScope.idx"
          :editing="editing"
        />
      </template>
      <template v-slot:[steps.STEP_3]="{ slotScope }">
        <ImageDisplayStep
          :index="slotScope.idx"
          :editing="editing"
        />
      </template>
      <template v-slot:[steps.STEP_4]="{ slotScope }">
        <PropertiesStep
          :index="slotScope.idx"
          :editing="editing"
        />
      </template>
    </CustomStepper>
  </div>
</template>

<script>
import { steps } from '@/constants'
import CategoryStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/CategoryStep'
import TextDisplayStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/TextDisplayStep'
import ImageDisplayStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/ImageDisplayStep'
import PropertiesStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/PropertiesStep'
import CustomStepper from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/CustomStepper'

export default {
  name: 'Content',
  inject: ['Wizard_nextTab'],
  components: {
    CustomStepper,
    CategoryStep,
    TextDisplayStep,
    ImageDisplayStep,
    PropertiesStep,
  },
  props: {
    editing: {
      type: Boolean,
      required: true,
      default: false
    },
  },
  data () {
    return {
      steps, // expose constants
      stepHeaders: [
        { name: 'Category', isRetain: false },
        { name: 'Text Display', isRetain: false },
        { name: 'Image Display', isRetain: false },
        { name: 'Properties', isRetain: false }
      ]
    }
  },
  methods: {
    handleDone () {
      this.Wizard_nextTab()
    }
  }
}
</script>

<style lang="sass">
</style>
