



































import { debounce, throttle } from 'lodash'
import { helperText } from '@/components/props/index'
import Select, { Option, FuseOptions } from '@/components/select/Select.vue'

export default {
  name: 'Autocomplete',
  components: {
    Select,
  },
  props: {
    /**
     * Select props
     */
    offsetHeight: {
      type: Number,
      required: false,
      default: 0,
    },
    showDisabledCopy: {
      type: Boolean,
      default: true
    },
    disabledCopy: {
      type: [String, Boolean],
      default: () => ''
    },
    closeIcon: {
      type: Boolean,
      default: true
    },
    reduce: {
      type: Function,
      required: false,
      default: option => option
    },
    initialSelect: {
      type: Object as () => Option,
      required: false,
      default: null
    },
    checkbox: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideSelected: {
      type: Boolean,
      default: false,
      required: false
    },
    value: {
      type: [Array, String, Object, Number],
      required: false,
      default: null
    },
    trackBy: {
      type: String,
      required: false,
      default: null
    },
    closeOnSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
    max: {
      type: Number,
      required: false,
      default: null
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    tabindex: {
      type: Number,
      default: 0,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    labelKey: {
      type: String,
      required: false,
      default: 'label'
    },
    labelClasses: {
      type: Array as () => string[],
      required: false,
      validator (value): boolean {
        return value.every(item => typeof item === 'string')
      },
      default: () => []
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    inputPlaceholder: {
      type: String,
      required: false,
      default: 'Start typing to search...'
    },
    emptyStateCopy: {
      type: String,
      required: false,
      default: 'No results found.'
    },
    fuseOptions: {
      type: Object as () => FuseOptions,
      required: false,
      default: () => ({
        includeMatches: false,
        isCaseSensitive: false,
        includeScore: false,
        shouldSort: false,
        findAllMatches: false,
        threshold: 0.0,
        ignoreLocation: false,
        keys: [] // labelKey should always be in this list
      })
    },
    helperText,
    /**
     * Autocomplete props
     */
    /**
     * Store action name for fetching Select component options
     */
    fetchActionName: {
      type: String,
      required: true
    },
    /**
     * Params to pass to the fetch action
     */
    fetchActionParams: {
      type: Object,
      requried: false,
      default: () => ({})
    },
    /**
     * Store getter name for passing options prop to Select component
     */
    optionsGetterName: {
      type: String,
      required: true
    },
  },
  computed: {
    selected: {
      get (): Option | Option[] {
        return this.value
      },
      set (val: Option | Option[]): void {
        this.$emit('input', val)
      }
    },
    options (): Option[] {
      return this.$store.getters[this.optionsGetterName]
    }
  },
  methods: {
    async fetchOptions (search: string): Promise<void> {
      if (search.length < 7 || search.endsWith(' ')) {
        await throttle(async () => await this.$store.dispatch(this.fetchActionName, { ...this.fetchActionParams, search }), 700)()
      } else {
        await debounce(async () => await this.$store.dispatch(this.fetchActionName, { ...this.fetchActionParams, search }), 300)()
      }
    }
  }
}
