




















import {
  SMALL,
  MEDIUM,
  LARGE,
  ACTIVE,
  PENDING,
  INACTIVE,
} from './constants'
import { StatusIndicator } from '@/types'
import Vue from 'vue'

export const component = {
  name: 'Status2',
  props: {
    status: {
      type: String,
      required: true,
      default: 'inactive',
      validator (value: string) {
        return [ACTIVE, PENDING, INACTIVE].includes(value)
      }
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator (value: string) {
        return [SMALL, MEDIUM, LARGE].includes(value)
      }
    },
  },
  computed: {
    isInactive (): boolean {
      return this.status === INACTIVE
    },
    marginRight (): string {
      return this.size === SMALL ? 'custom-margin-right' : 'standard-margin-right'
    },
    config (): StatusIndicator.Config {
      let fontColor: string
      let dimension: string
      let fontSize: string

      if (this.size === LARGE) {
        dimension = '18px'
        fontColor = 'text-gray-800'
        fontSize = '14px'
      } else if (this.size === MEDIUM) {
        dimension = '10px'
        fontColor = 'text-gray-800'
        fontSize = '14px'
      } else {
        dimension = '8px'
        fontColor = 'text-gray-400'
        fontSize = '11px'
      }

      return {
        dimension,
        fontColor,
        fontSize,
      }
    },
    backgroundColor (): string {
      const colors: { [k: string]: string } = {
        active: 'active-background-color',
        pending: 'pending-background-color',
        inactive: 'inactive-background-color'
      }

      const color = colors[this.status]
      return color || colors[INACTIVE]
    }
  }
}

export default Vue.extend(component)
