<template>
  <div
    id="laas-wizard-header"
    :style="{
      flexDirection: $breakpoints.mdAndUp ? 'row' : 'column',
      alignItems: $breakpoints.mdAndUp ? 'center' : 'flex-start',
      ...headerStyle
    }"
  >
    <div class="laas-tab-header-container-left">
      <div
        v-if="showBack"
        @click="$emit('clickEvent')"
        class="laas-back-icon"
      >
        <md-icon>keyboard_backspace</md-icon>
      </div>
      <div class="md-title lato-font laas-bold">
        <slot name="header"/>
      </div>
      <div class="caption">
        <slot name="caption"/>
      </div>
    </div>
    <div class="laas-tab-header-container-right">
      <slot name="button-container"/>
    </div>
  </div>
</template>

<script>
/**
 * renders header, caption and button-container slots.
 * emits `clickEvent` when the back button is clicked.
 */
export default {
  name: 'WizardHeader',
  props: {
    showBack: {
      type: Boolean,
      default: () => true
    },
    headerStyle: {
      type: Object,
      required: false,
    },
  }
}
</script>

<style lang="sass" scoped>
#laas-wizard-header
  display: flex
  // flex-direction set on component
  // align-items set on component
  justify-content: space-between
  padding: 12px 0
  background-color: transparent

.laas-tab-header-container-left
  display: flex
  flex-direction: row
  align-items: center
  justify-content: flex-start
  color: black

.laas-tab-header-container-right
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  margin-right: 16px

.caption
  font-size: 14px
  color: #979797
  margin-left: 8px
  margin-top: 4px
</style>
