import store from '@/store'
import { merchantViews } from '@/constants'
import {
  adminDashboard,
  hotelDashboard,
  merchantDashboard
} from '@/config/nav/adminToggleNavConfigs'
import { NavConfig } from '@/types'

const notMerchantSetup: () => boolean = () => {
  return store.state.route.name !== merchantViews.MERCHANT_ACCOUNT_NEW
}

export const merchantNavConfig: NavConfig = {
  topMenuLinks: [
    {
      copy: 'Home',
      routeName: merchantViews.MERCHANT,
    },
  ],
  links: [
    {
      copy: 'Promotions',
      icon: ['fas', 'gift'],
      routeName: null,
      show: notMerchantSetup,
      children: [
        {
          copy: 'Promotions Manager',
          routeName: merchantViews.MERCHANT_PROMOTIONS,
          disabled: () => false
        },
        {
          copy: 'Analytics',
          routeName: merchantViews.MERCHANT_ANALYTICS,
          disabled: () => false
        },
      ],
    },
    {
      copy: 'Settings',
      icon: ['fas', 'cog'],
      routeName: merchantViews.MERCHANT_ACCOUNT,
      show: notMerchantSetup,
      children: null
    }
  ],
  adminToggles: [
    adminDashboard,
    hotelDashboard,
  ],
  currentApp: merchantDashboard.copy,
  showCopyHeader: true,
}
