<template>
  <div id="hotel-status">
    <Status
      :active="HOTEL_IS_LIVE"
      :copy="copy"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Status from '@/components/status/Status'

export default {
  name: 'HotelStatus',
  components: {
    Status
  },
  data () {
    return {
      copy: {
        active: 'Live',
        inactive: 'Not Live',
        entity: 'Property'
      }
    }
  },
  computed: {
    ...mapGetters(['HOTEL_IS_LIVE'])
  }
}
</script>
