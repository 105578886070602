<template>
  <div id="oprm-wizard-properties-step">
    <WizardStep :editing="editing">
      <CustomStepHeader
        slot="step-header"
        :index="index"
        :label="'Properties'"
      />
      <Properties slot="left-content" />
      <ImageDisplayPreview
        v-if="!editing"
        slot="right-content"
      />
      <CustomStepButtons
        slot="button-group"
        :disabled="IS_PROPERTIES_DISABLED"
        :index="index"
      />
    </WizardStep>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WizardStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/WizardStep'
import Properties from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/Properties'
import ImageDisplayPreview from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/ImageDisplayPreview'
import CustomStepHeader from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepHeader'
import CustomStepButtons from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepButtons'

export default {
  components: {
    WizardStep,
    CustomStepHeader,
    Properties,
    ImageDisplayPreview,
    CustomStepButtons
  },
  props: {
    editing: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('oprm', [
      'IS_PROPERTIES_DISABLED'
    ]),
  }
}
</script>
