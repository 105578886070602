





















import { mapGetters } from 'vuex'
import WizardStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/WizardStep.vue'
import SeasonalReward from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/SeasonalReward.vue'
import CustomStepHeader from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepHeader.vue'
import CustomStepButtons from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepButtons.vue'

export default {
  name: 'SeasonalRewardStep',
  components: {
    WizardStep,
    CustomStepHeader,
    CustomStepButtons,
    SeasonalReward,
  },
  props: {
    editing: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    meta: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters('oprm', [
      'IS_SEASONAL_REWARD_DISABLED'
    ])
  }
}
