export default class PostMessageUtils {
  static sendPostMessage (target = window, data = {}) {
    target.postMessage(
      JSON.stringify(data),
      '*'
    )
  }

  static listenForPostMessage (target = window, key, callback) {
    target.addEventListener('message', event => {
      try {
        const data = JSON.parse(event.data)
        /**
         * written in this way to allow post messages
         * to be written { key: false } and still be heard
         * where formerly `if (data[key])` would never execute cb
         */
        if (Object.keys(data).includes(key)) {
          callback(data[key])
        }
      } catch (err) {
        // do nothing - undesired PM's will be heard
      }
    })
  }
}
