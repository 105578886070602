<template lang="html">
  <svg
    id="icon-dollar2"
    viewBox="0 0 424.98 424.98"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.dollar2}">

    <title id="title">dollar2 icon</title>
    <desc id="desc">dollar2 icon</desc>

    <g>
      <path d="M241.395,282.304c-1.587-1.738-3.595-3.038-5.67-4.121c-4.518-2.356-9.459-3.785-14.365-5.075v38.016
        c7.963-0.9,17.105-3.79,21.286-11.224l0,0c1.996-3.551,2.393-7.914,1.58-11.867C243.785,285.891,242.874,283.925,241.395,282.304
        z"
      />
    </g>
    <g>
      <path d="M242.604,299.973c0.016-0.027,0.025-0.044,0.042-0.073l0,0C242.632,299.924,242.618,299.948,242.604,299.973z"/>
    </g>
    <g>
      <path d="M184.009,221.532c-1.369,1.999-2.228,4.27-2.465,6.684c-0.237,2.419-0.104,5.11,0.815,7.387
        c0.875,2.17,2.708,3.772,4.6,5.062c2.123,1.444,4.458,2.572,6.836,3.528c1.995,0.803,4.239,1.571,6.658,2.313v-34.4
        C194.342,213.41,187.665,216.194,184.009,221.532z"
      />
    </g>
    <g>
      <path d="M242.804,299.619c-0.05,0.089-0.104,0.182-0.157,0.28l0,0C242.709,299.785,242.758,299.701,242.804,299.619z"/>
    </g>
    <g>
      <path d="M243.004,299.263C243.017,299.239,243.019,299.237,243.004,299.263L243.004,299.263z"/>
    </g>
    <g>
      <path d="M234.753,92.469c32.329-27.158,53.931-88.341,40.637-91.017c-17.664-3.557-56.022,12.04-74.562,14.788
        c-26.296,3.175-54.936-28.515-71.012-10.851c-13.071,14.362,9.371,66.592,44.482,89.346
        C69.546,146.219-77.69,404.673,179.171,423.426C534.582,449.375,356.615,142.639,234.753,92.469z M265.276,296.298
        c-1.093,10.076-6.433,19.188-14.415,25.374c-8.428,6.532-18.999,9.57-29.502,10.421v11.133c0,2.979-1.301,5.86-3.531,7.832
        c-3.065,2.712-7.569,3.381-11.289,1.667c-3.673-1.69-6.086-5.457-6.086-9.499v-12.168c-1.801-0.342-3.589-0.749-5.356-1.234
        c-9.816-2.697-18.921-7.954-25.572-15.732c-3.313-3.877-6.014-8.276-7.882-13.025c-0.488-1.241-0.923-2.505-1.304-3.783
        c-0.345-1.157-0.701-2.333-0.824-3.539c-0.207-2.023,0.194-4.087,1.137-5.889c1.938-3.707,6.022-5.946,10.192-5.574
        c4.104,0.364,7.701,3.212,8.993,7.124c0.398,1.205,0.668,2.44,1.115,3.632c0.443,1.184,0.978,2.335,1.607,3.431
        c1.242,2.158,2.798,4.148,4.59,5.875c3.694,3.559,8.399,5.872,13.304,7.248v-41.362c-9.591-2.483-19.491-5.69-27.411-11.848
        c-3.849-2.994-7.115-6.714-9.254-11.117c-2.257-4.647-3.192-9.824-3.23-14.966c-0.039-5.221,0.953-10.396,3.131-15.153
        c2.04-4.454,4.977-8.453,8.578-11.768c7.7-7.087,17.928-11.04,28.187-12.492v-0.91v-10.647c0-2.978,1.301-5.86,3.531-7.832
        c3.066-2.711,7.568-3.381,11.289-1.667c3.672,1.691,6.086,5.457,6.086,9.499v10.647v0.847c1.367,0.172,2.73,0.378,4.086,0.624
        c10.074,1.823,19.927,5.983,27.294,13.246c3.49,3.44,6.347,7.539,8.356,12.009c0.561,1.247,1.052,2.523,1.477,3.824
        c0.396,1.213,0.794,2.462,0.983,3.728c0.302,2.021-0.006,4.109-0.871,5.958c-1.772,3.788-5.746,6.2-9.927,6.021
        c-4.108-0.179-7.83-2.854-9.301-6.694c-0.438-1.142-0.657-2.351-1.104-3.49c-0.451-1.153-1.035-2.253-1.708-3.292
        c-1.308-2.02-3.003-3.752-4.938-5.179c-4.19-3.094-9.272-4.706-14.35-5.607v39.582c6.035,1.445,12.075,3.021,17.857,5.301
        c8.739,3.446,17.02,8.73,21.79,17.062c-0.74-1.298-1.46-2.563,0.025,0.043c1.458,2.56,0.762,1.34,0.03,0.057
        C264.854,280.704,266.101,288.701,265.276,296.298z"
      />
    </g>
    <g>
      <path d="M242.493,300.169c-0.061,0.109-0.114,0.205-0.156,0.278C242.373,300.384,242.427,300.289,242.493,300.169z"/>
    </g>
</svg>
</template>

<script>
export default {
  name: 'IconDollar2',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
