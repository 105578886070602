<template>
  <div class="flex flex-row justify-start w-full bg-gray-100 p-2 my-2">
    <div class="mr-2">
      <span class="text-gray-800">
        <font-awesome-icon :icon="icon" />
      </span>
    </div>
    <span class="text-sm">{{ copy }}</span>
  </div>
</template>

<script>
export default {
  name: 'Info',
  props: {
    copy: {
      type: String,
      required: true,
    },
    icon: {
      type: Array,
      required: true,
    }
  },
}
</script>
