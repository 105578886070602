<template>
  <div class="wizard-step pb-6">
    <slot v-if="editing" name="step-header" />
    <div
      class="md-layout md-size-100"
      :class="{
        'column-reverse': reverseOrderOnMobile,
        'laas-oprm-step-container': editing,
      }"
      :style="{ paddingTop: '8px' }"
      ref="step-container"
    >
      <!-- content -->
      <div class="md-layout-item md-small-size-100 md-size-60 step-col-left">
        <slot name="left-content"/>
      </div>
      <div class="md-layout-item md-small-size-100  md-size-40 step-col-right">
        <slot name="right-content"/>
      </div>

    </div>
    <!-- buttons -->
    <div v-if="!editing" class="md-layout-item md-small-size-100 md-size-60" ref="button-group">
      <div class="laas-oprm-stepper-button-container">
        <slot name="button-group"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WizardStep',
  props: {
    editing: {
      required: true,
      type: Boolean,
    },
    reverseOrderOnMobile: {
      required: false,
      type: Boolean,
      default: false
    },
    step: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="sass" scoped>
  .laas-oprm-step-container
    padding: 0 24px 40px 60px
  .step-col-left
    padding-right: 30px
  @media only screen and (max-width: 970px)
    .column-reverse
      flex-direction: column-reverse
    .step-col-right
      padding-bottom: 20px
      text-align: justify
      max-width: 550px
</style>
