import { sortByName } from '@/utils/misc'
import { schemas } from '@/schemas'
import { PromotionParser, MerchantParser } from '@/parsers'

export const getters = {
  /**
    * filters promotions by merchant being viewed
    */
  promotionsList (state) {
    return Object.values(state.promotionsObj).sort(sortByName)
  },
  promotionObj (state) {
    return ({ uuid }) => state.promotionsObj[uuid]
  },
  promotionPatchPayload () {
    return ({ properties }) => {
      const toRepr = PromotionParser.inverseParsePromotion(properties)
      // must be at top level
      if (toRepr.reward_creative_configs && toRepr.reward_creative_configs[0]) {
        toRepr.thumbnail_image = toRepr.reward_creative_configs[0].thumbnail_image
        delete toRepr.reward_creative_configs[0].thumbnail_image
      }
      return Object.keys(toRepr).reduce((acc, currKey) => {
        if (Object.values(schemas.promotionNestedFields).indexOf(currKey) > -1) {
          acc[currKey] = JSON.stringify(toRepr[currKey])
        } else {
          acc[currKey] = toRepr[currKey]
        }
        return acc
      }, {})
    }
  },
  merchantsList (state) {
    return Object.values(state.merchantsObj).sort(sortByName)
  },
  merchantAccessDropdown (state, getters) {
    const newMerchant = [{
      id: 0,
      name: 'Create a new merchant'
    }]
    return newMerchant.concat(getters.merchantsList)
  },
  merchantCognitoMetadata (state) {
    const newMerchant = {
      0: {
        id: 0,
        name: 'Account Pending'
      }
    }
    return { ...newMerchant, ...state.merchantsObj }
  },
  getMerchant (state, getters) {
    return ({ id }) => getters.merchantCognitoMetadata[id]
  },
  merchantInView (state) {
    if (!state.merchantId) {
      return
    }
    return state.merchantsObj[state.merchantId]
  },
  getMerchantNames (state, getters) {
    return (user) => {
      return user.merchantIds.map(id => {
        return getters.getMerchant({ id }) && getters.getMerchant({ id }).name
      })
    }
  },
  regionsList (state) {
    return state.regionsList.sort(sortByName)
  },
  /**
    * @return {array} sorted BE list with 'Other' as last if present
    */
  bookingEngineNames (state) {
    const OTHER = 'Other'
    const unsortedNames = Object.keys(state.bookingEnginesObj)
    const otherIdx = unsortedNames.indexOf(OTHER)
    if (otherIdx > -1) {
      const other = unsortedNames.splice(otherIdx, 1)[0]
      return [
        ...unsortedNames.sort(),
        other,
      ]
    } else {
      return Object.keys(state.bookingEnginesObj).sort()
    }
  },
  getBookingEngine (state) {
    return ({ id }) => {
      return state.bookingEnginesObj[id]
    }
  },
  merchantPayload () {
    return ({ merchant }) => {
      const formData = new FormData()
      const toRepr = MerchantParser.inverseParseMerchant(merchant)
      return Object.keys(toRepr).reduce((acc, currKey) => {
        // allow for values to be set as ''
        if (toRepr[currKey] !== undefined && toRepr[currKey] !== null) {
          acc.append(currKey, toRepr[currKey])
        }
        return acc
      }, formData)
    }
  },
  isInitialAccountSetup (state, getters) {
    return getters.merchantsList.length === 0
  },
  getMerchantsById (state, getters) {
    return (merchantIds) => {
      console.log('merchantIds')
      return merchantIds.map(id => getters.getMerchant({ id }))
    }
  },
}
