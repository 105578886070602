<template>
  <svg
    id="icon-wifi"
    viewBox="0 0 455.838 455.838"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.wifi}">

    <title id="title">wifi icon</title>
    <desc id="desc">wifi icon</desc>

    <g>
      <path d="M454.968,160.667c-0.175-0.257-0.374-0.498-0.595-0.719C393.884,99.46,
        313.461,66.147,227.919,66.147c-85.537,0-165.953,33.306-226.439,93.788c-0.94,
        0.933-1.48,2.21-1.48,3.552c0,1.326,0.526,2.598,1.465,3.536l33.113,33.114
        c0.938,0.938,2.209,1.464,3.535,1.464c1.326,0,2.598-0.527,3.535-1.464c49.836
        -49.839,115.988-77.285,186.271-77.285c70.282,0,136.434,27.447,186.271,77.284
        c0.938,0.938,2.209,1.464,3.534,1.464c1.326,0,2.599-0.527,3.535-1.464l33.113-33.114
        c0.938-0.938,1.465-2.209,1.465-3.536C455.839,162.481,455.534,161.499,454.968,160.667z"/>
      <path d="M227.919,153.719c-62.056,0-120.461,24.231-164.458,68.229c-1.952,1.953
        -1.952,5.119,0.001,7.071l33.01,33.009c0.938,0.938,2.209,1.465,3.535,1.465
        c1.326,0,2.598-0.527,3.535-1.465c33.277-33.277,77.448-51.605,124.377-51.605
        c46.931,0,91.102,18.328,124.376,51.605c0.938,0.938,2.21,1.465,3.536,1.465
        s2.598-0.527,3.535-1.465l33.011-33.009c0.938-0.938,1.465-2.209,1.465-3.536
        c0-1.327-0.525-2.598-1.465-3.536C348.38,177.951,289.975,153.719,227.919,153.719z"/>
      <path d="M227.919,241.292c-38.701,0-75.126,15.11-102.564,42.549c-0.939,0.938
        -1.465,2.209-1.465,3.537c0,1.326,0.525,2.598,1.465,3.535l33.01,33.01c0.938,
        0.938,2.209,1.465,3.535,1.465s2.598-0.527,3.535-1.465c16.719-16.719,38.909
        -25.926,62.484-25.926s45.767,9.209,62.485,25.926c0.938,0.938,2.209,1.465,
        3.534,1.465s2.598-0.527,3.535-1.465l33.01-33.01c1.952-1.953,1.952-5.119,0
        -7.07C303.046,256.402,266.621,241.292,227.919,241.292z"/>
      <path d="M227.919,334.083c-13.521,0-26.229,5.264-35.784,14.822c-1.952,1.953
        -1.952,5.118,0.001,7.07l32.248,32.25c0.938,0.938,2.209,1.465,3.535,1.465
        s2.599-0.527,3.535-1.465l32.248-32.25c1.952-1.953,1.952-5.118,0-7.071C254.146,
        339.347,241.438,334.083,227.919,334.083z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconWifi',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
