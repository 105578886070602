<template>
  <div
    class="laas-oprm-stepper-button-container"
    :style="{
      justifyContent: navConfig.previous ? 'space-between' : 'flex-end'
    }"
  >
    <StepperButton
      ref="previous"
      v-show="navConfig.previous"
      @clickEvent="handlePrevious"
      :disabled="false"
      :copy="copy.previous"
    />
    <StepperButton
      ref="next"
      @clickEvent="handleNext"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton'

export default {
  name: 'CustomStepperButtons',
  inject: [
    'CustomStepper_nextStep',
    'CustomStepper_setStepDone',
    'CustomStepper_navConfig',
    'CustomStepper_previousStep'
  ],
  components: {
    StepperButton
  },
  props: {
    disabled: {
      required: true,
      type: Boolean,
    },
    index: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      copy: {
        previous: 'Previous'
      }
    }
  },
  computed: {
    navConfig () {
      return this.CustomStepper_navConfig(this.index)
    }
  },
  methods: {
    handleNext () {
      // custom stepper will handle opening next step or emitting a done event to wizard
      this.CustomStepper_setStepDone(this.index)
      this.CustomStepper_nextStep()
    },
    handlePrevious () {
      this.CustomStepper_previousStep()
    }
  },
}
</script>

<style lang="sass" scoped>
// TODO extract to sass file w/ variables
.laas-oprm-stepper-button-container
  max-width: 570px
  display: flex
  padding-right: 20px
  padding-top: 10px
</style>
