var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('md-card',{staticClass:"fux-ctx-list"},[_c('md-card-header',[_c('div',{staticClass:"md-title no-wrap"},[_vm._v(" "+_vm._s(_vm.contextPretty)+" ")])]),_c('md-card-content',[_c('md-list',[_c('md-list-item',[_c('span',{staticClass:"md-caption"},[_vm._v("Desktop")]),_c('md-button',{staticClass:"md-icon-button md-list-action",on:{"click":function($event){return _vm.navToEditor({
            channel: 'dt'
          })}}},[_c('md-icon',[_vm._v("add_circle")])],1)],1),_c('md-divider'),_vm._l((_vm.dtFuxs),function(fux){return _c('md-list-item',{key:fux.uuid},[(fux.enabled)?_c('md-icon',{style:(_vm.enabled)},[_vm._v(" brightness_1 ")]):_c('md-icon',{staticClass:"disabled-circle"},[_vm._v(" brightness_1 ")]),_c('span',{staticClass:"md-list-item-text no-wrap"},[_vm._v(" "+_vm._s(fux.name)+" "),_c('md-tooltip',{staticClass:"fux-name-tooltip"},[_vm._v(" "+_vm._s(fux.name)+" ")])],1),_c('div',{staticClass:"button-tooltip-container"},[(_vm.disableEditor(fux))?_c('md-tooltip',{attrs:{"md-direction":"right"}},[_vm._v(" "+_vm._s(_vm.copy.disabledEditorTooltip)+" ")]):_vm._e(),_c('md-button',{staticClass:"md-icon-button md-list-action",attrs:{"disabled":_vm.disableEditor(fux)},on:{"click":function($event){return _vm.navToEditor({
              fuxUuid: fux.uuid,
              channel: 'dt'
            })}}},[_c('md-icon',[_vm._v("build")])],1)],1)],1)}),_c('md-divider'),_c('md-list-item',[_c('span',{staticClass:"md-caption"},[_vm._v("Mobile")]),_c('md-button',{staticClass:"md-icon-button md-list-action",on:{"click":function($event){return _vm.navToEditor({
            channel: 'mb'
          })}}},[_c('md-icon',[_vm._v("add_circle")])],1)],1),_c('md-divider'),_vm._l((_vm.mbFuxs),function(fux){return _c('md-list-item',{key:fux.uuid},[(fux.enabled)?_c('md-icon',{style:(_vm.enabled)},[_vm._v(" brightness_1 ")]):_c('md-icon',{staticClass:"disabled-circle"},[_vm._v(" brightness_1 ")]),_c('span',{staticClass:"md-list-item-text no-wrap"},[_vm._v(" "+_vm._s(fux.name)+" "),_c('md-tooltip',{staticClass:"fux-name-tooltip"},[_vm._v(" "+_vm._s(fux.name)+" ")])],1),_c('div',{staticClass:"button-tooltip-container"},[(_vm.disableEditor(fux))?_c('md-tooltip',{attrs:{"md-direction":"right"}},[_vm._v(" "+_vm._s(_vm.copy.disabledEditorTooltip)+" ")]):_vm._e(),_c('md-button',{staticClass:"md-icon-button md-list-action",attrs:{"disabled":_vm.disableEditor(fux)},on:{"click":function($event){return _vm.navToEditor({
              fuxUuid: fux.uuid,
              channel: 'mb'
            })}}},[_c('md-icon',[_vm._v("build")])],1)],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }