export const $breakpointMixin = {
  data () {
    return {
      windowWidth: window.innerWidth
    }
  },
  mounted () {
    if (!window) {
      console.warn('Cannot listen to window resize.')
      return
    }
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  computed: {
    $breakpoints () {
      const xs = this.windowWidth < 600
      const sm = this.windowWidth < 960 && !xs
      const md = this.windowWidth < 1264 && !(xs || sm)
      const lg = this.windowWidth < 1900 && !(xs || sm || md)
      const xl = this.windowWidth >= 1900
      return {
        xs,
        sm,
        md,
        lg,
        xl,
        smAndDown: (xs || sm) && !(md || lg || xl),
        smAndUp: !xs && (sm || md || lg || xl),
        mdAndDown: (xs || sm || md) && !(lg || xl),
        mdAndUp: !(xs || sm) && (md || lg || xl),
        lgAndDown: !xl && (xs || sm || md || lg),
        lgAndUp: !(xs || sm || md) && (lg || xl),

      }
    }
  }
}
