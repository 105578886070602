import { userViews, merchantViews, adminViews } from '@/constants'
import { AdminToggleConfig } from '@/types'

export const hotelDashboard: AdminToggleConfig = {
  copy: 'Hotel Dashboard',
  icon: 'hotel',
  routeName: userViews.DASHBOARD,
}

export const merchantDashboard: AdminToggleConfig = {
  copy: 'Merchant Dashboard',
  icon: 'shopping_cart',
  routeName: merchantViews.MERCHANT_PROMOTIONS,
}

export const adminDashboard: AdminToggleConfig = {
  copy: 'Admin Dashboard',
  icon: 'settings',
  routeName: adminViews.ADMIN,
}
