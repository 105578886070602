import { adminViews } from '@/constants'
import {
  hotelDashboard,
  merchantDashboard,
  adminDashboard
} from '@/config/nav/adminToggleNavConfigs'
import { NavConfig } from '@/types'

export const adminNavConfig: NavConfig = {
  topMenuLinks: [
    {
      copy: 'Home',
      routeName: adminViews.ADMIN
    }
  ],
  links: [
    {
      copy: 'Tools',
      icon: ['fas', 'tools'],
      routeName: null,
      children: [
        {
          copy: 'Bulk Duplicate/Edit',
          routeName: adminViews.BULK_UPDATE,
          disabled: () => false
        },
        {
          copy: 'Manage Users',
          routeName: adminViews.USERS,
          disabled: () => false
        },
      ]
    }
  ],
  adminToggles: [
    hotelDashboard,
    merchantDashboard,
  ],
  currentApp: adminDashboard.copy,
  showCopyHeader: false
}
