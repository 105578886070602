<template lang="html">
  <div id="wrapper">
    <div
      id="branding-container" class="flex items-center justify-center">
      <img id="logo" src="../../assets/img/laasie_logo_2020__dark.svg">
    </div>
    <div id="not-found-content" class="flex items-center justify-center">
      <div class="rectangle px-12 py-12 bg-white rounded">
        <div id="not-found-title" class="ml-2 text-center font-bold">
          <font-awesome-icon :icon="iconName"/>
          <span> {{ copy.header }} </span>
        </div>
        <div id="not-found-body" class="mt-8 text-center leading-5">
          <div> {{ copy.content1 }} </div>
          <br>
          <div> {{ copy.contact }} </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotFoundContainer',
  data () {
    return {
      iconName: 'exclamation-triangle',
      copy: {
        header: 'Page Not Found',
        content1: 'Oops, the page you are trying to reach does not exist, or you may not have access to it.',
        contact: 'If you believe this is an error, please contact support@laasie.ai.'
      }
    }
  },
}
</script>

<style lang="sass">
#wrapper
  background-color: #f7f7f7
  height: 100%
  width: 100%

  #logo
    width: 130px

  #branding-container
    padding: 90px 0 50px 0

  .rectangle
    width: 435px

  #not-found-title
    font-family: Lato
    font-size: 23px
    color: #161616

  #not-found-body
    font-family: Lato
    font-size: 13px
    color: #656565
</style>
