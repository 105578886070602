import { CampaignCategory } from '@/types'
import { userViews } from '@/constants'

export function getMetricsRoute (category: CampaignCategory): string {
  const _category: CampaignCategory = category || 'email_and_offer'
  const routes = {
    other: userViews.WORKFLOWS_METRICS_EMAIL_AND_OFFER,
    email_and_offer: userViews.WORKFLOWS_METRICS_EMAIL_AND_OFFER,
    email_only: userViews.WORKFLOWS_METRICS_EMAIL_ONLY,
    offer_only: userViews.WORKFLOWS_METRICS_OFFER_ONLY,
    email_design_test: userViews.WORKFLOWS_METRICS_EMAIL_DESIGN_TEST
  } as Record<CampaignCategory, string>

  return routes[_category] || userViews.WORKFLOWS_METRICS_EMAIL_AND_OFFER
}
