<template>
  <div id='oprm-wizard-reward-cost-step'>
    <WizardStep :editing="editing" :reverseOrderOnMobile="true">
      <CustomStepHeader slot="step-header" :index="index" :label="'Reward Cost & Value'" :meta="meta"/>
      <RedemptionRewardCost v-if="HOTEL_GROUP_IS_RETAIN" slot="left-content"/>
      <CustomStepButtons
        slot="button-group"
        :disabled="false"
        :index="index"
      />
    </WizardStep>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import WizardStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/WizardStep.vue'
import RedemptionRewardCost from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/RedemptionRewardCost.vue'
import CustomStepHeader from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepHeader.vue'
import CustomStepButtons from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepButtons.vue'

export default Vue.extend({
  name: 'RedemptionRewardCostStep',
  components: {
    WizardStep,
    CustomStepHeader,
    CustomStepButtons,
    RedemptionRewardCost,
  },
  props: {
    editing: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    meta: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters(['HOTEL_GROUP_IS_RETAIN']),
    ...mapGetters('oprm', [
      'IS_REDEMPTION_INSTRUCTIONS_DISABLED'
    ]),
  },
})
</script>
