<template>
  <div id="hotels-dropdown">
    <FilteredDropdown
      :selectedItem="primaryHotel"
      :items="hotelsList"
      @selected="handleSelected"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { userViews } from '@/constants'
import FilteredDropdown from '@/components/dropdown/FilteredDropdown'

export default {
  name: 'HotelsDropdown',
  components: {
    FilteredDropdown,
  },
  computed: {
    ...mapState([
      'primaryHotel',
    ]),
    ...mapGetters('hotel', [
      'hotelsList',
    ])
  },
  methods: {
    handleSelected (hotel) {
      this.$store.dispatch('SET_PRIMARY_HOTEL_BY_ID', hotel.id)
      this.$router.push({
        name: userViews.DASHBOARD,
        params: { hotelId: hotel.id }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
