





































import { mapGetters } from 'vuex'
import Drawer from '@/components/navigation/drawer/Drawer.vue'
import DrawerItem from '@/components/navigation/drawer/DrawerItem.vue'
import { getShow } from '@/utils/getShow'

export default {
  name: 'DashboardDrawer',
  components: {
    Drawer,
    DrawerItem,
  },
  props: {
    styles: {
      type: Object,
      required: true,
      default: () => ({})
    },
    navConfig: {
      type: Object,
      required: true,
      default: () => ({
        topMenuLinks: [],
        links: [],
        adminToggles: [],
      }),
    },
    dropdownComponent: {
      type: Object,
      required: false
    },
    statusComponent: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      copy: {
        header: 'View As',
        vanity: 'This is a corporate property.'
      }
    }
  },
  computed: {
    ...mapGetters(['IS_VANITY_PROPERTY'])
  },
  methods: {
    getShow
  }
}
