import { DateTime } from 'luxon'
import { Workflow } from '@/types'
import moment from 'moment-timezone'

// TODO remove luxon and replace w/ .tz
export function getTimeZone (): string {
  const d = DateTime.local()
  return `Time Zone: ${d.toFormat("z, ZZZZ 'GMT'ZZ")}`
}

export const triggerNodeDatetime = (node: Workflow): string => {
  const tz = moment.tz.guess()
  const d = moment(node.param as string).tz(tz)
  return `Starts ${d.format('dddd, MMMM Do, YYYY')} at ${d.format('h:mma z')}`
}
