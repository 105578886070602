<template>
  <svg
    id="icon-piggybank"
    viewBox="0 0 480 480"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.piggybank}">

    <title id="title">piggybank icon</title>
    <desc id="desc">piggybank icon</desc>

    <g>
      <path d="M155.2,241.7c66.7,0,120.8-54.1,120.8-120.8S221.9,0,155.2,0S34.4,
        54.1,34.4,120.8S88.5,241.7,155.2,241.7z M113,168.6c1.1-4,2.2-7.9,3.4-11.9
        c1.4-4.6,2.7-5.1,6.9-2.9c7.2,3.7,14.8,5.8,22.8,6.8c5.1,0.6,10.2,0.1,14.9
        -2c8.9-3.9,10.3-14.2,2.8-20.4c-2.6-2.1-5.5-3.7-8.5-5c-7.8-3.4-15.9-6-23.3
        -10.4c-11.9-7.1-19.5-17-18.6-31.5c1-16.4,10.3-26.7,25.3-32.1c6.2-2.3,6.2
        -2.2,6.3-8.7c0-2.2,0-4.4,0-6.6c0.1-4.9,1-5.7,5.8-5.9c1.5,0,3,0,4.5,0c10.4,
        0,10.4,0,10.4,10.4c0,7.3,0,7.4,7.3,8.5c5.6,0.9,10.9,2.5,16.1,4.8C192,63,
        193,65,192.2,68c-1.3,4.5-2.5,9-4,13.4c-1.4,4.2-2.7,4.8-6.7,2.9c-8.1-3.9
        -16.6-5.6-25.6-5.1c-2.4,0.1-4.6,0.4-6.8,1.4c-7.7,3.4-9,11.9-2.4,17.2c3.3,
        2.7,7.1,4.6,11.1,6.2c6.8,2.8,13.7,5.6,20.3,9.2c20.7,11.5,26.3,37.5,11.7,
        55.2c-5.3,6.4-12.1,10.7-20.2,12.9c-3.5,1-5.1,2.8-4.9,6.4c0.2,3.6,0,7.1,0,
        10.7c0,3.2-1.6,4.9-4.8,5c-3.8,0.1-7.7,0.1-11.5,0c-3.4-0.1-5-2-5-5.3c0-2.6,
        0-5.2-0.1-7.8c-0.1-5.7-0.2-6-5.8-6.9c-7.1-1.1-14-2.7-20.5-5.9C112.1,175.2,
        111.6,173.9,113,168.6z"/>
      <path d="M426.2,251.8h-14.1l0,0c-9.3-28.5-27.5-53.8-51.8-73.4c0-0.9-0.2-1.9
        -0.4-2.8c-5.1-17.9,1.2-34.2,8.5-45.9c5.1-8.2-1.5-18.7-11.1-17.5c-21.5,2.7
        -36.3,10.6-46.5,19.5c-7,6.1-12,14.3-14.2,23.4c-15.4,63.8-72.9,111.3-141.4,111.3
        c-33.3,0-63.9-11.2-88.4-30c-8.5,18.4-13.1,38.5-13.1,59.6c0,11.4,1.4,22.5,
        4,33.2l0,0c0,0,0,0.2,0.1,0.5c2.7,11.1,6.8,21.8,12,31.9c6.9,14.9,16.9,32.3,
        30.7,46.4c19.9,20.5,23.3,45.9,23.8,56.9v4.7c0,0.2,0,0.4,0,0.6c0,0.1,0,0.1,0,0.1
        l0,0c0.4,5.4,4.9,9.7,10.4,9.7h63.2c5.7,0,10.4-4.6,10.4-10.4V454c9.2,1.2,
        18.6,1.9,28.1,1.9c7.5,0,14.8-0.4,22.1-1.2v14.9c0,5.7,4.6,10.4,10.4,10.4
        h63.4c5.7,0,10.4-4.6,10.4-10.4V454c0.1-5.2,1.9-25.9,21.5-43.6c0.7-0.6,1.4-1.2,
        2.1-1.8c0.2-0.2,0.4-0.3,0.6-0.5l0,0c21.8-19.4,37.9-43.6,46.2-70.6h13.3c10.6,
        0,19.2-8.6,19.2-19.2V271C445.4,260.4,436.8,251.8,426.2,251.8z M347.1,255.2
        c-8.7,0-15.7-7-15.7-15.7s7-15.7,15.7-15.7s15.7,7,15.7,15.7C362.8,248.2,
        355.8,255.2,347.1,255.2z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconPiggybank',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
