<template>
  <div id="text-display-preview-container">
    <div class="md-body-2 laas-bold laas-section-title">
      {{ copy.title }}
    </div>
    <div class="md-body laas-oprm-body-text-color laas-section-title">
      {{ copy.body }}
    </div>
    <div id="preview">
      <div id="preview-content">
        <div id="preview-icon-tile">
          <div id="preview-icon-content">
            <md-icon v-if="showPlaceholderIcon">
              image
            </md-icon>
            <component
              :is="icon"
              v-if="!showPlaceholderIcon"
              id="preview-icon"
            />
          </div>
        </div>
        <div id="preview-title">
          <div :class="titleClass">
            {{ titleCopy }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swIcons from '@/components/smart-widget-icons/'

export default {
  name: 'TextDisplayPreview',
  components: {
    ...swIcons.components
  },
  data () {
    return {
      copy: {
        title: 'Text Display Preview',
        body: 'Customers will see your reward text and relevant icon when they are in the process of booking. Previews will demo your changes in real-time.',
        placeholder: 'Your promotion title will appear here'
      }
    }
  },
  computed: {
    title () {
      return this.$store.state.oprm.reward.creativeConfigs[0].promotedText
    },
    titleCopy () {
      return this.title || this.copy.placeholder
    },
    titleClass () {
      return this.title ? 'preview-title-active' : 'preview-title-placeholder'
    },
    icon () {
      return this.$store.state.oprm.reward.creativeConfigs[0].promotedIcon
    },
    showPlaceholderIcon () {
      return !this.$store.state.oprm.reward.creativeConfigs[0].promotedIcon
    },
  }
}
</script>

<style lang="sass" scoped>
#preview
  margin-top: 10px
  width: 350px
  height: 112px
  border-radius: 3px
  background-color: #f8f8f8
  display: flex
  justify-content: center
  align-items: center

#preview-content
  width: 298px
  height: 70px
  border-radius: 2.6px
  border: solid 2px #eee
  background-color: #ffffff
  display: flex

#preview-title
  width: 100%
  height: 36px
  height: 100%
  width: 240px
  display: flex
  align-items: center
  padding-right: 40px

  font-size: 15px
  font-weight: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: normal

.preview-title-placeholder
  font-style: italic
  color: #979797
.preview-title-active
  font-style: normal
  color: #000000

#preview-icon-tile
  width: auto
  height: 100%
  display: flex
  align-items: center
  padding: 0 14px
  // width: 58px

#preview-icon-content
  background-color: #d8d8d8
  border-radius: 50%
  height: 38px
  width: 38px
  display: flex
  align-items: center

#preview-icon
  width: 20px
  height: 20px
  margin: 0 auto
</style>
