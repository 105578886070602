<template>
  <div id="bulk-design-duplication-fux-search">
    <div class="md-body-2 laas-bold">
      {{ copy.body1 }}
    </div>
    <div class="md-body-1">
      {{ copy.body2 }}
    </div>

    <div id="name-auto-complete-selector">
      <md-autocomplete
        id="auto-complete"
        v-model="searchTerm"
        :md-options="FUX_AUTOCOMPLETE_LIST"
        :md-input-placeholder="copy.searchPlaceholder"
        @md-changed="searchFuxes($event)"
        @md-selected="setFuxToDuplicate($event)"
      >
        <template
          slot="md-autocomplete-item"
          slot-scope="{ item, term }"
        >
          <div class="auto-item">
            <md-highlight-text :md-term="term">
              {{ item.funnel_ux }}
            </md-highlight-text>
          </div>
        </template>
      </md-autocomplete>
    </div>
    <button
      v-if="showPreview"
      id="preview-design-button"
    >
      <a
        :href="`${previewFuxHref}`"
        target="_blank"
      >
        {{ copy.previewButton }}
      </a>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { EditorUtils } from '@/editor/utils'
import { CTX_URL_MAPPING } from '@/components/hotel-dashboard/editor/constants'

export default {
  name: 'FuxSearch',
  data () {
    return {
      searchTerm: undefined,
      showPreview: false,
      copy: {
        body1: 'Search Funnel UX',
        body2: 'Search for the funnel UX you would like to duplicate from the below search bar.',
        searchPlaceholder: 'Start typing the funnel UX name...',
        previewButton: 'Preview Design'
      }
    }
  },
  computed: {
    ...mapGetters('designDuplication', [
      'FUX_AUTOCOMPLETE_LIST',
    ]),
    ...mapState('hotel', [
      'cradleHotelObj',
    ]),
    ...mapState('designDuplication', [
      'design',
      'designSetSaveSuccess'
    ]),
    clientSite () {
      const key = CTX_URL_MAPPING[this.$store.state.designDuplication.fuxPreviewCtx]
      return this.cradleHotelObj[key] || 'https://rownyc.com'
    },
    previewFuxHref () {
      const previewLink = EditorUtils.buildUrl({
        fuxUuid: this.design.fux_uuid,
        channel: this.$store.state.designDuplication.fuxChannel,
        base: this.clientSite,
        context: this.$store.state.designDuplication.fuxPreviewCtx,
        hotelId: this.cradleHotelObj.id
      })
      return previewLink
    },
  },
  methods: {
    searchFuxes (searchTerm) {
      if (typeof searchTerm !== 'string') return
      if (!searchTerm || searchTerm.length < 2) return
      this.$store.dispatch('designDuplication/FETCH_FUXES', { searchTerm })
    },
    async setFuxToDuplicate (value) {
      this.showPreview = true
      this.$store.commit('designDuplication/SET_FUX_PREVIEW_CTX', value.context)
      this.$store.commit('designDuplication/SET_FUX_CHANNEL', value.channel)

      const hotelObj = this.$store.getters['hotel/hotelObjsByCradleId'][value.hotel]
      await this.$store.dispatch('designDuplication/FETCH_HOTEL_DETAIL', hotelObj.id)

      if (!value.design_set_name) {
        return this.$store.commit('designDuplication/SET_FUX_TO_DUPLICATE', value.uuid)
      }

      this.$store.commit('designDuplication/SET_DESIGN_NAME_TAKEN', true)
      this.$store.commit('designDuplication/SET_DESIGN_NAME', value.design_set_name)
      this.$store.commit('designDuplication/SET_FUX_TO_DUPLICATE', value.uuid)
    }
  },
  watch: {
    searchTerm (val) {
      if (val === '') {
        this.$store.commit('designDuplication/SET_DESIGN_NAME_TAKEN', false)
        this.$store.commit('designDuplication/SET_DESIGN_NAME', undefined)
        this.showPreview = false
      }
    },
    designSetSaveSuccess (to, from) {
      if (to === true && from === false) this.searchTerm = ''
    }
  },
}
</script>

<style scoped lang="sass">
#bulk-design-duplication-fux-search
  width: 600px

#auto-complete
  width: 100%
  background-color: #e9e9e9
  color: black
  input, div.md-menu
    padding-left: 5px
    padding-bottom: 8px
    overflow: hidden

#preview-design-button
  border: none
  background-color: #fff
  padding-left: 0
  a
    color: #fff
    font-family: Lato
    color: black
    text-decoration: underline
    font-weight: 600
</style>
