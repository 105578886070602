import LambdaUtils from '@/utils/LambdaUtils'
import { set } from 'vue-gtag'

export default {
  /** NOTE
    * primary vuex action for controlling data fetching flow.
    * the only place the spinner is set.
    */
  async FETCH_RENDER_DATA ({ state, getters, commit, dispatch }, view) {
    console.log('[FETCH_RENDER_DATA]', view)
    if (!state.isLoading) {
      dispatch('SET_IS_LOADING', true)
    }
    if (!state.userProfile) {
      await dispatch('SET_USER_PROFILE')
    }
    if (getters['hotel/hotelsList'].length === 0) {
      await dispatch('FETCH_USER_HOTELS', { fetchAll: false })
    }
    if (!state.primaryHotel) {
      let primaryHotel
      if (state.route.params.hotelId && getters.HOTEL_ID_ALLOWED(state.route.params.hotelId)) {
        primaryHotel = state.hotel.cradleHotelsObj[state.route.params.hotelId]
      } else {
        primaryHotel = getters['hotel/hotelsList'][0]
      }
      primaryHotel = primaryHotel || getters['userManagement/hotelCognitoMetadata'][0]
      commit('SET_PRIMARY_HOTEL', primaryHotel)
    }
    dispatch('FETCH_SPLIT_TEST')
    dispatch('SET_IS_LOADING', false)
    dispatch('FETCH_USER_HOTELS', { fetchAll: true })
  },

  async FETCH_USER_HOTELS ({ dispatch }, { fetchAll }) {
    if (!fetchAll) {
      await dispatch('hotel/FETCH_HOTELS', { fetchAll, pageSize: 20 })
    }
    dispatch('hotel/FETCH_HOTELS', { fetchAll, pageSize: 200 })
  },

  async FETCH_LOOKER_EMBED_URLS ({ commit, getters }, route) {
    const lookerEmbedUrls = await LambdaUtils.invokeFn({
      functionName: process.env.VUE_APP_LAMBDA_FN_LOOKER_SSO,
      payload: getters.LOOKER_SSO_PAYLOAD(route)
    })
    console.log('[FETCH_LOOKER_EMBED_URLS]', lookerEmbedUrls)
    return commit('SET_LOOKER_EMBED_URLS', lookerEmbedUrls.data)
  },

  async FETCH_SPLIT_TEST ({ commit, getters }) {
    const shouldShow = await LambdaUtils.invokeFn({
      functionName: 'show_ctrlexp',
      payload: getters.SHOW_CTRLEXP_PAYLOAD()
    })
    console.log('shouldShow splittest', shouldShow)
    if (shouldShow.error) {
      return
    }
    return commit('SET_SHOW_SPLIT_TEST', shouldShow.data)
  },

  SET_PRIMARY_HOTEL_BY_ID ({ state, commit, getters }, hotelId) {
    const primaryHotel = state.hotel.cradleHotelsObj[hotelId] ||
      getters['userManagement/hotelCognitoMetadata'][0]
    commit('SET_PRIMARY_HOTEL', primaryHotel)
  },

  async LOGIN_USER ({ state, commit, dispatch }, credentials) {
    const response = await state.authController.loginUser(credentials)
    console.log('login user response', response)
    if (response.error) {
      commit('SET_AUTH_ERROR', response.error)
    } else {
      await dispatch('SET_USER_PROFILE')
      commit('SET_USER_IS_AUTH', true)
      // clear previous error from a failed login if exists
      if (state.authError) {
        commit('SET_AUTH_ERROR', undefined)
      }
    }
  },

  async LOGOUT_USER ({ commit, state }) {
    commit('SET_USER_IS_AUTH', false)
    await state.authController.logout()
    // guarantees the state is reset
    window && window.location.reload()
  },

  SET_USER_IS_AUTH ({ commit }, data) {
    if (typeof data !== 'boolean') {
      throw new TypeError('data must be boolean', data)
    }
    commit('SET_USER_IS_AUTH', data)
  },

  async CHANGE_PASSWORD ({ state, commit }, data) {
    const response = await state.authController.changePassword(data)
    if (response.error) {
      return commit('SET_CHANGE_PASSWORD_ERROR', response.error)
    } else {
      return commit('SET_CHANGE_PASSWORD_ERROR', undefined)
    }
  },

  async CONFIRM_PASSWORD ({ state, commit }, data) {
    const response = await state.authController.confirmPassword(data)
    if (response.error) {
      return commit('SET_CONFIRM_PASSWORD_ERROR', response.error)
    } else {
      return commit('SET_CONFIRM_PASSWORD_ERROR', undefined)
    }
  },

  async RECOVER_PASSWORD ({ state, commit }, data) {
    const response = await state.authController.recoverPassword(data)
    if (response.error) {
      return commit('SET_RECOVER_PASSWORD_ERROR', response.error)
    } else {
      return commit('SET_RECOVER_PASSWORD_ERROR', undefined)
    }
  },

  async SET_USER_PROFILE ({ state, commit }) {
    const user = await state.authController.getUser()
    set({ dimension1: user.userProfile.email })
    set({ dimension2: user.userProfile.group_id })
    set({ dimension3: user.userProfile.hotel_code })
    set({ dimension4: user.userProfile.type })
    return commit('SET_USER_PROFILE', user.userProfile)
  },

  SET_IS_LOADING ({ commit }, bool) {
    console.log('set isloading', bool)
    commit('SET_IS_LOADING', bool)
  },

  /**
    * will refresh token if user enters app and token would expire before the
    * user would be prompted to end or continue session.
    * will set timeout to prompt user with modal to end or continue session.
    * can override timeout with localStorage for QA in dev & staging env's.
    * used in both the dashboard and the editor vue instances.
    * refernces the cognito identity token.
    */
  async MANAGE_TOKEN ({ state, commit, dispatch }) {
    if (state.authController.getTokenTimeRemaining() < 1) {
      commit('SHOW_SESSION_REFRESH_MODAL', false)
      return dispatch('LOGOUT_USER')
    }
    if (state.authController.tokenToExpireSoon()) {
      await state.authController.refreshSession()
    }

    let timeout = state.authController.getTokenRefreshTimeout()
    console.log('[MANAGE_TOKEN] 1 timeout', timeout)
    /**
     * handle case when session expiration is not retrieved from storage
     * and renders refresh session modal when logging in.
     */
    if (isNaN(timeout)) {
      await state.authController.refreshSession()
      timeout = state.authController.getTokenRefreshTimeout()
    }
    console.log('[MANAGE_TOKEN] 2 timeout', timeout)

    window.__tokenRefreshTimeout__ = setTimeout(() => {
      commit('SHOW_SESSION_REFRESH_MODAL', true)
    }, timeout)
  },

  async REFRESH_SESSION ({ state, commit, dispatch }) {
    await state.authController.refreshSession()
    commit('SHOW_SESSION_REFRESH_MODAL', false)
    dispatch('MANAGE_TOKEN')
  }
}
