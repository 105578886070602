
























import { Route } from 'vue-router'
import {
  PRIMARY,
  SECONDARY,
  DEFAULT,
  NORMAL,
  COMPACT,
  ROUND,
  ROUND_SMALL,
  AUTO,
} from '@/components/button/constants'
import { ButtonType, ButtonSize } from '@/types'

export default {
  name: 'Button',
  props: {
    type: {
      type: String as () => ButtonType,
      default: 'primary',
      validator (value) {
        return [PRIMARY, SECONDARY, DEFAULT].includes(value)
      },
      required: false,
    },
    size: {
      type: String as () => ButtonSize,
      default: 'normal',
      validator (value) {
        return [NORMAL, COMPACT, ROUND, ROUND_SMALL].includes(value)
      },
      required: false,
    },
    width: {
      type: [String, Number],
      default: 'auto',
      validator (value) {
        if (typeof value === 'number') {
          return value > 0
        }
        return [AUTO].includes(value)
      },
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
      validator (value) {
        return typeof value === 'boolean'
      }
    },
    to: {
      type: Object as () => Route,
      required: false
    }
  },
  computed: {
    typeClasses () {
      const opts = {
        [PRIMARY]: [
          'type--primary'
        ],
        [SECONDARY]: [
          'type--secondary'
        ],
        [DEFAULT]: [
          'type--default'
        ],
      }
      return opts[this.type]
    },
    sizeClasses (): string[] {
      const opts = {
        [NORMAL]: [
          'size--normal',
        ],
        [COMPACT]: [
          'size--compact',
        ],
        [ROUND]: [
          'size--round',
        ],
        [ROUND_SMALL]: [
          'size--round--small',
        ]
      }
      return opts[this.size]
    },
    computedWidth (): string {
      if (this.size === ROUND) {
        return '32px'
      }
      if (this.size === ROUND_SMALL) {
        return '24px'
      }
      if (typeof this.width !== 'number') {
        return 'auto'
      }
      return `${this.width}px`
    }
  },
  methods: {
    handleClick () {
      if (this.disabled) return

      if (this.to) {
        this.$router.push({
          ...this.to,
          params: this.$route.params
        })
      } else {
        this.$emit('click')
      }
    }
  }
}
