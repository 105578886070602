export const state = {
  cradleHotelObj: {
    apiName: undefined,
    name: undefined,
    id: undefined,
    legacyId: undefined,
    homepageHost: undefined,
    bookingEngineHost: undefined,
    bookingEngine: undefined,
    address: undefined,
    hotelGroup: undefined,
    region: undefined,
    image: undefined,
  },
  fetchHotelsSuccess: false,
  cradleHotelsObj: {},
  bookingEnginesObj: {},
  hotelGroupsObj: {},
  fuxs: [
    {
      uuid: 'fda',
      name: '',
      context: 'hp',
      channel: 'dt',
      hotel_legacy_id: -1,
      hotel_id: 5620
    }
  ],
  templateFields: {
    dt: {},
    mb: {},
    al: {},
  },
  allTemplateOpts: [],
  defaultTemplateData: {
    hp: {},
    be: {},
    cp: {},
  },
}
