import Vue from 'vue'
import Vuex from 'vuex'
import VueResource from 'vue-resource'
import root from '@/store/root'
import modules from '@/store/modules'

Vue.use(VueResource)
Vue.use(Vuex)

export default new Vuex.Store({
  state: root.state,
  actions: root.actions,
  mutations: root.mutations,
  getters: root.getters,
  modules,
})
