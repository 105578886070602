import CognitoAdapter from '@/lib/CognitoAdapter'
import cognitoConfig from '@/config/cognito'

export default {
  isMobile: window.innerWidth < 960,
  isLoading: false,
  primaryHotel: 0,
  lookerEmbedUrls: {},
  userProfile: undefined,
  userIsAuth: false,
  authError: undefined,
  changePasswordError: undefined,
  confirmPasswordError: undefined,
  recoverPasswordError: undefined,
  authController: new CognitoAdapter(cognitoConfig),
  showSplitTest: false,
  showSessionRefreshModal: false,
  /**
   * a number/timestamp that changes everytime the state
   * is toggled. used so other components can watch
   * and incorporate into their own logic.
   */
  drawerExpandedState: undefined
}
