<template>
  <div id="vertical-divider-container">
    <div id="vertical-divider"/>
    <span id="expander">
      <span id="icon-container">
        <font-awesome-icon :icon="icon" />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'VerticalDivider',
  props: {
    icon: {
      type: Array,
      required: true,
    }
  },
}
</script>

<style lang="sass" scoped>
#vertical-divider-container
  @apply h-screen
  @apply bg-white
  z-index: 99999
  width: 15px

#vertical-divider
  height: 100%
  z-index: 99999
  background-color: #eee
  width: 2px
  transform: translateX(15px)

#expander
  cursor: pointer
  color: white
  text-align: center
  vertical-align: middle
  display: inline-block
  line-height: 24px
  height: 25px
  width: 25px
  background-color: #acacac
  border-radius: 50%
  position: absolute
  z-index: 99998
  transform: translateX(5px)
  top: 10px

#icon-container
  font-size: 11px
</style>
