











import { Component } from 'vue'
import { mapState, mapGetters } from 'vuex'
import Dashboard from '@/components/dashboard/Dashboard.vue'
import HotelsDropdown from '@/components/dropdown/HotelsDropdown.vue'
import HotelStatus from '@/components/status/HotelStatus.vue'
import { hotelNavConfig } from '@/config/nav/hotelNavConfig'
import { userViews } from '@/constants'

export default {
  name: 'HotelDashboard',
  components: {
    Dashboard,
  },
  watch: {
    'showSplitTest' (newValue): void {
      // watch value in store, if falsey redirect to overview
      // this could change in store as user selects different hotel
      if (newValue === false) {
        this.$router.push({ name: userViews.HOMEPAGE })
      }
    }
  },
  data () {
    return {
      ready: false,
      navConfig: hotelNavConfig,
    }
  },
  computed: {
    ...mapState([
      'showSplitTest',
    ]),
    ...mapGetters([
      'HOTEL_ID',
      'HOTEL_ID_ALLOWED',
    ]),
    dropdownComponent (): Component {
      return HotelsDropdown
    },
    statusComponent (): Component {
      return HotelStatus
    }
  },
  async created (): Promise<void> {
    await this.$store.dispatch('FETCH_RENDER_DATA', this.$route.name)
    this.ready = true
    // a redirect that does not affect UI rendering
    if (!this.$route.params.hotelId || !this.HOTEL_ID_ALLOWED(this.$route.params.hotelId)) {
      this.$router.replace({
        name: this.$route.name,
        params: {
          hotelId: this.HOTEL_ID
        }
      })
    }
  },
}
