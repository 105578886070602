// import Vue from 'vue'
import axios from 'axios'
import endpoints from '@/config/endpoints'
import { CradleCognitoHotelParser, CradleHotelParser, BookingEngineParser, GroupParser, FunnelUxPayloadParser } from '@/parsers'
import { ParserUtils } from '@/parsers/utils'
import { createFormData, sortByFieldDesc } from '@/utils/misc'
import { userViews, userTypes } from '@/constants'
import router from '@/router'
import QueryString from 'query-string'

const NAME = 'name'
const ID = 'id'
const POST = 'post'
const PATCH = 'patch'

export const actions = {
  /**
    * get hotel from cradle
    * @param {number} hotelId - id to set in HotelPicker selected
    */
  async FETCH_HOTEL ({ commit, dispatch, rootGetters }, hotelId) {
    if (hotelId === undefined || hotelId === null) {
      throw new Error('hotelId is required')
    }
    console.log(`[FETCH_HOTEL]: hotelId: ${hotelId}`)
    const endpoint = endpoints.cradle.hotelRoute + `?legacy_id=${hotelId}`
    let response
    let error

    try {
      response = await axios.get(endpoint, {
        headers: {
          Authorization: 'Bearer ' + rootGetters.TOKEN
        }
      })
    } catch (err) {
      error = err.response.data
    }
    console.log('[FETCH_HOTEL]', response)

    if (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      const hotelsObj = ParserUtils.getParsedObject({
        rawList: response.data.results || response.data,
        parser: CradleHotelParser.parseHotel,
        indexKey: 'legacyId',
      })
      const hotel = Object.values(hotelsObj)[0]
      if (hotel) {
        commit('SET_FUNNEL_UXS', hotel.funnelUxs)
        delete hotel.funnelUxs
        commit('SET_CRADLE_HOTEL_OBJ', hotel)
      }
    }
  },
  async FETCH_HOTELS ({ commit, dispatch, rootState, },
    { fetchAll, searchTerm, nextUrl, pageSize }
  ) {
    // console.log('[FETCH_HOTELS] params: ', { fetchAll, searchTerm, nextUrl, pageSize })
    let endpoint
    const queryParams = {
      fields: 'funnel_uxs',
      search: searchTerm,
      page_size: pageSize,
      legacy_id: undefined,
    }
    if (!fetchAll && !searchTerm && rootState.route.params.hotelId) {
      // this is a hard load on `/:hotelId/view`
      queryParams.legacy_id = rootState.route.params.hotelId
    }
    const qs = QueryString.stringify(queryParams).replace('fields=', 'fields!=')
    endpoint = `${endpoints.cradle.hotelRoute}?${qs}`
    // nextUrl reuses previous query params
    if (nextUrl) {
      endpoint = nextUrl.replace('http', 'https')
    }
    let response
    let error

    try {
      response = await axios.get(endpoint, {
        headers: {
          Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
        }
      })
    } catch (err) {
      error = err.response.data
    }
    // console.log('[FETCH_HOTELS]', response)

    if (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      const hotelsObj = ParserUtils.getParsedObject({
        rawList: response.data.results || response.data,
        parser: CradleCognitoHotelParser.parseHotel,
        indexKey: ID,
      })
      commit('SET_CRADLE_HOTELS_OBJ', hotelsObj)
    }
    if (fetchAll) {
      if (response.data.next) {
        await dispatch('FETCH_HOTELS', { nextUrl: response.data.next, fetchAll: true })
      } else {
        commit('FETCH_HOTELS_SUCCESS', true)
      }
    } else {
      commit('FETCH_HOTELS_SUCCESS', true)
    }
  },
  async FETCH_FIELDS ({ commit, dispatch, rootGetters }, { templateName, channel }) {
    if (!templateName) throw new Error('templateName is required')
    console.log(`[FETCH_FIELDS]: templateName: ${templateName}`)
    const params = `template__name=${templateName}&template__channel_type__type=${channel}`
    const endpoint = `${endpoints.cradle.fieldRoute}?${params}`
    let response
    let error

    try {
      response = await axios.get(endpoint, {
        headers: {
          Authorization: 'Bearer ' + rootGetters.TOKEN
        }
      })
    } catch (err) {
      error = err.response.data
    }
    console.log('[FETCH_FIELDS] response:', response)

    // add field value so we can use against the fux dash endpoint
    // and dedepulicate be response
    const enriched = response.data.reduce((obj, field) => {
      const o = Object.assign({}, field)
      o.field_value = undefined
      obj[o.field_id] = o
      return obj
    }, {})

    if (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
    }
    commit('SET_TEMPLATE_FIELDS', {
      templateName: templateName,
      channel: channel,
      fields: Object.values(enriched)
    })
  },
  async FIELD_OPTIONS ({ state, dispatch, }, { templateName, channel }) {
    if (!state.templateFields[channel][templateName]) {
      await dispatch('FETCH_FIELDS', {
        templateName: templateName,
        channel: channel,
      })
    }
  },
  async CREATE_OR_UPDATE_IMAGE_COMPONENT ({ rootGetters }, imageParam) {
    let method
    let endpoint

    if (imageParam.itemId) {
      method = PATCH
      endpoint = `${process.env.VUE_APP_CRADLE_HOST}/api/v1/hotels/dash/hotel-component-param-files/${imageParam.itemId}/`
    } else {
      method = POST
      endpoint = `${process.env.VUE_APP_CRADLE_HOST}/api/v1/hotels/dash/hotel-component-param-files/`
    }

    let response
    let error

    const payload = createFormData({
      hotel_component: imageParam.hotelComponent,
      field: imageParam.field,
      field_value: imageParam.fieldValue,
    })

    try {
      response = await axios[method](
        endpoint,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + rootGetters.TOKEN,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    } catch (err) {
      error = err.response.data
    }
    console.log('[CREATE_OR_UPDATE_IMAGE_COMPONENT] response', response)

    if (error) {
      console.log('[CREATE_OR_UPDATE_IMAGE_COMPONENT] error', error)
    } else {
      return response.data
    }
  },
  async CREATE_OR_UPDATE_FUX ({ dispatch, rootGetters }, { uuid, fux }) {
    const endpoint = uuid
      ? `${endpoints.cradle.fuxRoute}${uuid}/`
      : endpoints.cradle.fuxRoute
    const method = uuid ? PATCH : POST
    let response
    let error
    console.log('[CREATE_OR_UPDATE_FUX] payload', fux)
    try {
      response = await axios[method](
        endpoint,
        FunnelUxPayloadParser.parse(fux),
        {
          headers: {
            Authorization: 'Bearer ' + rootGetters.TOKEN
          }
        }
      )
    } catch (err) {
      error = err.response.data
    }
    console.log('[CREATE OR UPDATE FUX] response', response)

    if (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      dispatch(
        'messages/ADD_SUCCESS',
        { message: 'Design has been published.' },
        { root: true }
      )
      return response.data
    }
  },
  async FETCH_BOOKING_ENGINES ({ rootState, commit, dispatch }) {
    const endpoint = endpoints.cradle.bookingEnginesRoute
    let response
    let error

    try {
      response = await axios.get(endpoint,
        {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
          }
        }
      )
    } catch (err) {
      error = err.response.data
    }
    console.log('[FETCH_BOOKING_ENGINES]', response.data)

    if (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      commit(
        'SET_BOOKING_ENGINES_OBJ',
        ParserUtils.getParsedObject({
          rawList: response.data,
          parser: BookingEngineParser.parseBookingEngine,
          indexKey: NAME,
        })
      )
    }
  },
  async FETCH_HOTEL_GROUPS ({ rootState, commit, dispatch }) {
    const endpoint = endpoints.cradle.hotelGroupsRoute
    let response
    let error

    try {
      response = await axios.get(endpoint,
        {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
          }
        }
      )
    } catch (err) {
      error = err.response.data
    }
    console.log('[FETCH_HOTEL_GROUPS]', response)

    if (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      const hotelsGroupObj = ParserUtils.getParsedObject({
        rawList: response.data,
        parser: GroupParser.parseGroup,
        indexKey: ID,
      })
      commit(
        'SET_HOTEL_GROUPS_OBJ',
        hotelsGroupObj
      )
    }
  },
  async CREATE_OR_UPDATE_HOTEL (
    { state, rootState, rootGetters, getters, commit, dispatch },
    { hotel }
  ) {
    const endpoint = hotel.id
      ? `${endpoints.cradle.hotelRoute}${hotel.id}/`
      : endpoints.cradle.hotelRoute
    const method = hotel.id
      ? PATCH
      : POST

    const hotelPayload = { ...hotel }
    if (hotelPayload.id) delete hotelPayload.apiName
    if (hotelPayload.image === undefined) delete hotelPayload.image

    // TODO move to getter with unit tests
    const updatingRegion = state.cradleHotelObj.region &&
      hotelPayload.region !== state.cradleHotelObj.region.name
    const creatingWithRegion = !state.cradleHotelObj.region &&
      hotelPayload.region

    if (updatingRegion || creatingWithRegion) {
      const regions = rootGetters['merchant/regionsList']
        .filter(region => region.name === hotel.region)
      const region = regions.length > 0
        ? regions[0]
        : undefined
      if (region) hotelPayload.region = region.id
    } else {
      delete hotelPayload.region
    }

    const rawPayload = getters.hotelPayload({ hotel: hotelPayload })
    const payload = createFormData(rawPayload)

    let response
    let error

    try {
      response = await axios[method](
        endpoint,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
          }
        }
      )
    } catch (err) {
      error = err.response.data
    }
    console.log('[CREATE_OR_UPDATE_HOTEL]', response)

    if (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      commit('SET_CRADLE_HOTEL_OBJ', response.data)
      const hotelObj = CradleCognitoHotelParser.parseHotel(response.data)
      commit('ADD_CRADLE_HOTEL_OBJ', hotelObj)
      if (method === POST) {
        const hotels = [
          // filter out placeholder hotel object after create
          ...rootState.userProfile.hotels.map(h => h.id).filter(id => id !== 0),
          response.data.id
        ].map(id => rootGetters['userManagement/getHotelPayloadById'](id))

        const user = {
          username: rootState.userProfile.username,
          attributes: {
            customProfile: {
              ...rootState.userProfile,
              hotels: hotels,
            }
          }
        }
        const updateResponse = await rootState.authController.updateUserAttributes({
          user,
          isAdminEditing: rootState.userProfile.type === userTypes.ADMIN
        })
        if (updateResponse.error) {
          return dispatch('messages/ADD_ERROR', updateResponse.error, { root: true })
        }
        const refreshSession = await rootState.authController.refreshSession()
        if (refreshSession.error) {
          return dispatch(
            'messages/ADD_ERROR',
            refreshSession.error,
            { root: true }
          )
        }
        await dispatch('SET_USER_PROFILE', null, { root: true })
      }
      router.push({
        name: userViews.HOMEPAGE,
        params: {
          hotelId: hotelObj.id
        }
      })
    }
  },
  async FETCH_CRADLE_TEMPLATES ({ rootGetters, commit, dispatch }) {
    const endpoint = endpoints.cradle.templateRoute
    let response
    let error

    try {
      response = await axios.get(endpoint,
        {
          headers: {
            Authorization: 'Bearer ' + rootGetters.TOKEN,
          }
        }
      )
    } catch (err) {
      error = err.response.data
    }

    if (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      commit(
        'SET_CRADLE_TEMPLATES',
        response.data.sort(sortByFieldDesc('channel_type'))
      )
    }
  },
  async fetchFieldsByTemplate ({ dispatch }, template) {
    await dispatch('FIELD_OPTIONS', {
      templateName: template.name,
      channel: template.channel_type
    })
  },
  async fetchDefaultTemplateData ({ getters, dispatch, commit }, { context, channel }) {
    const { expanded, minimized } = getters.defaultTemplateConfig(context)
    await Promise.all([
      dispatch('fetchFieldsByTemplate', expanded),
      dispatch('fetchFieldsByTemplate', minimized)
    ])
    const designData = {
      fux_mab_enabled: false,
      hcs: [{
        template: expanded,
        hotel_component_param_set: getters.fieldDefaults(expanded.name, expanded.channel_type)
      }, {
        template: minimized,
        hotel_component_param_set: getters.fieldDefaults(minimized.name, minimized.channel_type)
      }]
    }
    const cleaned = getters.cleanDefaultTemplateData(context, channel, designData)
    commit('SET_DEFAULT_TEMPLATE_DATA', {
      context: context,
      templateData: cleaned,
    })
  }
}
