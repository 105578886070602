<template>
  <svg
    id="icon-shopping"
    viewBox="0 0 490.1 490.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.shopping}">

    <title id="title">shopping icon</title>
    <desc id="desc">shopping icon</desc>

    <g>
      <path d="M268.15,287.4c-6.4-3.2-13.4-5.9-19.9-8.6c-3.8-1.6-7.5-3.2-10.7-5.9
        c-6.4-5.4-5.4-13.4,2.1-16.7c2.1-0.5,4.3-1.1,6.4-1.1c8.6-0.5,16.7,1.1,24.7,
        4.8c3.8,2.1,5.4,1.6,6.4-2.7c1.1-4.3,2.7-8.6,3.8-12.9c0.5-2.7-0.5-4.8-3.2-5.9
        c-4.8-2.1-10.2-3.8-15.6-4.8c-7-1.1-7-1.1-7-8.1c0-10.2,0-10.2-10.2-10.2c-1.6,
        0-2.7,0-4.3,0c-4.3,0.5-5.4,1.1-5.4,5.9c0,2.1,0,4.3,0,6.4c0,5.9,0,5.9-5.9,
        8.1c-14.5,5.4-23.6,15.6-24.7,31.2c-0.5,14,6.4,23.1,18.3,30.1c7,4.3,15,7,22.6,10.2
        c2.7,1.1,5.4,2.7,8.1,4.8c7.5,5.9,5.9,15.6-2.7,19.3c-4.8,2.1-9.7,2.7-14.5,
        2.1c-7.5-1.1-15-2.7-22-6.4c-3.8-2.1-4.8-1.6-6.4,2.7c-1.1,3.8-2.1,7.5-3.2,
        11.3c-1.6,4.8-1.1,6.4,3.8,8.6c6.4,3.2,12.9,4.8,19.9,5.9c5.4,0.5,5.4,1.1,5.4,
        6.4c0,2.7,0,4.8,0,7.5s1.6,4.8,4.8,4.8c3.8,0,7.5,0,11.3,0c3.2,0,4.8-1.6,4.8
        -4.8s0-7,0-10.2s1.6-4.8,4.8-5.9c7.5-2.1,14-6.4,19.3-12.4C292.85,324,287.45,
        298.7,268.15,287.4z"/>
      <path d="M349.25,103.2c0-59.1-46.7-103.2-104.2-103.2s-104.2,44.1-104.2,103.2
        h-83.8V417c0,39.8,33.3,73.1,73.6,73.1h228.3c40.3,0,74.1-33.3,74.1-73.1
        V103.2H349.25z M245.05,29c41.4,0,74.7,36.5,74.7,74.1h-149.4C170.35,65.6,
        203.65,29,245.05,29z M334.75,383.6c-41.3,40.9-104.7,47.8-153.1,19.4c-19.3,
        14-39.8,16.7-53.7,14.5c-4.8-0.5-5.9-7-1.6-9.1c12.9-6.4,21-18.8,25.8-29
        l-1.1-1.1c-44.1-48.9-41.9-124.1,5.4-171.4c49.4-48.9,128.9-48.9,178.4,0
        C383.65,255.7,383.65,334.7,334.75,383.6z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconShopping',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
