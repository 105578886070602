<template>
  <div
    id="text-display"
    class="laas-oprm-stepper-content"
  >
    <div class="md-body-2 laas-bold laas-section-title">
      {{ copy.textTitle }}
      &nbsp;
      <span class="laas-required">
        {{ copy.required }}
      </span>
    </div>
    <div class="md-body-1 laas-oprm-body-text-color">
      {{ copy.textBody }}
    </div>
    <div id="text-display-title-input">
      <md-field>
        <md-input
          v-model="title"
          required
          :md-counter="maxLength"
          :maxlength="maxLength"
          :placeholder="copy.placeholder"
        />
      </md-field>
    </div>

    <div
      class="md-body-2"
      :style="{ paddingBottom: '12px' }"
    >
      <div class="md-body-2 laas-bold laas-section-title">
        {{ copy.iconTitle }}
        &nbsp;
        <span class="laas-required">
          {{ copy.required }}
        </span>
      </div>
      <div class="md-body-1 laas-oprm-body-text-color laas-section-title">
        {{ copy.iconBody }}
      </div>
    </div>

    <div id="icon-options-container">
      <div
        v-for="(value, name) in swIconComponents"
        :key="name"
        class="icon-tile"
      >
        <div
          class="icon-tile-content"
          :class="{ 'active-icon-tile': isActive(name) }"
          @click="isActive(name) ? setIcon(null) : setIcon(name)"
        >
          <component :is="name" />
        </div>
      </div>
    </div>

    <div class="laas-caption laas-italic">
      {{ copy.iconFooter }}
    </div>
  </div>
</template>

<script>
import swIcons from '@/components/smart-widget-icons/'

export default {
  name: 'TextDisplay',
  components: {
    ...swIcons.components
  },
  data () {
    return {
      copy: {
        textTitle: 'Add Your Reward Title',
        iconTitle: 'Select Your Icon',
        required: 'Required',
        textBody: 'A brief and clear explanation of the reward. Eg. 1 Hour Free Hotel Parking',
        iconBody: 'This icon will appear with the reward title.',
        iconFooter: 'Don’t see an icon you like? Contact your customer success manager and we’ll try to help!',
        placeholder: 'Reward Title',
      },
      maxLength: '35'
    }
  },
  computed: {
    swIconComponents () {
      return swIcons.components
    },
    title: {
      get: function () {
        return this.$store.state.oprm.reward.creativeConfigs[0].promotedText
      },
      set: function (value) {
        const field = 'creativeConfigs'
        this.$store.commit('oprm/SET_REWARD_FIELD', {
          field,
          value: [
            {
              ...this.$store.state.oprm.reward.creativeConfigs[0],
              promotedText: value
            }
          ]
        })
      }
    },
    icon () {
      return this.$store.state.oprm.reward.creativeConfigs[0].promotedIcon
    },
  },
  methods: {
    setIcon (value) {
      const field = 'creativeConfigs'
      this.$store.commit('oprm/SET_REWARD_FIELD', {
        field,
        value: [
          {
            ...this.$store.state.oprm.reward.creativeConfigs[0],
            promotedIcon: value
          }
        ]
      })
    },
    isActive (name) {
      return this.icon === name
    }
  },
}
</script>

<style lang="sass">
#text-display-body
  @media only screen and (max-width: 970px)
    max-width: 550px

.icon-tile-content
  background-color: #eee !important
  width: 42px
  height: 42px
  padding: 10px
  border-radius: 50%
.icon-tile-content:hover
  background-color: rgba(34, 34, 80, 0.2) !important

#text-display-title-input
  max-width: 550px

#icon-options-container
  display: flex
  flex-wrap: wrap
  width: 100%

.icon-tile
  width: 55px
  height: 55px

.active-icon-tile
  background-color: rgba(34, 34, 80, 0.2) !important
</style>
