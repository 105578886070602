<template>
  <svg
    id="icon-beverage2"
    viewBox="0 0 486.999 486.999"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.beverage2}">

    <title id="title">beverage 2 icon</title>
    <desc id="desc">beverage 2 icon</desc>

    <g>
      <path d="M421.63,291.656c4.479-28.052-0.91-59.794-15.168-89.39c-2.902
        -6.031-4.324-8.698-7.47-8.698l-1.967,0.065l-1.211,1.366c-1.569,1.772-1.333,
        3.788-0.764,8.649c0.285,2.374,0.951,8.064,0.553,10.77c-0.382-0.228-0.886
        -0.553-1.512-1.04l-1.991-1.544l-119.246,57.445c0.569,1.227,1.211,2.422,
        1.837,3.625c0.634,1.203,1.252,2.406,1.943,3.577c7.251,12.347,17.615,23.053,
        30.035,32.433c6.47,4.885-4.072,13.534-10.486,8.689c-12.225-9.234-22.549
        -19.866-30.238-31.864c-1.122-1.748-2.178-3.528-3.178-5.332c-1.642-2.967
        -3.113-6.015-4.422-9.145c-0.431-0.431-0.878-0.878-1.179-1.187c-3.373-3.398
        -4.763-4.812-7.015-4.812l-2.414,0.089l-1.195,1.902c-1.463,2.333-0.553,
        4.698,2.422,10.876c23.077,47.91,64.98,80.091,104.257,80.099l0,0c10.063,0,
        19.582-2.138,28.296-6.332C402.82,341.647,417.061,320.252,421.63,291.656z"/>
      <path d="M468.727,400.888l-29.848,14.379l-24.5-50.852c16.558-15.184,27.735
        -37.318,32.083-64.525c5.771-36.156-1.154-77.051-19.5-115.133c-14.396
        -29.872-34.603-55.453-58.453-73.986l-3.577-2.78l-153.118,73.775c7.031-25.946,
        13.656-55.055,19.915-87.447l0.992-5.145L40.781,52.115l-0.992,5.137C7.259,
        225.684,16.737,316.074,69.76,339.037c4.471,1.935,9.21,3.447,14.298,4.43c5.088,
        0.984,10.055,1.341,14.916,1.211c41.74-1.13,75.482-43.439,103.265-130.374c2.601,
        23.077,9.462,46.544,20.256,68.947c29.742,61.745,83.887,103.217,134.731,
        103.225l0,0c4.633,0,9.137-0.471,13.583-1.154l24.524,50.909l-29.848,14.379
        c-6.348,3.056-9.006,10.681-5.95,17.021c3.056,6.34,10.681,9.006,17.021,
        5.95l103.225-49.731c6.348-3.056,9.006-10.681,5.95-17.021C482.675,400.489,
        475.067,397.831,468.727,400.888z M147.615,300.646c-18.777,25.914-38.253,
        32.783-60.493,28.491c-22.272-4.3-38.351-18.07-46.122-49.178c-0.845-3.146
        -1.577-6.535-2.178-10.185c-8.299-44.984-4.536-109.711,12.811-201.142l164.856
        ,31.84c-17.932,91.235-38.513,152.679-62.931,191.339C151.598,295.054,149.615,
        297.988,147.615,300.646z M357.235,371.861c-45.341-0.008-94.194-38.156
        -121.555-94.95c-12.697-26.353-19.565-54.185-19.964-80.782l147.33-70.979
        c20.557,16.891,38.042,39.611,50.739,65.963c17.07,35.424,23.54,73.238,18.232,
        106.476c-5.154,32.262-20.834,56.217-44.163,67.459C378.459,369.56,368.16,
        371.861,357.235,371.861C357.243,371.861,357.243,371.861,357.235,371.861z"/>
      <path d="M95.503,181.034c-2.544,20.972-4.097,41.992-2.934,63.313c0.862,
        15.721-23.532,15.639-24.386,0c-1.252-22.849,0.561-45.422,3.406-67.93l
        -20.297-3.918c-11.071,104.233,11.445,138.21,38.383,143.404c26.938,5.202,
        57.94-15.257,89.016-118.807L95.503,181.034z"/>
      <path d="M111.224,463.039l-33.839-6.535l19.289-99.892c-4.967,0.073-9.941
        -0.366-14.9-1.325s-9.738-2.406-14.323-4.324l-19.289,99.9l-36.14-6.982c
        -4.983-0.959-9.608,1.325-10.34,5.113l-1.585,8.185c-0.732,3.788,2.715,
        7.641,7.698,8.6l99.201,19.159c4.983,0.959,9.608-1.325,10.348-5.113l1.577
        -8.185C119.645,467.851,116.206,463.998,111.224,463.039z"/>
      <path d="M271.348,20.048c-4.235-1.496-8.868,0.772-10.34,5.007l-18.744,54.047
        c-1.471,4.243,0.772,8.876,5.015,10.34c0.878,0.309,1.78,0.455,2.666,0.455
        c3.365,0,6.511-2.105,7.682-5.462l18.744-54.047C277.835,26.144,275.592,
        21.519,271.348,20.048z"/>
      <path d="M346.074,3.612c-3.512-2.796-8.633-2.211-11.421,1.292L269.219,87.1
        c-2.796,3.52-2.211,8.633,1.292,11.421c1.496,1.187,3.284,1.772,5.064,
        1.772c2.39,0,4.755-1.049,6.365-3.064l65.435-82.196C350.163,11.513,349.578,
        6.408,346.074,3.612z"/>
      <path d="M337.45,86.198l-49.926,23.857c-4.056,1.935-5.763,6.787-3.829,10.835
        c1.398,2.918,4.308,4.625,7.34,4.625c1.179,0,2.374-0.252,3.503-0.797l49.926
        -23.857c4.056-1.935,5.763-6.787,3.829-10.835C346.351,85.979,341.498,84.264,
        337.45,86.198z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconBeverage2',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
