var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"design-duplication-container-wrapper"}},[_c('CustomStepper',{attrs:{"editing":true,"stepHeaders":_vm.stepHeaders},scopedSlots:_vm._u([{key:_vm.steps.STEP_1,fn:function(ref){
var slotScope = ref.slotScope;
return [_c('WizardStep',{attrs:{"editing":true,"reverseOrderOnMobile":false}},[_c('CustomStepHeader',{attrs:{"slot":"step-header","index":slotScope.idx,"label":_vm.stepHeaders[0].name},slot:"step-header"}),_c('FuxSearch',{staticClass:"bdd-sub-component",attrs:{"slot":"left-content","index":slotScope.idx},slot:"left-content"})],1)]}},{key:_vm.steps.STEP_2,fn:function(ref){
var slotScope = ref.slotScope;
return [_c('WizardStep',{attrs:{"editing":true,"reverseOrderOnMobile":false}},[_c('CustomStepHeader',{attrs:{"slot":"step-header","index":slotScope.idx,"label":_vm.stepHeaders[1].name},slot:"step-header"}),_c('DuplicationDestination',{staticClass:"bdd-sub-component",attrs:{"slot":"left-content","index":slotScope.idx},slot:"left-content"})],1)]}},{key:_vm.steps.STEP_3,fn:function(ref){
var slotScope = ref.slotScope;
return [_c('WizardStep',{attrs:{"editing":true,"reverseOrderOnMobile":false}},[_c('CustomStepHeader',{attrs:{"slot":"step-header","index":slotScope.idx,"label":_vm.stepHeaders[2].name},slot:"step-header"}),_c('DesignSetName',{staticClass:"bdd-sub-component",attrs:{"slot":"left-content","index":slotScope.idx},slot:"left-content"})],1)]}},{key:_vm.steps.STEP_4,fn:function(ref){
var slotScope = ref.slotScope;
return [_c('WizardStep',{attrs:{"editing":true,"reverseOrderOnMobile":false}},[_c('CustomStepHeader',{attrs:{"slot":"step-header","index":slotScope.idx,"label":_vm.stepHeaders[3].name},slot:"step-header"}),_c('MabEnable',{staticClass:"bdd-sub-component",attrs:{"slot":"left-content","index":slotScope.idx},slot:"left-content"})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }