<template>
  <div v-if="show" id="opr-container" :style="{ width: '100%', height: 'auto' }" class="md-content-item">
    <Header :copy="copy">
      <!-- FIXME this is not set up to pass a link for vue router -->
      <StepperButton
        slot="button"
        class="new-reward-btn"
        @clickEvent="navToWizard()"
        :disabled="false"
        :copy="copy.newRewardButton"
      />
    </Header>
    <div :style="{ backgroundColor: 'white', height: 'auto', width: '100%' }">
      <md-tabs md-sync-route md-alignment="fixed">
        <template slot="md-tab" slot-scope="{ tab }">
          <span class="md-title lato-font">{{ tab.label }}</span>
        </template>
        <md-tab
          :id="tabOpts.TAB_LEFT"
          :md-label="copy.leftColTitle"
          :to="{ hash: `#${tabOpts.TAB_LEFT}` }"
        >

          <OnPropertyRewardsTable
             v-if="isHash(tabOpts.TAB_LEFT)"
             ref="opr-rewards-table"
          />
        </md-tab>
        <md-tab
          :id="tabOpts.TAB_RIGHT"
          :md-label="copy.rightColTitle"
          :to="{ hash: `#${tabOpts.TAB_RIGHT}` }"
          md-dynamic-height="true"
        >
          <OnPropertyBusinessesTable
            v-if="isHash(tabOpts.TAB_RIGHT)"
            ref="opr-biz-table"
          />
        </md-tab>
      </md-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { userViews } from '@/constants'
import OnPropertyRewardsTable from '@/components/hotel-dashboard/on-property-rewards-manager/OnPropertyRewardsTable.vue'
import OnPropertyBusinessesTable from '@/components/hotel-dashboard/on-property-rewards-manager/OnPropertyBusinessesTable.vue'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton.vue'
import Header from '@/components/common/Header.vue'

export const TAB_LEFT = 'all-rewards'
export const TAB_RIGHT = 'rewards-by-business'

export default {
  name: 'OnPropertyRewardsContainer',
  components: {
    OnPropertyRewardsTable,
    OnPropertyBusinessesTable,
    StepperButton,
    Header,
  },
  data () {
    return {
      show: false,
      tabOpts: {
        TAB_LEFT,
        TAB_RIGHT
      },
      copy: {
        title: 'Welcome to your On-Property Reward Manager',
        subtitle: 'As a premiere hotel partner, we showcase your on-property rewards to high-spending travel consumers who book at your hotel.',
        newRewardButton: 'Create New Reward',
        leftColTitle: 'All Rewards',
        rightColTitle: 'Rewards by Business'
      }
    }
  },
  computed: {
    wizardLink () {
      return {
        name: userViews.OP_REWARDS_WIZARD_NEW,
        params: {
          hotelId: this.$route.params.hotelId
        }
      }
    }
  },
  methods: {
    ...mapActions('oprm', ['FETCH_REWARDS']),
    navToWizard () {
      return this.$router.push(this.wizardLink)
    },
    isHash (id) {
      return this.$route.hash === `#${id}`
    },
    async syncRouteHashToActiveTab () {
      if (!this.$route.hash) {
        await this.$router.replace({
          name: this.$route.name,
          params: this.$route.params,
          hash: `#${this.tabOpts.TAB_LEFT}`
        })
      }
    },
  },
  async mounted () {
    await this.FETCH_REWARDS({ page: 1, pageSize: 10 })
    await this.syncRouteHashToActiveTab()
    this.show = true
  },
  async beforeRouteUpdate (to, from, next) {
    this.show = false
    await this.FETCH_REWARDS({ page: 1, pageSize: 10 })
    this.show = true
    next()
  }
}
</script>

<style lang="sass">
#opr-container
  font-family: Lato !important
  // targets disabled tabs; set by vue mat based on click
  a.router-link-active
    background-color: white

  .md-tabs.md-theme-default .md-tabs-navigation
    @apply bg-gray-100

  .md-tabs-navigation.md-elevation-0
    display: flex
    justify-content: space-around
    width: 100%
    > a
      min-width: 50%
      height: 55px
      border: none
    .md-title
      color: black
      font-weight: 600
      font-size: 16px
      text-transform: none !important

  .md-tabs .md-tabs-indicator
    background-color: transparent !important

  // removes thin line between steps
  .md-steppers .md-stepper:after
    width: 0px !important

  .md-table-cell-container
    padding: 6px 16px 6px 16px
    margin-left: 6px

#header-wrapper
  .md-layout-item
    margin-left: 15px

.new-reward-btn-wrapper
  margin-right: 15px
  margin-top: 45px

.new-reward-btn
  width: 180px !important

#rewards-by-business
  // hot fix for dynamic height
  min-height: 174px
</style>
