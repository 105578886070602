import { startCase } from 'lodash'

const whitelist = [
  'iTunes',
  'eBay'
]

export const capitalize = value => {
  if (typeof value !== 'string') {
    return ''
  }
  return value[0].toUpperCase() + value.slice(1).toLowerCase()
}

/* uses whitelist */
export const capitalizeAllWords = phrase => {
  if (typeof phrase !== 'string') return ''
  return phrase
    .split(' ')
    .map((word: string) => {
      let result = word
      if (!whitelist.includes(word)) {
        result = word.charAt(0).toUpperCase() + word.slice(1)
      }
      return result
    })
    .join(' ')
}

export const titleCase = phrase => {
  if (typeof phrase !== 'string') return ''

  return startCase(phrase.toLowerCase())
}
