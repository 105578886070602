import userManagement from '@/store/modules/userManagement'
import merchant from '@/store/modules/merchant'
import hotel from '@/store/modules/hotel'
import messages from '@/store/modules/messages'
import editor from '@/store/modules/editor'
import oprm from '@/store/modules/oprm'
import designDuplication from '@/store/modules/designDuplication'
import ram from '@/store/modules/ram'
import edm from '@/store/modules/edm'
import ecm from '@/store/modules/ecm'
import loyaltyPortalEditor from '@/store/modules/loyalty-portal-editor'
import flex from '@/store/modules/flex'

export default {
  userManagement,
  merchant,
  hotel,
  messages,
  editor,
  oprm,
  designDuplication,
  ram,
  edm,
  ecm,
  loyaltyPortalEditor,
  flex,
}
