import { Dag, DagMap, WorkflowMap, Campaign, Workflow } from '@/types'
import { MILESTONE_NAMES } from '@/constants'

const { BOOKING, ROOM_NIGHTS, LTV } = MILESTONE_NAMES

/**
 *
 * @note recursive function to create a map of all nested nodes
 */
export function createDagMap (node: Dag): DagMap {
  const obj = {}

  function create (node: Dag): void {
    obj[node.id] = node
    node.children.forEach((child: Dag) => {
      create(child)
    })
  }

  create(node)

  return obj
}

export function parseChildrenToIds (children: Dag[]): number[] {
  return children.map((child: Dag) => child.id)
}

/**
 * @note replaces list of dag objects with list of number ids.
 * create new object and populate values for TS to infer change
 * in type of children key.
 */
export function createWorkflowMap (dagMap: DagMap): WorkflowMap {
  const workflowMap = {} as WorkflowMap

  Object.keys(dagMap).forEach((key: string): void => {
    const dag = dagMap[key]
    // TODO can this be .map?
    const children = dag.children
      .reduce((acc, curr): number[] => {
        acc.push(curr.id)
        return acc
      }, [])

    workflowMap[key] = {
      ...dag,
      children
    }
  })

  return workflowMap
}

export function parse (dag: Dag): WorkflowMap {
  const dagMap = createDagMap(dag)
  return createWorkflowMap(dagMap)
}

export function getWorkflowMap (campaign: Campaign): WorkflowMap {
  if (!campaign?.dag) return {}
  const parsed = parse(campaign.dag)
  return parsed
}

export function createDagFromWorkflow (workflowMap: WorkflowMap): Workflow | void {
  function nest (node) {
    if (node.children.length === 0) {
      return
    }
    // change id's to objects
    node.children = node.children.map((id) => workflowMap[id])
    node.children.forEach((node) => nest(node))
  }

  const first = Object.values(workflowMap).find(node => !node.parent)
  if (!first) return

  const node = { ...first }
  nest(node) // side-effect on node

  return node
}

export function isBookingMilestone (id: string): boolean {
  return id === BOOKING
}

export function isRoomNightsMilestone (id: string): boolean {
  return id === ROOM_NIGHTS
}

export function isLifetimeValueMilestone (id: string): boolean {
  return id === LTV
}
