
















import props from '@/components/icons/props'

const ARC_RADIUS = 6

export default {
  name: 'SplitPathLine',
  props: {
    ...props,
    columnGap: {
      type: String,
      required: true,
      default: '0',
    }
  },
  data () {
    return {
      arcCoord: ARC_RADIUS,
      archFlag: '0 0 1', // https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths#arcs
      style: {
        '--color': this.color,
        marginTop: `-${ARC_RADIUS}px`
      },
    }
  },
  computed: {
    /**
     * these cps construct the value passed to <path>'s d attribute
     * making a rounded-edge half-rectangle—an example final value would be:
     * M150, 25,              - move to a coordinate
     * v-20                   - draw an ascending vertical line from coordinate
     * a20, 20 0 0 1 20, -20, - draw an upward rounded arc
     * h260,                  - draw a horizontal line 260 px
     * a20, 20 0 0 1 20, 20   - draw a downward rounded arc
     * v20                    - draw a vertical line descending 20px
     */
    pathDimensions (): string {
      return `${this.startingCoordinates}, v-${this.verticalLineLength}, ${this.upwardArc}, ${this.horizontalLine}, ${this.downwardArc}, v${this.verticalLineLength}`
    },
    verticalLineLength (): number {
      return (this.height - this.arcCoord) - this.arcCoord
    },
    horizontalLine (): string {
      return `h${this.width / 2}`
    },
    startingCoordinates (): string {
      const widthNoMargin = this.width - this.columnGapToNum
      return `M${widthNoMargin / 4}, ${this.height}`
    },
    columnGapToNum (): number {
      return this.columnGap === '0' ? 0 : parseInt(this.columnGap.slice(0, 2))
    },
    upwardArc (): string {
      return `a${this.arcCoord}, ${this.arcCoord} ${this.archFlag} ${this.arcCoord}, -${this.arcCoord}`
    },
    downwardArc (): string {
      return `a${this.arcCoord}, ${this.arcCoord} ${this.archFlag} ${this.arcCoord}, ${this.arcCoord}`
    }
  },
}
