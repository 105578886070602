<template lang="html">
  <md-table-row
    :class="['promotions-table-row', { 'reward-active': this.promotion.active }]">
    <md-table-cell id="promotion-edit-icon">
      <span
        id="promotion-edit-icon-span"
        @click="navToPromotionsWizard"
      >
        <md-icon>
          {{ this.icons.edit }}
        </md-icon>
      </span>
    </md-table-cell>
    <md-table-cell :md-label="copy.headers.name" id="promotion-name">
      <span
        id="promotion-name-span"
        @click="navToPromotionsWizard"
      >
        {{ this.name }}
      </span>
    </md-table-cell>
    <md-table-cell :md-label="copy.headers.created" id="promotion-created">
      {{ this.created }}
    </md-table-cell>
    <md-table-cell :md-label="copy.headers.status" id="promotion-status">
      {{ getStatus(this.promotion.active) }}
    </md-table-cell>
    <md-table-cell id="promotion-status-toggle">
      <md-switch v-model="active"></md-switch>
    </md-table-cell>
  </md-table-row>
</template>

<script>
import moment from 'moment'
import { merchantViews } from '@/constants'
/**
  * can emit a change in its 'active' boolean value
  * parent container can dispatch action to update store but first
  * send req to API.
  */
const UPDATE_ACTIVE = 'updateActive'

export default {
  name: 'PromotionsTableRow',
  props: {
    promotion: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      icons: {
        edit: 'edit',
      },
      copy: {
        headers: {
          name: 'Promotion Name',
          created: 'Created',
          status: 'Status',
        },
        status: {
          active: 'Active',
          inactive: 'Inactive',
          inProgress: 'In Progress',
        }
      }
    }
  },
  computed: {
    active: {
      get () {
        return this.promotion.active
      },
      set () {
        this.updateActive()
      }
    },
    name () {
      return this.promotion.creativeConfigs &&
        this.promotion.creativeConfigs.length > 0 &&
          this.promotion.creativeConfigs[0].promotedText
    },
    created () {
      return this.promotion.created &&
        moment(this.promotion.created).format('MM/DD/YY')
    }
  },
  methods: {
    updateActive () {
      this.$emit(
        UPDATE_ACTIVE,
        { uuid: this.promotion.uuid, active: !this.promotion.active }
      )
    },
    getStatus (active) {
      if (this.promotion.inProgress) {
        return this.copy.status.inProgress
      } else {
        if (active) {
          return this.copy.status.active
        } else {
          return this.copy.status.inactive
        }
      }
    },
    navToPromotionsWizard () {
      this.$router.push({
        name: merchantViews.MERCHANT_PROMOTIONS_WIZARD_EDIT,
        params: {
          id: this.$route.params.id,
          uuid: this.promotion.uuid,
        }
      })
    }
  },
}
</script>

<style lang="sass">
  #promotion-name
    width: 55%

  #promotion-edit-icon
    max-width: 40px

  .promotions-table-row.reward-active
    background-color: #eefff1
</style>
