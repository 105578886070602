<template lang="html">
  <div class="flex flex-col bg-white pb-6 min-h-full">
    <Header :copy="{ title: copy.title}"/>
    <div class="w-full flex flex-row flex-wrap justify-start px-6 pt-4 pb-6" >
      <FuxCtxList context="hp" class="mr-4 mb-4"/>
      <FuxCtxList context="be" class="mr-4 mb-4"/>
      <FuxCtxList context="cp" class="mr-4 mb-4"/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/common/Header'
import FuxCtxList from '@/components/hotel-dashboard/funnel-ux/FuxCtxList'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'FuxHome',
  components: {
    FuxCtxList,
    Header,
  },
  data () {
    return {
      copy: {
        title: 'Embedded Designs'
      }
    }
  },
  computed: {
    ...mapState([
      'primaryHotel',
    ]),
  },
  methods: {
    ...mapActions('hotel', [
      'FETCH_HOTEL',
    ]),
  },
  created () {
    console.log('[FuxHome] created')
    this.FETCH_HOTEL(this.$route.params.hotelId)
  },
}
</script>

<style lang="sass" scoped>
</style>
