
















import Vue from 'vue'
export default Vue.extend({
  name: 'RedemptionRewardCost',
  data () {
    return {
      copy: {
        costLabel: 'What is the cost of this reward in USD?',
        valueLabel: 'What is the perceived value to the customer (MSRP) of this reward in USD?'
      }
    }
  },
  computed: {
    rewardCost: {
      get (): string | undefined {
        return this.$store.state.oprm.reward.cost
      },
      set (value): void {
        this.$store.commit('oprm/SET_REWARD_FIELD', {
          field: 'cost',
          value
        })
      }
    },
    rewardValue: {
      get (): string | undefined {
        return this.$store.state.oprm.reward.value
      },
      set (value): void {
        this.$store.commit('oprm/SET_REWARD_FIELD', {
          field: 'value',
          value
        })
      }
    },
  },
  methods: {
    formatCurrency (value: string): string {
      // up to 2 decimal places
      let result = ''

      if (value) {
        const matches = value.match(/^\d*\.?\d{0,2}/)
        result = matches ? matches[0] : ''
      }
      return result
    },
    formatCost (): void {
      this.rewardCost = this.formatCurrency(this.rewardCost)
    }
  }
})
