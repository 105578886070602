




























export default {
  name: 'Checkbox',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: [Array, Boolean, String, Number, Object],
      required: false,
    },
    checked: {
      type: [Array, Boolean, String, Number, Object],
    },
    /**
     * use following props to set custom values for true/false state
     */
    trueValue: {
      type: Boolean,
      required: false,
      default: true
    },
    falseValue: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isChecked (): boolean {
      if (this.checked instanceof Array) {
        return this.checked.includes(this.value)
      }
      return this.checked === this.trueValue
    }
  },
  methods: {
    handleChange (event: Event, labelClick: boolean): void {
      if (this.disabled) {
        return
      }
      let isChecked
      if (labelClick) {
        // need to emit opposite value if click coming from label as the
        // checked state has not yet been updated
        isChecked = !this.isChecked
      } else {
        const target = event.target as HTMLInputElement
        isChecked = target.checked
      }
      if (this.checked instanceof Array) {
        const newValue = [...this.checked]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          // remove
          newValue.splice(newValue.indexOf(this.value), 1)
        }
        this.$emit('change', newValue)
      } else {
        const value = isChecked ? this.trueValue : this.falseValue
        this.$emit('change', value)
      }
    }
  }
}
