<template>
  <div id='oprm-wizard-redemption-terms-step'>
    <WizardStep :editing="editing" :reverseOrderOnMobile="true">
      <CustomStepHeader slot="step-header" :index="index" :label="'Activate Reward'"/>
      <RedemptionActivate slot="left-content"/>
      <RedemptionPreview slot="right-content"/>
      <CustomStepButtons
        slot="button-group"
        :disabled="disableLastStep"
        :index="index"
      />
    </WizardStep>
  </div>
</template>

<script>
import WizardStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/WizardStep'
import RedemptionActivate from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/RedemptionActivate'
import RedemptionPreview from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/RedemptionPreview'
import CustomStepHeader from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepHeader'
import CustomStepButtons from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepButtons'

export default {
  name: 'RedemptionInstructionsStep',
  components: {
    WizardStep,
    CustomStepHeader,
    CustomStepButtons,
    RedemptionActivate,
    RedemptionPreview
  },
  props: {
    editing: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    disableLastStep: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
