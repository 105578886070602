var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMounted)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutside),expression:"handleClickOutside"}],ref:_vm.refLabels.dropdownContainer,class:[
    'dropdown-container' ].concat( _vm.containerClasses )},[(_vm.showLabel)?_c('div',{staticClass:"mb-1"},[_c('Label',{attrs:{"required":_vm.required}},[_vm._v(" "+_vm._s(_vm.label)+" ")])],1):_vm._e(),_c('div',{ref:_vm.refLabels.control,class:[
      _vm.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      'text-sm' ],on:{"click":_vm.handleClick,"mouseenter":_vm.handleHover}},[(_vm.useSlot)?_c('div',[_vm._t("control",[_vm._v(" "+_vm._s(_vm.label)+" ")],{"control":{ label: _vm.label }})],2):_c('div',{class:_vm.typeClasses.concat( ['size--normal'] ),style:({
        width: _vm.computedControlWidth
      })},[_c('span',{class:[
          'label-container' ].concat( _vm.labelClasses )},[_vm._v(" "+_vm._s(_vm.selectedItem)+" ")]),_c('span',{staticClass:"h-full ml-2"},[_c('IconsContainer',{attrs:{"show-close":false,"fill":_vm.iconFill}})],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],ref:_vm.refLabels.optionsContainer,class:{
      'options-container': true,
    },style:(Object.assign({}, {width: _vm.optionsContainerWidth},
      _vm.optionsX,
      _vm.optionsY))},[_c('DropdownOptions',{attrs:{"show":_vm.show,"options":_vm.options,"label-key":_vm.labelKey,"options-classes":_vm.optionsClasses,"options-styles":_vm.optionsStyles},on:{"selected":function($event){[
        _vm.toggleOptions(),
        _vm.setSelected($event),
        _vm.emitSelected($event)
      ]},"ready":function($event){return _vm.handleReady($event)},"scrolled":_vm.throttledHandleScroll},scopedSlots:_vm._u([{key:"option",fn:function(ref){
      var option = ref.option;
return [_vm._t("option",null,{"option":option})]}}],null,true)})],1),_vm._t("helper-text",[(_vm.helperText)?_c('HelperText',{ref:"helper-text",attrs:{"helper-text":_vm.helperText}}):_vm._e()])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }