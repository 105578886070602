var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{ref:"button",class:_vm.typeClasses.concat( _vm.sizeClasses,
    [_vm.disabled ? 'disabled' : ''],
    ['py-0'],
    ['serif'],
    ['transition-colors'],
    ['duration-200']
  ),style:({
    width: _vm.computedWidth
  }),attrs:{"role":"button"},on:{"click":_vm.handleClick}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }