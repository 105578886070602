import { state } from '@/store/modules/userManagement/state'
import { mutations } from '@/store/modules/userManagement/mutations'
import { actions } from '@/store/modules/userManagement/actions'
import { getters } from '@/store/modules/userManagement/getters'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
