






































































































import { schemas } from '@/schemas'
import WysiwygEditor from '@/components/common/WysiwygEditor.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'RedemptionInstructions',
  components: {
    WysiwygEditor
  },
  data () {
    return {
      copy: {
        description:
          'Explain how to redeem your reward so that your users can enjoy them.',
        method: {
          title: 'How will this reward be redeemed?',
          body:
            'Not the right redemption method? Open the dropdown to see more redemption methods.'
        },
        instructions: {
          title: 'Add Redemption Instructions',
          body:
            'Please add step by step instructions to your guests on how to redeem your reward. You can reference the copy sample below.'
        },
        sampleInstructions: {
          title: 'Sample Instructions',
          ip: `To redeem your reward during your upcoming stay:
            <ol>
             <li> At your leisure, go to our on-property restaurant</li>
             <li> Access this reward on your phone. You can do so by clicking the link in the email with your redemption instructions or by accessing <Hotel Brand’s> loyalty portal.</li>
             <li> Show your waiter the reward on your phone. Our staff will tap the reward to mark it as redeemed. </li>
             <li> Enjoy the free appetizer of your choice! </li>
            </ol>`,
          re:
            'Use the code LaaSieLoyalty at checkout when you purchase an item from our online shop for 10% off.',
          lp: `<ol> <li> Go to YOUR LINK HERE and follow the directions provided.</li>
            <li>The discount will be automatically applied at checkout.</li> </ol>`
        },
        required: 'Required',
        helpText: {
          ip:
            'Redeemed on property by tapping the "redeem" button in the guest\'s portal.',
          re: 'Redeemed using a promo code which can be used multiple times.',
          // single use not supported currently
          // 'su': 'Redeemed using a promo code which can only be used once.',
          // 'vo': 'Redeemed using a voucher that is included as an attachment in the redemption email.',
          lp: 'Redeemed via a redemption link.'
        },
        helpText2: {
          ip:
            'Make sure to include where this reward can be redeemed on your property.',
          re:
            'Make sure to include the reusable code in the redemption instructions below.',
          // single use not supported currently
          // 'su': 'Our support team will reach out with the next steps for finalizing your reward.',
          // 'vo': 'Our support team will reach out with the next steps for finalizing your reward.',
          lp:
            'Make sure to include your link in the redemption instructions below.'
        },
        onlyRetainUser: 'Only accessible by Retain users'
      },
      ctaFieldTitle: {
        re: 'Add Reusable Code',
        lp: 'Add Redemption Link'
      },
      ctaFieldBody: {
        re: `This promo code will be embedded directly into the button in the loyalty portal.
          Please use the same code that was included in the instructions.`,
        lp: `This redemption link will be embedded directly into the button in the loyalty portal.
          Please use the same link that was included in the instructions.`
      },
      ctaFieldMaxLength: {
        re: 35,
        lp: 500
      }
    }
  },
  computed: {
    ...mapGetters(['HOTEL_GROUP_IS_RETAIN']),
    redemptionMethods () {
      return schemas.oprmRedemptionMethods
    },
    redemptionMethod: {
      get () {
        return this.$store.state.oprm.reward.redemptionMethod
      },
      set (value) {
        this.$store.commit('oprm/SET_REWARD_FIELD', {
          field: 'redemptionMethod',
          value
        })
      }
    },
    redemptionInstructions: {
      get () {
        return this.$store.state.oprm.reward.redemptionInstructions
      },
      set (value) {
        this.$store.commit('oprm/SET_REWARD_FIELD', {
          field: 'redemptionInstructions',
          value
        })
      }
    },
    redemptionUrl: {
      get () {
        return this.$store.state.oprm.reward.redemptionUrl
      },
      set (value) {
        this.$store.commit('oprm/SET_REWARD_FIELD', {
          field: 'redemptionUrl',
          value
        })
      }
    },
    reusablePromoCode: {
      get () {
        return this.$store.state.oprm.reward.reusablePromoCode
      },
      set (value) {
        this.$store.commit('oprm/SET_REWARD_FIELD', {
          field: 'reusablePromoCode',
          value
        })
      }
    },
    showRedemptionCtaFields (): boolean {
      return (
        this.HOTEL_GROUP_IS_RETAIN &&
        (this.isRedemptionMethod('Reusable code') ||
          this.isRedemptionMethod('Redemption link'))
      )
    },
  },
  methods: {
    isRedemptionMethod (name: string): boolean {
      return this.redemptionMethod === this.redemptionMethods[name]
    },
    getSampleInstructions (redemptionMethod: string): string {
      const sampleInstructions = {
        ip: `To redeem your reward during your upcoming stay:
          <ol>
           <li> At your leisure, go to our on-property restaurant</li>
           <li> Access this reward on your phone. You can do so by clicking the link in the email with your redemption instructions or by accessing <Hotel Brand’s> loyalty portal.</li>
           <li> Show your waiter the reward on your phone. Our staff will tap the reward to mark it as redeemed. </li>
           <li> Enjoy the free appetizer of your choice! </li>
          </ol>`,
        re: 'Use the code LaaSieLoyalty at checkout when you purchase an item from our online shop for 10% off.',
        lp: `<ol> <li> Go to YOUR LINK HERE and follow the directions provided.</li>
          <li>The discount will be automatically applied at checkout.</li> </ol>`
      }
      return sampleInstructions[redemptionMethod]
    },
    isRedemptionMethodDisable (name: string): boolean {
      if (name === 'Tap to Redeem' && !this.HOTEL_GROUP_IS_RETAIN) {
        return true
      } else {
        return false
      }
    }
  }
}
