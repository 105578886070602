


















































































































































import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { debounce, throttle } from 'lodash'
import { createAutocompleteObject } from '@/utils/misc'
import MerchantModal from '@/components/util/MerchantModal.vue'
import { userViews } from '@/constants'

/*
 * this component will disable the merchant dropdown
 * if the reward has an existing relationship with a merchant
 * or after the user creates a new relationship.
 */

export default {
  name: 'ImageDisplay',
  components: {
    MerchantModal
  },
  data () {
    return {
      fetchMerchantsDebounced: undefined,
      fetchMerchantsThrottled: undefined,
      isMerchantDisabled: false,
      expandFileElement: false,
      searchTerm: undefined,
      maxLength: '50',
      showModal: false,
      editMerchant: undefined,
      modalError: '',
      merchantDoesntExist: false,
      copy: {
        disabledAutocompleteTooltip: 'Sorry, you can\'t remove your business once you create it with our current system.',
        placeholder: {
          description: 'Description',
          name: 'Start typing to search...'
        },
        nameTitle: 'Add Your Business Name',
        required: 'Required',
        nameBody: 'This business name is shown on the live reward display.',
        nameFooterStart: 'Not editable here. You can create a new reward with a new business or rename the existing business on the',
        nameFooterEnd: ' tab.',
        rewardsByBusiness: 'Rewards by Business',
        descriptionTitle: 'Add Your Reward Description',
        descriptionBody: 'This should explain the reward in more detail and will be shown with your business name. Eg. Get 1 hour of hotel parking free. Valid for hotel guests.',
        imageTitle: 'Add Your Reward Image',
        imageBody: 'This image should represent what the reward is at a glance. ',
        imageFooter: '250px x 150px recommended. JPEG & PNG only.',
        labels: {
          image: 'Upload Reward Image'
        },
        businessNameDoesNotExist: 'This business name does not exist.',
        createNewOne: 'Click here to create a new one.'
      }
    }
  },
  computed: {
    ...mapState('oprm', [
      'merchants',
    ]),
    ...mapGetters('oprm', [
      'MERCHANT_OPTS',
    ]),
    merchant () {
      return this.$store.state.oprm.reward.merchant
    },
    description: {
      get: function () {
        return this.$store.state.oprm.reward.creativeConfigs[0].thumbnailText
      },
      set: function (value) {
        const field = 'creativeConfigs'
        this.$store.commit('oprm/SET_REWARD_FIELD', {
          field,
          value: [
            {
              ...this.$store.state.oprm.reward.creativeConfigs[0],
              thumbnailText: value
            }
          ]
        })
      }
    },
    image () {
      return this.$store.state.oprm.reward.creativeConfigs[0].thumbnailImage
    },
    opRewardsLink () {
      return {
        name: userViews.OP_REWARDS,
        params: {
          hotelId: this.$route.params.hotelId
        },
        hash: '#rewards-by-business'
      }
    }
  },
  created () {
    this.fetchMerchantsDebounced = debounce(this.FETCH_MERCHANTS, 300)
    this.fetchMerchantsThrottled = throttle(this.FETCH_MERCHANTS, 700)
  },
  async mounted () {
    await this.$store.dispatch('oprm/FETCH_MERCHANTS', { fetchAll: false })
    if (this.merchant && this.merchant.name) {
      this.searchTerm = createAutocompleteObject({ data: this.merchant, key: 'name' })
      // disable if there is existing merchant-reward relationship
      this.isMerchantDisabled = true
    }
  },
  beforeDestroy () {
    this.CLEAR_IMAGE_UPLOAD_FILE()
  },
  methods: {
    ...mapActions('oprm', [
      'FETCH_MERCHANTS'
    ]),
    ...mapMutations('oprm', [
      'CLEAR_IMAGE_UPLOAD_FILE'
    ]),
    openModal () {
      this.showModal = true
    },
    closeModal () {
      this.showModal = false
    },
    onFileChange (files) {
      const file = files[0]
      if (!file) {
        this.$store.commit('oprm/SET_IMAGE_UPLOAD_FILE', null)
        return
      }
      // expand the component to render filename
      if (file && file.name && file.name.length > 25) {
        this.expandFileElement = true
      } else {
        this.expandFileElement = false
      }
      this.$store.commit('oprm/SET_IMAGE_UPLOAD_FILE', file)
    },
    searchMerchants (search) {
      // searchMerchants is triggered via md-change when selecting a merchant
      // when the merchant object is passed search term we want to return early
      if (typeof search !== 'string') return

      if (!search || search.length < 2) return

      if (search.endsWith(' ')) {
        this.fetchMerchantsThrottled({ search })
      } else {
        this.fetchMerchantsDebounced({ search })
      }
    },
    setMerchant (value) {
      const field = 'merchant'
      this.$store.commit('oprm/SET_REWARD_FIELD', { field, value })
    },
    async handleSuccess (merchant) {
      console.log(merchant)
      this.isMerchantDisabled = true
      // keep the search term in sync with a newly created merchant via modal
      this.searchTerm = createAutocompleteObject({ data: merchant, key: 'name' })
      this.closeModal()
    },
    navToOpRewards () {
      return this.$router.push(this.opRewardsLink)
    },
    termMatchesMerchantName (): boolean {
      return this.searchTerm?.toLowerCase() !== this.merchant.name?.toLowerCase()
    },
    setMerchantDoesntExist (): void {
      this.merchantDoesntExist = this.termMatchesMerchantName()
    },
  },
}
