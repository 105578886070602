<template>
  <svg
    id="icon-coupon"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.coupon}">

    <title id="title">coupon icon</title>
    <desc id="desc">coupon icon</desc>

    <g>
      <path d="M395.13,306.087c-9.206,0-16.696,7.49-16.696,16.696c0,9.206,7.49,
        16.696,16.696,16.696s16.696-7.49,16.696-16.696C411.826,313.577,404.336,
        306.087,395.13,306.087z"/>
    </g>

    <g>
      <path d="M261.565,172.522c-9.206,0-16.696,7.49-16.696,16.696s7.49,16.696,
        16.696,16.696c9.206,0,16.696-7.49,16.696-16.696S270.771,172.522,261.565,172.522z"/>
    </g>

    <g>
      <path d="M495.304,72.348H144.696v50.087c0,9.217-7.479,16.696-16.696,16.696
        s-16.696-7.479-16.696-16.696V72.348H16.696C7.479,72.348,0,79.826,0,89.044
        v333.913c0,9.217,7.479,16.696,16.696,16.696h94.609v-50.087c0-9.217,7.479
        -16.696,16.696-16.696s16.696,7.479,16.696,16.696v50.087h350.609c9.217,0,
        16.696-7.479,16.696-16.696V89.044C512,79.826,504.521,72.348,495.304,72.348
        z M144.696,322.783c0,9.217-7.479,16.696-16.696,16.696s-16.696
        -7.479-16.696-16.696v-33.391c0-9.217,7.479-16.696,16.696-16.696s16.696,
        7.479,16.696,16.696V322.783z M144.696,222.609c0,9.217-7.479,16.696-16.696,
        16.696s-16.696-7.479-16.696-16.696v-33.391c0-9.217,7.479-16.696,16.696-16.696s16.696,
        7.479,16.696,16.696V222.609z M211.478,189.217c0-27.619,22.468-50.087,50.087
        -50.087c27.619,0,50.087,22.468,50.087,50.087c0,27.619-22.468,50.087-50.087,
        50.087C233.946,239.304,211.478,216.836,211.478,189.217z M257.512,343.544c
        -4.271,0-8.544-1.631-11.804-4.892c-6.521-6.521-6.521-17.087,0-23.609L387.37,
        173.37c6.521-6.522,17.086-6.522,23.608,0c6.521,6.521,6.521,17.087,0,
        23.609L269.315,338.652C266.054,341.914,261.782,343.544,257.512,343.544z
         M395.13,372.87c-27.619,0-50.087-22.468-50.087-50.087c0-27.619,22.468-50.087,
         50.087-50.087s50.087,22.468,50.087,50.087C445.217,350.402,422.75,372.87,
         395.13,372.87z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconCoupon',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
