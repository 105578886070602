<template lang="html">
  <div id="promotions-wizard-container" class="md-layout-item" ref="parent">
    <WizardHeader @clickEvent="navToPromotionsTable">
      <template slot="header">
        <span>{{ wizardHeading }}</span>
      </template>
    </WizardHeader>

    <!-- scrolls to top of md-app-scroller when steps change -->
    <md-steppers
      :md-active-step.sync="active"
      md-linear
      @md-changed="scrollTop"
      id="promotions-wizard-stepper"
    >
      <md-step :id="steps.STEP_1" md-label="Content" :md-done.sync="step1">
        <Content
          v-if="isActiveStep(steps.STEP_1)"
          :promotionWizardNextStep="promotionWizardNextStep"
          :initFormInput="initContentProps"
          @contentFormInput="updateContentFormInput"
        />
      </md-step>
      <md-step :id="steps.STEP_2" md-label="Audience" :md-done.sync="step2">
        <Audience
          v-if="isActiveStep(steps.STEP_2)"
          :promotionWizardNextStep="promotionWizardNextStep"
          :initFormInput="initAudienceProps"
          @audienceFormInput="updatePromotionFormInput"
        />
      </md-step>
      <md-step :id="steps.STEP_3" md-label="Redemption" :md-done.sync="step3">
        <Redemption
          v-if="isActiveStep(steps.STEP_3)"
          :promotionWizardNextStep="promotionWizardNextStep"
          :initFormInput="initRedemptionProps"
          @redemptionFormInput="updatePromotionFormInput($event), formComplete()"
        />
      </md-step>
    </md-steppers>
  </div>
</template>

<script>
/** View steps need v-if bindings to ensure their nested .md-stepper elements
  * do not appear in query selection for dynamic sizing on the parent stepper
  */
import { schemas } from '@/schemas'
import { payloadDeepCopy } from '@/utils/misc'
import { merchantViews, steps } from '@/constants'
import WizardHeader from '@/components/common/WizardHeader'

const SCROLL_TOP_EVENT = 'scrollTop'

/* houses all of the steps to create a promotion */
export default {
  name: 'PromotionsWizardContainer',
  components: {
    Content: () => import('@/components/merchant-dashboard/PromotionsWizard/Content.vue'),
    Audience: () => import('@/components/merchant-dashboard/PromotionsWizard/Audience.vue'),
    Redemption: () => import('@/components/merchant-dashboard/PromotionsWizard/Redemption.vue'),
    WizardHeader,
  },
  props: {
    promotionObj: {
      type: Object,
      default () {
        return {
          active: true,
          promotionCategory: schemas.promotionCategories.entertainment,
          destinationGeotargets: [],
          termsAndConditions: '',
          redemptionMethod: schemas.redemptionMethods['Reusable code'],
          redemptionInstructions: '',
          division: schemas.divisions.local,
          merchant: this.$store.state.merchant.merchantId,
          creativeConfigs: [{
            identifier: undefined, // defaults to a uuid if not provided
            thumbnailText: '',
            thumbnailImage: undefined,
            promotedIcon: 'IconAmazon',
            promotedText: '',
          }],
        }
      }
    }
  },
  data () {
    return {
      steps, // constants
      active: steps.STEP_1,
      [steps.STEP_1]: false,
      [steps.STEP_2]: false,
      [steps.STEP_3]: false,
      formInput: payloadDeepCopy(this.promotionObj),
      copy: {
        createHeading: 'Create New Promotion',
        updateHeading: 'Update Promotion'
      }
    }
  },
  computed: {
    isCreateView () {
      return this.$route.name === merchantViews.MERCHANT_PROMOTIONS_WIZARD_NEW
    },
    wizardHeading () {
      return this.isCreateView
        ? this.copy.createHeading
        : this.copy.updateHeading
    },
    mesaActionName () {
      return this.isCreateView
        ? 'merchant/CREATE_PROMOTION'
        : 'merchant/UPDATE_PROMOTION'
    },
    initContentProps () {
      const creativeConfigInput = this.formInput.creativeConfigs[0]
      return {
        promotionCategory: this.formInput.promotionCategory,
        thumbnailText: creativeConfigInput.thumbnailText,
        thumbnailImageUrl: creativeConfigInput.thumbnailImageUrl,
        thumbnailImage: creativeConfigInput.thumbnailImage,
        promotedIcon: creativeConfigInput.promotedIcon,
        promotedText: creativeConfigInput.promotedText
      }
    },
    initAudienceProps () {
      return {
        division: this.formInput.division,
        destinationGeotargets: this.formInput.destinationGeotargets
      }
    },
    initRedemptionProps () {
      return {
        termsAndConditions: this.formInput.termsAndConditions,
        redemptionMethod: this.formInput.redemptionMethod,
        redemptionInstructions: this.formInput.redemptionInstructions,
      }
    }
  },
  methods: {
    scrollTop () {
      this.$emit(SCROLL_TOP_EVENT)
    },
    promotionWizardNextStep (id, index) {
      this[id] = true
      if (index) {
        this.active = index
      }
    },
    updateContentFormInput (formInput) {
      this.formInput.promotionCategory = formInput.promotionCategory
      this.formInput.creativeConfigs[0] = {
        ...this.formInput.creativeConfigs[0],
        thumbnailText: formInput.thumbnailText,
        promotedText: formInput.promotedText,
        promotedIcon: formInput.promotedIcon,
        thumbnailImage: formInput.thumbnailImage,
        thumbnailImageUrl: formInput.thumbnailImageUrl,
      }
    },
    updatePromotionFormInput (formInput) {
      this.formInput = Object.assign(this.formInput, formInput)
    },
    formComplete () {
      this.$store.dispatch(this.mesaActionName, {
        uuid: this.formInput.uuid,
        properties: this.formInput,
        nextRoute: {
          name: merchantViews.MERCHANT_PROMOTIONS,
          params: { id: this.$route.params.id }
        }
      })
    },
    isActiveStep (step) {
      return this.active === step
    },
    navToPromotionsTable () {
      this.$router.push({
        name: merchantViews.MERCHANT_PROMOTIONS,
        params: { ...this.$route.params }
      })
    }
  },
}
</script>

<style lang="sass">
  #promotions-wizard-container
    width: 100%
    height: auto
</style>
