<template>
  <div class="oprm-switch-wrapper-container">
    <md-switch v-model="active"></md-switch>
    <div
      :class="['status-text', 'md-caption', { 'active-text-color': this.active }]"
    >
      {{ this.active ? 'active' : 'inactive' }}
    </div>

    <!-- modal -->
    <ToggleRewardModal
      v-if="showModal"
      :reward="reward"
      @cancel="this.closeModal"
    />
  </div>
</template>

<script>
import ToggleRewardModal from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/ToggleRewardModal.vue'

export default {
  name: 'SwitchWrapper',
  components: {
    ToggleRewardModal
  },
  props: {
    reward: {
      type: Object,
      required: true
    }
  },
  data () {
    return { showModal: false }
  },
  computed: {
    active: {
      get () {
        return this.reward.active
      },
      set () {
        this.showModal = true
      }
    }
  },
  methods: {
    closeModal () {
      this.showModal = false
    }
  }
}
</script>

<style lang="sass">
.oprm-switch-wrapper-container
  min-width: 102px

  .md-switch
    margin-bottom: 6px

.status-text
  text-transform: capitalize
  padding-bottom: 15px
  vertical-align: middle

.active-text-color
  color: #00C82C !important

.md-table-cell
  // switch circle
  .md-checked .md-switch-thumb
    background-color: #00C82C !important

  // switch rail
  .md-checked .md-switch-container
    background-color: #00C82C38 !important
</style>
