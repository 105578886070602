import { userViews } from '@/constants'

export function getGroupHotelIds ({ isGroupUser, isAdminUser, hotel }): number[] | string {
  if (isGroupUser || isAdminUser) {
    const ids = hotel.hotelGroupHotelIds as number[] | void
    if (Array.isArray(ids)) {
      return ids
    }
  }
  return ''
}

/**
 * will potentially invoke custom function from route meta coniguration
 * or fallback on default method to determining hotelId
 */
export function getHotelId ({ route, primaryHotel, cradleHotelsObj }): number | string {
  let hotelId

  if (typeof route.meta?.looker?.hotelId === 'function') {
    hotelId = route.meta.looker.hotelId()
  }

  if (hotelId) {
    return hotelId
  }

  if (route.name === userViews.GROUP) {
    hotelId = Object.keys(cradleHotelsObj)
  } else if (primaryHotel) {
    hotelId = primaryHotel.id
  } else {
    hotelId = 0
  }
  return hotelId
}
