










































































import { IncorrectPasswordErrorHandler, UserDoesNotExistErrorHandler } from '@/lib/error-handlers'

export default {
  name: 'LoginContainer',
  data () {
    return {
      username: '',
      password: '',
      show: true,
      errorMsg: '',
      showResetSuccess: false,
      copy: {
        errorEmail: 'Please enter your email.',
        errorPassword: 'Please enter your password.',
        errorOccured: 'An error occured. Please try again.',
        incorrectPassword: IncorrectPasswordErrorHandler.friendlyMessage,
        userDoesNotExist: UserDoesNotExistErrorHandler.friendlyMessage
      },
      refLabels: {
        usernameField: 'username-field',
        passwordField: 'password-field'
      },
    }
  },
  mounted () {
    // display message for successul pw reset based off route params
    this.showResetSuccess = this.$route?.query?.reset
  },
  methods: {
    checkRequiredFields (): boolean {
      this.errorMsg = ''
      if (!this.password) {
        this.errorMsg = this.copy.errorPassword
        this.$refs[this.refLabels.passwordField].$el.classList.add('md-invalid')
      }
      if (!this.username) {
        this.errorMsg = this.copy.errorEmail
        this.$refs[this.refLabels.usernameField].$el.classList.add('md-invalid')
      }
      return !this.errorMsg
    },
    /**
     * disregard the event as first argument.
     * _window argment is for testing purposes.
     */
    async login (_, _window = window): Promise<void> {
      if (this.checkRequiredFields()) {
        this.$store.dispatch('SET_IS_LOADING', true)
        await this.$store.dispatch('LOGIN_USER', {
          username: this.username.trim().toLowerCase(),
          password: this.password,
        })

        if (this.$store.state.authError) {
          if (IncorrectPasswordErrorHandler.check(this.$store.state.authError.message).isMatch) {
            this.errorMsg = IncorrectPasswordErrorHandler.friendlyMessage
          } else if (UserDoesNotExistErrorHandler.check(this.$store.state.authError.message).isMatch) {
            this.errorMsg = UserDoesNotExistErrorHandler.friendlyMessage
          } else {
            this.errorMsg = this.copy.errorOccured
          }
          this.$store.dispatch('SET_IS_LOADING', false)
        } else {
          this.$store.dispatch('MANAGE_TOKEN')

          const initialView = this.$store.getters.INITIAL_VIEW()
          const pushToInitialView = () => this.$router.push({ name: initialView.view })
          const redirectPath = _window.sessionStorage?.getItem('redirect')

          if (redirectPath) {
            return await this.$router.push({ path: redirectPath })
              .catch(error => {
                console.log('[login] redirect error:', error)
                // push to initial view if there is an error
                // and prevent the user from getting stuck
                pushToInitialView()
              })
          } else {
            pushToInitialView()
          }
        }
      }
    }
  }
}
