





















import { mapState, mapGetters } from 'vuex'
import Select from '@/components/select/Select.vue'
import { getRangeOptions } from '@/utils/dropdown-utils'
import { menuStyles } from '@/constants'

export default {
  name: 'SelectableRewards',
  components: {
    Select,
  },
  data () {
    return {
      menuStyles,
      copy: {
        body: 'Select the number of rewards that the guest can choose.'
      }
    }
  },
  computed: {
    ...mapState('ram', [
      'algo'
    ]),
    ...mapGetters('ram', [
      'TOTAL_REWARDS_OFFERED',
    ]),
    options (): Array<{ label: string; value: number }> {
      if (!this.TOTAL_REWARDS_OFFERED) {
        return [{ label: '0', value: 0 }]
      }
      return getRangeOptions(1, this.TOTAL_REWARDS_OFFERED)
    },
    selectableLimit: {
      get () {
        return this.algo.maxRewardSelections
      },
      set (value) {
        this.$store.commit('ram/SET_ALGO_SELECTABLE_LIMIT', value)
      }
    }
  },
}
