import { reverseObject } from '@/utils/misc'

export const schemas = {
  user: {
    username: 'Username',
    attributes: 'Attributes',
    createDate: 'UserCreateDate',
    lastModifiedDate: 'UserLastModifiedDate',
    enabled: 'Enabled',
    status: 'UserStatus',
  },
  attribute: {
    hssEditorAccess: 'custom:hss_editor_access',
    reportingAccess: 'custom:reporting_access',
    oprmAccess: 'custom:oprm_access',
    edmAccess: 'custom:edm_access',
    rewardAlgoAccess: 'custom:reward_algo_access',
    flexAccess: 'custom:flex_access',
    customProfile: 'custom:profile',
    sub: 'sub',
    email: 'email',
    emailVerified: 'email_verified',
    groups: 'custom:groups',
  },
  profile: {
    createEnv: 'create_env',
    email: 'username',
    type: 'type',
    hotels: 'hotels',
    group: 'group', // TODO remove in p2
    merchantIds: 'merchant_ids',
  },
  hotel: {
    hotelCode: 'hotel_code',
    name: 'name',
    id: 'id',
    hotelGroupId: 'hotel_group_id',
  }
}

export const reverseSchemas = {
  user: reverseObject(schemas.user),
  attributes: reverseObject(schemas.attribute),
  profile: reverseObject(schemas.profile),
  hotel: reverseObject(schemas.hotel),
}
