















































import Vue from 'vue'
import { CustomStepper as CustomStepperConfig } from '@/types'
import { steps } from '@/constants'
import CustomStepper from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/CustomStepper.vue'
import FuxSearch from '@/components/hotel-dashboard/bulk-update/bulk-duplicate/FuxSearch.vue'
import DuplicationDestination from '@/components/hotel-dashboard/bulk-update/bulk-duplicate/DuplicationDestination.vue'
import DesignSetName from '@/components/hotel-dashboard/bulk-update/bulk-duplicate/DesignSetName.vue'
import MabEnable from '@/components/hotel-dashboard/bulk-update/bulk-duplicate/MabEnable.vue'
import WizardStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/WizardStep.vue'
import CustomStepHeader from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepHeader.vue'

export default Vue.extend({
  name: 'BulkDesignDuplication',
  components: {
    CustomStepper,
    FuxSearch,
    DuplicationDestination,
    DesignSetName,
    MabEnable,
    WizardStep,
    CustomStepHeader
  },
  data () {
    return {
      steps, // expose constants
      stepHeaders: [
        { name: 'Select Funnel UX to be Duplicated', isRetain: false },
        { name: 'Select Destination(s) to Duplicate To', isRetain: false },
        { name: 'Name the Design Set', isRetain: false },
        { name: 'Enable/Disable Design Set', isRetain: false }
      ] as CustomStepperConfig.StepHeaders
    }
  }
})
