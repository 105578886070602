<template>
  <div
    v-if="hasMessages"
    id="snackbar"
  >
    <md-snackbar
      v-for="message in messagesList"
      :key="message.id"
      :class="{
        'message-success': message.type === 'success',
        'message-error': message.type === 'error'
      }"
      :md-position="position"
      :md-active.sync="showSnackbar"
      :md-persistent="true"
    >
      <div id="messages-container">
        <div
          class="message-container"
        >
          <div class="message">
            {{ message.message }}
          </div>
          <div class="button-container">
            <md-button
              class="md-simple dismiss-button"
              @click="CLEAR_MESSAGE(message.id)"
            >
              <md-icon>close</md-icon>
            </md-button>
          </div>
        </div>
      </div>
    </md-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Snackbar',
  props: {
    position: {
      type: String,
      required: false,
      default: () => 'center'
    },
    duration: {
      type: [Number],
      required: false,
      default: () => undefined // default value is inlined on md-snackbar
    }
  },
  data () {
    return {
      // keep as true, control rendering w/ v-if
      showSnackbar: true,
      copy: {
        dismiss: 'Dismiss'
      }
    }
  },
  computed: {
    ...mapGetters('messages', [
      'hasMessages',
      'messagesList'
    ]),
  },
  methods: {
    ...mapMutations('messages', [
      'CLEAR_MESSAGE',
    ]),
  }
}
</script>

<style lang="sass">
  .md-snackbar
    max-height: 800px !important
  .md-snackbar-content
    display: block !important
</style>

<style lang="sass" scoped>
.message-success
  background: #E4F2DE !important
  color: #3C763D !important
  .md-icon
    color: #3C763D !important
.message-error
  background: #FFE8E8 !important
  color: #B90505 !important
  .md-icon
    color: #B90505 !important
#messages-container
  width: 100%
  height: auto
.message-container
  padding: 5px 0
  width: 100%
  display: flex
  justify-content: space-between
  algin
.message
  max-width: 420px
.dismiss-button.md-button.md-theme-default
  color: white
</style>
