import { schemas } from '@/schemas'
import IconShopping3 from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/icons/IconShopping3'
import IconEntertainment from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/icons/IconEntertainment'
import IconTravel from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/icons/IconTravel'
import IconWellness1 from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/icons/IconWellness1'
import IconDining2 from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/icons/IconDining2'
import IconTours1 from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/icons/IconTours1'

export const categoryIconConfigs = [
  {
    component: IconEntertainment,
    category: schemas.promotionCategories.entertainment
  },
  {
    component: IconTravel,
    category: schemas.promotionCategories.travel
  },
  {
    component: IconShopping3,
    category: schemas.promotionCategories.shopping
  },
  {
    component: IconWellness1,
    category: schemas.promotionCategories.healthAndWellness
  },
  {
    component: IconDining2,
    category: schemas.promotionCategories.foodAndBeverage
  },
  {
    component: IconTours1,
    category: schemas.promotionCategories.tours
  },
]
