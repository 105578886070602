<template>
  <div id="oprm-wizard-category-step">
    <WizardStep
      :editing="editing"
      :reverse-order-on-mobile="true"
    >
      <CustomStepHeader
        slot="step-header"
        :index="index"
        :label="'Category'"
      />
      <CategorySelector slot="left-content" />
      <template slot="right-content">
        <div :style="{ alignText: 'justify' }">
          <div class="md-headline laas-bold ">
            {{ copy.headline }}
          </div>
          <br>
          <div
            class="md-subheading"
            :style="{ alignText: 'justify' }"
            v-html="copy.body"
          />
        </div>
      </template>
      <CustomStepButtons
        slot="button-group"
        :disabled="IS_CATEGORY_SELECTOR_DISABLED"
        :index="index"
      />
    </WizardStep>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WizardStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/WizardStep'
import CategorySelector from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/content/CategorySelector'
import CustomStepHeader from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepHeader'
import CustomStepButtons from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepButtons'

export default {
  name: 'CategoryStep',
  components: {
    WizardStep,
    CategorySelector,
    CustomStepHeader,
    CustomStepButtons
  },
  props: {
    editing: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      copy: {
        headline: 'Welcome to your On-Property Reward Creation Wizard',
        body: 'We will take you through the step by step process of creating a reward through our wizard. You can toggle to an active tab by clicking on it. <br/><br/>Visit this wizard from your home screen at any time via the “New Reward” button.',
      }
    }
  },
  computed: {
    ...mapGetters('oprm', [
      'IS_CATEGORY_SELECTOR_DISABLED'
    ]),
  },
}
</script>
