/**
 * @note the fn does not always a return a number
 * because the undefined return value is used by dropdown
 * components to render a placeholder.
 */
export function getTotalRewardsOffered ({
  marketPlaceRewards, onPropertyRewards
}: {
  marketPlaceRewards: number | void;
  onPropertyRewards: number | void;
}): number | void {
  if (typeof onPropertyRewards === 'number' &&
  typeof marketPlaceRewards === 'number') {
    return onPropertyRewards + marketPlaceRewards
  }
}
