























































































































































import { orderBy, isPlainObject } from 'lodash'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import {
  ASC,
  DESC,
} from './constants'
import { pageSizeOptions, menuStyles } from '@/constants'
import { TableColumn } from '@/types'
import ArrowDown from '@/components/icons/ArrowDown.vue'
import ArrowUp from '@/components/icons/ArrowUp.vue'

export default {
  name: 'Table',
  components: {
    Dropdown,
    ArrowDown,
    ArrowUp,
  },
  props: {
    /**
     * if true will show all content in row even if its overflowing.
     * if false will hide overflow and only show it when user hovers over cell.
     */
    showOverflow: {
      type: Boolean,
      default: false
    },
    /**
     * column objects have a key, name (for column header),
     * sortable boolean, alignment (flex-start, flex-end, center),
     * and width which is passed to the th's min-width
     */
    columns: {
      type: Array as () => TableColumn[],
      required: false, // allows for empty state
      default: () => [],
      validator (value): boolean {
        if (Array.isArray(value) && value.length === 0) {
          return true
        }
        return value.every(col => isPlainObject(col))
      },
    },
    /**
     * an array of objects whose values are accessed according
     * to corresponding column keys
     */
    rowData: {
      type: Array,
      required: false,
      default: () => [],
      validator (value): boolean {
        if (Array.isArray(value) && value.length === 0) {
          return true
        }
        return value.every(row => isPlainObject(row))
      },
    },
    /**
     * number of rows per page
     */
    pageSize: {
      type: Number,
      required: false,
      default: 10,
      validator (value) {
        return value > 0 && value <= 100
      }
    },
    /**
     * governs whether an updateSort event is emitted,
     * when clicking sort so that the parent can update data
     */
    backendSort: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * governs whether an updatePage event is emitted,
     * when clicking next/prev page so that the parent can update data
     */
    backendPaginate: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * sort on a column on render
     */
    initialSort: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * used in tandem with backendPaginate to show
     * totalResult count, part of the response obj,
     * otherwise will use rowData.length
     */
    totalResults: {
      type: Number,
      required: true,
    },
    /**
     * show pagination row or not
     */
    showPagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * a pixel or percentage (of parent) value
     * passed to table element's width attribute
     */
    tableWidth: {
      type: String,
      required: false,
      validator (value) {
        return value.includes('%') || value.includes('px')
      },
      default: '100%'
    },
    /**
     * can apply padding rule to all column headers. will be
     * e when col slot is refactored.
     */
    colHeaderPadding: {
      type: String,
      validator (value): boolean {
        return value.includes('%') || value.includes('px')
      },
      default: '15px 25px',
      required: false
    },
    /**
     * can apply padding rule to all columns. will be
     * replaced when col slot is refactored.
     */
    colPadding: {
      type: String,
      validator (value): boolean {
        return value.includes('%') || value.includes('px')
      },
      default: '15px 25px',
      required: false
    },
    page: {
      type: Number,
      required: false,
      default: 1,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    }
  },
  data () {
    return {
      menuStyles,
      refLabels: {
        emptyState: 'empty-state',
      },
      sortOn: '',
      desc: true,
      currentPage: this.page,
      startIndex: this.index,
      sortIconWidth: 12,
      options: pageSizeOptions,
      icons: {
        right: ['fas', 'chevron-right'],
        left: ['fas', 'chevron-left'],
      },
      copy: {
        rowsPerPage: 'Rows per page:',
        defaultEmptyState: 'No entries found',
      },
    }
  },
  computed: {
    emptyStateStyles (): { [k: string]: string } {
      return {
        height: this.$slots[this.refLabels.emptyState]?.length >= 1 ? 'auto' : '300px',
        'vertical-align': 'middle',
        'text-align': 'center',
      }
    },
    paginationCopy (): string {
      return `${this.startIndex + 1} - ${this.endIndex} of ${this.totalResultCount}`
    },
    colNumber (): number {
      return this.columns.length
    },
    showEmptyState (): boolean {
      return this.rows.length <= 0
    },
    colSortedOn (): (col: TableColumn) => boolean {
      return (col) => col.key === this.sortOn
    },
    showReducedOpacityArrow (): (col: TableColumn) => boolean {
      return (col) => !this.colSortedOn(col) && this.colSortable(col)
    },
    totalResultCount (): number {
      return this.backendPaginate ? this.totalResults : this.rowData.length
    },
    hasNextPage (): boolean {
      return (this.currentPage * this.pageSize) < this.totalResultCount
    },
    hasPrevPage (): boolean {
      return this.currentPage > 1
    },
    rows (): Array<{}> {
      if (this.backendPaginate && this.backendSort) {
        return this.rowData
      } else {
        const direction = this.desc ? DESC : ASC
        return orderBy(this.pageData, this.sortOn, direction)
      }
    },
    endIndex (): number {
      const range = this.startIndex + this.pageSize

      if (range > this.totalResultCount) {
        return this.totalResultCount
      }
      return range
    },
    pageData (): Array<{}> {
      if (!this.backendPaginate) {
        return this.rowData.slice(this.startIndex, this.endIndex)
      }
      return this.rowData
    },
    sortIconColor (): string {
      return this.$theme.colors.black
    }
  },
  mounted (): void {
    // sort the table by a specified column if initialSort prop is provided
    if (this.initialSort) {
      const colKeyValues = this.columns.flatMap(col => Object.values(col)) as TableColumn[]

      if (colKeyValues.length === 0 || !colKeyValues.includes(this.initialSort)) {
        console.warn('initial sort value does not match any column')
      } else {
        this.sortOn = this.initialSort
      }
    }
  },
  methods: {
    colSortable (col: TableColumn): boolean {
      return col.sortable
    },
    updateSortOn (column: TableColumn): void {
      if (column.sortable === false) return
      const { key } = column

      // have intial sort be desc when sorting new col
      if (this.sortOn === key) {
        this.desc = !this.desc
      } else if (this.sortOn !== key) {
        this.desc = true
        this.sortOn = key
      }

      if (this.backendSort) {
        /**
         * emits contains sort key and direction
         *
         * @event success
         */
        return this.$emit('updateSort', {
          sortOn: key,
          direction: this.desc
        })
      }
    },
    // FIXME emit the number, not the event
    updateResultsPerPage (e: Event): void {
      /**
       * emits new number of results to display
       *
       * @event updateResultsPerPage
       */
      this.$emit('updateResultsPerPage', e)
    },
    updatePageEmit (): void {
      /**
       * emits current page and page size
       *
       * @event updatePage
       */
      this.$emit('updatePage', {
        page: this.currentPage,
        pageSize: this.pageSize,
        index: this.startIndex
      })
    },
    nextPage (): void {
      if (this.hasNextPage) {
        this.currentPage++
        this.startIndex += this.pageSize

        if (this.backendPaginate) this.updatePageEmit()
      }
    },
    prevPage (): void {
      if (this.hasPrevPage) {
        this.currentPage--
        this.startIndex -= this.pageSize

        if (this.backendPaginate) this.updatePageEmit()
      }
    },
  },
}

