import { render, staticRenderFns } from "./FuxSearch.vue?vue&type=template&id=1ded5646&scoped=true&"
import script from "./FuxSearch.vue?vue&type=script&lang=js&"
export * from "./FuxSearch.vue?vue&type=script&lang=js&"
import style0 from "./FuxSearch.vue?vue&type=style&index=0&id=1ded5646&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ded5646",
  null
  
)

export default component.exports