












































import { schemas } from '@/schemas'
import { categoryIconConfigs } from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/icons'
import { Promo } from '@/types'

export default {
  name: 'CategorySelector',
  data () {
    return {
      copy: {
        title: 'Which category best captures your reward?',
        footer: 'Don’t see a category that fits? Contact your customer success manager and we’ll try to help!',
        required: 'Required',
      }
    }
  },
  computed: {
    category (): Promo.Category {
      return this.$store.state.oprm.reward.promotionCategory
    },
    categories (): Promo.CategoryMap {
      return schemas.promotionCategories
    },
    categoryIcons (): Promo.CategoryIcon[] {
      return categoryIconConfigs
    },
  },
  methods: {
    setCategory (value: string): void {
      const field = 'promotionCategory'
      this.$store.commit('oprm/SET_REWARD_FIELD', { field, value })
    },
    isActive (category): boolean {
      return this.category === category
    },
  }
}
