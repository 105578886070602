<template lang="html">
  <svg
    id="icon-shopping3"
    xmlns="http://www.w3.org/2000/svg"
    data-name="icon-shopping3"
    viewBox="0 0 35 35"
    role="img"
  >
    <title>{{ this.name }}</title>
    <desc id="desc">{{ this.name }}</desc>
    <path
      class="cls-1"
      d="M28.38,10.73v-.32a.36.36,0,0,0-.35-.36l-4.74,0v0a3.59,3.59,0,0,0-3.72-3.4H19A3.88,3.88,0,0,0,17.22,7a4.5,4.5,0,0,0-.63-.07h-.67a4.45,4.45,0,0,0-4.14,3l-3.06,0h-.1s0,0-.07,0,0,0-.07,0,0,0,0,.07l0,0L7.85,11.2,6.72,12l0,0s0,0,0,0,0,0,0,.08,0,0,0,.07v2.58a.11.11,0,0,0,0,.1s0,.07,0,.11v13a.33.33,0,0,0,.35.36h18.4a.2.2,0,0,0,.14,0l0,0s0,0,.07,0l1.27-1.18,1.19.11a.39.39,0,0,0,.28-.11.4.4,0,0,0,.11-.25ZM19,7.3h.57a2.87,2.87,0,0,1,3,2.68H20.69a4.49,4.49,0,0,0-2.31-2.65A4.16,4.16,0,0,1,19,7.3Zm1.94,8.27a.39.39,0,1,1-.39-.4A.43.43,0,0,1,20.91,15.57ZM17.32,7.73A3.68,3.68,0,0,1,20,10l-4,0A2.73,2.73,0,0,1,17.32,7.73Zm-1.43-.07h.38a3.13,3.13,0,0,0-1,2.29H12.52A3.65,3.65,0,0,1,15.89,7.66ZM12.2,11.41a4.76,4.76,0,0,1,.07-.75l7.86,0a3.88,3.88,0,0,1,.07.71V12h-8Zm-.39,3.8a.4.4,0,0,1,0,.79.4.4,0,0,1,0-.79ZM8.3,11.77a.42.42,0,0,0,.11-.14l.49-1h2.67a4.76,4.76,0,0,0-.07.75V12H8ZM24.94,27.7H7.25V15a.13.13,0,0,0,0-.11s0-.07,0-.1V12.7H11.5v1.79a1.08,1.08,0,0,0-.78,1,1.09,1.09,0,1,0,2.18,0,1.06,1.06,0,0,0-.67-1V12.7h8v1.79a1.07,1.07,0,0,0-.77,1,1.09,1.09,0,1,0,2.17,0,1.06,1.06,0,0,0-.66-1V12.7h4v15Zm.18-15.64A.2.2,0,0,0,25,12h-4v-.61a4.27,4.27,0,0,0-.07-.71l6,0-.84.36s-.07,0-.11.07ZM26.7,26.45a.33.33,0,0,0-.25.11l-.81.71V12.52l.74-.75,1.27-.53V26.56Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconShopping3',
  data () {
    return {
      name: 'shopping icon 3'
    }
  },
}
</script>

<style lang="css">
</style>
