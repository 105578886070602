import { render, staticRenderFns } from "./Users.vue?vue&type=template&id=70bc4155&scoped=true&"
import script from "./Users.vue?vue&type=script&lang=ts&"
export * from "./Users.vue?vue&type=script&lang=ts&"
import style0 from "./Users.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Users.vue?vue&type=style&index=1&lang=sass&"
import style2 from "./Users.vue?vue&type=style&index=2&id=70bc4155&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70bc4155",
  null
  
)

export default component.exports