
























import WizardStep from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/WizardStep.vue'
import CustomStepHeader from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepHeader.vue'
import CustomStepButtons from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/CustomStepButtons.vue'
import AdvanceNotice from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/AdvanceNotice.vue'

export default {
  name: 'AdvancedNoticeStep',
  components: {
    WizardStep,
    CustomStepHeader,
    CustomStepButtons,
    AdvanceNotice,
  },
  props: {
    editing: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    meta: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {}
  }
}
