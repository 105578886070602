































































import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { TableHeaderUtils } from '@/utils/table-headers'
import Table from '@/components/table/Table.vue'
import RadioButton from '@/components/radio-button/RadioButton.vue'
import { NodeTemplate, EmailDesignType, PageInfo } from '@/types'

/**
 * component uses props over provide/inject because its being
 * passed as slot to Modal and is not considered a direct descendant
 * of WorkflowManagerContainer
 */

export default {
  name: 'EmailDesignTableEmbed',
  components: {
    Table,
    RadioButton,
  },
  data () {
    return {
      columns: [
        {
          key: 'id',
          name: '',
          sortable: false,
          alignment: 'center',
          width: '10',
        },
        {
          key: 'name',
          name: 'Name',
          sortable: false,
          alignment: 'flex-start',
          width: '100',
        },
        {
          key: 'type',
          name: 'Type',
          sortable: false,
          alignment: 'flex-start',
          width: '30',
        },
        {
          key: 'modified',
          name: 'Modified',
          sortable: false,
          alignment: 'flex-start',
          width: '50',
        },
      ],
    }
  },
  computed: {
    ...mapState('edm', [
      'pageInfo',
      'emailDesignsFetchSuccess'
    ]),
    ...mapState('ecm', [
      'campaign',
      'activeNode',
    ]),
    ...mapGetters('edm', [
      'SORTED_EMAIL_DESIGNS_LIST',
      'GET_EMAIL_DESIGN',
    ]),
    ...mapGetters('hotel', [
      'hotelByCradleId'
    ]),
    isChecked (): (id: number) => boolean {
      return (id) => (this.activeNode as NodeTemplate).emailDesignId === id
    },
  },
  mounted () {
    this.SET_PAGE(1)
    this.SET_PAGE_INDEX(0)
    this.fetchPaginatedEmailDesigns()
  },
  methods: {
    ...mapMutations('ecm', [
      'SET_ACTIVE_NODE_EMAIL_DESIGN_ID',
    ]),
    ...mapMutations('edm', [
      'SET_PAGE',
      'SET_PAGE_SIZE',
      'SET_EMAIL_DESIGN',
      'SET_PAGE_INDEX',
    ]),
    ...mapActions('edm', [
      'FETCH_EMAIL_DESIGNS',
      'CREATE_OR_UPDATE_EMAIL_DESIGN',
    ]),
    getUpdatedOn: TableHeaderUtils.getUpdatedOn,
    getName: TableHeaderUtils.getName,
    getType: TableHeaderUtils.getType,
    async fetchPaginatedEmailDesigns () {
      const { page, pageSize } = this.pageInfo

      await this.FETCH_EMAIL_DESIGNS({ page, pageSize, templateType: 'su' as EmailDesignType })
    },
    updatePage (pageInfo: PageInfo): void {
      this.SET_PAGE(pageInfo.page)
      this.SET_PAGE_INDEX(pageInfo.index)
      this.fetchPaginatedEmailDesigns()
    },
    updatePageSize (pageSize): void {
      this.SET_PAGE_SIZE(pageSize)
      this.fetchPaginatedEmailDesigns()
    },
  },
}
