<template>
  <div id="design-set-name-input">
    <md-field>
      <md-input
        required
        v-model="designSetName"
        :placeholder="copy.placeholder"
        :disabled="designSetNameTaken">
      </md-input>
    </md-field>
    <div class="design-set-error-messages">
      <div v-if="designSetNameTaken">
        {{ copy.cantRename }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DesignSetName',
  data () {
    return {
      copy: {
        placeholder: 'Enter a Unique Design Set Name',
        cantRename: 'The funnel UX to be duplicated is already part of a design test. You may not change the name of the existing design test.'
      }
    }
  },
  computed: {
    ...mapState('designDuplication', [
      'designSetNameTaken',
    ]),
    designSetName: {
      get () {
        return this.$store.state.designDuplication.design.design_set_name
      },
      set (value) {
        this.$store.commit('designDuplication/SET_DESIGN_NAME', value)
      }
    }
  }
}
</script>

<style scoped lang="sass">
#design-set-name-input
  background-color: #e9e9e9
  border: 0
  background: #fff
  font-family: 'Lato'
  padding-bottom: 8px
  input, div.md-menu
    padding-left: 5px
    overflow: hidden
  .md-field
    width: 600px

.design-set-error-messages
  color: #e02020
</style>
