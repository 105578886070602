import { ErrorHandler } from '@/types'

// a Cognito error must be matched on its message string
// the friendlyMessage is what we render to the user

export const ErrorHandlerCopy: ErrorHandler.CopyConfigMap = {
  incorrectPassword: {
    message: 'Incorrect username or password.',
    friendlyMessage: 'Incorrect email or password.'
  },
  userDoesNotExist: {
    message: 'User does not exist.',
    friendlyMessage: 'User does not exist.'
  },
  enterAValidUrl: {
    message: 'enter a valid url.',
    friendlyMessage: 'Please enter a valid URL. Protocol is required (http://, https://)'
  },
  algorithmNameIsTaken: {
    message: 'The fields hotel, identifier must make a unique set.',
    friendlyMessage: 'Algorithm Name: This name is taken. Please use another name.'
  }
}
