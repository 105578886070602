


























import { mapState } from 'vuex'
const MAX_BODY_CHAR_LENGTH = 55

export default {
  name: 'Node',
  components: {},
  props: {
    copy: {
      type: Object as () => { body: string; title: string },
      required: true,
    },
    classes: {
      type: Array as () => string[],
      required: false,
      default: () => []
    },
    width: {
      type: [Number, String],
      required: false,
      default: null
    },
    nodeId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    ...mapState('ecm', [
      'newestNodeIds',
    ]),
    nodeClasses () {
      return this.newestNodeIds.includes(this.nodeId) ? ['green-background', 'transition-background'] : []
    },
    computedWidth (): string | void {
      if (typeof this.width === 'number') {
        return `${this.width}px`
      }
      return 'max-content'
    },
    truncateBody (): boolean {
      if (!this.copy.body) return false
      return this.copy.body.length > MAX_BODY_CHAR_LENGTH
    },
    body (): string {
      return this.truncateBody
        ? this.copy.body.slice(0, MAX_BODY_CHAR_LENGTH).trim() + '...'
        : this.copy.body
    }
  }
}
