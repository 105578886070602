<template>
  <svg
    id="icon-car"
    viewBox="0 0 972 972"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    role="img"
    v-bind:style="{width: icon.car}">

    <title id="title">car icon</title>
    <desc id="desc">car icon</desc>

    <g>
      <path d="M894.2,832.662v-57.6H747.9v57.6C748.3,934.562,894.2,935.763,894.2,832.662z"/>
      <path d="M223.4,832.662v-57.6H77.7v57.6C77.5,935.763,223.4,934.562,223.4,832.662z"/>
      <path d="M972,655.062v-202c-0.2-70.4-56.3-107.6-88.2-111.7l-79.7-205.7c-14.899
        -39.4-47.5-72.9-109.1-73.2H582.7h-192H276.3c-61.3,0.3-93.9,33.8-109.1,73.2l
        -79.7,205.7c-31.6,4.1-87.6,41.3-87.5,111.7v202c0,33.1,26.9,60,60,60h852C945.1,
        715.062,972,688.263,972,655.062z M163,566.962c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,
        0-0.2,0c-37.2,0-67.2-31-67.1-69.199c-0.2-38.301,29.7-69.301,66.7-69.301c0.2,
        0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c36.9,0,66.8,31,66.7,69.301C230.2,535.962,
        200.1,566.962,163,566.962z M486,339.462h-0.7H235.9c-22,0-36.9-22.4-28.3-42.7l42-99.3
        c6.3-15.7,18.5-39.5,45.1-39.9h381.7c26.199,0.4,32.8,15.1,45.1,39.9l42,99.3c8.6,
        20.3-6.3,42.7-28.3,42.7H486L486,339.462zM804.3,571.962c-0.1,0-0.1,0-0.2,0c-0.1,
        0-0.1,0-0.199,0c-37.4,0-67.5-31-67.7-69.199c0.2-38.301,30.1-69.301,67.399-69.301
        c0.2,0,0.4,0,0.601,0s0.399,0,0.6,0c36.601,0,66.5,31,66.7,69.301C871.2,540.962,
        841.1,571.962,804.3,571.962z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconCar',
  props: {
    icon: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
