<template>
  <div id="redemption-terms-container" class="laas-oprm-stepper-content">
    <div class="md-body-2 terms-header">
      {{ copy.termsDescription }}
    </div>
    <WysiwygEditor v-model="redemptionTerms" />
  </div>
</template>

<script>
import WysiwygEditor from '@/components/common/WysiwygEditor.vue'

export default {
  name: 'RedemptionTerms',
  components: {
    WysiwygEditor
  },
  data () {
    return {
      copy: {
        termsDescription: 'What are the terms and conditions of your reward? Eg. Only valid for 1 purchase.'
      }
    }
  },
  computed: {
    redemptionTerms: {
      get: function () {
        return this.$store.state.oprm.reward.termsAndConditions
      },
      set: function (value) {
        this.$store.commit('oprm/SET_REWARD_FIELD', {
          field: 'termsAndConditions',
          value
        })
      }
    },
  },
  methods: {}
}
</script>

<style lang="sass">
.terms-header
  margin-bottom: 20px
</style>
