



























import Button from '@/components/button/Button.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import { UserManagement } from '@/types'
import { menuStyles } from '@/constants'

export type Label = 'Create New User' | 'Create New Merchant'

export default {
  name: 'Toolbar',
  components: {
    Button,
    Dropdown
  },
  inject: [
    'Users__toggleShowAddUser',
    'Users__createNewMerchant'
  ] as UserManagement.ProvidedFunctions[],
  data () {
    return {
      menuStyles,
      createButton: {
        label: 'Create',
        options: [
          { label: 'Create New User' },
          { label: 'Create New Merchant' }
        ] as Record<'label', Label>[],
      }
    }
  },
  methods: {
    selected (event: Record<'label', string>): void {
      if (!event.label) return
      const opts: Record<Label, () => void> = {
        'Create New User': this.Users__toggleShowAddUser,
        'Create New Merchant': this.Users__createNewMerchant
      }
      const fn = opts[event.label]
      if (fn) fn()
    }
  }
}
