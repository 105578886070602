import IconAmazon from './IconAmazon'
import IconBellhop from './IconBellhop'
import IconBell from './IconBell'
import IconBeverage2 from './IconBeverage2'
import IconBeverage from './IconBeverage'
import IconCar from './IconCar'
import IconCasinoChip from './IconCasinoChip'
import IconCoffee from './IconCoffee'
import IconCoupon from './IconCoupon'
import IconCowboyHat from './IconCowboyHat'
import IconDestination2 from './IconDestination2'
import IconDestination from './IconDestination'
import IconDining from './IconDining'
import IconDiscount from './IconDiscount'
import IconDollar2 from './IconDollar2'
import IconDollar from './IconDollar'
import IconEmail from './IconEmail'
import IconEnvelope from './IconEnvelope'
import IconFlamingoBay from './IconFlamingoBay'
import IconFlight from './IconFlight'
import IconGift from './IconGift'
import IconSquareGift from './IconSquareGift'
import IconItunes from './IconItunes'
import IconMuseum from './IconMuseum'
import IconParking from './IconParking'
import IconPiggybank from './IconPiggybank'
import IconPlane from './IconPlane'
import IconPlus from './IconPlus'
import IconRollerCoaster from './IconRollerCoaster'
import IconSavings from './IconSavings'
import IconShopping2 from './IconShopping2'
import IconShopping from './IconShopping'
import IconShuttle from './IconShuttle'
import IconSkiPark from './IconSkiPark'
import IconTap from './IconTap'
import IconTicket from './IconTicket'
import IconWifi from './IconWifi'

export default {
  components: {
    IconAmazon,
    IconBellhop,
    IconBell,
    IconBeverage2,
    IconBeverage,
    IconCar,
    IconCasinoChip,
    IconCoffee,
    IconCoupon,
    IconCowboyHat,
    IconDestination2,
    IconDestination,
    IconDining,
    IconDiscount,
    IconDollar2,
    IconDollar,
    IconEmail,
    IconEnvelope,
    IconFlamingoBay,
    IconFlight,
    IconGift,
    IconSquareGift,
    IconItunes,
    IconMuseum,
    IconParking,
    IconPiggybank,
    IconPlane,
    IconPlus,
    IconRollerCoaster,
    IconSavings,
    IconShopping2,
    IconShopping,
    IconShuttle,
    IconSkiPark,
    IconTap,
    IconTicket,
    IconWifi,
  }
}
