
































import {
  SMALL,
  MEDIUM,
  LARGE,
} from '@/components/radio-button/constants'
import { RadioButtonSize } from '@/types'
export type Direction = 'left' | 'right' | 'top' | 'bottom'

export default {
  name: 'RadioButton',
  props: {
    model: [String, Number, Boolean, Object],
    value: {
      type: [String, Number, Boolean, Object],
      default: 'on'
    },
    id: {
      type: String,
      default: () => `radio-${Date.now()}`
    },
    /**
     * Sets radio button height and width
     * accepts a predefined size value SMALL, MEDIUM, LARGE
     * or a custom size value in number
     */
    size: {
      type: [String, Number],
      default: 'medium',
      validator (value) {
        if (typeof value === 'number') {
          return value > 0
        }
        return [SMALL, MEDIUM, LARGE].includes(value)
      },
      required: false,
    },
    name: [String, Number],
    required: Boolean,
    disabled: Boolean,
    checked: Boolean,
    tooltip: {
      type: String,
      required: false,
      default: ''
    },
    tooltipDirection: {
      type: String as () => Direction,
      required: false,
      default: 'right',
      validator (value): boolean {
        return [
          'left',
          'right',
          'top',
          'bottom'
        ].includes(value)
      }
    }
  },
  model: {
    prop: 'model',
    event: 'change'
  },
  computed: {
    showTooltip (): boolean {
      return this.disabled && this.tooltip
    },
    isSelected (): boolean {
      if (this.checked) return true
      const falsey = [null, undefined, '']
      if (falsey.includes(this.value)) {
        return falsey.includes(this.model)
      }
      return this.model === this.value
    },
    radioClasses (): Record<string, boolean> {
      return {
        radio__checked: this.isSelected,
        radio__disabled: this.disabled,
        radio__required: this.required
      }
    },
    sizeStyle (): Record<'height'|'width', string> {
      let size
      if (typeof this.size === 'number') {
        size = {
          height: `${this.size}px`,
          width: `${this.size}px`,
          minHeight: `${this.size}px`,
          minWidth: `${this.size}px`
        }
      }
      if (typeof this.size === 'string') {
        const opts = {
          [SMALL]: {
            height: '13px',
            width: '13px',
            minHeight: '13px',
            minWidth: '13px',
          },
          [MEDIUM]: {
            height: '16px',
            width: '16px',
            minHeight: '16px',
            minWidth: '16px',
          },
          [LARGE]: {
            height: '18px',
            width: '18px',
            minHeight: '18px',
            minWidth: '18px',
          },
        } as Record<RadioButtonSize, { height: string; width: string }>
        if (opts[this.size]) {
          size = opts[this.size]
        }
      }
      return size
    }
  },
  methods: {
    toggleCheck (): void {
      if (!this.disabled) {
        this.$emit('change', this.value)
      }
    }
  }
}
