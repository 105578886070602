import { ElementRect } from '@/types'

/**
 * element's left position + width > content width indicates
 * that the element is crossing over the edge of the viewport
 */
export function isOnHorizontalViewportEdge (rect: DOMRect, contentWidth: number): boolean {
  const totalWidth = rect.x + rect.width
  return totalWidth > contentWidth
}

/**
 * elements height and bounding rect y > content height indicates
 * that the dropdown is crossing the bottom edge of the viewport
 * and needs to be inverted
 */
export function isOnVerticalViewportEdge ({
  parentContainer,
  elementHeight,
  contentHeight
}: {
  parentContainer: ElementRect;
  elementHeight: number;
  contentHeight: number;
}): boolean {
  const totalHeight = parentContainer.height + parentContainer.top + elementHeight
  return totalHeight > contentHeight
}
