export const createFileUrl = (item: string | File | void): string => {
  if (!item) return ''
  return item instanceof File ? URL.createObjectURL(item) : item
}

export const getFile = (items): File | undefined => {
  if (items instanceof FileList && items.length === 0) {
    return
  }
  return items[0]
}
