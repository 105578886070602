import { Reward } from '@/types'
import { expiryTypes } from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/redemption/constants'

export const rewardDefaults: Reward = {
  active: false,
  promotionCategory: undefined,
  destinationGeotargets: [],
  termsAndConditions: '',
  redemptionMethod: 're',
  redemptionInstructions: '',
  cost: '0',
  value: '0',
  division: 'op',
  merchant: undefined,
  creativeConfigs: [{
    identifier: undefined, // defaults to a uuid if not provided
    thumbnailText: '',
    thumbnailImage: undefined,
    promotedIcon: undefined,
    promotedText: '',
  }],
  onPropertyHotels: [],
  redemptionUrl: '',
  reusablePromoCode: '',
  expiryType: expiryTypes.NO_EXPIRATION,
  redemptionExpiration: undefined,
  requiresNotice: false,
  seasonal: false,
  seasonStart: undefined,
  seasonEnd: undefined,
  specificExpirationDate: undefined,
}

// different default values for retain users
export const rewardDefaultsRetain: Reward = {
  ...rewardDefaults,
  expiryType: expiryTypes.AFTER_CHECK_OUT
}
