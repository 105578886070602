



















import { Campaign, } from '@/types'
import { mapActions } from 'vuex'

const ACTIVE = 'Active'
const INACTIVE = 'Not Active'
const ECM = 'ecm'
const MESSAGES = 'messages'

export type WorkflowStatusStyle = {
  (arg: Campaign): {
    color: '#00C82C' | '';
    marginRight: string;
  };
}

export type WorkflowStatus = {
  (workflow: Campaign): 'Active' | 'Not Active';
}

export default {
  name: 'WorkflowTableContainer',
  props: {
    workflow: {
      type: Object,
      required: true,
      validator (value: Campaign): boolean {
        return 'active' in value && typeof value.active === 'boolean'
      }
    }
  },
  computed: {
    getStatus (): WorkflowStatus {
      return (workflow: Campaign) => {
        return workflow.active ? ACTIVE : INACTIVE
      }
    },
    statusStyle (): WorkflowStatusStyle {
      return (workflow: Campaign) => {
        return {
          color: workflow.active ? '#00C82C' : '',
          marginRight: '10px'
        }
      }
    }
  },
  methods: {
    ...mapActions(ECM, [
      'TOGGLE_CAMPAIGN_ACTIVE'
    ]),
    ...mapActions(MESSAGES, [
      'ADD_SUCCESS',
    ]),
    async toggleWorkflowActiveState (workflow: Campaign): Promise<void> {
      const resp = await this.TOGGLE_CAMPAIGN_ACTIVE({
        id: workflow.id,
        active: workflow.active
      })

      if (!resp.error) {
        const newWorkflowState = resp.data.data.active ? ACTIVE : INACTIVE
        this.ADD_SUCCESS({ message: `Your workflow is now ${newWorkflowState}.` })
      }
    },
  },
}
