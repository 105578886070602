<template>
  <div id="bulk-update-container" :style="{ width: '100%', height: 'auto' }" class="md-content-item pb-12 mb-12">
    <div :style="{ backgroundColor: 'white', height: 'auto', width: '100%' }">
      <md-tabs md-sync-route md-alignment="fixed">
        <template slot="md-tab" slot-scope="{ tab }">
          <span class="tab-title lato-font">{{ tab.label }}</span>
        </template>
        <md-tab
          :to="{ hash: `#${bddHashOptions.DUPLICATION}` }"
          :id="bddHashOptions.DUPLICATION"
          :md-label="copy.leftColTitle"
        >
          <BulkDesignDuplication :editing="true" v-if="isHash(bddHashOptions.DUPLICATION)" />
        </md-tab>
        <md-tab
          :to="{ hash: `#${bddHashOptions.EDITING}` }"
          :id="bddHashOptions.EDITING"
          :md-label="copy.rightColTitle"
        >
          <BulkDesignEditor v-if="isHash(bddHashOptions.EDITING)" />
        </md-tab>
      </md-tabs>
    </div>

    <div id="design-duplication-footer">
      <div class="button-container pt-3">
        <StepperButton
          :copy="copy.cancel"
          @clickEvent="cancel"
          :disabled="false"
          :inverse="true"
        />
        <StepperButton
          :copy="isHash(bddHashOptions.DUPLICATION) ? copy.duplicate : copy.save"
          :disabled="disabled"
          @clickEvent="submit"
        />
      </div>
    </div>

    <DuplicationConfirmationModal
      v-if="showModal"
      @closeDuplicationModal="closeModal"
      @duplicationsuccess="resetFormValues"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BulkDesignDuplication from '@/components/hotel-dashboard/bulk-update/bulk-duplicate/BulkDesignDuplication.vue'
import BulkDesignEditor from '@/components/hotel-dashboard/bulk-update/bulk-edit/BulkDesignEditor.vue'
import DuplicationConfirmationModal from '@/components/hotel-dashboard/bulk-update/bulk-duplicate/DuplicationConfirmationModal.vue'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton.vue'
import { bddHashOptions } from '@/constants'

export default {
  name: 'BulkUpdateContainer',
  components: {
    BulkDesignDuplication,
    BulkDesignEditor,
    StepperButton,
    DuplicationConfirmationModal
  },
  async mounted () {
    this.syncRouteHashToActiveTab()
    const fetchAll = true
    this.$store.dispatch('hotel/FETCH_HOTELS', { fetchAll, pageSize: 50 })
    this.$store.dispatch('hotel/FETCH_HOTEL_GROUPS', { fetchAll, pageSize: 50 })
  },
  data () {
    return {
      bddHashOptions,
      showModal: false,
      copy: {
        duplicate: 'Duplicate',
        save: 'Save',
        cancel: 'Cancel',
        leftColTitle: 'Bulk Design Duplication',
        rightColTitle: 'Bulk Design Editing',
      }
    }
  },
  computed: {
    ...mapGetters('designDuplication', [
      'DUPLICATION_BUTTON_ENABLED',
      'SAVE_BUTTON_ENABLED'
    ]),
    disabled () {
      if (this.isHash(bddHashOptions.DUPLICATION)) {
        return !this.DUPLICATION_BUTTON_ENABLED
      } else {
        return !this.SAVE_BUTTON_ENABLED
      }
    },
  },
  methods: {
    isHash (id) {
      return this.$route.hash === `#${id}`
    },
    syncRouteHashToActiveTab () {
      if (!this.$route.hash) {
        this.$router.replace({
          name: this.$route.name,
          params: this.$route.params,
          hash: `#${bddHashOptions.DUPLICATION}`
        })
      }
    },
    submit () {
      return this.isHash(bddHashOptions.DUPLICATION)
        ? this.submitDesignDuplicate()
        : this.submitBulkEdit()
    },
    cancel () {
      if (this.isHash(bddHashOptions.DUPLICATION)) {
        this.$store.commit('designDuplication/RESET_DESIGN_DUPLICATION')
        this.showModal = false
      } else {
        this.$store.commit('designDuplication/RESET_BULK_EDIT')
      }
    },
    submitDesignDuplicate () {
      this.showModal = true
    },
    async submitBulkEdit () {
      const resp = await this.$store.dispatch('designDuplication/SUBMIT_BULK_EDIT')

      if (!resp.err) this.resetFormValues()
    },
    closeModal () {
      this.showModal = false
    },
    resetFormValues () {
      if (this.isHash(bddHashOptions.DUPLICATION)) {
        this.$store.commit('designDuplication/RESET_DESIGN_DUPLICATION')
      } else {
        this.$store.commit('designDuplication/RESET_BULK_EDIT')
      }
    },
  }
}
</script>

<style lang="sass">
#bulk-update-container
  font-family: Lato !important

  .md-tabs .md-tabs-indicator
    background-color: transparent !important

  // targets disabled tabs; set by vue mat based on click
  a.router-link-active
    background-color: white

  .md-tabs.md-theme-default .md-tabs-navigation
    @apply bg-gray-100

  .md-tabs-navigation.md-elevation-0
    display: flex
    justify-content: space-around
    width: 100%
    > a
      min-width: 50%
      height: 55px
      border: none
    .tab-title
      color: black
      font-weight: 600
      font-size: 16px
      text-transform: none !important

  .md-tabs .md-tabs-indicator
    background-color: transparent !important

  .md-tabs .md-tabs-indicator
    background-color: #e3e3e3

  // removes thin line between steps
  .md-steppers .md-stepper:after
    width: 0px !important

  .md-table-cell-container
    padding: 6px 16px 6px 16px
    margin-left: 6px

  #header-wrapper
    .md-layout-item
      margin-left: 15px

  #design-duplication-container-footer
    display: flex
    width: 100%
    justify-content: flex-end

  .button-container
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    padding-right: 12px
    width: 470px

  #dismiss-button.md-button.md-theme-default
    color: white
</style>
