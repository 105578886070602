



























































































































































































































































































































































import {
  userViews,
  campaignTypeOptions,
  SCHEDULED_SEND,
  campaignStateOptions,
  menuStyles,
} from '@/constants'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import Info from '@/components/common/Info.vue'
import Button from '@/components/button/Button.vue'
import { DateTime as LuxonDateTime } from 'luxon'
import Select from '@/components/select/Select.vue'
import { ErrorObject, EmailDesign, CampaignType, Workflow, Dag } from '@/types'
import { getTotalRewardsOffered } from '@/utils/reward-algos'
import { titleCase } from '@/lib/filters'

export type CampaignTypeOption = {
  label: string;
  value: CampaignType;
}

export type CampaignTriggerOption = {
  label: string;
  value: number;
}

const { TIME, EVENT } = campaignTypeOptions
const { SENT } = campaignStateOptions

export default {
  name: 'CampaignSettings',
  components: {
    Datetime,
    Info,
    Select,
    Button,
  },
  filters: {
    titleCase
  },
  props: {
    hasChanges: {
      type: Boolean,
      required: true
    },
    editing: {
      type: Boolean,
      required: true
    },
    workflow: {
      type: Boolean,
      required: false,
      default: false,
    },
    handleCreateNewRewardAlgo: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      menuStyles,
      show: false,
      eventTypeOptions: [
        {
          label: 'Time',
          value: TIME,
        },
        {
          label: 'Event',
          value: EVENT

        }
      ] as CampaignTypeOption[],
      icon: ['fas', 'exclamation-triangle'],
      copy: {
        headers: {
          edit: 'Edit Your Campaign',
          audience: 'Audience Segment',
          schedule: 'Schedule Send',
          test: 'Test Your Campaign',
          type: 'Campaign Type',
        },
        subheaders: {
          name: 'Campaign Name',
          fromSender: 'From Sender',
          subject: 'Subject',
          buttonUrl: 'Button URL',
          rewardAlgo: 'Reward Algorithm',
          schedule: 'Schedule',
          audience: 'Send To',
          sendTest: 'Send a Test Email',
          required: 'Required',
          datetime: 'Time',
        },
        placeholders: {
          campaignNamePlaceholder: 'Name Your Campaign',
          buttonUrl: 'Add Your URL',
          email: 'Enter Email Address',
          campaignType: 'Choose your campaign type',
          triggerInfo: 'This selection triggers the campaign to send. It can only be set to one event at the moment.',
          eventTriggered: 'Choose your event trigger',
          timeTriggered: 'Choose your time trigger',
        },
        captions: {
          internalOnly: 'This name is internal only.',
          fromSender: 'This is your default sender. If you would like to change it, please contact your CSM.',
          subject: 'This is the subject line that will appear when you send your email. \n\n If you would like to change it please edit your email design.',
          buttonUrl: 'This link will be appended to any mention of the “CTA link” merge tag in the design.',
          rewardAlgo: 'Select which algorithm the campaign will use to determine rewards offered to your guest.',
          audience: 'Select which audience segment(s) will receive this email.',
          schedule: 'Choose whether your email will send immediately, or schedule your message to send at a later time.',
          addUrl: 'Add Your URL',
          selectAlgo: 'Select Algorithm',
          selectAudience: 'Select Audience',
          sendImmediately: 'Send Immediately',
          email: 'Enter up to 10 email addresses, separated by commas.',
          noAlgoAvailable: 'No algorithms available.',
          createOne: 'Create one?',
        },
        warnings: {
          design: 'Please select your email design before editing your campaign.',
          save: 'Please fill out and save your design before testing it.'
        },
        errors: {
          invalidName: 'Please provide a campaign name.',
          invalidCtaLink: 'Please provide a valid CTA link.',
          invalidRewardAlgo: 'Please select a reward algorithm.',
          invalidSegment: 'Please select a segment to send your campaign to.',
          invalidSchedule: 'Please select a campaign sending schedule.',
          missingSender: 'There is no verified send address associated with this account. Please contact your CSM for assistance.',
        },
        buttons: {
          view: 'View',
          sendTestButton: 'Send Test Message'
        }
      }
    }
  },
  computed: {
    ...mapState('ram', [
      'pageInfo',
    ]),
    ...mapGetters('ram', [
      'ALGOS_LIST',
    ]),
    ...mapGetters('ecm', [
      'SEGMENTS_LIST',
      'IS_EVENT_BASED',
      'IS_TIME_BASED',
      'CAMPAIGN_TRIGGER',
      'CAMPAIGN_EVENT_TRIGGERS',
      'TIME_TRIGGERS',
    ]),
    ...mapState('ecm', [
      'campaign',
      'activeNode',
    ]),
    ...mapGetters('edm', [
      'SENDER_EMAIL',
      'GET_EMAIL_DESIGN'
    ]),
    ...mapGetters('edm', [
      'GET_REQUIRES_CTA_LINK',
      'GET_REQUIRES_REWARD_ALGO',
    ]),
    campaignSent (): boolean {
      return this.campaign.state === SENT
    },
    showDatePicker (): boolean {
      return Boolean(this.CAMPAIGN_TRIGGER &&
        this.CAMPAIGN_TRIGGER.name === (SCHEDULED_SEND))
    },
    // TODO replace w/ utils.data.getTimeZone
    timeZone (): string {
      const d = LuxonDateTime.local()
      return `Time Zone: ${d.toFormat("z, ZZZZ 'GMT'ZZ")}`
    },
    emailDesign (): EmailDesign | string {
      const design = this.GET_EMAIL_DESIGN({ id: this.node.emailDesignId })
      return design || ''
    },
    designSelected (): boolean {
      return Boolean(this.node.emailDesignId)
    },
    sendTestInputDisabled (): boolean {
      return !this.designSelected || this.hasChanges
    },
    sendTestButtonDisabled (): boolean {
      return this.testEmailRecipients.length === 0 || !this.SENDER_EMAIL
    },
    nameIsValid (): boolean {
      return Boolean(this.campaign.name)
    },
    ctaLinkIsValid (): boolean {
      if (this.GET_REQUIRES_CTA_LINK({ id: this.node.emailDesignId })) {
        return Boolean(this.node.ctaLink)
      }
      return true
    },
    rewardAlgoIsValid (): boolean {
      // temporarily removing algo validation for https://staywanderful.atlassian.net/browse/SW-3174
      // if (!this.requiresRewardAlgo) {
      //   return true
      // }
      // return Boolean(this.node.rewardAlgoId)
      return true
    },
    segmentIsValid (): boolean {
      return this.campaign.segmentIds.length > 0
    },
    urlCharCount (): number {
      return this.requiresCtaLink ? 500 : 0
    },
    requiresRewardAlgo (): boolean {
      return this.GET_REQUIRES_REWARD_ALGO({ id: this.node.emailDesignId })
    },
    requiresCtaLink (): boolean {
      return this.GET_REQUIRES_CTA_LINK({ id: this.node.emailDesignId })
    },
    buttonUrl: {
      get (): string {
        return this.node.ctaLink
      },
      set (value: string | void): void {
        this.workflow ? this.SET_ACTIVE_NODE_CTA_LINK(value) : this.SET_CTA_LINK(value)
      }
    },
    campaignName: {
      get (): string {
        return this.$store.state.ecm.campaign.name
      },
      set (value: string | void): void {
        this.SET_CAMPAIGN_NAME(value)
      }
    },
    rewardAlgoId: {
      get (): number | void {
        return this.node.rewardAlgoId
      },
      set (value: number | void): void {
        this.workflow ? this.SET_ACTIVE_NODE_ALGO_ID(value) : this.SET_ALGO_ID(value)
      }
    },
    audience: {
      get (): number[] {
        return this.$store.state.ecm.campaign.segmentIds
      },
      set (value: number[]): void {
        this.SET_SEGMENT_IDS(value)
      }
    },
    datetime: {
      get (): string | void {
        return this.campaign.dag.param
      },
      set (value: string): void {
        // remove fractional seconds to match back-end formatting for hasChanges
        const formatted = moment.utc(value).format()
        this.SET_PARAM(formatted)
      }
    },
    testEmailRecipients: {
      get (): string[] {
        return this.$store.state.edm.testEmailRecipients
      },
      set (value: string[]): void {
        this.SET_TEST_EMAIL_RECIPIENTS(value)
      }
    },
    triggerTypeId: {
      get (): string | void {
        return this.CAMPAIGN_TRIGGER && this.CAMPAIGN_TRIGGER.id
      },
      set  (value: number): void {
        this.SET_CAMPAIGN_TRIGGER_TYPE_ID(value)
        this.HANDLE_SEND_IMMEDIATELY_SCHEDULE_DATETIME()
      }
    },
    /**
       * @note this is a superficial data attribute that is used for dropdown
       * render logic.
       * when the campaign is first fetched the type field is inferred
       * from the trigger type.
       * we will change the value in the store strictly for the campaign
       * type dropdown menu but the outgoing create/update payload does not
       * required the type field.
       */
    type: {
      get (): string | void {
        return this.campaign.type
      },
      set (value: string): void {
        this.SET_CAMPAIGN_TYPE(value)
      }
    },
    eventTriggerOptions (): { label: string; option: string }[] {
      return this.CAMPAIGN_EVENT_TRIGGERS.map(trigger => ({
        label: trigger.copy || trigger.name,
        value: trigger.id
      }))
    },
    timeTriggerOptions (): { label: string; option: string }[] {
      return this.TIME_TRIGGERS.map(trigger => ({
        label: trigger.copy || trigger.name,
        value: trigger.id
      }))
    },
    node (): Workflow | Dag {
      if (this.workflow) {
        return this.activeNode
      } else {
        return this.campaign.dag
      }
    }
  },
  watch: {
    /**
     * reset the trigger type id every time the type is toggled
     */
    type (newValue: CampaignType | void, oldValue: CampaignType | void): void {
      if (!oldValue) {
        // do not reset when lifecycle hooks are setting typwe
        return
      }
      if (newValue !== oldValue) {
        this.SET_CAMPAIGN_TRIGGER_TYPE_ID(null)
      }
    }
  },
  async mounted (): Promise<void> {
    await this.setup()
    this.show = true

    if (this.$route.query.fromCampaignTestTable) {
      setTimeout(() => this.scrollToView('#page-bottom', 'nearest'), 0)
    } else {
      // Selected reward algorithm scrolls into view
      setTimeout(() => this.scrollToView('.md-radio.md-checked', 'center'), 0)
    }
  },
  methods: {
    ...mapMutations('edm', [
      'SET_EMAIL_DESIGN',
      'RESET_EMAIL_DESIGN',
      'SET_TEST_EMAIL_RECIPIENTS',
    ]),
    ...mapActions('edm', [
      'SEND_TEST_EMAIL',
      'FETCH_EMAIL_DESIGNS',
      'FETCH_EMAIL_STRING_AND_MERGE',
    ]),
    ...mapMutations('ecm', [
      'SET_ALGO_ID',
      'SET_CAMPAIGN_TRIGGER_TYPE_ID',
      'SET_PARAM',
      'SET_SEGMENT_IDS',
      'SET_CTA_LINK',
      'SET_CAMPAIGN_NAME',
      'SET_CAMPAIGN_TYPE',
      'SET_ACTIVE_NODE_CTA_LINK',
      'SET_ACTIVE_NODE_ALGO_ID'
    ]),
    ...mapActions('ecm', [
      'FETCH_SEGMENTS',
      'SET_DEFAULT_EVENT_TRIGGER_ID',
      'HANDLE_SEND_IMMEDIATELY_SCHEDULE_DATETIME',
    ]),
    ...mapActions('messages', [
      'ADD_ERROR',
    ]),
    ...mapMutations('messages', [
      'CLEAR_MESSAGES',
    ]),
    checkAndDisplayErrors (): ErrorObject[] {
      const errors = []
      if (!this.nameIsValid) {
        errors.push({ message: this.copy.errors.invalidName })
      }
      if (!this.ctaLinkIsValid) {
        errors.push({ message: this.copy.errors.invalidCtaLink })
      }
      if (!this.rewardAlgoIsValid) {
        errors.push({ message: this.copy.errors.invalidRewardAlgo })
      }
      if (errors.length > 0) {
        errors.forEach((error) => this.ADD_ERROR(error))
      }
      return errors
    },
    async sendTestEmail (): Promise<void> {
      this.CLEAR_MESSAGES()
      const errors = this.checkAndDisplayErrors()
      if (errors.length === 0) {
        const emailDesign = this.GET_EMAIL_DESIGN({ id: this.node.emailDesignId })
        this.SET_EMAIL_DESIGN(emailDesign)
        await this.SEND_TEST_EMAIL({ nodeId: this.node.id })
        this.RESET_EMAIL_DESIGN()
      }
    },
    async fetchAllAlgos (): Promise<void> {
      const { page, pageSize } = this.pageInfo
      const fetchAll = true
      const override = false
      await this.$store.dispatch('ram/FETCH_ALGOS', ({ page, pageSize, fetchAll, override }))
    },
    async fetchAllSegments (): Promise<void> {
      await this.$store.dispatch('ecm/FETCH_SEGMENTS', ({ fetchAll: true }))
    },
    scrollToView (selector: string, blockPosition: ScrollLogicalPosition): void {
      const element = document.querySelector(selector)

      if (element) element.scrollIntoView({ behavior: 'smooth', block: blockPosition })
    },
    navToAlgoEdit (id: number): void {
      if (!this.editing) {
        return
      }
      this.$router.push({
        name: userViews.REWARD_ALGOS_WIZARD_EDIT,
        params: {
          algoId: id,
          hotelId: this.$route.params.hotelId
        },
        query: {
          fromEcm: true
        }
      })
    },
    async setup (): Promise<void> {
      if (this.workflow) {
        await this.FETCH_EMAIL_STRING_AND_MERGE({
          id: this.node.emailDesignId,
          url: this.emailDesign.htmlUrl
        })
      }
      if (!this.SENDER_EMAIL) {
        this.ADD_ERROR(new Error(this.copy.errors.missingSender))
      }
      await Promise.all([this.fetchAllAlgos(), this.fetchAllSegments()])
    },
    getRewardsOffered (onPropertyRewards: number | void, marketPlaceRewards: number | void): number {
      return this.getRewardsWithFallback(
        getTotalRewardsOffered({
          onPropertyRewards: this.getRewardsWithFallback(onPropertyRewards),
          marketPlaceRewards: this.getRewardsWithFallback(marketPlaceRewards),
        })
      )
    },
    getRewardsWithFallback (value: number | void): number {
      return value || 0
    }
  },
  async beforeRouteUpdate (to, from, next): Promise<void> {
    this.show = false
    await this.setup()
    this.show = true
    next()
  },
}
