

















import { userViews } from '@/constants'
import CampaignTable from '@/components/hotel-dashboard/campaign-manager/CampaignTable.vue'
import Header from '@/components/common/Header.vue'
import StepperButton from '@/components/hotel-dashboard/on-property-rewards-manager/wizard/shared/StepperButton.vue'

export default {
  name: 'CampaignManagerContainer',
  components: {
    CampaignTable,
    Header,
    StepperButton
  },
  data () {
    return {
      copy: {
        title: 'Campaign Manager',
        button: 'Create a Campaign',
      }
    }
  },
  methods: {
    navToNew (): void {
      this.$router.push({
        name: userViews.CAMPAIGNS_NEW,
        params: this.$route.params
      })
    }
  },
}
