<template lang="html">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="icon-tours-1"
    data-name="icon-tours-1"
    viewBox="0 0 35 35"
  >
    <title>{{ this.name }}</title>
    <desc id="desc">{{ this.name }}</desc>
    <path d="M26.11,25.11A.91.91,0,1,1,27,24.2.91.91,0,0,1,26.11,25.11Z"/>
    <path d="M8.88,25.11a.91.91,0,1,1,.91-.91A.91.91,0,0,1,8.88,25.11Z"/>
    <path d="M24,26.11h0c-.05-.05-.08-.11-.13-.17Z"/>
    <polygon points="3.21 26.11 3.19 26.11 3.19 26.11 3.21 26.11"/>
    <path
      d="M28,26.21l.14-.16a2.78,2.78,0,0,0,.55-.92l0-.07h2.63a.38.38,0,0,0,.38-.38V10.32a.38.38,0,0,0-.38-.38H27V9.36A1.35,1.35,0,0,0,25.63,8H23.24A1.34,1.34,0,0,0,21.9,9.36v.58H12.5V9.36A1.34,1.34,0,0,0,11.16,8H8.88A1.34,1.34,0,0,0,7.54,9.36v.58H3.62a.39.39,0,0,0-.39.38V24.68a.39.39,0,0,0,.39.38H6.25l0,.07a2.78,2.78,0,0,0,.55.92l.14.16H3.23V27H31.76v-.77H28ZM31,20.37v1.05H29.85V20.28H31Zm0-7.66v5.36H28.41V12.62H31ZM22.67,9.84V9.36a.58.58,0,0,1,.57-.57h2.39a.58.58,0,0,1,.58.57v.58H22.67Zm-14.36,0V9.36a.58.58,0,0,1,.57-.57h2.28a.58.58,0,0,1,.57.57v.58H8.31ZM6.11,24.2v.09H4V10.71H31v1.14H28a.39.39,0,0,0-.39.39v6.22a.39.39,0,0,0,.39.38h3v.67H29.46a.38.38,0,0,0-.38.38v1.92a.38.38,0,0,0,.38.38H31v2.1h-2.1V24.2a2.78,2.78,0,1,0-5.55,0v.09H21.23V11.76a.38.38,0,0,0-.38-.39H16.06a.38.38,0,0,0-.38.39V24.29h-4V24.2a2.78,2.78,0,1,0-5.55,0Zm14.26-1.82H16.45V21.23h4v1.15Zm.09.77v1.14h-4V23.15h4Zm-.09-2.68H16.45V12.14h4v8.33ZM8.88,26.21a2,2,0,1,1,2-2A2,2,0,0,1,8.88,26.21Zm15.1,0H10.81l.14-.16a2.78,2.78,0,0,0,.55-.92l0-.07h12l0,.07a2.78,2.78,0,0,0,.55.92l.14.16Zm2.13,0a2,2,0,1,1,2-2A2,2,0,0,1,26.11,26.21Z"
    />
    <path
      d="M8.88,11.37H5.53a.38.38,0,0,0-.38.39V17a.39.39,0,0,0,.38.39H8.88A.39.39,0,0,0,9.27,17V11.76A.38.38,0,0,0,8.88,11.37Zm-.38.87v4.4H5.92v-4.5H8.5Z"/><path d="M14.15,11.37H10.8a.39.39,0,0,0-.39.39V17a.4.4,0,0,0,.39.39h3.35a.39.39,0,0,0,.38-.39V11.76A.38.38,0,0,0,14.15,11.37Zm-.39.87v4.4H11.18v-4.5h2.58Z"/><path d="M26.11,11.37H22.76a.38.38,0,0,0-.38.39V17a.39.39,0,0,0,.38.39h3.35A.39.39,0,0,0,26.5,17V11.76A.38.38,0,0,0,26.11,11.37Zm-.38.87v4.4H23.15v-4.5h2.58Z"/><polygon points="5.05 19.37 5.05 19.37 5.05 19.37 5.05 19.37"
    />
    <rect x="5.15" y="18.55" width="3.64" height="0.77"/>
    <rect x="7.06" y="19.99" width="1.73" height="0.77"/>
    <polygon points="26.59 18.5 26.59 18.51 26.59 18.5 26.59 18.5"/>
    <rect x="22.86" y="18.55" width="3.64" height="0.77"/>
    <polygon points="22.76 19.36 22.76 19.37 22.76 19.37 22.76 19.36"/>
    <polygon points="22.83 18.46 22.81 18.46 22.81 18.46 22.83 18.46"/>
    <rect x="24.77" y="19.99" width="1.73" height="0.77"/>
    <polygon points="24.73 19.89 24.72 19.89 24.72 19.89 24.73 19.89"/>
  </svg>
</template>

<script>
export default {
  name: 'IconTours1',
  data () {
    return {
      name: 'icon tours 1'
    }
  },
}
</script>

<style lang="css">
</style>
