// import Vue from 'vue'
import axios from 'axios'
import endpoints from '@/config/endpoints'
import router from '@/router'
import { MerchantParser, PromotionParser, BookingEngineParser } from '@/parsers'
import { ParserUtils } from '@/parsers/utils'
import { userTypes } from '@/constants'
import { createFormData } from '@/utils/misc'

const UUID = 'uuid'
const ID = 'id'
const NAME = 'name'
const POST = 'post'
const PATCH = 'patch'

export const actions = {
  /**
    * get merchants from mes4 merchants endpoint, commit to store
    * @param {number} seletMerchant - id to set in MerchantPicker selected
    */
  async FETCH_MERCHANTS ({ commit, dispatch, rootState, getters }, selectMerchant = null) {
    const endpoint = endpoints.mesa.selfServeMerchantRoute
    let response
    let error

    try {
      response = await axios.get(endpoint, {
        headers: {
          Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
        }
      })
    } catch (err) {
      error = err.response.data
    }
    console.log('[FETCH MERCHANTS]', response)

    if (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      commit('SET_MERCHANTS_OBJECT',
        ParserUtils.getParsedObject({
          rawList: response.data,
          parser: MerchantParser.parseMerchant,
          indexKey: ID,
        })
      )

      if (selectMerchant) {
        dispatch('SET_MERCHANT_ID', { id: selectMerchant })
      } else if (getters.merchantsList.length) {
        dispatch('SET_MERCHANT_ID', { id: getters.merchantsList[0].id })
      }
    }
  },
  /**
    * get promotions from mes4 self serve promotion endpoint, commit to store
    */
  async FETCH_PROMOTIONS ({ commit, dispatch, rootState, state }) {
    commit('SET_PROMOTIONS_FETCH_SUCCESS', false)
    const endpoint = endpoints.mesa.selfServePromotionRoute + `?merchant=${state.merchantId}`
    let response
    let error

    try {
      response = await axios.get(endpoint, {
        headers: {
          Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
        }
      })
    } catch (err) {
      error = err.response.data
    }
    console.log('[FETCH PROMOTIONS]', response)

    if (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      commit('SET_PROMOTIONS_OBJECT',
        ParserUtils.getParsedObject({
          rawList: response.data,
          parser: PromotionParser.parsePromotion,
          indexKey: UUID,
        })
      )
      commit('SET_PROMOTIONS_FETCH_SUCCESS', true)
    }
  },
  /**
    * PATCH will update the given field(s) on the promotion object
    * @param {string} uuid - used to look up merchant in store
    * @param {object} properties fields on merchant to add to payload
    */
  async UPDATE_PROMOTION (
    { rootState, commit, getters, dispatch },
    { uuid, properties, nextRoute }
  ) {
    const endpoint = `${endpoints.mesa.selfServePromotionRoute}${uuid}/`
    const rawPayload = getters.promotionPatchPayload({ properties })
    const payload = createFormData(rawPayload)

    let response
    let error

    try {
      response = await axios.patch(endpoint,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    } catch (err) {
      error = err.response.data
    }

    if (error) {
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      commit('SET_PROMOTION', PromotionParser.parsePromotion(response.data))
      const refreshSession = await rootState.authController.refreshSession()
      if (refreshSession.error) {
        return dispatch('messages/ADD_ERROR', refreshSession.error, { root: true })
      }
      if (nextRoute) {
        router.push({
          name: nextRoute.name,
          params: nextRoute.params || rootState.route.params,
        })
      }
    }
  },
  /**
    * POST will create the promotion object
    * @param {object} properties fields on merchant to add to payload
    */
  async CREATE_PROMOTION (
    { rootState, commit, getters, dispatch }, { properties, nextRoute }
  ) {
    const endpoint = `${endpoints.mesa.selfServePromotionRoute}`
    const rawPayload = getters.promotionPatchPayload({ properties })
    const payload = createFormData(rawPayload)

    let response
    let error

    try {
      response = await axios.post(endpoint,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    } catch (err) {
      error = err.response.data
    }

    console.log('[CREATE_PROMOTION]', response)

    if (error) {
      console.log(error)
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      commit('SET_PROMOTION', PromotionParser.parsePromotion(response.data))
      const refreshSession = await rootState.authController.refreshSession()
      if (refreshSession.error) {
        return dispatch('messages/ADD_ERROR', refreshSession.error, { root: true })
      }
      if (nextRoute) {
        router.push({
          name: nextRoute.name,
          params: rootState.route.params
        })
      }
    }
  },

  SET_MERCHANT_ID ({ getters, commit }, { id }) {
    if (getters.getMerchant({ id })) {
      commit('SET_MERCHANT_ID', id)
    } else {
      console.log('[SET_MERCHANT_ID] merchant does not exist')
    }
  },

  async FETCH_REGIONS ({ rootState, commit, dispatch }, { search, isNational = false }) {
    const queryParam = (search + '').toLowerCase()

    let endpoint
    let response
    let error

    isNational
      ? endpoint = `${endpoints.mesa.regionsRoute}?search=${queryParam}&type__in=country,state`
      : endpoint = `${endpoints.mesa.regionsRoute}?search=${queryParam}&type__in=city,state`

    console.log('[FETCH_REGIONS] endpoint', endpoint)

    try {
      response = await axios.get(endpoint,
        {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
          }
        }
      )
    } catch (err) {
      error = err.response.data
    }

    if (error) {
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      commit('SET_REGIONS_LIST', response.data)
    }
  },

  async FETCH_BOOKING_ENGINES ({ rootState, commit, dispatch }) {
    const endpoint = endpoints.mesa.bookingEnginesRoute

    let response
    let error

    try {
      response = await axios.get(endpoint,
        {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
          }
        }
      )
    } catch (err) {
      error = err.reponse.data
    }

    console.log('[FETCH_BOOKING_ENGINES]', response.data)
    if (error) {
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      commit(
        'SET_BOOKING_ENGINES_OBJ',
        ParserUtils.getParsedObject({
          rawList: response.data,
          parser: BookingEngineParser.parseBookingEngine,
          indexKey: NAME,
        })
      )
    }
  },

  /**
    * can create or update a merchant. after create will update user's
    * merchant_ids list on the 'custom:profile' attribute in cognito user pool
    * and refresh user's merchants in store
    * @param {object} merchant
    * @param {object} nextRoute optional arg to push the route after successful request
    */
  async CREATE_OR_UPDATE_MERCHANT (
    { rootState, dispatch, getters }, { merchant, nextRoute }
  ) {
    let METHOD
    let endpoint = endpoints.mesa.selfServeMerchantRoute
    if (merchant.id === undefined) {
      METHOD = POST
    } else {
      METHOD = PATCH
      endpoint = `${endpoint}${merchant.id}/`
    }
    const payload = getters.merchantPayload({ merchant })
    console.log(
      '[CREATE_OR_UPDATE_MERCHANT] payload',
      JSON.stringify(payload, null, 2)
    )

    let response
    let error

    try {
      response = await axios[METHOD](
        endpoint,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
          }
        }
      )
    } catch (err) {
      error = err.reponse.data
    }
    console.log('[CREATE_OR_UPDATE_MERCHANT]', response)

    if (error) {
      dispatch('messages/ADD_ERROR', error, { root: true })
    } else {
      if (METHOD === POST) {
        const updateResponse = await dispatch(
          'UPDATE_COGNITO_USER_MERCHANT_IDS',
          {
            userProfile: rootState.userProfile,
            merchantIds: [
              ...rootState.userProfile.merchantIds.filter(id => id !== 0),
              response.data.id,
            ]
          }
        )
        if (updateResponse.error) {
          return dispatch('messages/ADD_ERROR', updateResponse.error, { root: true })
        }
        const refreshSession = await rootState.authController.refreshSession()
        if (refreshSession.error) {
          return dispatch('messages/ADD_ERROR', refreshSession.error, { root: true })
        }
        await dispatch('SET_USER_PROFILE', null, { root: true })
      }

      await dispatch('FETCH_MERCHANTS', response.data.id)
      if (nextRoute) {
        router.push({
          name: nextRoute.name,
          params: nextRoute.params || rootState.route.params,
        })
      }
    }
  },

  /**
    * update cognito user's merchant_ids list in the custom:profile attr
    * @param {array} merchantIds - appended to Cognito User's merchant_ids
    * NOTE will not work with user.hotels or user.group
    * @return {object} { error, data }
    */
  async UPDATE_COGNITO_USER_MERCHANT_IDS ({ rootState }, { merchantIds, userProfile }) {
    // indicates that we're using form to reset merchant_ids on user
    const isResetMerchantUser = merchantIds.indexOf(0) > -1
    const merchantIdsPayload = isResetMerchantUser
      ? merchantIds.filter(id => id === 0)
      : merchantIds
    const user = {
      username: userProfile.username,
      attributes: {
        customProfile: {
          ...userProfile,
          merchantIds: merchantIdsPayload,
        }
      }
    }
    const updateResponse = await rootState.authController.updateUserAttributes({
      user,
      isAdminEditing: rootState.userProfile.type === userTypes.ADMIN
    })
    console.log('[CREATE_OR_UPDATE_MERCHANT] updateResponse', updateResponse)
    return updateResponse
  },
}
