
































































































import { mapState } from 'vuex'
import Button from '@/components/button/Button.vue'
import { Tab, InitialTab } from '@/types'
import { FALSY } from '@/constants'

export type TabGroup = {
  totalWidth: number;
  tabs: number[];
}

export default {
  name: 'Tabs',
  components: {
    Button,
  },
  provide () {
    return {
      Tabs__registerTab: this.registerTab,
      Tabs__isActive: this.isActive,
      Tabs__updateTab: this.updateTab,
      Tabs__getIdx: this.getIdx,
      Tabs__tabIdx: this.tabIdx,
    }
  },
  props: {
    /**
     * set explicit height for container.
     * default will allow content length of each tab
     * to determine the height.
     */
    height: {
      type: [Number, String],
      required: false,
      default: 'auto'
    },
    /**
     * set the explicit width of container.
     * default will fill parent's width.
     */
    width: {
      type: [Number, String],
      required: false,
      default: 'auto'
    },
    /**
     * limit the length of titles. will be trimmed with an ellipses on the end.
     */
    titleCharLimit: {
      type: Number,
      default: 100,
      required: false,
    },
    /**
     * can set the initial value of the current tab.
     */
    activeTab: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      timeoutId: undefined as number | void, // used to fire event at end of resize event
      show: false,
      /**
       * tabs are grouped based on the width of the title-container and how many
       * tab titles will fit within that space. the tapGroupIdx is the one in view.
       */
      tabGroupIdx: 0,
      tabGroups: [] as TabGroup[],
      /**
       * initially undefined but set when mounted. need to eval activeTab prop
       * to determine if there is a predetermined active tab.
       */
      tabIdx: undefined as number | void,
      tabs: [] as Tab[],
      icons: {
        prev: ['fas', 'chevron-left'],
        next: ['fas', 'chevron-right'],
      },
    }
  },
  computed: {
    ...mapState([
      'drawerExpandedState'
    ]),
    visibleTabIsInVisibleTabGroup (): boolean {
      return this.tabsInViewIdxs.indexOf(this.tabIdx) > -1
    },
    visibleTab (): Tab {
      return this.tabs[this.tabIdx] || {}
    },
    numberOfTabs (): number {
      return this.tabs.length
    },
    visibleTabGroup (): TabGroup {
      return this.tabGroups[this.tabGroupIdx]
    },
    tabsInViewIdxs (): number[] {
      const group = this.visibleTabGroup
      return (group && group.tabs) || []
    },
    showNext (): boolean {
      const { length } = this.tabGroups
      if (length === 1) return false
      return this.tabGroupIdx < (length - 1)
    },
    showPrev (): boolean {
      if (this.tabGroups.length === 1) return false
      return this.tabGroupIdx >= 1
    },
    computedWidth (): string | number {
      if (typeof this.width === 'number') {
        return `${this.width}px`
      }
      return this.width
    },
    computedHeight (): string | number {
      if (typeof this.height === 'number') {
        return `${this.height}px`
      }
      return this.height
    },
  },
  watch: {
    drawerExpandedState (newValue: boolean, oldValue: boolean): void {
      if (newValue !== oldValue) {
        setTimeout(this.reorganize, 500)
      }
    },
    async tabIdx (newValue: number, oldValue: number): Promise<void> {
      if (newValue !== oldValue) {
        await this.$nextTick()
        this.positionSlider()
      }
    },
    activeTab (activeTab: number): void {
      this.tab = activeTab
    },
    async tabGroupIdx (newValue: number, oldValue: number): Promise<void> {
      if (newValue !== oldValue) {
        this.setTitleVisibility()
        await this.$nextTick()
        this.positionSlider()
      }
    },
  },
  async mounted (): Promise<void> {
    window.addEventListener('resize', this.handleResize)
    // override tabIdx with prop
    if (typeof this.activeTab === 'number') {
      this.tabIdx = this.activeTab
    }
    await this.$nextTick()
    this.show = true
    // wait one tick to ensure tabs' width can be calculated.
    await this.$nextTick()
    this.organize()
  },
  beforeDestroy (): void {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    titleClasses (idx: number): string[] {
      const classes = [
        'font-bold',
        'text-sm',
        'px-6',
        'transition',
        'duration-200',
        'ease-in-out',
      ]
      if (this.isActiveIdx(idx)) {
        classes.push('text-brown-200')
      } else {
        classes.push('text-gray-300 hover:text-gray-400')
      }
      return classes
    },
    titleOpacity (tab: Tab): string {
      return tab.disabled ? '.4' : '1'
    },
    handleResize (): void {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(this.reorganize, 500)
    },
    /**
     * if the viewport changes and groups are reorganized
     * the tab title that was in view may be in a new group.
     * this method will find that new group and update the tabGroupIdx
     * so that it remains in view for the user.
     */
    syncGroupIdx (): void {
      if (this.tabsInViewIdxs.indexOf(this.tabIdx) === -1) {
        const tabGroupIdx: number = this.tabGroups
          .findIndex((group: TabGroup): boolean => {
            return group.tabs.indexOf(this.tabIdx) > -1
          })
        this.tabGroupIdx = tabGroupIdx
      }
    },
    async reorganize (): Promise<void> {
      /**
         * briefly render all titles so that the clientWidth of the
         * titles can be determined before regrouping the titles, otherwise
         * the clientWidth is 0.
         */
      this.showAllTitles()
      await this.$nextTick()
      this.organize()
    },
    async organize (): Promise<void> {
      this.groupTabTitles()
      this.setTitleVisibility()
      await this.$nextTick()
      this.syncGroupIdx()
      await this.$nextTick()
      this.positionSlider()
    },
    /**
     * removed undefined ref on new tab and preserve
     * ref from old tab. use Vue.set here.
     */
    updateTab (idx: number, newTab: InitialTab): void {
      const oldTab = this.tabs[idx]
      const _newTab = { ...newTab }
      delete _newTab.ref
      this.$set(this.tabs, idx, { ...oldTab, ..._newTab })
    },
    getIdx (tab: Tab): number {
      return this.tabs.findIndex((_tab: Tab) => {
        return tab.title === _tab.title
      })
    },
    getTitle (title: string | void) {
      if (!title) return ''
      if (title.length > this.titleCharLimit) {
        const abbrev = title.slice(0, this.titleCharLimit)
        return `${abbrev}...`
      }
      return title
    },
    registerTab (tab: Tab): void {
      const matches = this.tabs.filter(_tab => tab.title === _tab.title)
      if (matches.length > 0) {
        console.log('Could not register duplicate tab with id:', tab.title)
        return
      }

      const idx = this.tabs.length
      this.tabs.push({
        ...tab,
        ref: `tab-title-${idx}`
      })
    },
    isActive (tab: Tab): boolean {
      return tab.title === this.visibleTab?.title
    },
    isActiveIdx (idx: number): boolean {
      return idx === this.tabIdx
    },
    setTab (idx: number): void {
      const tab = this.tabs[idx]
      if (tab.disabled) {
        console.log('[setTab] tab is disabled', tab.title)
        return
      }
      this.tabIdx = idx
    },
    async positionSlider (): Promise<void> {
      if (FALSY.includes(this.tabIdx)) {
        return
      }
      if (!this.visibleTabIsInVisibleTabGroup) {
        return
      }
      const ref = `tab-title-${this.tabIdx}`
      const titles = this.$refs[ref]
      if (Array.isArray(titles)) {
        const active = titles[0]
        const { left: parentLeft } = active.offsetParent.getBoundingClientRect()
        const { left, width } = active.getBoundingClientRect()
        const { slider } = this.$refs
        slider.style.left = `${left - parentLeft}px`
        slider.style.width = `${width}px`
      }
    },
    groupTabTitles (): void {
      const container = this.$refs['tabs-navigation']
      if (!container) {
        console.log('[groupTabTitles] titles-container ref not in DOM')
        return
      }
      const BUTTON_CONTAINERS_COMBINED_WIDTH = 48
      const maxWidth = container.clientWidth - BUTTON_CONTAINERS_COMBINED_WIDTH

      /**
       * each group's combined totalWidth must not exceed offsetWidth.
       * before adding a tab title to a group check to see if adding
       * it's offsetWidth to group's totalWidth will be greater
       * than the parent container's offsetWidth.
       * if it will not fit start new group.
       */

      this.tabGroups = [{ totalWidth: 0, tabs: [] }]

      let groupIdx = 0

      this.tabs.forEach((tab, idx) => {
        const titleWidth = this.$refs[tab.ref][0].offsetWidth

        const group = this.tabGroups[groupIdx]

        const potentialNewWidth = titleWidth + group.totalWidth

        const wontFit = (potentialNewWidth) >= maxWidth

        if (wontFit) {
          // start new group
          this.tabGroups[groupIdx + 1] = { totalWidth: 0, tabs: [] }
          this.tabGroups[groupIdx + 1].tabs.push(idx)
          this.tabGroups[groupIdx + 1].totalWidth += titleWidth
          groupIdx++
        } else {
          // add to current group
          group.tabs.push(idx)
          // increase current group's totalWidth
          group.totalWidth += titleWidth
        }
      })
    },
    showAllTitles (): void {
      this.tabs.forEach((tab: Tab): void => {
        const refs = this.$refs[tab.ref]
        if (!Array.isArray(refs) || !refs[0]) {
          console.log('[showAllTitles] could not find ref to show:', tab.ref)
          return
        }
        refs[0].style.display = 'flex'
      })
    },
    setTitleVisibility (): void {
      /**
       * check visisble group index
       * iterate over all tab titles via refs
       * if the idx is not in the visible group's title idx list then set display=none
       */
      this.tabs.forEach((tab: Tab, idx: number): void => {
        const refs = this.$refs[tab.ref]

        if (!Array.isArray(refs) || !refs[0]) {
          console.log('[setTitleVisibility] could not find ref', tab.ref)
          return
        }

        if (this.tabsInViewIdxs.indexOf(idx) === -1) {
          refs[0].style.display = 'none'
        } else {
          refs[0].style.display = 'flex'
        }
      })
    },
  }

}
